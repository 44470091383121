import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
} from '@chakra-ui/react';
import * as turf from '@turf/turf';
export const boundaryId = 'boundary';

import { closeModal, saveBoundary } from 'libs/global/slices/sets';
import { selectSpatialFilter } from 'libs/global/selectors/sets';

import { BodyTextSecondary } from '../components';
import { Warning } from 'phosphor-react';
import { useAppDispatch, useAppSelector } from 'libs/global/hooks';

const BoundaryModal = ({ isOpen, boundaryId }: { isOpen: boolean; boundaryId?: string | null }) => {
    const dispatch = useAppDispatch();
    const [name, setName] = useState('');
    const boundaries = useAppSelector((state) => state.sets.savedBoundaries);

    const [encodedLayer, setEncodedLayer] = useState<string>();
    const [description, setDescription] = useState<string>('');
    const spatialFilter = useAppSelector((state) => selectSpatialFilter(state, 'potential-listing-points'));
    const boundary = boundaries.find((boundary) => boundary.id === boundaryId);

    useEffect(() => {
        if (boundaryId) {
            if (boundary) {
                setName(boundary.name);
                setDescription(boundary.description);
                setEncodedLayer(JSON.stringify(boundary.boundary));
            }
            return;
        }

        const simplifiedCoords: [number, number][] = [];
        turf.coordEach(spatialFilter, (currentCoord) => {
            simplifiedCoords.push([parseFloat(currentCoord[0].toFixed(5)), parseFloat(currentCoord[1].toFixed(5))]);
        });
        const newSpatialFilter = {
            type: 'FeatureCollection',
            features: [
                {
                    ...spatialFilter,
                    geometry: {
                        coordinates: [simplifiedCoords],
                        type: 'Polygon',
                    },
                },
            ],
        };

        setEncodedLayer(JSON.stringify(newSpatialFilter));
    }, [spatialFilter, boundaryId, boundary]);

    const generateStaticImageURL = async () => {
        const mapboxURL = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/geojson(${encodedLayer})/auto/300x300@2x?before_layer=bridge-primary-case&access_token=pk.eyJ1IjoicGhpbGtleXMiLCJhIjoiY2owYmFwdTk2MDJ3NjMybzJxNndwbWxyayJ9.snc2_isAmGSK14P2nwD-FA`;
        try {
            const response = await fetch(mapboxURL);
            if (!response.ok) {
                throw new Error('Failed to fetch map image');
            }

            return response.url;
        } catch (error) {
            console.error(error);
        }
    };

    const onSave = async () => {
        const date = new Date();
        if (!boundaryId) {
            const image = await generateStaticImageURL();

            dispatch(
                saveBoundary({
                    id: `set_${Math.floor(Math.random() * 1000)}`,
                    name,
                    boundary: spatialFilter,
                    description,
                    created_at: date,
                    updated_at: date,
                    image,
                }),
            );
        } else {
            dispatch(
                saveBoundary({
                    ...boundary!,
                    name,
                    description,
                    updated_at: date,
                }),
            );
        }

        dispatch(closeModal());
    };

    return (
        <Modal isOpen={isOpen} onClose={() => dispatch(closeModal())} isCentered={true}>
            <ModalOverlay />
            <ModalContent pt={3} rounded="lg">
                <ModalHeader gap={4} display="flex" flexDirection="column">
                    <Flex alignItems="center" gap={4}>
                        <Flex
                            borderBottom="1px"
                            borderRight="1px"
                            borderColor="gray.100A"
                            overflow="hidden"
                            position="relative"
                            rounded="lg"
                            bg="gray.100A"
                            w={'42px'}
                            h={'42px'}>
                            <Box h="full" w="100%" bg="#B3E4F4" />
                            <Box h="full" w="100%" bg="#DCF2CD" />
                            <Box h="full" w="100%" bg="#F9F4F2" />
                            <Box rounded="sm" border="1px" borderColor="gray.300A" position="absolute" top={2} right={2} left={2} bottom={2} bg="gray.300A" />
                        </Flex>
                        <Box>
                            <Text fontSize="lg">Save a new boundary</Text>
                            <BodyTextSecondary>Great for frequently used boundaries</BodyTextSecondary>
                        </Box>
                    </Flex>
                    {encodedLayer === null ? (
                        <Flex
                            boxShadow="0 0 0 3px rgba(231, 100, 100, 0.1)"
                            bg="gray.25A"
                            rounded="sm"
                            alignItems="center"
                            gap={2}
                            py={2}
                            px={3}
                            border="1px solid"
                            borderColor="red.200A">
                            <Icon color="red.300" boxSize={5} as={Warning} />
                            <Text letterSpacing="normal" fontSize="13px" fontWeight={400} color="red.700">
                                Before saving you need to create a boundary.
                            </Text>
                        </Flex>
                    ) : null}
                </ModalHeader>
                <ModalCloseButton top={4} />
                <ModalBody py={0}>
                    <Stack>
                        <FormControl>
                            <FormLabel fontSize="13px" fontWeight={450} mb={1} color="gray.700">
                                Name*
                            </FormLabel>
                            <Input
                                isDisabled={encodedLayer == null}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                size="lg"
                                fontSize="13px"
                                rounded="sm"
                                borderColor="gray.100"
                                type="text"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontSize="13px" fontWeight={450} mb={1} color="gray.700">
                                Description
                            </FormLabel>
                            <Input
                                isDisabled={encodedLayer == null}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                size="lg"
                                fontSize="13px"
                                rounded="sm"
                                borderColor="gray.100"
                                type="text"
                            />
                        </FormControl>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button variant="secondary" onClick={onSave} isDisabled={!name.length}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default BoundaryModal;
