'use client';

import React from 'react';
import { LinkForChakra } from 'libs/global/components/NextConversionLink';
import styled from '@emotion/styled';
import { Box, BoxProps, Button, Menu, MenuButton, MenuButtonProps, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { formatMoney } from 'libs/global/components/Pro/utils';
import { LEFT_MENU_WIDTH } from 'libs/constants';
import { css } from '@emotion/react';
import { theme } from 'libs/wheelhouse_design/theme';

const mixin = {
    scrollableY: css`
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    `,
    customScrollbar: ({ width = 8, background = theme.colors.gray['300'] } = {}) => css`
        &::-webkit-scrollbar {
            width: ${width}px;
            height: ${width}px;
        }
        &::-webkit-scrollbar-track {
            background: none;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 99px;
            background: ${background};
        }
    `,
};

export const Sidebar = styled(Box)`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${LEFT_MENU_WIDTH}px;
    ${mixin.scrollableY};
    ${mixin.customScrollbar()};
    @media (max-width: 767px) {
        display: none;
    }
`;

export const StyledLink = (props: any) => (
    <LinkForChakra
        fontSize="md"
        color="purple.500"
        _active={{ outline: 'none' }}
        _focus={{ outline: 'none' }}
        _hover={{ textDecoration: 'none', color: 'purple.500' }}
        {...props}
    />
);

interface NavigationDropdownProps extends MenuButtonProps {
    title: string;
}
export const NavigationDropdown = ({ title, children, style, ...props }: NavigationDropdownProps) => {
    return (
        <Menu>
            <MenuButton as={Button} variant="ghost" rightIcon={<ChevronDownIcon />} style={style} {...props}>
                {title}
            </MenuButton>
            <MenuList>{children}</MenuList>
        </Menu>
    );
};

export const MetricContainer = React.forwardRef((props: BoxProps, ref) => (
    <Box ref={ref} width="100%" maxWidth="1280px" mx="auto" bg="white" p={6} rounded="md" {...props} />
));

export function formatY(format?: string, value?: number | string, currency?: string | undefined, toFixed: boolean = false): string {
    if (!value || value == '') {
        if (value != 0) {
            return '-';
        }
    }
    const numeric_value = parseFloat(value.toString());
    switch (format) {
        case 'percent':
            return `${numeric_value.toFixed(0)}%`;
        case 'currency':
            return `${formatMoney(numeric_value.toFixed(0), currency)}`;
        case 'days':
            return `${toFixed ? numeric_value.toFixed(1) : numeric_value.toFixed(0)} days`;
        case 'nights':
            return `${toFixed ? numeric_value.toFixed(1) : numeric_value.toFixed(0)} nights`;
        case 'listings':
            return `${toFixed ? numeric_value.toFixed(1) : numeric_value.toFixed(0)} listings`;
        case 'rooms':
            return `${numeric_value.toFixed(0)} rooms`;
        default:
            return numeric_value.toString();
    }
}
