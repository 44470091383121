'use client';

import React from 'react';
import Link from 'next/link';
import { Button, Link as ChakraLink, forwardRef, LinkProps } from '@chakra-ui/react';

type Props = {
    to?: string;
    href?: string;
    children?: React.ReactNode;
    locale?: string;
};

const NextConversionLink = ({ to, href, children, locale = 'true', ...rest }: Props & LinkProps) => (
    <Link href={to || href || ''} passHref={true} legacyBehavior={true} locale={locale}>
        <ChakraLink {...rest}>{children}</ChakraLink>
    </Link>
);

export const NextConversionLinkAsButton = forwardRef(({ to, href, children, locale = 'true', isDisabled, ...rest }, ref) => {
    if (isDisabled) {
        return (
            <Button ref={ref} isDisabled={isDisabled} _hover={{ textDecoration: 'none' }} {...rest} cursor="pointer">
                {children}
            </Button>
        );
    } else {
        return (
            <Link href={to || href || ''} passHref={true} legacyBehavior={true} locale={locale}>
                <Button as={ChakraLink} ref={ref} _hover={{ textDecoration: 'none' }} {...rest} cursor="pointer">
                    {children}
                </Button>
            </Link>
        );
    }
});

/**
 * This component should be used when setting the "as" prop for chakra.
 */
export const LinkForChakra = forwardRef((props, ref) => <NextConversionLink {...props} />);

export default NextConversionLink;
