import { flatten, uniq } from 'lodash';
import { ACCESS_LEVEL_OWNED, ACCESS_NAME_BY_LEVEL } from 'libs/constants';
import CustomAccessHeader from './BulkListingAccess';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { FilterCategoryTypes } from 'libs/types/meta';

export const LEVEL_READ = 'R';
export const LEVEL_FULL = 'F';
export const LEVEL_NOT = 'N';
const ACCESS_LEVELS = [LEVEL_FULL, LEVEL_READ];

export const NAME_BY_LEVEL = {
    [LEVEL_FULL]: 'Editor',
    [LEVEL_READ]: 'Viewer',
};
const PARAM_KEY_BY_LEVEL = {
    [LEVEL_FULL]: 'listing_views.number_of_editors',
    [LEVEL_READ]: 'listing_views.number_of_viewers',
};

const colIdByLevel = (level: string) => {
    return `access_${level}`;
};

const accessColumnByLevel = (level: string): ColDef & { paramKey: string; categoryId?: FilterCategoryTypes } => {
    return {
        colId: colIdByLevel(level),
        paramKey: PARAM_KEY_BY_LEVEL[level],
        headerName: `${NAME_BY_LEVEL[level]}s`,
        headerComponent: CustomAccessHeader,
        headerComponentParams: {
            level: level,
        },
        width: 100,
        hide: true,
        pinned: true,
        editable: false,
        sortable: true,
        chartDataType: 'excluded',
        suppressHeaderMenuButton: true,
        cellRenderer: 'editAccessCellRenderer',
        cellClass: 'tags',
        cellStyle: () => ({
            padding: 0,
        }),
        valueGetter: (params) => {
            return uniq(params?.data?.managed_listings?.filter((m) => m.level == level).map((l) => l.user_id) || []);
        },
    };
};

const accessColumnDefs: (ColGroupDef & { categoryId?: FilterCategoryTypes })[] = [
    {
        headerName: 'Teams',
        children: [
            {
                colId: 'access_level',
                headerName: 'Your Access',
                width: 60,
                hide: true,
                pinned: true,
                editable: false,
                sortable: false,
                chartDataType: 'excluded',
                suppressHeaderMenuButton: true,
                valueGetter: (params) => {
                    const userId = params?.context?.userId;
                    const isOwned = params?.data?.access_level == ACCESS_LEVEL_OWNED;
                    if (isOwned) {
                        return ACCESS_LEVEL_OWNED;
                    }
                    return params?.data?.managed_listings?.find(({ user_id }) => user_id == userId)?.level;
                },
                valueFormatter: (params) => {
                    return ACCESS_NAME_BY_LEVEL[params.value] || NAME_BY_LEVEL[params.value];
                },
            },
            {
                colId: 'listings.operating_user_id',
                field: 'operating_user_id',
                headerName: 'Admin',
                width: 60,
                hide: true,
                pinned: true,
                editable: false,
                sortable: false,
                chartDataType: 'excluded',
                suppressHeaderMenuButton: true,
                cellRenderer: 'adminCellRenderer',
            },
            ...ACCESS_LEVELS.map((level) => accessColumnByLevel(level)),
        ],
        groupId: 'sub_users',
        categoryId: 'listing_status',
    },
];

export const accessColIds = flatten(
    accessColumnDefs.map((columnGroup) => {
        return columnGroup.children?.map((column: ColDef) => column.colId);
    }),
).filter((colId) => colId) as string[];

export default accessColumnDefs;
