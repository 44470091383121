import { useMemo } from 'react';
import { toast } from 'sonner';
import fetcher from 'libs/utils/fetcher';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { inviteSubUserPath, membershipsPath, subusersPath } from 'libs/routes';
import { del, post } from 'libs/requests';
import { useAppSelector } from '../hooks';
import { SubUser } from 'libs/types/subUser';

export const useSubUsers = () => {
    const userId = useAppSelector(({ user }) => user?.id);
    const url = subusersPath(userId);
    return useQuery({
        queryKey: [url],
        queryFn: async () => {
            const data: SubUser[] = await fetcher(url);
            return data;
        },
        enabled: Boolean(userId),
    });
};

export const useActiveSubUsers = () => {
    const { data: subUsers } = useSubUsers();
    return useMemo(() => {
        return subUsers?.filter((subUser) => subUser.status == 'A') || [];
    }, [subUsers]);
};

export const useMemberships = () => {
    const userId = useAppSelector(({ user }) => user?.id);
    const url = membershipsPath(userId);
    return useQuery({
        queryKey: [url],
        queryFn: async () => {
            const data: SubUser[] = await fetcher(url);
            return data;
        },
        enabled: Boolean(userId),
    });
};

export const useSendInvite = () => {
    const userId = useAppSelector(({ user }) => user?.id);
    const url = inviteSubUserPath(userId);
    const refreshSubUsers = useRefreshSubUsers();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ email, billing_delegated }: { email: string; billing_delegated: boolean | undefined }) => {
            return post(url, { email, billing_delegated });
        },
        onMutate: ({ email, billing_delegated }: { email: string; billing_delegated: boolean | undefined }) => {
            // Cancel outgoing refetches and snapshot previous value
            const queryKey = [subusersPath(userId)];
            queryClient.cancelQueries({
                queryKey: queryKey,
            });
            const previousState = queryClient.getQueryData(queryKey);

            // Optimistically update to the new value
            queryClient.setQueryData(queryKey, (old: SubUser[]) => {
                return [...old, { subuser_email: email, status: 'P' }];
            });

            // Return the snapshotted value
            return () => queryClient.setQueryData(queryKey, previousState);
        },
        onSettled: () => {
            refreshSubUsers();
        },
        onError: (error, variables, rollback) => {
            rollback?.();
        },
    });
};

export const useForceAcceptInvite = () => {
    const refreshSubUsers = useRefreshSubUsers();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ user_id, sub_user_id }: { user_id: number; sub_user_id: number }) => {
            return post(membershipsPath(sub_user_id) + '/' + user_id);
        },
        onMutate: ({ user_id, sub_user_id }: { user_id: number; sub_user_id: number }) => {
            // Cancel outgoing refetches and snapshot previous value
            const queryKey = [subusersPath(user_id)];
            queryClient.cancelQueries({
                queryKey: queryKey,
            });
            const previousState = queryClient.getQueryData(queryKey);

            // Optimistically update to the new value
            queryClient.setQueryData(queryKey, (old: SubUser[]) => {
                return old.map((su) => {
                    if (su.status == 'A' || su.user_id != user_id || su.sub_user_id != sub_user_id) {
                        return su;
                    }
                    return { ...su, status: 'A' };
                });
            });

            // Return a rollback function.
            return () => queryClient.setQueryData(queryKey, previousState);
        },
        onSettled: () => {
            refreshSubUsers();
        },
        onError: (error, variables, rollback) => {
            rollback?.();
        },
    });
};

export const useAcceptInvite = () => {
    const refreshMemberships = useRefreshMemberships();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ user_id, sub_user_id }: SubUser) => {
            return post(membershipsPath(sub_user_id) + '/' + user_id);
        },
        onMutate: ({ user_id, sub_user_id }) => {
            // Cancel outgoing refetches and snapshot previous value
            const queryKey = [membershipsPath(sub_user_id)];
            queryClient.cancelQueries({
                queryKey: queryKey,
            });
            const previousState = queryClient.getQueryData(queryKey);

            // Optimistically update to the new value
            queryClient.setQueryData(queryKey, (old: SubUser[]) => {
                return old.map((su) => {
                    if (su.user_id == user_id && su.sub_user_id == sub_user_id) {
                        return { ...su, status: 'A' };
                    } else {
                        return su;
                    }
                });
            });

            // Return a rollback function.
            return () => queryClient.setQueryData(queryKey, previousState);
        },
        onSettled: () => {
            refreshMemberships();
        },
        onError: (_error, _variables, rollback) => {
            toast.error('Failed to accept invite. Please try again or contact support.');
            rollback?.();
        },
    });
};

export const useLeaveTeam = () => {
    const refreshMemberships = useRefreshMemberships();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ user_id, sub_user_id }: SubUser) => {
            return del(membershipsPath(sub_user_id) + '/' + user_id);
        },
        onMutate: ({ user_id, sub_user_id }) => {
            // Cancel outgoing refetches and snapshot previous value
            const queryKey = [membershipsPath(sub_user_id)];
            queryClient.cancelQueries({
                queryKey: queryKey,
            });
            const previousState = queryClient.getQueryData(queryKey);

            // Optimistically update to the new value
            let updatedStatus: string = '';
            queryClient.setQueryData(queryKey, (old: SubUser[]) => {
                return old.map((su) => {
                    if (su.user_id == user_id && su.sub_user_id == sub_user_id) {
                        updatedStatus = su.status == 'A' ? 'I' : 'D';
                        return { ...su, status: updatedStatus };
                    } else {
                        return su;
                    }
                });
            });
            const msg = updatedStatus == 'D' ? 'decline invite' : 'leave team';
            return {
                rollback: () => queryClient.setQueryData(queryKey, previousState),
                errorMsg: 'Failed to ' + msg + '. Please try again or contact support.',
            };
        },
        onSettled: () => {
            refreshMemberships();
        },
        onError: (_error, _variables, context: { rollback: () => void; errorMsg: string }) => {
            toast.error(context.errorMsg);
            context.rollback();
        },
    });
};

export const useRevokeAccess = () => {
    const refreshSubUsers = useRefreshSubUsers();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ user_id, sub_user_id }: SubUser) => {
            return del(subusersPath(user_id) + '/' + sub_user_id);
        },
        onMutate: ({ user_id, sub_user_id }) => {
            // Cancel outgoing refetches and snapshot previous value
            const queryKey = [subusersPath(user_id)];
            queryClient.cancelQueries({
                queryKey: queryKey,
            });
            const previousState = queryClient.getQueryData(queryKey);

            // Optimistically update to the new value
            queryClient.setQueryData(queryKey, (old: SubUser[]) => {
                return old.map((su) => {
                    if (su.sub_user_id == sub_user_id) {
                        return { ...su, status: 'I' };
                    } else {
                        return su;
                    }
                });
            });

            return () => queryClient.setQueryData(queryKey, previousState);
        },
        onSettled: () => {
            refreshSubUsers();
        },
        onError: (_error, _variables, rollback) => {
            toast.error('Failed to revoke access. Please try again or contact support.');
            rollback?.();
        },
    });
};

export const useRefreshSubUsers = () => {
    const queryClient = useQueryClient();
    const userId = useAppSelector(({ user }) => user?.id);
    return () => {
        queryClient.invalidateQueries({
            queryKey: [subusersPath(userId)],
        });
    };
};

export const useRefreshMemberships = () => {
    const queryClient = useQueryClient();
    const userId = useAppSelector(({ user }) => user?.id);
    return () => {
        queryClient.invalidateQueries({
            queryKey: [membershipsPath(userId)],
        });
    };
};

export const useUpdateSubUsersCache = () => {
    const queryClient = useQueryClient();
    const userId = useAppSelector(({ user }) => user?.id);
    return (subUsers: SubUser[]) =>
        queryClient.setQueryData([subusersPath(userId)], (_current) => {
            return subUsers;
        });
};

export const useUpdateMembershipsCache = () => {
    const queryClient = useQueryClient();
    const userId = useAppSelector(({ user }) => user?.id);
    return (memberships: SubUser[]) =>
        queryClient.setQueryData([membershipsPath(userId)], (_current) => {
            return memberships;
        });
};

export const useSubUsersByUserId = () => {
    const { data: subUsers } = useSubUsers();
    return useMemo(() => {
        return (
            subUsers?.reduce(
                (acc, subUser) => {
                    return {
                        ...acc,
                        [subUser.sub_user_id]: subUser,
                    };
                },
                {} as Record<number, SubUser>,
            ) || {}
        );
    }, [subUsers]);
};

export const getNameOfSubUser = (subUser: SubUser) => {
    return `${subUser.subuser_first_name} ${subUser.subuser_last_name}`;
};
