import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { UserFilter, UserFilterColumn } from 'libs/types/userFilter';
import { useAppSelector } from 'libs/global/hooks';
import fetcher from 'libs/utils/fetcher';
import { post } from 'libs/requests';
import { portfolioFiltersPath } from 'libs/routes';

// TODO: Will eventually support multiple filters
export const DEFAULT_FILTER_ID = 0;

export const DEFAULT_FILTERS = {
    is_active: {
        filterType: 'radio',
        type: 'set',
        values: ['active'],
    },
    removed_by_owner: {
        filterType: 'radio',
        type: 'set',
        values: ['not hidden'],
    },
    'listing_views.in_market': {
        filterType: 'radio',
        type: 'set',
        values: ['active'],
    },
};

const queryKey = (userId: number) => {
    return ['userSettings', userId];
};

const queryFn = (userId: number) => {
    return () =>
        fetcher(portfolioFiltersPath(userId)).then((data) => {
            const { settings }: { settings: UserFilter[] } = data;
            const r: Record<number, UserFilter> = {};
            if (isEmpty(settings)) {
                r[DEFAULT_FILTER_ID] = { columns: new Array(), filter_id: DEFAULT_FILTER_ID };
            } else {
                settings.forEach((f) => {
                    r[f.filter_id] = f;
                    if (!f.columns) {
                        r[f.filter_id].columns = [];
                    }
                });
            }
            return r;
        });
};

export const useGetUserSettings = (user_id: number) => {
    return useQuery({
        queryKey: queryKey(user_id),
        queryFn: queryFn(user_id),
        enabled: !!user_id,
        staleTime: 1000 * 60 * 5,
    });
};

export const usePrefetchUserSettings = () => {
    const userId = useAppSelector(({ user }) => user?.id);
    const queryClient = useQueryClient();

    if (!userId) {
        return;
    }

    return queryClient.prefetchQuery({
        queryKey: queryKey(userId),
        queryFn: queryFn(userId),
        gcTime: 1000 * 60 * 60,
    });
};

interface ModifySettingsParams {
    name: string;
    description: string;
    columns: any;
    filter_id: number;
}

export const useSaveUserSettings = () => {
    const userId = useAppSelector(({ user }) => user?.id);
    const queryClient = useQueryClient();
    const toast = useToast();
    const { mutate } = useMutation({
        mutationFn: (variables: ModifySettingsParams) => {
            const url = portfolioFiltersPath(userId) + `/${variables.filter_id}`;
            return post(url, variables);
        },
        onMutate: (variables) => {
            const qk = queryKey(userId);

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            queryClient.cancelQueries({
                queryKey: qk,
            });

            // Snapshot the previous value
            const previousSettings = queryClient.getQueryData(qk);

            // Optimistic update of cache
            queryClient.setQueryData(qk, (old: any) => {
                const new_settings = {};
                new_settings[variables.filter_id] = variables;
                return {
                    ...old,
                    ...new_settings,
                };
            });

            // Return the snapshotted value
            return () => queryClient.setQueryData(qk, previousSettings);
        },
        // If mutation fails, roll back to previous version
        onError: (_err, _variables, rollback) => {
            rollback!();
            toast({
                title: 'An error occurred',
                description: 'We were unable to create a default column view for your user. Please try again.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        },
        // Always refetch after error or success
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: queryKey(userId),
            });
        },
        onSuccess: () => {
            toast({
                title: 'Column view saved',
                description: "We've successfully created a default column view for your user.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        },
    });

    return (columnState: UserFilterColumn[]) => {
        mutate({ filter_id: DEFAULT_FILTER_ID, name: '', description: '', columns: columnState });
    };
};
