import { ListingMetrics, SetListingItem } from 'libs/types/set';
import * as turf from '@turf/turf';
import { compCandidatesPath, compSetIdPath } from 'libs/routes';
import { mapboxAccessToken } from './constants';

function formatMoney(amount: string | number = 0, currency = 'USD') {
    const amountNumber = Number(amount);
    if (isNaN(amountNumber)) {
        return null;
    }

    const options = {
        style: 'currency' as const,
        currency,
        minimumFractionDigits: 0,
    };
    // if it's a whole, dollar amount, leave off the .00
    if (amountNumber % 100 === 0) {
        options.minimumFractionDigits = 0;
    }
    const formatter = new Intl.NumberFormat('en-US', options);

    return formatter.format(amountNumber);
}

const formatY = (format, value, currency) => {
    const x = !value ? 0 : value;
    switch (format) {
        case 'percent':
            return `${(100 * x).toFixed(0)}%`;
        case 'currency':
            return `${formatMoney(x.toFixed(0), currency)}`;
        case 'days':
            return `${x.toFixed(1)}`;
        default:
            return x;
    }
};

const types = ['90_0', '365_0', '0_90', '0_365'];

interface Metric {
    label: string;
    key: string;
    format: string;
    currency?: string;
}

const metrics_dict: Metric[] = [
    {
        label: 'Asking rate',
        key: 'apr',
        format: 'currency',
    },
    {
        label: 'Avg. nightly rate',
        key: 'anr',
        format: 'currency',
    },
    {
        label: 'Nightly revenue',
        key: 'nrevenue',
        format: 'currency',
    },
    {
        label: 'Nightly revpar',
        key: 'nrevpar',
        format: 'currency',
    },
    {
        label: 'Occupancy',
        key: 'occupancy',
        format: 'percent',
    },
    {
        label: 'Lead time',
        key: 'lead_time',
        format: 'days',
    },
    {
        label: 'Length of stay',
        key: 'los',
        format: 'days',
    },
    {
        label: 'Openness',
        key: 'openness',
        format: 'percent',
    },
    {
        label: 'Adjusted Nightly RevPar',
        key: 'nrevpar_open',
        format: 'currency',
    },
    {
        label: 'Adjusted Occupancy',
        key: 'open_occupancy',
        format: 'percent',
    },
    {
        label: 'Available nights',
        key: 'available_nights',
        format: 'days',
    },
    {
        label: 'Blocked nights',
        key: 'blocked_nights',
        format: 'days',
    },
    {
        label: 'Bookable nights',
        key: 'bookable_nights',
        format: 'days',
    },
    {
        label: 'Booked nights',
        key: 'booked_nights',
        format: 'days',
    },
];

const generateValue = (value, format, formatValue = 'USD') => {
    if (value === null || value === undefined) {
        return null;
    }

    if (format === 'currency') {
        if (value !== null && value !== undefined) {
            return formatMoney(value.toFixed(1), formatValue ? formatValue : 'USD');
        } else {
            return null;
        }
    } else if (format === 'percent') {
        return `${(value * 100).toFixed(1)}%`;
    } else {
        return value.toFixed(1);
    }
};

interface Metrics {
    [key: string]: {
        label?: string;
        format?: string;
        values?: number[];
        average?: number | null;
    };
}

const reduceListingsMetrics = (listings): Metrics => {
    const metrics_setup: Metrics = {};

    // loop through metrics dict and create a new object with the metrics, have properties for each type, and each type has an array of values from the listings prop
    metrics_dict.forEach((metric) => {
        metrics_setup[metric.key] = {};
        types.forEach((type) => {
            const values = listings.map((listing) => listing[`${metric.key}_${type}`]).filter((e) => e !== null && e !== undefined);
            const average = values?.length > 0 ? values.reduce((a, b) => a + b, 0) / values.length : null;

            metrics_setup[`${metric.key}_${type}`] = {
                label: metric.label,
                format: metric.format,
                values,
                average,
            };
        });
    });

    metrics_setup.total = listings?.length;

    return metrics_setup;
};

const reduceListingMetrics = (listing: SetListingItem): ListingMetrics => {
    const metrics_setup = {};

    // loop through metrics dict and create a new object with the metrics, have properties for each type, and each type has an array of values from the listings prop
    metrics_dict.forEach((metric) => {
        metrics_setup[metric.key] = {};
        types.forEach((type) => {
            const value = listing[`${metric.key}_${type}`];

            metrics_setup[`${metric.key}_${type}`] = {
                label: metric.label,
                format: metric.format,
                value,
                currency: listing.currency,
            };
        });
    });

    return metrics_setup;
};

export const getCoordinates = (viewport) => {
    const newPolygon = turf.bboxPolygon(viewport);
    const coordinates = newPolygon?.geometry?.coordinates?.[0];
    return coordinates;
};

// Depending on number of boundaries, we need to flatten the array
const getFilter = (filter) => {
    return filter?.length > 1 ? filter.flat() : filter;
};

export const fetchLocation = async (coordinates) => {
    const { latitude, longitude } = coordinates;
    const endpoint = 'mapbox.places';
    const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/${endpoint}/${longitude},${latitude}.json?types=region&access_token=${mapboxAccessToken}`,
    );
    const data = await response.json();
    const newLocation = data?.features?.[0]?.text ? data?.features?.[0]?.text.toLowerCase() : null;

    return newLocation;
};

export const saveFilters = async (id, filters) => {
    const url = compSetIdPath(id);
    const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(filters),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response;
};

export const fetchListings = async (
    type,
    body,
    extra?: {
        limit?: number;
        user_id?: number;
    },
) => {
    // If no body, return early
    if (!body) {
        return;
    }
    // configure the body
    switch (type) {
        case 'viewport':
            body = {
                coordinates: getCoordinates(body),
                ...extra,
            };
            break;
        case 'market':
            body = {
                market_ids: body,
                ...extra,
            };
            break;
        case 'boundary':
            body = {
                coordinates: body,
                ...extra,
            };
            break;

        default:
            break;
    }

    try {
        const response = await fetch(compCandidatesPath(), {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            // Handle HTTP errors
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data: {
            potential_listings: SetListingItem[];
            user_listings: SetListingItem[];
        } = await response.json();

        return data;
    } catch (error) {
        console.error('Error fetching listings:', error);
    }
};

export const multipleFetchListings = async (
    viewport,
    filter,
    extra?: {
        limit?: number;
        user_id?: number;
    },
) => {
    const boundaries = filter ? getFilter(filter) : viewport && [getCoordinates(viewport)];

    if (!boundaries) {
        return;
    }

    const promises = await boundaries.map(async (coordinates) => {
        return await fetchListings('boundary', coordinates, extra);
    });

    const result = await Promise.all(promises);

    // Because of the multiple promises we need to combine them once returned
    const reducedResults: {
        potential_listings: SetListingItem[];
        user_listings: SetListingItem[];
    } = await result.reduce(
        (acc, val) => {
            return {
                potential_listings: [...acc.potential_listings, ...val.potential_listings],
                user_listings: [...acc.user_listings, ...val.user_listings],
            };
        },
        {
            potential_listings: [],
            user_listings: [],
        },
    );

    return reducedResults;
};

// utility functions for dealing with saving and loading saved boundaries
function ensureMultiBoundary(boundaryData) {
    // Check if the input is an array of arrays of tuples (multi-boundary)
    if (boundaryData.length > 1) {
        return boundaryData; // Already a multi-boundary
    }
    // If not, wrap it in an array to make it a multi-boundary
    return [boundaryData];
}

function wrapSinglePolygonAsMulti(boundary) {
    if (!boundary) {
        return null;
    }
    // Check if the first element is an array of arrays (multi-polygon structure)
    if (Array.isArray(boundary[0][0][0])) {
        return boundary; // Already a multi-polygon
    }
    return [boundary];
}

function unwrapSinglePolygonFromMulti(multiPolygon) {
    // Check if it's a multi-polygon with only one polygon inside
    if (multiPolygon.length === 1) {
        return multiPolygon[0]; // Unwrap the single polygon
    }
    return multiPolygon; // Return as is if it's already multiple polygons
}

// Filter functions

function transformFilters(filters) {
    if (!filters) {
        return null;
    }

    const transformed = {};

    Object.keys(filters).forEach((key) => {
        const filter = filters[key];

        if (filter.between) {
            const values = filter.between.values.flat();
            const lower = Math.min(...values);
            const upper = Math.max(...values);

            transformed[key] = {
                lower,
                upper,
            };
        } else if (filter.in) {
            transformed[key] = {
                one_of: filter.in.values,
            };
        } else if (filter.arraySearch) {
            const method = filter.arraySearch.params?.method || 'any';
            const includeKey = method === 'all' ? 'include_all' : 'include_any';

            transformed[key] = {
                [includeKey]: filter.arraySearch.values,
            };
        }
    });

    return transformed;
}

function untransformFilters(transformed, widgets) {
    const untransformed = {};

    Object.keys(transformed).forEach((key) => {
        const filter = transformed[key];

        if (filter.lower !== undefined && filter.upper !== undefined) {
            const widget = widgets.find((w) => w.id === key);
            const bins = widget?.bins || 10;

            const filterTicks = () => {
                if (filter.lower !== undefined && filter.upper && bins) {
                    const result: number[] = [filter.lower];
                    for (let i = 1; i < bins; i += 1) {
                        result.push(filter.lower + (filter.upper - filter.lower) * (i / bins));
                    }
                    return result;
                }
                return [];
            };

            const ticksArray = filterTicks();

            const thresholds = ticksArray.map((tick, index, arr) => {
                if (index === arr.length - 1) {
                    return [tick, filter.upper];
                }
                return [tick, arr[index + 1]];
            });

            untransformed[key] = {
                between: {
                    values: thresholds,
                    owner: key,
                },
            };
        } else if (filter.one_of !== undefined) {
            // This was an 'in' filter
            untransformed[key] = {
                in: {
                    values: filter.one_of,
                    owner: key,
                },
            };
        } else if (filter.include_all !== undefined) {
            // This was an 'arraySearch' filter with method 'all'
            untransformed[key] = {
                arraySearch: {
                    values: filter.include_all,
                    params: {
                        method: 'all',
                    },
                    owner: key,
                },
            };
        } else if (filter.include_any !== undefined) {
            // This was an 'arraySearch' filter with method 'any'
            untransformed[key] = {
                arraySearch: {
                    values: filter.include_any,
                    params: {
                        method: 'any',
                    },
                    owner: key,
                },
            };
        }
    });

    return untransformed;
}

const popoutStyles = {
    initial: { x: -100, opacity: 0 },
    position: 'absolute' as const,
    zIndex: 10,
    top: 4,
    minW: 300,
    left: 4,
    bottom: 4,
    transition: { duration: '0.7', ease: 'easeIn' },
    animate: {
        x: 0,
        opacity: 1,
    },
    exit: {
        x: -300,
        opacity: 0,
        transition: { delay: 0.15, duration: 0.3 },
    },
};

const rightPopoutStyles = {
    initial: { x: 100, opacity: 0 },
    position: 'absolute',
    zIndex: 10,
    top: 4,
    minW: 330,
    shadow: 'xl',
    rounded: 'md',
    right: 4,
    bottom: 4,
    transition: { duration: 0.25, ease: 'easeIn' },
    animate: {
        x: 0,
        opacity: 1,
    },
    exit: {
        x: 200,
        opacity: 0,
        transition: { delay: 0.05, duration: 0.3 },
    },
};

const pinnedStyles = {
    initial: { width: 0 },
    flexShrink: 0,
    animate: {
        width: 325,
    },

    exit: {
        width: 0,
        transition: { delay: 0.05, duration: 0.3 },
    },
};

export {
    transformFilters,
    untransformFilters,
    unwrapSinglePolygonFromMulti,
    wrapSinglePolygonAsMulti,
    ensureMultiBoundary,
    rightPopoutStyles,
    generateValue,
    pinnedStyles,
    popoutStyles,
    formatY,
    reduceListingsMetrics,
    reduceListingMetrics,
    metrics_dict,
    formatMoney,
};
