import React from 'react';
import ErrorIcon from 'libs/images/error-icon.svg';
import { useIntercom } from 'libs/global/hooks/useIntercom';
import Image from 'next/image';

const ErrorPage = () => {
    const showIntercom = useIntercom();
    return (
        <div
            style={{
                margin: 'auto',
                textAlign: 'center',
                maxWidth: 710,
            }}>
            <div
                style={{
                    marginTop: 50,
                }}>
                <Image alt="error icon" role="presentation" width={150} height={150} src={ErrorIcon} />
            </div>
            <div
                style={{
                    marginTop: 50,
                    fontSize: 48,
                }}>
                <b>Yeesh!</b> We just had a server error.
            </div>
            <div
                style={{
                    fontSize: 32,
                    lineHeight: 1.4,
                    marginTop: 42,
                }}>
                An automatic alert was just sent to our team, with details about your account, as well as what went wrong. That said, if this problem persists,
                don’t refrain from{' '}
                <b style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => showIntercom()}>
                    messaging us!
                </b>
            </div>
        </div>
    );
};

export default ErrorPage;
