'use client';

import React from 'react';
import { difference, flatten, isEmpty, lowerCase, uniq } from 'lodash';
import {
    Box,
    Checkbox,
    CheckboxGroup,
    Flex,
    HStack,
    Icon,
    Input,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    StackDivider,
    Tooltip,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { Info } from 'phosphor-react';
import { useActiveSubUsers } from 'libs/global/hooks/useTeams';
import { getNameOfSubUser } from './ListingAccess';
import { LEVEL_FULL, LEVEL_NOT, LEVEL_READ } from './accessColumnDefs';
import { SubUser } from 'libs/types/subUser';
import { useAppDispatch, useAppSelector } from 'libs/global/hooks';
import { actions } from 'libs/global/slices/proState';

const getNameByLevel = (level: string) => {
    return {
        [LEVEL_FULL]: 'edit-access',
        [LEVEL_READ]: 'view-access',
    }[level];
};

interface Payload {
    grantSubUserIds?: string[];
    revokeSubUserIds?: string[];
}

const reducer = (
    currentPayload: Payload,
    action: {
        type: 'grant' | 'revoke' | 'reset';
        value?: string;
    },
): Payload => {
    switch (action.type) {
        case 'grant':
            return {
                ...currentPayload,
                grantSubUserIds: [...(currentPayload?.grantSubUserIds || []), action.value!],
                revokeSubUserIds: currentPayload?.revokeSubUserIds?.filter((id) => id != action.value),
            };
        case 'revoke':
            return {
                ...currentPayload,
                grantSubUserIds: currentPayload?.grantSubUserIds?.filter((id) => id != action.value),
                revokeSubUserIds: [...(currentPayload?.revokeSubUserIds || []), action.value!],
            };
        case 'reset':
            return {};
    }
};

interface SubUserInfoIconProps {
    subUser: SubUser;
    grantSubUserIds?: string[];
    revokeSubUserIds?: string[];
    level: string;
}
const SubUserInfoIcon = ({ subUser, grantSubUserIds = [], revokeSubUserIds = [], level }: SubUserInfoIconProps) => {
    const user_id = subUser?.sub_user_id?.toString();
    const name = getNameOfSubUser(subUser);

    return grantSubUserIds.includes(user_id) || revokeSubUserIds.includes(user_id) ? (
        <Tooltip
            label={
                grantSubUserIds.includes(user_id)
                    ? `Grant ${getNameByLevel(level)} of all selected listings to ${name} if not already present.`
                    : `Revoke ${getNameByLevel(level)} of all selected listings from ${name} if present.`
            }>
            <Icon as={Info} color="gray.300" />
        </Tooltip>
    ) : null;
};

interface CustomAccessHeaderProps {
    setSort: (sortOrder: string | null) => void;
    column: any;
    displayName: string;
    level: string;
}
export const CustomAccessHeader = (params: CustomAccessHeaderProps) => {
    const dispatch = useAppDispatch();
    const initialRef = React.useRef(null);
    const { setSort, column } = params;
    const sortOrder = column.getSort();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const selectedListings = useAppSelector(({ proState }) => proState.selectedListings);
    const [payload, setPayload] = React.useReducer(reducer, {} as Payload);
    const [search, setSearch] = React.useState<string | null>(null);
    const subUsers = useActiveSubUsers();

    const subUsersByUserId = subUsers?.reduce((obj, subuser) => {
        obj[subuser.sub_user_id] = subuser;
        return obj;
    }, {});

    const onSortChange = () => {
        switch (sortOrder) {
            case 'asc':
                setSort('desc');
                break;
            case 'desc':
                setSort(null);
                break;
            default:
                setSort('asc');
        }
    };

    const filterBySearch = (subUser: SubUser) => {
        const name = getNameOfSubUser(subUser);
        if (!search || search?.trim() == '' || !name) {
            return true;
        }

        return lowerCase(name).includes(lowerCase(search));
    };

    const selectedSubUserIds = () => {
        const managedListings = selectedListings.map((l) => l?.managed_listings);
        return uniq(
            flatten(managedListings)
                ?.filter((m) => m && m.level == params.level)
                ?.filter(({ user_id }) => subUsersByUserId[user_id])
                ?.map(({ user_id }) => user_id),
        );
    };

    const selectedSubUsers = () => {
        return selectedSubUserIds()
            ?.map((user_id) => subUsersByUserId[user_id])
            ?.filter((subUser) => subUser)
            ?.filter(filterBySearch);
    };

    const notSelectedSubUsers = () => {
        return difference(subUsers, selectedSubUsers())?.filter(filterBySearch);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // If the subuser has access to at least one the selected listings
        // there are two actions
        // 1: Revoke access on all selected listings
        // 2: Grant access to all selected listings

        // If the subuser has no access to any of the selected listings
        // there are two actions
        // 1: Add access to all selected listings
        // 2: Discard change, i.e. don't add access to all listings
        setPayload({
            type: e.target.checked ? 'grant' : 'revoke',
            value: e.target.value,
        });
    };

    const resetState = () => {
        setSearch(null);
        setPayload({ type: 'reset' });
    };

    const onCancel = () => {
        onClose();
        resetState();
    };

    const getFormattedPayload = () => {
        const { grantSubUserIds, revokeSubUserIds } = payload || ({} as Payload);

        return selectedListings
            .map(({ id }) => id!)
            .reduce((obj, id) => {
                obj[id] = {} as Record<number, string>;
                grantSubUserIds?.forEach((userId) => {
                    obj[id][userId] = params.level;
                });
                revokeSubUserIds?.forEach((userId) => {
                    obj[id][userId] = LEVEL_NOT;
                });
                if (isEmpty(obj[id])) {
                    delete obj[id];
                }
                return obj;
            }, {});
    };

    const onSave = () => {
        onClose();
        const formattedPayload = getFormattedPayload();
        if (!isEmpty(formattedPayload)) {
            dispatch(actions.saveSubUserAccess(formattedPayload, true));
        }
        resetState();
    };

    const SortIcon = () => {
        return {
            asc: <i className="ag-icon ag-icon-asc" />,
            desc: <i className="ag-icon ag-icon-desc" />,
        }[sortOrder];
    };
    return (
        <Flex align="center" justify="space-between" pr={2}>
            <HStack spacing={1} onClick={onSortChange} cursor="pointer">
                <SortIcon />
                <Box fontSize={13} fontWeight={400}>
                    {params.displayName}
                </Box>
            </HStack>
            <Tooltip label={selectedListings?.length === 0 ? 'Please select multiple listings to edit' : ''}>
                <Box>
                    <Popover isLazy={true} isOpen={isOpen} onClose={onSave} initialFocusRef={initialRef}>
                        <PopoverTrigger>
                            <button
                                disabled={selectedListings?.length === 0}
                                className={`ag-header-edit-button ${selectedListings?.length > 1 ? null : 'isDisabled'}`}
                                onClick={selectedListings?.length > 1 ? onOpen : undefined}>
                                Edit
                            </button>
                        </PopoverTrigger>
                        <Portal>
                            <PopoverContent>
                                <PopoverHeader pt={3}>
                                    <Input
                                        ref={initialRef}
                                        placeholder="Search for a user..."
                                        variant="unstyled"
                                        width="auto"
                                        value={search || ''}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </PopoverHeader>
                                <PopoverCloseButton onClick={onCancel} />
                                <PopoverBody p={0}>
                                    <VStack align="flex-start" spacing={0}>
                                        <Flex maxHeight="210px" overflow="auto" width="100%" py={2}>
                                            <VStack divider={<StackDivider borderColor="gray.100" />} spacing={4} align="stretch" width="100%">
                                                {selectedSubUsers()?.length ? (
                                                    <VStack align="flex-start" px={4} display={!selectedSubUserIds()?.length ? 'none' : 'flex'}>
                                                        <CheckboxGroup defaultValue={selectedSubUserIds().map((user_id) => user_id.toString())}>
                                                            {selectedSubUsers()?.map((subUser) => {
                                                                if (!subUser) {
                                                                    return null;
                                                                }
                                                                return (
                                                                    <Flex key={subUser.sub_user_id} width="100%" align="center" justify="space-between">
                                                                        <Checkbox value={subUser.sub_user_id.toString()} onChange={onChange}>
                                                                            {getNameOfSubUser(subUser)}
                                                                        </Checkbox>
                                                                        <SubUserInfoIcon {...{ subUser, level: params.level, ...payload }} />
                                                                    </Flex>
                                                                );
                                                            })}
                                                        </CheckboxGroup>
                                                    </VStack>
                                                ) : null}
                                                {notSelectedSubUsers()?.length ? (
                                                    <VStack align="flex-start" px={4}>
                                                        <CheckboxGroup defaultValue={[]}>
                                                            {notSelectedSubUsers()?.map((subUser) => {
                                                                if (!subUser) {
                                                                    return null;
                                                                }
                                                                return (
                                                                    <Flex key={subUser.sub_user_id} width="100%" align="center" justify="space-between">
                                                                        <Checkbox value={subUser.sub_user_id.toString()} onChange={onChange}>
                                                                            {getNameOfSubUser(subUser)}
                                                                        </Checkbox>
                                                                        <SubUserInfoIcon {...{ subUser, level: params.level, ...payload }} />
                                                                    </Flex>
                                                                );
                                                            })}
                                                        </CheckboxGroup>
                                                    </VStack>
                                                ) : null}
                                            </VStack>
                                        </Flex>
                                    </VStack>
                                </PopoverBody>
                            </PopoverContent>
                        </Portal>
                    </Popover>
                </Box>
            </Tooltip>
        </Flex>
    );
};

export default CustomAccessHeader;
