import LogRocket from '../logrocket';
import { datadogRum } from '@datadog/browser-rum';
import { AnalyticsBrowser } from '@segment/analytics-next';
const analytics = AnalyticsBrowser.load({ writeKey: process.env.NEXT_PUBLIC_SEGMENT_ID! }, { obfuscate: true });

if (process.browser) {
    LogRocket.getSessionURL((recordingURL) => {
        analytics.track('LogRocket', {
            recordingURL,
        });
    });

    if (process.env.NEXT_PUBLIC_DATA_DOG_APPLICATION_ID) {
        datadogRum.init({
            applicationId: process.env.NEXT_PUBLIC_DATA_DOG_APPLICATION_ID,
            clientToken: process.env.NEXT_PUBLIC_DATA_DOG_CLIENT_TOKEN!,
            site: 'datadoghq.com',
            service: 'dynaroo/client',
            sessionSampleRate: 100,
            trackUserInteractions: true,
        });
        datadogRum.startSessionReplayRecording();
    }
}

export default analytics;
