'use client';

import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Toaster } from 'sonner';
import { clearUiMessages, pageChanged } from 'libs/global/slices/ui';
import Notification from 'libs/global/components/Notification';
import Head from 'next/head';
import analytics from 'libs/utils/analytics';
import { RequiredDataFetching } from 'libs/global/context/requiredDataFetching';
import { ToastContainer } from 'libs/utils/toast';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { usePrefetchUserSettings } from 'libs/global/components/Pro/settings-next/filters/Filters';
import { usePrefetchPortfolioFilter } from 'libs/global/hooks/usePortfolioFilter';
import { useAppDispatch, useAppSelector } from '../hooks';

const LoaderOverlay = dynamic(() => import('libs/global/components/LoaderOverlay'));
const UpgradeModal = dynamic(() => import('libs/global/components/Accounts/Pricing/UpgradeModal'));
const DowngradeModal = dynamic(() => import('libs/global/components/Accounts/Pricing/DowngradeModal'));
const AdminWarning = dynamic(() => import('libs/global/components/AdminWarning'));

const AppContainer = ({ children }) => {
    const router = useRouter();
    const user = useAppSelector(({ user }) => user);
    const isLoading = useAppSelector(({ ui }) => ui.isLoading);
    const successMsg = useAppSelector(({ ui }) => ui.successMsg);
    const errorMsg = useAppSelector(({ ui }) => ui.errorMsg);
    const serverErrorMsg = useAppSelector(({ server_ui }) => server_ui.errorMsg);
    const showUpgradeModal = useAppSelector(({ ui }) => ui.showUpgradeModal);
    const showDowngradeModal = useAppSelector(({ ui }) => ui.showDowngradeModal);
    const dispatch = useAppDispatch();

    // Prefetch data used on portfolio page
    usePrefetchUserSettings();
    usePrefetchPortfolioFilter();

    // Handle app init
    useEffect(() => {
        if (window.performance) {
            analytics.track('Performance: Page Mount', { milliseconds: window.performance.now() });
        }
    }, []);

    // Handle page change event
    useEffect(() => {
        analytics.page(undefined, { is_admin: user == null ? false : user.is_admin });
        analytics.track('Account: Page Viewed', window.location);
        dispatch(pageChanged());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    return (
        <RequiredDataFetching>
            <ToastContainer />
            <div style={{ height: '100%' }}>
                <Head>
                    <title>Wheelhouse</title>
                </Head>
                {(successMsg || errorMsg || serverErrorMsg) && (
                    <Notification successMsg={successMsg} errorMsg={errorMsg || serverErrorMsg} close={() => dispatch(clearUiMessages())} />
                )}

                {isLoading ? <LoaderOverlay /> : null}
                <AdminWarning />
                {showUpgradeModal && <UpgradeModal />}
                {showDowngradeModal && <DowngradeModal />}
                {children}
            </div>

            <ReactQueryDevtools />
            <Toaster position="bottom-center" style={{ bottom: 36 }} />
        </RequiredDataFetching>
    );
};

export default AppContainer;
