import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import { priceForDay } from 'libs/utils/rates';
import { AppDispatch, RootState } from '../store';
import { BaseCalendarDay } from 'libs/types/calendarDay';
import { RATE_TYPE, RatePeriod } from 'libs/types/ratePeriod';

export const BULK_SETTING_TYPE = {
    REPLACE: 'replace',
    RELATIVE_ADJUSTMENT: 'relative-adjustment',
    RELATIVE_PERCENT: 'relative-percent',
    RELATIVE: 'relative',
};

export const DRAG_STATE = {
    DEFAULT: 'default',
    MOVING: 'moving',
};

interface CalendarUiState {
    startDate?: Moment | null;
    endDate?: Moment | null;
    rateType: RATE_TYPE | null;
    rate?: any | null;
    dragState: string;
    is_blocked?: boolean | null;
    default?: number | null;
    defaultPrice?: number | string | null;
    monday?: string | number | null;
    tuesday?: string | number | null;
    wednesday?: string | number | null;
    thursday?: string | number | null;
    friday?: string | number | null;
    saturday?: string | number | null;
    sunday?: string | number | null;
    expires_at?: string | Moment | null;
    price_adjustment: number | null;
    draggingDate: Moment | null;
    // This is used to set the initial date visible on the calendar.
    visibleDate?: Moment;
}

const initialState: CalendarUiState = {
    startDate: null,
    endDate: null,
    rateType: null,
    rate: null,
    dragState: DRAG_STATE.DEFAULT,
    is_blocked: null,
    default: null,
    defaultPrice: null,
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
    expires_at: null,
    price_adjustment: null,
    draggingDate: null,
};

const calendarUiSlice = createSlice({
    name: 'calendarUi',
    initialState,
    reducers: {
        setDate: (state, action: PayloadAction<{ date: moment.Moment; dayInfo: BaseCalendarDay | undefined }>) => {
            const { date, dayInfo } = action.payload;

            state.startDate = date;
            state.endDate = date;
            state.is_blocked = null;
            state.defaultPrice = null;
            state.expires_at = dayInfo?.rate?.expires_at || null;
            state.rateType = dayInfo?.rate?.fixed ? RATE_TYPE.FIXED : RATE_TYPE.AUTOMATED;

            if (dayInfo?.rate) {
                const price = priceForDay(dayInfo.rate, date);
                state.defaultPrice = price;
            }
        },
        setRate: (state, action: PayloadAction<RatePeriod>) => {
            const rate = action.payload;
            state.rate = rate;
            state.rateType = rate.fixed ? RATE_TYPE.FIXED : RATE_TYPE.AUTOMATED;
            state.startDate = moment.max(moment(), moment(rate.start_date));
            state.endDate = moment(rate.end_date);

            state.monday = rate.monday;
            state.tuesday = rate.tuesday;
            state.wednesday = rate.wednesday;
            state.thursday = rate.thursday;
            state.friday = rate.friday;
            state.saturday = rate.saturday;
            state.sunday = rate.sunday;
        },
        updateRateType: (state, action: PayloadAction<RATE_TYPE | null>) => {
            const rateType = action.payload;
            if (rateType == state.rateType) {
                return;
            }

            state.rateType = rateType;
            state.is_blocked = null;
            state.default = null;
            state.defaultPrice = null;
            state.monday = null;
            state.tuesday = null;
            state.wednesday = null;
            state.thursday = null;
            state.friday = null;
            state.saturday = null;
            state.sunday = null;

            // Show blocked by default once selected.
            if (rateType == RATE_TYPE.BLOCKED) {
                state.is_blocked = true;
            }
        },
        updateCalendarUi: (state, action: PayloadAction<Partial<CalendarUiState>>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        setExpirationDate: (state, action: PayloadAction<string | Moment>) => {
            state.expires_at = action.payload;
        },
        setEndDate: (state, action: PayloadAction<moment.Moment>) => {
            const endDate = action.payload;
            const startDate = state.startDate ? moment.min(state.startDate, endDate) : endDate;
            state.startDate = startDate;
            state.endDate = endDate;
        },
        setStartDate: (state, action: PayloadAction<moment.Moment>) => {
            const startDate = action.payload;
            const endDate = state.endDate ? moment.max(startDate, state.endDate) : startDate;
            state.startDate = startDate;
            state.endDate = endDate;
        },
        clearCalendarUi: () => {
            return initialState;
        },
        onStartDateDragging: (state, action: PayloadAction<moment.Moment>) => {
            state.dragState = DRAG_STATE.MOVING;
            state.draggingDate = action.payload;
            state.startDate = action.payload;
            state.endDate = action.payload;
        },
        onFinishDrag: (state) => {
            state.dragState = DRAG_STATE.DEFAULT;
            state.draggingDate = null;
        },
        onDateEnter: (state, action: PayloadAction<moment.Moment>) => {
            const date = action.payload;
            // If the start or end date stays the same return the original state.
            // This will prevent extra rerenders.
            if (state.dragState == DRAG_STATE.MOVING) {
                if (moment(date).isBefore(state.draggingDate)) {
                    state.startDate = date;
                    state.endDate = state.draggingDate;
                } else {
                    state.startDate = state.draggingDate;
                    state.endDate = date;
                }
            }
        },
        setVisibleDate: (state, action: PayloadAction<string>) => {
            // Set the initial date visible on the new calendar.
            // Save this an a moment object (a new instance) to force
            // renders ever time this function is called.
            state.visibleDate = moment(action.payload);
        },
    },
});

const addTopLevelDragListener = (dispatch: AppDispatch) => {
    const eventListener = () => {
        dispatch(calendarUiSlice.actions.onFinishDrag());
        document.body.removeEventListener('mouseup', eventListener, false);
    };
    document.body.addEventListener('mouseup', eventListener, false);
};

export const {
    clearCalendarUi,
    onDateEnter,
    onFinishDrag,
    setEndDate,
    setExpirationDate,
    setRate,
    setStartDate,
    updateCalendarUi,
    updateRateType,
    setVisibleDate,
} = calendarUiSlice.actions;

export const onStartDateDragging = (date: Moment) => {
    return (dispatch: AppDispatch) => {
        dispatch(calendarUiSlice.actions.onStartDateDragging(date));
        dispatch(setDate(date));
        addTopLevelDragListener(dispatch);
    };
};

export const setDate = (date: Moment) => {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const dayInfo = getState().calendar[date.format('YYYY-MM-DD')] as BaseCalendarDay | undefined;

        dispatch(
            calendarUiSlice.actions.setDate({
                dayInfo,
                date,
            }),
        );
    };
};

export default calendarUiSlice.reducer;
