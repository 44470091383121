'use client';

import React from 'react';
import { difference, flatten, lowerCase, sortBy, trim, uniq } from 'lodash';
import {
    Box,
    Checkbox,
    CheckboxGroup,
    Flex,
    Icon,
    Input,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    StackDivider,
    Tag,
    Tooltip,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { useRefreshListingsMeta } from 'libs/global/hooks/usePortfolioFilter';
import { Info } from 'phosphor-react';
import { AddNewTag } from './ListingTag';
import { useAppDispatch, useAppSelector } from 'libs/global/hooks';
import { actions } from 'libs/global/slices/proState';
import type { CheckboxFilter } from 'libs/types/filters';

const reducer = (currentPayload, action) => {
    switch (action.type) {
        case 'add':
            return {
                ...currentPayload,
                addTagIds: [...(currentPayload?.addTagIds || []), action.value],
                deleteTagIds: currentPayload?.deleteTagIds?.filter((id) => id != action.value),
            };
        case 'remove':
            return {
                ...currentPayload,
                addTagIds: currentPayload?.addTagIds?.filter((id) => id != action.value),
                deleteTagIds: [...(currentPayload?.deleteTagIds || []), action.value],
            };
        case 'create':
            return {
                ...currentPayload,
                nameOfNewTags: [...(currentPayload.nameOfNewTags || []), action.value],
            };
        case 'discard_create':
            return {
                ...currentPayload,
                nameOfNewTags: currentPayload?.nameOfNewTags?.filter((name) => name != action.value),
            };
        case 'reset':
            return {};
    }
};

interface TagInfoIconProps {
    tag: { id: number; name: string };
    addTagIds: string[];
    deleteTagIds: string[];
    preselected: boolean;
}
const TagInfoIcon = ({ tag, addTagIds = [], deleteTagIds = [], preselected }: TagInfoIconProps) => {
    const tag_id = tag?.id?.toString();
    if (!tag_id) {
        return (
            <Tooltip label={`Creating a new tag called '${tag.name}', and adding it to all selected listings`}>
                <Tag colorScheme="purple" size="sm" height="16px">
                    New
                </Tag>
            </Tooltip>
        );
    }
    if (!preselected) {
        return addTagIds.includes(tag_id) ? (
            <Tooltip label={`Adding tag '${tag.name}' to all selected listings`}>
                <Icon as={Info} color="gray.300" />
            </Tooltip>
        ) : null;
    }

    return addTagIds.includes(tag_id) || deleteTagIds.includes(tag_id) ? (
        <Tooltip
            label={
                addTagIds.includes(tag_id)
                    ? `Adding tag '${tag.name}' to all selected listings if not already present.`
                    : `Removing tag '${tag.name}' from all selected listings if present.`
            }>
            <Icon as={Info} color="gray.300" />
        </Tooltip>
    ) : null;
};

export const CustomTagHeader = () => {
    const dispatch = useAppDispatch();
    const initialRef = React.useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const selectedListings = useAppSelector(({ proState }) => proState.selectedListings);
    const filters = useAppSelector(({ proState }) => proState.filters);
    const [payload, setPayload] = React.useReducer(reducer, {});
    const [search, setSearch] = React.useState<string | null>(null);
    const refreshMeta = useRefreshListingsMeta();

    const allTags = (filters?.staticSchema?.tags as CheckboxFilter)?.options || [];
    const tagsById = allTags.reduce((obj, tag) => {
        obj[tag.id] = tag;
        return obj;
    }, {});

    const filterBySearch = (params: { name: string } | undefined) => {
        const name = params?.name;
        if (!search || search?.trim() == '' || !name) {
            return true;
        }

        return lowerCase(name).includes(lowerCase(search));
    };

    const selectedTagIds = () => {
        return uniq(flatten(selectedListings.map((l) => l.tags).filter((e) => e)).map(({ id }) => id));
    };

    const selectedTags = () => {
        return selectedTagIds()
            ?.map((id) => tagsById[id])
            ?.filter(filterBySearch);
    };

    const notSelectedTags = () => {
        return difference(allTags, selectedTags())?.filter(filterBySearch);
    };

    const createdTags = () => {
        return sortBy(payload.nameOfNewTags);
    };

    const validSearch = () => search?.length! > 1;

    const headerTitle = () => {
        if (selectedTagIds()?.length == 0) {
            return 'Add tags...';
        }

        return 'Edit tags..';
    };

    const onCreate = () => {
        setPayload({
            type: 'create',
            value: trim(search!),
        });
    };

    const onDiscardCreate = (e) => {
        setPayload({
            type: 'discard_create',
            value: e.target.value,
        });
    };

    const onChange = (e) => {
        // If at least one the selected listings are associated with the tag
        // there are two actions
        // 1: Remove tag from all selected listings
        // 2: Add tag to all selected listings

        // If none of the selected listings are associated with the tag
        // there are two actions
        // 1: Add tag to all selected listings
        // 2: Discard change, i.e. don't add tag to all listings
        setPayload({
            type: e.target.checked ? 'add' : 'remove',
            value: e.target.value,
        });
    };

    const resetState = () => {
        setSearch(null);
        setPayload({ type: 'reset' });
    };

    const onCancel = () => {
        onClose();
        resetState();
    };

    const formattedPayload = () => {
        const nonDeletableIds = notSelectedTags().map(({ id }) => id);
        return {
            ...payload,
            deleteTagIds: payload?.deleteTagIds?.filter((id) => !nonDeletableIds.includes(parseInt(id))),
        };
    };

    const onSave = () => {
        onClose();
        dispatch(actions.saveBulkTags(formattedPayload())).then((e) => {
            if (payload?.deleteTagIds?.length || payload?.nameOfNewTags?.length) {
                // Only need to refresh if tags were removed, or
                // new tags were created
                refreshMeta();
            }
        });
        resetState();
    };

    const showAddNewTag = () => {
        if (!validSearch()) {
            return false;
        }

        return !allTags?.find(({ name }) => {
            return lowerCase(trim(name)) == lowerCase(trim(search!));
        });
    };

    return (
        <Flex align="center" justify="space-between" pr={2}>
            <Box fontSize={13} fontWeight={400}>
                Tags
            </Box>
            <Tooltip label={selectedListings?.length === 0 ? 'Please select multiple listings to edit' : ''}>
                <Box>
                    <Popover isLazy={true} isOpen={isOpen} onClose={onSave} initialFocusRef={initialRef}>
                        <PopoverTrigger>
                            <button
                                disabled={selectedListings?.length === 0}
                                className={`ag-header-edit-button ${selectedListings?.length > 1 ? null : 'isDisabled'}`}
                                onClick={selectedListings?.length > 1 ? onOpen : undefined}>
                                Edit
                            </button>
                        </PopoverTrigger>
                        <Portal>
                            <PopoverContent>
                                <PopoverHeader pt={3}>
                                    <Input
                                        ref={initialRef}
                                        placeholder={allTags?.length ? headerTitle() : 'Add your first tag...'}
                                        variant="unstyled"
                                        width="auto"
                                        value={search || ''}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </PopoverHeader>
                                <PopoverCloseButton onClick={onCancel} />
                                <PopoverBody p={0}>
                                    <VStack align="flex-start" spacing={0}>
                                        <Flex maxHeight="210px" overflow="auto" width="100%" py={2}>
                                            <VStack divider={<StackDivider borderColor="gray.100" />} spacing={4} align="stretch" width="100%">
                                                {selectedTags()?.length ? (
                                                    <VStack align="flex-start" px={4} display={!selectedTagIds()?.length ? 'none' : 'flex'}>
                                                        <CheckboxGroup defaultValue={selectedTagIds().map((id) => id.toString())}>
                                                            {selectedTags()?.map((tag) => {
                                                                if (!tag) {
                                                                    return null;
                                                                }
                                                                return (
                                                                    <Flex key={tag.id} width="100%" align="center" justify="space-between">
                                                                        <Checkbox value={tag.id.toString()} onChange={onChange}>
                                                                            {tag.name}
                                                                        </Checkbox>
                                                                        <TagInfoIcon {...{ tag, preselected: true, ...payload }} />
                                                                    </Flex>
                                                                );
                                                            })}
                                                        </CheckboxGroup>
                                                    </VStack>
                                                ) : null}
                                                {notSelectedTags()?.length ? (
                                                    <VStack align="flex-start" px={4}>
                                                        <CheckboxGroup defaultValue={[]}>
                                                            {notSelectedTags()?.map((tag) => {
                                                                if (!tag) {
                                                                    return null;
                                                                }
                                                                return (
                                                                    <Flex key={tag.id} width="100%" align="center" justify="space-between">
                                                                        <Checkbox value={tag.id.toString()} onChange={onChange}>
                                                                            {tag.name}
                                                                        </Checkbox>
                                                                        <TagInfoIcon {...{ tag, ...payload }} />
                                                                    </Flex>
                                                                );
                                                            })}
                                                        </CheckboxGroup>
                                                    </VStack>
                                                ) : null}
                                                {createdTags()?.length ? (
                                                    <VStack align="flex-start" px={4}>
                                                        {createdTags().map((name) => {
                                                            if (!name) {
                                                                return null;
                                                            }
                                                            return (
                                                                <Flex key={name} width="100%" align="center" justify="space-between">
                                                                    <Checkbox isChecked={true} colorScheme="purple" value={name} onChange={onDiscardCreate}>
                                                                        {name}
                                                                    </Checkbox>
                                                                    <TagInfoIcon {...{ tag: { name }, ...payload }} />
                                                                </Flex>
                                                            );
                                                        })}
                                                    </VStack>
                                                ) : null}
                                                {showAddNewTag() ? (
                                                    <AddNewTag filteredTags={allTags} search={search} validSearch={validSearch()} onClick={onCreate} />
                                                ) : null}
                                            </VStack>
                                        </Flex>
                                    </VStack>
                                </PopoverBody>
                            </PopoverContent>
                        </Portal>
                    </Popover>
                </Box>
            </Tooltip>
        </Flex>
    );
};

export default CustomTagHeader;
