import { useQuery } from '@tanstack/react-query';
import fetcher from 'libs/utils/fetcher';
import { pricingTiersPath, userProPricingTiersCount } from 'libs/routes';
import { FullListingPricingTier, PricingTier, PricingTierFeatureMap } from 'libs/types/pricingTier';
import { useMemo } from 'react';
import { useAppSelector } from '../hooks';
import { PRICING_TIER_LEGACY_START } from 'libs/constants';

export const isLegacyTier = (pricingTier: FullListingPricingTier | PricingTier) => {
    return pricingTier.name.startsWith(PRICING_TIER_LEGACY_START);
};

export const isCustomTier = (pricingTier: FullListingPricingTier | PricingTier) => {
    return pricingTier.is_custom;
};

export const isOnBoardingTier = (pricingTier: FullListingPricingTier | PricingTier) => {
    return pricingTier.is_onboarding;
};

export const isTrialTier = (pricingTier: FullListingPricingTier | PricingTier) => {
    return pricingTier.name == 'Pro Trial';
};

const tierOrder = (tier: PricingTier) => {
    if (isLegacyTier(tier)) {
        return -1;
    }
    if (isCustomTier(tier)) {
        return 100;
    }
    return tier.level;
};

export const usePricingTiers = (): PricingTier[] => {
    const { data } = useQuery({
        queryKey: [pricingTiersPath()],
        queryFn: async () => {
            const data: { pricing_tiers: PricingTier[] } = await fetcher(pricingTiersPath());
            data.pricing_tiers.sort((a, b) => tierOrder(a) - tierOrder(b));
            return data.pricing_tiers;
        },
        staleTime: Infinity,
    });

    return data || [];
};

export const usePricingTier = (id?: number) => {
    const pricingTiers = usePricingTiers();
    if (!id) {
        return null;
    }
    return pricingTiers.find((c: PricingTier) => c.id === id);
};

export const usePricingTierFeatures = () => {
    const pricingTiers = usePricingTiers();
    const tiersMap = useMemo(() => {
        const tiersObj: Record<number, PricingTierFeatureMap> = {};
        pricingTiers.forEach((pricingTier) => {
            const featuresObj: PricingTierFeatureMap = {};
            if (pricingTier) {
                pricingTier.features.forEach((feature) => {
                    featuresObj[feature.name] = feature;
                });
            }
            tiersObj[pricingTier.id] = featuresObj;
        });
        return tiersObj;
    }, [pricingTiers]);
    return tiersMap;
};

export const usePricingTierFeaturesForStateListing = () => {
    const listing = useAppSelector(({ listing }) => listing);
    const pricingTierFeatures = usePricingTierFeatures();
    if (!listing || !listing.pricing_tier) {
        return null;
    }

    return pricingTierFeatures[listing.pricing_tier.id];
};

export const useTierByListing = () => {
    const listing = useAppSelector(({ listing }) => listing);
    const pricingTier = usePricingTier(listing.pricing_tier?.id);
    return pricingTier;
};

export const usePublicTiers = () => {
    const pricingTiers = usePricingTiers();
    return pricingTiers.filter((t) => t.is_active && (isLegacyTier(t) || !isCustomTier(t) || isOnBoardingTier(t)));
};

export const useUserProListingsCount = () => {
    const userId = useAppSelector(({ user }) => user.id);
    return useQuery({
        queryKey: ['userProListingsCount', userId],
        queryFn: async () => {
            const data: number = await fetcher(userProPricingTiersCount(userId));
            return data;
        },
        staleTime: 0,
    });
};
