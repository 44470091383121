import { createSelector } from 'reselect';
import { generateListingFeatures } from '../slices/sets';
import { metrics_label_dict } from '../components/CompNext/constants';
import { RootState } from '../store';

/**
 * Redux selector to get a source by ID
 */

const emptySource = {
    data: {
        features: [],
    },
};

export const selectSourceById = (state: RootState, id: string) => {
    return state.sets.dataSources[id] || emptySource;
};

export const selectFeaturesBySourceId = createSelector([selectSourceById], (dataSources) => {
    return dataSources.data?.features || [];
});

export const selectorSelectSourceById = createSelector([selectSourceById], (dataSources) => {
    return dataSources || {};
});

export const selectPopupConfiguration = (state: RootState) => state.sets.popupConfiguration;

export const selectorPopupConfiguration = createSelector([selectPopupConfiguration], (popupConfiguration) => {
    return popupConfiguration;
});

const selectSidebarListingConfiguration = (state: RootState) => state.sets.sidebarListingConfiguration;

export const selectorSidebarListingConfiguration = createSelector([selectSidebarListingConfiguration], (sidebarListingConfiguration) => {
    return sidebarListingConfiguration;
});

export const selectActiveListings = (state: RootState) => state.sets.active;
export const selectHiddenListings = (state: RootState) => state.sets.hidden;
export const selectReviewListings = (state: RootState) => state.sets.review;
export const selectUserListings = (state: RootState) => state.sets.user_listings;
export const selectCompareListings = (state: RootState) => state.sets?.compare_listing_data || [];

export const selectActiveListingCount = createSelector([selectActiveListings], (active) => {
    const length = active?.length || 0;
    return length;
});

export const selectListingsInSetAsOne = createSelector([selectActiveListings, selectHiddenListings, selectReviewListings], (active, hidden, review) => {
    // add a status property to each listing
    const al = generateListingFeatures(active);
    const hl = generateListingFeatures(hidden);
    const rl = generateListingFeatures(review);
    return [...al, ...hl, ...rl];
});

export const selectInSetListingsAsFeatures = createSelector([selectActiveListings], (active) => generateListingFeatures(active));
export const selectHiddenListingsAsFeatures = createSelector([selectHiddenListings], (hidden) => generateListingFeatures(hidden));
export const selectReviewListingsAsFeatures = createSelector([selectReviewListings], (review) => generateListingFeatures(review));

export const selectorSelectSetListings = createSelector(
    [selectActiveListings, selectHiddenListings, selectReviewListings, selectUserListings],
    (active, hidden, review, user_listings) => {
        return {
            active_listings: active,
            hidden_listings: hidden,
            review_listings: review,
            user_listings: user_listings,
        };
    },
);

export const selectDatasourceFilter = createSelector([selectSourceById], (dataSources) => {
    return dataSources.spatialFilter;
});

const modalSet = (state: RootState) => state.sets.modalSet;
const basicSet = createSelector(
    (state) => state,
    (state: RootState) => ({
        id: state.sets?.id,
        name: state.sets?.name,
        description: state.sets?.description,
        created_at: state.sets?.created_at,
        updated_at: state.sets?.updated_at,
        changelog: state.sets?.changelog,
        associated_listings: state.sets?.associated_listings,
        listings_with_metrics: {
            active: state.sets?.active,
            hidden: state.sets?.hidden,
            review: state.sets?.review,
        },
        active: state.sets?.active,
        review: state.sets?.review,
        hidden: state.sets?.hidden,
    }),
);

export const selectSet = createSelector([modalSet, basicSet], (modalSet, basicSet) => {
    return modalSet ? modalSet.id : basicSet.id;
});

/**
 * Redux selector to select the active viewport
 */
export const selectViewport = (state: RootState) => {
    return state.sets.viewport;
};

export const selectLatitudeLongitude = (state: RootState) => {
    return state.sets.viewState
        ? {
              latitude: state.sets.viewState.latitude,
              longitude: state.sets.viewState.longitude,
          }
        : null;
};

/**
 * Redux selector to select the spatial filter of a given sourceId or the root one
 */
export const selectSpatialFilter = (state: RootState, sourceId?: string) => {
    let spatialFilterGeometry = state.sets.spatialFilter;
    if (spatialFilterGeometry?.properties?.disabled) {
        spatialFilterGeometry = null;
    }
    return sourceId ? state.sets.dataSources[sourceId]?.spatialFilter || spatialFilterGeometry : spatialFilterGeometry;
};

export const selectSourceFilterValueObject = (state: RootState, source: string) => state.sets.dataSources[source]?.filters || {};

export const selectAmenityFilterValues = createSelector([selectSourceFilterValueObject], (filters) => {
    if (filters?.amenities) {
        return {
            values: filters.amenities?.arraySearch?.values,
            method: filters.amenities?.arraySearch?.params?.method,
        };
    } else {
        return {
            values: [],
            method: 'all',
        };
    }
});

const selectFilterNames = (state: RootState, source: string) => state.sets.dataSources[source]?.filters || {};

export const selectSourceFilters = createSelector([selectFilterNames], (filters) => {
    const filterNames = Object.keys(filters).map((key) => metrics_label_dict[key] || key);

    return filterNames.length ? filterNames : null;
});

export const selectCoordinates = createSelector([selectSpatialFilter], (spatialFilter) => {
    return spatialFilter?.geometry?.coordinates || null;
});

export const selectUserMarkerListings = createSelector([selectUserListings, selectCompareListings], (user_listings, compare_listings) => {
    const user_listings_with_property = user_listings.map((listing) => {
        return {
            ...listing,
            type: 'user',
        };
    });
    const compare_listings_with_property = compare_listings?.map((listing) => {
        return {
            ...listing,
            type: 'compare',
        };
    });

    const ul = generateListingFeatures(user_listings_with_property);
    const cl = generateListingFeatures(compare_listings_with_property);

    return [...ul, ...cl];
});

/**
 * Redux selector to select the colorized widget
 */
export const selectColorizedWidget = (state: RootState) => state.sets?.colorizedWidget?.id;

/**
 * Redux selector to know if features from a certain source are ready
 */
export const selectAreFeaturesReadyForSource = (state: RootState, id: string) => !!state.sets.featuresReady[id];

// Input selector: gets the raw compare listing data from the state
const getCompareListingData = (state) => state?.sets?.compare_listing_data;

// Memoized selector: processes the raw data and memoizes the result
export const getCompareListings = createSelector([getCompareListingData], (compare_listing_data) => {
    if (!compare_listing_data) {
        return [];
    }

    const compare_listings_with_property = compare_listing_data.map((listing) => ({
        ...listing,
        type: 'compare',
    }));

    const compare_listings = generateListingFeatures(compare_listings_with_property);
    return compare_listings;
});

// Input selector: gets the raw user listing data from the state
const getUserListingData = (state) => state.sets.user_listings;

// Memoized selector: processes the raw data and memoizes the result
export const getUserListings = createSelector([getUserListingData], (user_listings) => {
    if (!user_listings) {
        return [];
    }

    const user_listings_with_property = user_listings.map((listing) => ({
        ...listing,
        type: 'user',
    }));

    const listings_with_features = user_listings_with_property.map((listing) => ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [listing.long, listing.lat],
        },
        id: listing.listing_id,
        properties: {
            ...listing,
        },
    }));

    return listings_with_features;
});
