export const metricOptions = (arr: number[]) =>
    arr.map((p) => ({
        name: `${p} days`,
        value: p,
    }));

export const PERIODS = [7, 14, 21, 30, 60, 90, 180];
export const HISTORICAL_PERIODS = [7, 14, 30];
export const MONTH_PERIODS = [30, 60, 90];
export const METRIC_OPTIONS = metricOptions(PERIODS);
export const HISTORICAL_METRIC_OPTIONS = metricOptions(HISTORICAL_PERIODS);
