import {
    ONBOARDING_APPROACH,
    ONBOARDING_CALENDAR_CONFIGURATION_ONE,
    ONBOARDING_CALENDAR_CONFIGURATION_TWO,
    ONBOARDING_DATA_DRIVEN,
    ONBOARDING_INTER,
    ONBOARDING_INTRO,
    ONBOARDING_PREVIEW,
    ONBOARDING_REVIEW,
} from '../actions/Calendar/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const steps = {
    [ONBOARDING_INTRO]: 1,
    [ONBOARDING_REVIEW]: 2,
    [ONBOARDING_APPROACH]: 3,
    [ONBOARDING_DATA_DRIVEN]: 4,
    [ONBOARDING_CALENDAR_CONFIGURATION_ONE]: 5,
    [ONBOARDING_CALENDAR_CONFIGURATION_TWO]: 6,
    [ONBOARDING_PREVIEW]: 7,
};

interface ListingState {
    furthest_step: string;
    previous_step: string[];
    step: string;
    preview: boolean;
    days_out?: number | string;
    visited_settings: boolean;
    approach?: string;
}

const initialListingState: ListingState = {
    furthest_step: ONBOARDING_INTRO,
    previous_step: new Array(),
    step: ONBOARDING_INTRO,
    preview: false,
    days_out: 0,
    visited_settings: false,
    approach: '',
};

const initialState: Record<number, ListingState | undefined> = {};

const guideSlice = createSlice({
    name: 'guide',
    initialState,
    reducers: {
        goBack: (state, action: PayloadAction<{ id: number }>) => {
            const listingState = state[action.payload.id];
            if (!listingState) {
                return;
            }

            listingState.step = listingState?.previous_step[0];
            listingState.previous_step = listingState?.previous_step.slice(1);
        },
        setPreview: (state, action: PayloadAction<{ id: number; preview: boolean }>) => {
            const { id, preview } = action.payload;
            const guide = state[id];
            if (!guide) {
                return;
            }
            guide.preview = preview;
        },
        setRatesToKeep: (state, action: PayloadAction<{ id: number; daysOut: number | string }>) => {
            const { daysOut, id } = action.payload;
            const guide = state[id];
            if (!guide) {
                return;
            }
            guide.days_out = daysOut;
        },
        generateGuide: (state, action: PayloadAction<number>) => {
            state[action.payload] = initialListingState;
        },
        navigateGuide: (state, action: PayloadAction<{ id: number; step: string; extraState?: Partial<ListingState> }>) => {
            const { step, id, extraState } = action.payload;
            let listingState = state[id];

            if (!listingState) {
                return;
            }

            listingState = {
                ...listingState,
                previous_step: [listingState.step, ...listingState.previous_step],
                furthest_step: step && steps[step] > steps[listingState.furthest_step] ? step : listingState.furthest_step,
                ...(step && { step: step }),
                ...extraState,
            };
            state[id] = listingState;
        },
        setApproach: (state, action: PayloadAction<{ id: number; approach: string }>) => {
            const { id, approach } = action.payload;
            const guide = state[id];
            if (!guide) {
                return;
            }

            guide.approach = approach;
        },
        setFurthestStep: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload;
            const guide = state[id];
            if (!guide) {
                return;
            }

            guide.furthest_step = ONBOARDING_CALENDAR_CONFIGURATION_ONE;
            guide.step = ONBOARDING_INTER;
            guide.previous_step = [ONBOARDING_APPROACH, ONBOARDING_REVIEW, ONBOARDING_INTRO];
            guide.preview = true;
        },
        resetGuide: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload;
            const guide = state[id];
            if (!guide) {
                return;
            }

            guide.preview = false;
            guide.approach = undefined;
            guide.days_out = undefined;
            guide.visited_settings = false;
        },
        visitedSettings: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload;
            const guide = state[id];
            if (!guide) {
                return;
            }

            guide.visited_settings = true;
        },
    },
});

export const { goBack, setPreview, setRatesToKeep, generateGuide, setApproach, setFurthestStep, resetGuide, visitedSettings } = guideSlice.actions;

export const navigateGuide = (step: string, id: number, extraState?: Partial<ListingState>) => {
    return guideSlice.actions.navigateGuide({
        step,
        id,
        extraState,
    });
};

export default guideSlice.reducer;
