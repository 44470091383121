import { createSelector } from 'reselect';

import { CHANNEL_ACCOUNT_OAUTH_STATUS, LISTING_DASHBOARD_STATE, LISTING_FILTER } from 'libs/constants';
import { SORT_COLUMNS } from 'libs/global/slices/dashboardUi';
import { listingBulkPathV2 } from 'libs/routes';
import queryString from 'query-string';
import { each } from 'lodash';
import { RootState } from '../store';
import { ListingV2 } from 'libs/types/listingV2';
import { useIsOutOfMarket } from 'libs/global/hooks/useMarkets';

const searchQuerySelector = (state: RootState) => state.dashboardUi.query;
const dashboardUiSelector = (state: RootState) => state.dashboardUi;

export const filterableURL = (path: string, options: Record<string, any>) => {
    const queryParams = {} as Record<string, any>;
    each(options.filter_settings || {}, (vals, key) => {
        if (!vals) {
            return;
        }
        if (options.filter_ranges.enums[key]) {
            if (vals.length == 0) {
                return;
            }
            queryParams[key] = vals.map((val: Record<string, any>) => val?.id);
        } else if (options.filter_ranges.bools[key]) {
            // Handle both true and false for this one
            if (vals.value === true || vals.value === false) {
                queryParams[key] = vals.value;
            }
        } else if (options.filter_ranges.partial_strings[key]) {
            if (vals != null && vals != '') {
                queryParams[key] = vals;
            }
        } else if (options.filter_ranges.ranges[key]) {
            if (vals != null && vals.length !== 0) {
                if (vals[0] != null) {
                    queryParams[key + '_gte'] = vals[0];
                }
                if (vals[1] != null) {
                    queryParams[key + '_lte'] = vals[1];
                }
            }
        }
    });

    // Pull in pagination.
    if (options.page != null) {
        queryParams.page = options.page;
    }
    if (options.per_page != null) {
        queryParams.per_page = options.per_page;
    }

    // Pull in sort order
    if (options.sortKey != null) {
        queryParams.order = options.sortKey;
        if (!options.sortDescending) {
            queryParams.order = '-' + queryParams.order;
        }
    }

    // Re-query can be triggered by updating refreshed_at timestamp.
    if (options.refreshed_at != null) {
        queryParams.refreshed_at = options.refreshed_at;
    }

    // Additional params specific to API.
    for (const [key, val] of Object.entries(options.params || {})) {
        if (val != null) {
            queryParams[key] = val;
        }
    }

    return path + '?' + queryString.stringify(queryParams, { arrayFormat: 'bracket' });
};
const getParamForfilterByListingStatus = (status: string) => {
    switch (status) {
        case LISTING_FILTER.ACTIVE.filter_key:
            return { preset: 'live' };
        case LISTING_FILTER.INACTIVE.filter_key:
            return { preset: 'inactive' };
        case LISTING_FILTER.OUT_OF_MARKET.filter_key:
            return { preset: 'out_of_market' };
        case LISTING_FILTER.HIDDEN.filter_key:
            return { preset: 'hidden' };
        case LISTING_FILTER.HYPOTHETICAL.filter_key:
            return { preset: 'hypothetical' };
        case LISTING_FILTER.NEEDS_DATA.filter_key:
            return { preset: 'needs_data' };
        case LISTING_FILTER.PENDING.filter_key:
            return { preset: 'pending' };
        default:
            return {};
    }
};

const getSortOrder = (column: string, ascending: boolean) => {
    let values: string[];
    switch (column) {
        case SORT_COLUMNS.LISTING:
            values = ['listing_views.display_name'];
            break;
        case SORT_COLUMNS.BASE_PRICE:
            values = ['listing_views.display_base_price'];
            break;
        case SORT_COLUMNS.PRICING:
            values = ['listing_preferences.automatic_rate_posting_enabled'];
            break;
        case SORT_COLUMNS.REV_SCORE:
            values = ['listing_stats.revenue_score_0_30'];
            break;
        case SORT_COLUMNS.CHANNEL:
            values = ['source'];
            break;
        case SORT_COLUMNS.COMP_SET:
            values = ['listing_stats.comp_set_count'];
            break;
        case SORT_COLUMNS.CREATED_AT:
            values = ['listings.created_at'];
            break;
        case SORT_COLUMNS.UNITS:
            values = ['listings.number_of_units'];
            break;
        default:
            return;
    }
    if (!values) {
        return;
    }
    return ascending ? `-${values.join(',-')}` : values.join(',');
};

export const getFilterableURLForListings = (options: object) => {
    return createSelector(dashboardUiSelector, searchQuerySelector, (uiState, query) => {
        query = query.replace('’', "'");
        const fullOptions = {
            params: {
                'listing_views.searchable': query,
                // If channel is nullable, set the value to an empty string to remove param.
                source: uiState.channel || '',
                market_id: uiState.market,
                bedrooms: uiState.bedrooms,
                'listing_preferences.automatic_rate_posting_enabled': uiState.wheelhousePricingStatus,
                'pricing_tiers.id': uiState.tier,
                order: getSortOrder(uiState.sort, uiState.sortAscending),
                // Needs to come after "source: uiState.channel".
                ...getParamForfilterByListingStatus(uiState.listingFilter),
            },
            ...options,
        };

        return filterableURL(listingBulkPathV2(), fullOptions);
    });
};

export const useDashboardListingState = (listing: ListingV2) => {
    const isOutOfMarket = useIsOutOfMarket(listing.market_id);
    if (listing.channel_account?.status == CHANNEL_ACCOUNT_OAUTH_STATUS) {
        return LISTING_DASHBOARD_STATE.DISCONNECTED;
    } else if (isOutOfMarket) {
        return LISTING_DASHBOARD_STATE.OUT_OF_MARKET;
    } else if (!listing.is_active) {
        return LISTING_DASHBOARD_STATE.INACTIVE;
    } else if (listing.preferences == null) {
        return LISTING_DASHBOARD_STATE.NO_RECOMMENDATIONS;
    } else if (listing.preferences.automatic_rate_posting_enabled) {
        return LISTING_DASHBOARD_STATE.WHEELHOUSE_PRICING;
    }
    return LISTING_DASHBOARD_STATE.PRICING_ELIGIBLE;
};
