/* eslint-disable react/no-unknown-property */
import React from 'react';
import AppContainer from 'libs/global/components/AppContainer';
import { Provider } from 'react-redux';
import { store } from 'libs/global/store';
import '../polyfill';
import { QueryClientProvider } from '@tanstack/react-query';
import { Inter } from 'next/font/google';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from 'libs/wheelhouse_design/theme';
import { IntlProvider } from 'react-intl';
import getLanguage from 'libs/utils/getLanguage';
import * as Sentry from '@sentry/nextjs';
import ErrorPage from 'libs/global/components/Site/ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';

// Set the default locale data for all pages.
import 'intl';
import 'intl/locale-data/jsonp/en';

import '../styles/phoenix.css.scss';
import { AppProps } from 'next/app';
import { queryClient } from 'libs/global/query';

const inter = Inter({
    subsets: ['latin'],
});

theme.fonts = {
    sans: inter.style.fontFamily,
    serif: inter.style.fontFamily,
    mono: 'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
    heading: inter.style.fontFamily,
    body: inter.style.fontFamily,
};

const formats = {
    date: {
        short: {
            day: 'numeric' as const,
            month: 'short' as const,
            year: 'numeric' as const,
        },
    },
};

interface LayoutComponentProps {
    Component: {
        getLayout: (page: React.ReactNode) => React.ReactNode;
    };
}

const MyApp = ({ Component, pageProps }: AppProps & LayoutComponentProps) => {
    const getLayout = Component.getLayout || ((page: React.ReactNode) => page);
    const language = getLanguage();

    return (
        <>
            <style jsx={true} global={true}>{`
                html,
                body {
                    font-family: ${inter.style.fontFamily};
                }
            `}</style>
            <Provider store={store}>
                <ChakraProvider resetCSS={true} theme={theme} portalZIndex={40}>
                    <QueryClientProvider client={queryClient}>
                        <IntlProvider locale={language || 'en-US'} formats={formats}>
                            <ErrorBoundary
                                FallbackComponent={ErrorPage}
                                onError={(error) => {
                                    Sentry.captureException(error);
                                }}>
                                <AppContainer>{getLayout(<Component {...pageProps} />)}</AppContainer>
                            </ErrorBoundary>
                        </IntlProvider>
                    </QueryClientProvider>
                </ChakraProvider>
            </Provider>
        </>
    );
};

export default MyApp;
