import React from 'react';
import { Button, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, UnorderedList } from '@chakra-ui/react';
import { closeModal } from 'libs/global/slices/sets';
import { useAppDispatch } from 'libs/global/hooks';
import { BodyText } from '../components';
import { useDeleteSet } from 'libs/global/hooks/useSets';

export const DeleteModalId = 'delete';

const DeleteModal = ({ isOpen, set }) => {
    const dispatch = useAppDispatch();
    const { mutateAsync: deleteSet, isPending: isSaving } = useDeleteSet();

    const confirmDeleteSet = async () => {
        deleteSet(set?.id).then(() => {
            dispatch(closeModal());
        });
    };

    return (
        <Modal motionPreset="slideInBottom" isOpen={isOpen} onClose={() => dispatch(closeModal())} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete {set?.name ? set?.name : 'this set'}?</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <BodyText fontSize="md" mb={1}>
                        Once deleted:
                    </BodyText>
                    <UnorderedList>
                        <ListItem>
                            <BodyText fontSize="md">All listings will be unassigned from this set.</BodyText>
                        </ListItem>
                        <ListItem>
                            <BodyText fontSize="md">If this was the default set, you'll need to set a new default.</BodyText>
                        </ListItem>
                    </UnorderedList>
                </ModalBody>

                <ModalFooter>
                    <Button isDisabled={isSaving} variant="secondary" mr={3} onClick={() => dispatch(closeModal())}>
                        Cancel
                    </Button>
                    <Button isLoading={isSaving} onClick={confirmDeleteSet} variant="dark">
                        Confirm
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DeleteModal;
