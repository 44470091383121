export const getLocalStorage = <T>(key: string, initial: T | null = null): T | null => {
    // Function to check if localStorage is available and usable
    const isLocalStorageAvailable = () => {
        try {
            const testKey = '__storage_test__';
            localStorage.setItem(testKey, testKey);
            localStorage.removeItem(testKey);
            return true;
        } catch (e) {
            return (
                e instanceof DOMException &&
                // everything except Firefox
                (e.code === 22 ||
                    // Firefox
                    e.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    e.name === 'QuotaExceededError' ||
                    // Firefox
                    e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                localStorage &&
                localStorage.length !== 0
            );
        }
    };

    // Only proceed if localStorage is available
    if (isLocalStorageAvailable()) {
        try {
            const item = localStorage.getItem(key);
            return item !== null ? JSON.parse(item) : initial;
        } catch (error) {
            console.error(`Error parsing localStorage item "${key}":`, error);
            return initial;
        }
    } else {
        return initial;
    }
};

export const setLocalStorage = <T>(key: string, payload: T, merge: boolean = false, initial: T | null = null): void => {
    const existing = merge ? getLocalStorage<T>(key, initial) : null;
    const dataToStore = merge ? { ...existing, ...payload } : payload;

    localStorage.setItem(key, JSON.stringify(dataToStore));
};
