import queryString from 'query-string';
import * as UrlParser from 'url';
import { defaultHeaders, extractCSRFToken, post } from 'libs/requests';

let globalAs: string | null = null;
let globalRefresh: string | null = null;
if (typeof window !== 'undefined') {
    const query = queryString.parse(window.location.search);
    // + is considered a space.  Since "as" is an email address we can convert
    // the spaces back to +.
    const as = query.as as string;
    if (as) {
        globalAs = as.replace(/ /g, '+');
    }
    globalRefresh = query.refresh as string | null;
}

const handleError = async (res: Response) => {
    const error = new Error(`An error occurred while fetching data for ${res.url}`) as Error & { info: any; status: number };
    // Attach extra info to the error object.
    try {
        error.info = await res.json();
    } catch (e) {
        // No data.
    }
    error.status = res.status;
    return Promise.reject(error);
};

const fetcher = async (path: string) => {
    const res = await fetcherWithResponse(path);

    if (!res.ok) {
        return handleError(res);
    }

    // We send the latest CSRF token with every request.
    extractCSRFToken(res);

    const json = await res.json();
    return json;
};

export const fetcherWithResponse = async (path: string) => {
    const urlObj = UrlParser.parse(path, true);

    if (globalRefresh) {
        urlObj.query.refresh = globalRefresh;
    }

    if (globalAs) {
        urlObj.search = null;
        urlObj.query.as = globalAs;
    }

    const res = await fetch(UrlParser.format(urlObj), {
        headers: defaultHeaders(),
    });
    return res;
};

export const fetchV2API = async <ItemType>(url: string) => {
    const res = await fetcherWithResponse(url);

    if (!res.ok) {
        return handleError(res);
    }

    const items: ItemType = await res.json();
    return {
        pageCount: parseInt(res.headers.get('x-total-pages')!),
        itemCount: parseInt(res.headers.get('x-total')!),
        items,
    };
};

export const postWithBody = async ([path, body]: [string, object?]) => {
    const urlObj = UrlParser.parse(path, true);

    urlObj.search = null;
    if (globalAs) {
        urlObj.query.as = globalAs;
    }
    const res = await post(UrlParser.format(urlObj), body);
    return res;
};

export default fetcher;
