import colors from './colors';
import { hexToRgba } from 'libs/wheelhouse_design/utils/color-conversion';

const hoverState = '&:not([disabled]):not([data-disabled]):hover';
const focusState = '&:not([disabled]):not([data-disabled]):focus';
const activeState =
    '&:not([disabled]):not([data-disabled]):active, &:not([disabled]):not([data-disabled])[aria-expanded="true"], &:not([disabled]):not([data-disabled])[data-active]';

const getRootButtonStates = (variant: string, hoverColor = '500') => ({
    [hoverState]: {
        backgroundColor: colors[variant][hoverColor],
    },
    [focusState]: {
        boxShadow: `0px 0px 0px 3px ${colors[variant]['100A']}`,
    },
    [activeState]: {
        boxShadow: `inset 0 1px 3px rgba(0, 0, 0, 0.5), 0px 0px 0px 3px ${colors[variant]['200A']}`,
    },
});

const defaultButtonStyling = (variant: string) => ({
    backgroundColor: colors[variant][600],
    border: 0,
    ...getRootButtonStates(variant),
    [activeState]: {
        backgroundColor: colors[variant][800],
    },
});

const lightButtonStyling = (variant: string) => {
    return {
        backgroundColor: colors[variant]['100A'],
        color: colors[variant][800],
        border: 0,
        ...getRootButtonStates(variant, '200A'),
        [activeState]: {
            backgroundColor: colors[variant]['200A'],
        },
    };
};

const darkButtonStyling = (variant: string) => ({
    backgroundColor: colors[variant][1000],
    color: colors.white,
    border: 0,
    [hoverState]: {
        backgroundColor: colors[variant][700],
        outline: 'none',
    },
    [focusState]: {
        boxShadow: `0px 0px 0px 3px ${colors[variant]['100A']}`,
    },
    [activeState]: {
        boxShadow: `inset 0 1px 2px rgba(0, 0, 0, 0.3), 0px 0px 0px 3px ${colors[variant]['100A']}`,
    },
});

const outlineButtonStyling = (variant: string) => ({
    backgroundColor: colors[variant]['50A'],
    border: `1px solid ${hexToRgba(colors[variant]['500'], 0.2)}`,
    color: colors[variant][900],
    ...getRootButtonStates(variant, '100A'),
});

const plainButtonStyling = (variant: string) => ({
    backgroundColor: 'transparent',
    color: colors[variant][1000],
    border: 0,
    ...getRootButtonStates(variant, '100A'),
});

const buttons = {
    default: {
        ...defaultButtonStyling('primary'),
        color: colors.white,
    },
    defaultInverse: {
        ...defaultButtonStyling('whites'),
        color: colors.black,
    },
    defaultSecondary: {
        ...defaultButtonStyling('secondary'),
        color: colors.white,
    },
    defaultSuccess: {
        ...defaultButtonStyling('success'),
        color: colors.white,
    },
    defaultWarning: {
        ...defaultButtonStyling('warning'),
        color: colors.white,
    },
    defaultDestructive: {
        ...defaultButtonStyling('destructive'),
        color: colors.white,
    },
    defaultBlack: {
        ...defaultButtonStyling('blacks'),
        color: colors.white,
    },
    light: {
        ...lightButtonStyling('primary'),
    },
    lightSecondary: {
        ...lightButtonStyling('secondary'),
    },
    lightWarning: {
        ...lightButtonStyling('warning'),
        color: colors.warning[1000],
    },
    lightDestructive: {
        ...lightButtonStyling('destructive'),
    },
    lightSuccess: {
        ...lightButtonStyling('success'),
    },
    lightBlack: {
        ...lightButtonStyling('blacks'),
        color: colors.black,
    },
    outline: {
        ...outlineButtonStyling('primary'),
        color: colors.primary[500],
    },
    outlineBlack: {
        ...outlineButtonStyling('gray'),
        color: colors.gray[500],
    },
    outlineSecondary: {
        ...outlineButtonStyling('secondary'),
        color: colors.secondary[500],
    },
    outlineWarning: {
        ...outlineButtonStyling('warning'),
        color: colors.warning[1000],
    },
    outlineDestructive: {
        ...outlineButtonStyling('destructive'),
        color: colors.destructive[600],
    },
    outlineSuccess: {
        ...outlineButtonStyling('success'),
        color: colors.success[600],
    },
    outlineInverse: {
        ...outlineButtonStyling('whites'),
        color: colors.whites[600],
    },
    plain: {
        ...plainButtonStyling('primary'),
    },
    plainSecondary: {
        ...plainButtonStyling('secondary'),
    },
    plainSuccess: {
        ...plainButtonStyling('success'),
    },
    plainWarning: {
        ...plainButtonStyling('warning'),
        color: colors.warning[1000],
    },
    plainDestructive: {
        ...plainButtonStyling('destructive'),
    },
    plainBlack: {
        ...plainButtonStyling('blacks'),
    },
    plainInverse: {
        ...plainButtonStyling('whites'),
        color: colors.white,
    },
    dark: {
        ...darkButtonStyling('primary'),
    },
    darkSecondary: {
        ...darkButtonStyling('secondary'),
    },
    darkWarning: {
        ...darkButtonStyling('warning'),
    },
    darkDestructive: {
        ...darkButtonStyling('destructive'),
    },
    darkSuccess: {
        ...darkButtonStyling('success'),
    },
};

export default buttons;
