import analytics from 'libs/utils/analytics';
import { ajaxUpdate } from 'libs/global/reducers/ajaxUpdate';
import { showSuccess, startLoading, stopLoading, stopLoadingError } from 'libs/global/slices/ui';
import { post, put } from 'libs/requests';
import { listingsUpgradeCostBreakdownPath, updateBillingInfo, updateListingPricingTierPath, updateListingsPricingTierPath } from 'libs/routes';
import { AppDispatch } from '../store';

interface ListingTierMapping {
    listing_id: number;
    tier_level: number;
    starting_at?: string;
}

export const saveBilling = (paymentMethod: string, cb: Function) => {
    return (dispatch: AppDispatch) => {
        dispatch(startLoading('Saving Billing Information'));
        return post(updateBillingInfo(), { payment_method: paymentMethod })
            .then((result) => {
                analytics.track('User: Payment Added');
                dispatch(ajaxUpdate(result.data));
                cb(true);
            })
            .catch((err) => {
                const data = err.response.data || {};
                const error = data.error || {};
                dispatch(stopLoadingError(error.message || 'Sorry something went wrong'));
                cb(false);
            })
            .then(() => {
                dispatch(stopLoading());
            });
    };
};

export const updateListingTier = (listingId: number, tierLevel: number) => {
    return (dispatch: AppDispatch) => {
        return put(updateListingPricingTierPath(listingId, tierLevel))
            .then((result) => {
                dispatch(showSuccess('Your subscription has been successfully edited.'));
            })
            .catch((err) => {
                const data = err?.response?.data || {};
                const error = data.error || {};
                return error ? error : data;
            });
    };
};

export const updateListingsTier = (listings: ListingTierMapping[]) => {
    return (dispatch: AppDispatch) => {
        return put(updateListingsPricingTierPath(), { listing_tiers: listings })
            .then((result) => {
                // Identify paid tiers
                // TODO: Make the ids of paid tiers dynamically defined
                const paid = result.data.tiers_updated.filter(({ tier_id }) => [2, 3].includes(tier_id));
                if (paid.length > 0) {
                    analytics.track('User: Listing Upgraded', { paid_listings: paid });
                }
                dispatch(showSuccess('Your subscriptions have been successfully upgraded!'));
            })
            .catch((err) => {
                const data = err.response.data || {};
                const error = data.error || {};
                return error ? error : data;
            });
    };
};

export const getListingsUpgradeCost = (listings: ListingTierMapping[]) => {
    return put(listingsUpgradeCostBreakdownPath(), { listing_tiers: listings })
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            let error = err.response?.data?.error;
            error ||= err.response?.data;
            error ||= {};
            return error;
        });
};
