'use client';
import { createAction, createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { WebMercatorViewport } from '@deck.gl/core';
import { debounce } from 'lodash';
import type { Layer } from 'mapbox-gl';
import type { Geometry } from 'geojson';

import {
    BOUNDARY_TYPE,
    defaultWidgets,
    histogramIds,
    initialSet,
    LEFT_SIDEBAR_CONTENT,
    localStorageTypes,
    SEARCH_DEFAULT,
    SETS_DRAFT_KEY,
    SETS_LISTING_SIDEBAR_KEY,
    SETS_POPUP_KEY,
    SETS_SAVED_BOUNDARIES_KEY,
    SETS_UI_KEY,
    SETS_WIDGETS_SIDEBAR_KEY,
    SOURCE,
    STATUS,
} from '../components/CompNext/constants';
import { compCandidatesPath, compCreatePath, compSetAddListingsPath, compSetIdPath } from 'libs/routes';
import { removeWorker } from '../components/CompNext/CartoWidgets/react-workers';
import { reduceListingsMetrics, transformFilters, untransformFilters, wrapSinglePolygonAsMulti } from '../components/CompNext/utils';
import {
    BoundaryType,
    ChangelogItem,
    CompSet,
    Feature,
    MapListing,
    MetricPeriod,
    PreviewBoundary,
    SaveBoundary,
    SetListingItem,
    SpatialFilter,
    viewPort,
    viewState,
    Widget,
} from 'libs/types/set';
import { modalIds } from '../components/CompNext/Modals';
import { postWithBody } from 'libs/utils/fetcher';
import { defaultHeaders } from 'libs/requests';
import { User } from 'libs/types/user';

interface PopupConfiguration {
    title: boolean;
    address: boolean;
    thumbnail: boolean;
    thumbnailSize?: string;
    attributes: {
        property: string;
        label: string;
        type: string;
    }[];
    metrics: {
        property: string;
        label: string;
        type: string;
    }[];
    calendar: boolean;
    googleMapsStreetView?: boolean;
}

export interface SetsState {
    // map details
    search_limit?: number;
    static: boolean;
    preciseLocationEnabled?: boolean;
    viewState: viewState;
    viewport: viewPort | null;
    lastFetchViewport: viewPort | null;
    basemap: string;
    exaggeration: number;
    searchMarker: {
        type: string;
        latitude: number;
        longitude: number;
    } | null;
    boundaryType?: BoundaryType | null;
    previewBoundary: PreviewBoundary | null;
    spatialFilter?: SpatialFilter | null;
    postalCodeLocation: string | null;
    layers: {
        [key: string]: {
            id: string;
            name: string;
            description?: string;
            type: string;
            enabled: boolean;
        };
    };
    colorizedWidget?: {
        id: string;
        type: string;
        column: string;
        data: any[];
    } | null;
    showLayers: boolean;
    boundaryId: string | null;

    // state of interface details
    isFetchingListings: boolean;
    mapLoaded: boolean;
    widgetsLoaded: boolean;
    hiddenMarkers: string[]; // legend
    rightSidebarContent: 'charts' | 'listings' | 'compare';
    leftSidebarContent: (typeof LEFT_SIDEBAR_CONTENT)[keyof typeof LEFT_SIDEBAR_CONTENT];
    showSavedBoundaries: boolean;

    modalId: string | null;
    modalProps?: { defaultIndex?: number };

    showWidgetConfiguration: boolean;
    pinLeftSidebar: boolean;
    autoSearch: boolean;
    metricPeriod: MetricPeriod;
    showRightSidebar: boolean;
    showLeftSidebar: boolean;
    popupTrigger: 'hover' | 'click';
    popupOffset: number;
    savedBoundaries: SaveBoundary[];
    popupConfiguration: PopupConfiguration;
    sidebarListingConfiguration: PopupConfiguration;

    // set details
    id?: number | null;
    status?: (typeof STATUS)[keyof typeof STATUS];
    name: string;
    description?: string;
    created_at: Date;
    updated_at: Date;
    kind?: string;
    active: SetListingItem[];
    review: SetListingItem[];
    hidden: SetListingItem[];
    associated_listings: SetListingItem[];
    user_listings: SetListingItem[];
    changelog: ChangelogItem[];
    compare_listing_ids: number[];
    compare_listing_data?: SetListingItem[];
    compare_listing_metrics?: any[];
    showListingSidebar: boolean;
    isEditingBoundary: boolean;
    sidebars: string[];
    featuresReady: {
        [key: string]: boolean;
    };
    feature: Feature | null;
    listing?: MapListing | null;
    widgets: any[];
    widgetsExpanded: boolean;
    modalSet: {
        id: number;
        name: string;
        description?: string;
        updated_at: Date;
        listings_with_metrics: {
            active: SetListingItem[];
            hidden: SetListingItem[];
            review: SetListingItem[];
        };
        changelog?: ChangelogItem[];
    } | null;
    initialFetch: boolean;
    showIntro: boolean;

    // data
    dataSources: {
        [key: string]: {
            id: string;
            data: {
                type: string;
                features: any[];
            };
            spatialFilter?: any;
            filters?: {
                [key: string]: {
                    [key: string]: {
                        values: any[];
                        owner: string;
                        params?: any;
                    };
                };
            };
        };
    };
}

const initialState: SetsState = {
    search_limit: SEARCH_DEFAULT,
    name: 'Draft',
    description: '',
    created_at: new Date(),
    updated_at: new Date(),
    static: true,
    active: [],
    review: [],
    hidden: [],
    associated_listings: [],
    changelog: [],
    compare_listing_ids: [],
    compare_listing_data: [],
    compare_listing_metrics: [],
    hiddenMarkers: [],
    user_listings: [],
    viewState: {
        latitude: 30.408588492199314,
        longitude: -86.30929857446615,
        zoom: '14.04',
        pitch: '0',
        bearing: '0',
        dragRotate: true,
        width: 969,
        height: 799,
    },
    viewport: null,
    lastFetchViewport: null,
    basemap: 'wheelhouse',
    exaggeration: 1.5,
    dataSources: {
        'potential-listing-points': {
            id: 'potential-listing-points',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        },
        'set-listing-points': {
            id: 'set-listing-points',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        },
        'review-listing-points': {
            id: 'review-listing-points',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        },
        'hidden-listing-points': {
            id: 'hidden-listing-points',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        },
    },
    layers: {
        potentialListings: {
            id: 'potentialListings',
            name: 'Potential listings',
            description: 'Listings within the viewport that are not in the set, hidden, or in review',
            type: 'Geojson',
            enabled: true,
        },
        inSetListings: {
            id: 'inSetListings',
            name: 'In-set listings',
            description: 'Listings within the set',
            type: 'Geojson',
            enabled: true,
        },
        reviewListings: {
            id: 'reviewListings',
            name: 'Review listings',
            description: 'Listings within review',
            type: 'Geojson',
            enabled: true,
        },
        hiddenListings: {
            id: 'hiddenListings',
            name: 'Hidden listings',
            description: 'Listings within hidden',
            type: 'Geojson',
            enabled: true,
        },
        postalCodes: {
            id: 'postalCodes',
            name: 'Postal codes',
            description: 'Postal codes for a given state in the US',
            type: 'Geojson',
            enabled: false,
        },
        buildings: {
            id: 'buildings',
            name: '3D Buildings',
            description: 'When zoomed in, view 3D buildings',
            type: 'Fill-extrusion',
            enabled: false,
        },
        hillShading: {
            id: 'hillShading',
            name: 'Hillshading',
            description: 'Provides realistic hill shading on exaggerated terrain',
            type: 'Fill-extrusion',
            enabled: false,
        },
    },
    spatialFilter: null,
    featuresReady: {
        [SOURCE.POTENTIAL_LISTINGS_SOURCE]: true,
        [SOURCE.SET_LISTINGS_SOURCE]: true,
        [SOURCE.REVIEW_LISTINGS_SOURCE]: true,
        [SOURCE.HIDDEN_LISTINGS_SOURCE]: true,
        [SOURCE.COMPARE_LISTINGS_SOURCE]: true,
    },
    listing: null,
    rightSidebarContent: 'charts',
    savedBoundaries: [],
    showSavedBoundaries: false,
    showLayers: false,
    previewBoundary: null,
    modalId: null,
    leftSidebarContent: 'active',
    boundaryType: null,
    feature: null,
    isFetchingListings: false,
    widgetsLoaded: true,
    mapLoaded: false,
    widgets: defaultWidgets,
    widgetsExpanded: true,
    showIntro: true,
    initialFetch: false,
    showWidgetConfiguration: false,
    pinLeftSidebar: true,
    modalSet: null,
    sidebars: [],
    showListingSidebar: false,
    isEditingBoundary: false,
    boundaryId: null,
    // MAP UI
    metricPeriod: '90_0',
    showRightSidebar: true,
    showLeftSidebar: false,
    autoSearch: false,
    popupTrigger: 'hover',
    popupOffset: 20,
    // POPUP UI
    popupConfiguration: localStorageTypes['sets-popup-configuration'].initial,
    sidebarListingConfiguration: localStorageTypes['sets-sidebar-listing-configuration'].initial,
    postalCodeLocation: null,
    preciseLocationEnabled: false,
    searchMarker: null,
};

export const addPotentialListings = createAction<{
    potential_listings: SetListingItem[];
    user_listings: SetListingItem[];
}>('sets/addPotentialListings');
export const fetchListingsButtonClicked = createAction('sets/fetchListingsButtonClicked');
export const resetLocalStorage = createAction('sets/resetLocalStorage');

export const generateFeatures = (shape) => {
    return shape?.map((coord) => {
        return {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [coord],
            },
        };
    });
};

const getLocalStorage = (key: string, initial: any) => {
    if (typeof window === 'undefined') {
        return initial;
    }

    const item = localStorage.getItem(key);

    return item !== null ? JSON.parse(item) : initial;
};

const setItemInLocalStorage = (key: string, payload: any) => {
    try {
        localStorage.setItem(key, JSON.stringify(payload));
    } catch (e) {
        if (e.name === 'QuotaExceededError' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
            console.warn('LocalStorage quota exceeded. Data not stored.');
        } else {
            console.error('Failed to store data in LocalStorage:', e);
        }
    }
};

const setLocalStorage = async (key: string, payload: any, merge?: boolean) => {
    if (merge) {
        const item = localStorage.getItem(key);
        const existing = item !== null ? JSON.parse(item) : localStorageTypes[key]?.initial;
        setItemInLocalStorage(key, {
            ...existing,
            ...payload,
        });
    } else {
        setItemInLocalStorage(key, payload);
    }

    return await new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, 1500);
    });
};

interface ErrorPayload {
    error: string;
}

export const saveSet = createAsyncThunk<
    CompSet,
    {
        name: string;
        kind: string;
        description?: string;
    },
    {
        state: {
            sets: SetsState;
            user: User;
            logged_in_as: User;
        };
        rejectValue: ErrorPayload;
    }
>('sets/saveSet', async ({ name, description, kind }, { getState, rejectWithValue }) => {
    const { active, hidden, review, dataSources } = getState().sets;
    // For admin testing the filter saving
    const logged_in_as = getState().logged_in_as;
    const is_admin = logged_in_as?.is_admin;

    // need to check if potential-listing-points is in the data sources and if it has either filters or spatial filter and if so grab them
    const potentialListingsSource = dataSources['potential-listing-points'];
    const spatialFilter = potentialListingsSource?.spatialFilter;

    // For admin testing, grab and transform the filters
    const filters = potentialListingsSource?.filters;
    const transformedFilters = transformFilters(filters);

    // if spatialfilter need to grab the geometry.coordinates
    const shape = spatialFilter?.geometry?.coordinates;
    const boundary = shape ? wrapSinglePolygonAsMulti(shape) : null;

    // return set;
    const activeListings = active.map((x) => x.listing_id);
    const hiddenListings = hidden.map((x) => x.listing_id);
    const reviewListings = review.map((x) => x.listing_id);

    const response = await postWithBody([
        compCreatePath(),
        {
            name,
            description,
            listing_ids: {
                active: activeListings,
                hidden: hiddenListings,
                review: reviewListings,
            },
            boundary,
            // Admin testing the saving of filter sets
            ...(is_admin && { filters: transformedFilters }),
            kind,
        },
    ]);

    if (response.status !== 200 && response.status !== 201) {
        const modifiedError = response.data.error || response.data;
        return rejectWithValue(modifiedError);
    }

    return response.data as CompSet;
});

// This is be the new version for saving a set BEFORE going into the setup process
export const initialSaveSet = createAsyncThunk<
    CompSet,
    {
        name: string;
        kind: string;
        description?: string;
        type?: string;
    },
    {
        state: {
            sets: SetsState;
        };
        rejectValue: ErrorPayload;
    }
>('sets/initialSaveSet', async ({ name, description, kind, type }, { getState, rejectWithValue }) => {
    const { id } = getState().sets;

    const body = {
        id,
        name,
        description,
        static: type == 'static',
        kind,
    };

    const response = await postWithBody([compCreatePath(), body]);

    if (response.status !== 200 && response.status !== 201) {
        const modifiedError = response.data.error || response.data;
        return rejectWithValue(modifiedError);
    }

    return response.data as CompSet;
});

export const addListingsToSet = createAsyncThunk<
    CompSet,
    {
        type: 'active' | 'hidden' | 'review';
        listings: SetListingItem[];
    },
    {
        state: {
            sets: SetsState;
        };
    }
>('sets/addListingsToSet', async ({ type, listings }, { getState, rejectWithValue }) => {
    const ids = listings.map((x) => x.listing_id);
    const { id, active, hidden, review } = getState().sets;

    if (!id) {
        // filter out the listings if they are already in the set
        const filteredActive = active.filter((x) => !ids.includes(x.listing_id));
        const filteredHidden = hidden.filter((x) => !ids.includes(x.listing_id));
        const filteredReview = review.filter((x) => !ids.includes(x.listing_id));

        const listings_with_metrics = {
            active: type === 'active' ? [...filteredActive, ...listings] : filteredActive,
            hidden: type === 'hidden' ? [...filteredHidden, ...listings] : filteredHidden,
            review: type === 'review' ? [...filteredReview, ...listings] : filteredReview,
        };

        await setLocalStorage(
            SETS_DRAFT_KEY,
            {
                listings_with_metrics,
            },
            true,
        );

        return {
            listings_with_metrics,
        };
    }

    const response = await fetch(compSetAddListingsPath(id), {
        method: 'PUT',
        body: JSON.stringify({
            [type]: ids,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200 && response.status !== 201) {
        const error = await response.json();
        const modifiedError = error.error || error;
        return rejectWithValue(modifiedError);
    }

    return await response.json();
});

export const deleteListingsFromSet = createAsyncThunk<
    CompSet,
    number[],
    {
        state: {
            sets: SetsState;
        };
    }
>('sets/deleteListingsFromSet', async (listings, { getState, rejectWithValue, dispatch }) => {
    const { id, active, hidden, review, dataSources } = getState().sets;
    const { 'set-listing-points': setListingPoints, 'review-listing-points': reviewListingPoints, 'hidden-listing-points': hiddenListingPoints } = dataSources;

    // Grab features from data sources to modify potential source
    const features = listings.map((id) => {
        const setListings = setListingPoints?.data?.features || [];
        const reviewListings = reviewListingPoints?.data?.features || [];
        const hiddenListings = hiddenListingPoints?.data?.features || [];
        const listings = [...setListings, ...reviewListings, ...hiddenListings];
        const feature = listings?.find((x) => x.properties.listing_id === id);
        return feature;
    });

    if (!id) {
        const listings_with_metrics = {
            active: active.filter((x) => !listings.includes(x.listing_id)),
            hidden: hidden.filter((x) => !listings.includes(x.listing_id)),
            review: review.filter((x) => !listings.includes(x.listing_id)),
        };

        await setLocalStorage(
            SETS_DRAFT_KEY,
            {
                listings_with_metrics,
            },
            true,
        );

        dispatch(
            updateDataSourceFeatures({
                id: 'potential-listing-points',
                method: 'add',
                features: features,
            }),
        );

        return {
            listings_with_metrics,
        };
    } else {
        const response = await fetch(compSetAddListingsPath(id), {
            method: 'DELETE',
            body: JSON.stringify({
                listing_ids: listings,
            }),
            headers: defaultHeaders(),
        });

        if (response.status !== 200 && response.status !== 201) {
            const error = await response.json();
            const modifiedError = error.error || error;
            return rejectWithValue(modifiedError);
        }
        // We need to put this listing back in potential without having to do a new fetch for potential
        dispatch(
            updateDataSourceFeatures({
                id: 'potential-listing-points',
                method: 'add',
                features: features,
            }),
        );

        return await response.json();
    }
});

export const fetchSetById = createAsyncThunk<
    CompSet,
    any,
    {
        state: {
            sets: SetsState;
        };
    }
>('sets/fetchSetById', async (id: any, { rejectWithValue }) => {
    // If no id, we should save this to local storage

    if (!id) {
        return getLocalStorage(SETS_DRAFT_KEY, initialSet);
    }

    const response = await fetch(compSetIdPath(id), {
        method: 'GET',
    });

    if (response.status !== 200 && response.status !== 201) {
        const error = await response.json();
        const modifiedError = error.error || error;
        return rejectWithValue(modifiedError);
    }

    return await response.json();
});

const setsSlice = createSlice({
    name: 'sets',

    initialState: (): SetsState => {
        let savedBoundaries = initialState.savedBoundaries;
        let widgets = initialState.widgets;
        let showIntro = initialState.showIntro;
        let popupConfiguration = initialState.popupConfiguration;
        let sidebarListingConfiguration = initialState.sidebarListingConfiguration;
        let ui: Partial<SetsState> = {};

        try {
            if (typeof window !== 'undefined') {
                showIntro = localStorage.getItem('sets-intro') ? false : true;
            }
            savedBoundaries = getLocalStorage(SETS_SAVED_BOUNDARIES_KEY, savedBoundaries);
            ui = getLocalStorage(SETS_UI_KEY, ui);
            popupConfiguration = getLocalStorage(SETS_POPUP_KEY, popupConfiguration);
            sidebarListingConfiguration = getLocalStorage(SETS_LISTING_SIDEBAR_KEY, sidebarListingConfiguration);
            widgets = getLocalStorage(SETS_WIDGETS_SIDEBAR_KEY, defaultWidgets);
        } catch (error) {
            console.warn('Error loading from local storage', error);
        }

        return {
            ...initialState,
            ...ui,
            showIntro,
            savedBoundaries,
            widgets,
            popupConfiguration,
            sidebarListingConfiguration,
        };
    },
    reducers: {
        setListingLoading: (state, action: PayloadAction<boolean>) => {
            state.isFetchingListings = action.payload;
        },
        setShowIntro: (state) => {
            state.showIntro = false;
        },
        setMapLoaded: (state) => {
            state.mapLoaded = true;
        },
        setWidgetsLoaded: (state) => {
            state.widgetsLoaded = true;
        },
        // For previewing the boundary on the map
        setPreviewBoundary: (state, action: PayloadAction<PreviewBoundary | null>) => {
            state.previewBoundary = action.payload;
        },
        setBoundaryId: (state, action: PayloadAction<string>) => {
            state.boundaryId = action.payload;
            state.modalId = modalIds.BOUNDARY;
        },
        setStatus: (state, action: PayloadAction<SetsState['status']>) => {
            state.status = action.payload;
        },
        setPostalCodeLocation: (state, action: PayloadAction<string | null>) => {
            state.postalCodeLocation = action.payload;
        },
        addSource: (
            state,
            action: PayloadAction<{
                type: string;
                id: string;
                spatialFilter?: SpatialFilter;
                data: {
                    type: string;
                    features: any[];
                };
            }>,
        ) => {
            if (action.payload.id in state.dataSources) {
                state.dataSources[action.payload.id] = {
                    ...state.dataSources[action.payload.id],
                    id: state.dataSources[action.payload.id].id,
                    data: {
                        type: 'FeatureCollection',
                        features: action.payload.data.features,
                    },
                    ...(action.payload.spatialFilter && {
                        spatialFilter: action.payload.spatialFilter,
                    }),
                };
            } else {
                state.dataSources[action.payload.id] = action.payload;
            }
        },
        addLayer: (state, action: PayloadAction<SetsState['layers'][0]>) => {
            state.layers[action.payload.id] = action.payload;
        },
        updateLayer: (
            state,
            action: PayloadAction<{
                id: string;
                layerAttributes: {
                    enabled: boolean;
                };
            }>,
        ) => {
            const layers = current(state).layers;
            const layer = layers[action.payload.id];
            if (layer) {
                const newLayer = { ...layer, ...action.payload.layerAttributes };
                state.layers[action.payload.id] = {
                    ...newLayer,
                };
            }
        },
        removeLayer: (state, action: PayloadAction<string>) => {
            delete state.layers[action.payload];
        },
        updateDataSourceFeatures: (
            state,
            action: PayloadAction<{
                id: string;
                features: any[];
                method: 'add' | 'replace';
            }>,
        ) => {
            const { id, features, method } = action.payload;
            const source = state.dataSources[id];
            if (method == 'add') {
                state.dataSources[id] = {
                    ...source,
                    data: {
                        type: 'FeatureCollection',
                        features: [...source.data.features, ...features],
                    },
                };
            }
        },
        setSearchLimit: (state, action: PayloadAction<number>) => {
            state.search_limit = action.payload;
        },
        // temporary
        updateSource: (
            state,
            action: PayloadAction<{
                type?: string;
                id: string;
                spatialFilter?: SpatialFilter;
                data: {
                    type: string;
                    features: any[];
                };
            }>,
        ) => {
            // action.payload.credentials = action.payload.credentials || state.credentials;
            if (action.payload.id in state.dataSources) {
                state.dataSources[action.payload.id] = {
                    ...state.dataSources[action.payload.id],
                    id: state.dataSources[action.payload.id].id,
                    data: {
                        type: 'FeatureCollection',
                        features: action.payload.data.features,
                    },
                    ...(action.payload.spatialFilter && {
                        spatialFilter: action.payload.spatialFilter,
                    }),
                };
            } else {
                state.dataSources[action.payload.id] = action.payload;
            }
        },
        removeSource: (state, action: PayloadAction<number>) => {
            delete state.dataSources[action.payload];
            removeWorker(action.payload);
        },
        setFeaturesReady: (
            state,
            action: PayloadAction<{
                sourceId: string;
                ready: boolean;
            }>,
        ) => {
            const { sourceId, ready } = action.payload;

            state.featuresReady = {
                ...state.featuresReady,
                [sourceId]: ready,
            };
        },
        startOver: (state) => {
            state.active = [];
            state.review = [];
            state.hidden = [];

            state.dataSources = {
                'potential-listing-points': {
                    id: 'potential-listing-points',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            ...state.dataSources['potential-listing-points'].data.features,
                            ...state.dataSources['set-listing-points'].data.features,
                            ...state.dataSources['review-listing-points'].data.features,
                            ...state.dataSources['hidden-listing-points'].data.features,
                        ],
                    },
                },
                'set-listing-points': {
                    id: 'set-listing-points',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                },
                'review-listing-points': {
                    id: 'review-listing-points',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                },
                'hidden-listing-points': {
                    id: 'hidden-listing-points',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                },
            };
        },
        resetState: (state) => {
            state.id = null;
            state.name = 'Draft';

            state.description = '';
            state.changelog = [];
            state.updated_at = new Date();
            state.associated_listings = [];
            state.active = [];
            state.review = [];
            state.hidden = [];
            state.compare_listing_ids = [];
            state.compare_listing_data = [];
            state.compare_listing_metrics = [];
            state.initialFetch = false;
            state.lastFetchViewport = null;
            state.viewport = null;
            state.mapLoaded = false;
            state.boundaryType = null;

            state.dataSources = {
                'potential-listing-points': {
                    id: 'potential-listing-points',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                },
                'set-listing-points': {
                    id: 'set-listing-points',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                },
                'review-listing-points': {
                    id: 'review-listing-points',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                },
                'hidden-listing-points': {
                    id: 'hidden-listing-points',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                },
            };
        },
        setBoundaryEditing: (state, action: PayloadAction<boolean>) => {
            state.isEditingBoundary = action.payload;
        },

        setViewState: (state, action: PayloadAction<viewState>) => {
            const viewState = action.payload;
            state.viewState = { ...state.viewState, ...viewState };
        },
        setViewPort: (state) => {
            const webMercatorViewport = new WebMercatorViewport(state.viewState);
            state.viewport = webMercatorViewport.getBounds();
        },

        addSpatialFilter: (
            state,
            action: PayloadAction<{
                sourceId: string;
                filter: SpatialFilter | undefined;
            }>,
        ) => {
            const { sourceId, filter } = action.payload;

            if (sourceId) {
                const source = state.dataSources[sourceId];

                if (source) {
                    state.dataSources[sourceId].spatialFilter = filter;
                    state.boundaryType = BOUNDARY_TYPE.POLYGON;
                }
            } else {
                state.spatialFilter = filter;
                state.boundaryType = BOUNDARY_TYPE.POLYGON;
            }
        },
        removeSpatialFilter: (state, action: PayloadAction<string>) => {
            const sourceId = action.payload;
            if (sourceId) {
                const source = state.dataSources[sourceId];

                if (source) {
                    source.spatialFilter = null;
                }
            }
            state.spatialFilter = null;
            state.boundaryType = null;
        },
        addColorizedWidget: (
            state,
            action: PayloadAction<{ id: string; data: any[]; type: string; column: string; operation?: string; ticks?: number[]; min?: number; max?: number }>,
        ) => {
            if (!state.colorizedWidget || state.colorizedWidget?.id !== action.payload.id) {
                state.colorizedWidget = action.payload;
            } else {
                state.colorizedWidget = null;
            }
        },

        removeColorizedWidget: (state) => {
            state.colorizedWidget = null;
        },
        addFilter: (
            state,
            action: {
                payload: {
                    id: string;
                    column: string;
                    type: string;
                    values: any[];
                    owner: string;
                    params?: any;
                };
            },
        ) => {
            const { id, column, type, values, owner, params } = action.payload;
            const source = state.dataSources[id];

            if (source) {
                if (!source.filters) {
                    source.filters = {};
                }

                if (!source.filters[column]) {
                    source.filters[column] = {};
                }

                source.filters[column][type] = { values, owner, params };
            }
        },
        removeFilter: (
            state,
            action: PayloadAction<{
                id: string;
                column: string;
                owner?: string;
            }>,
        ) => {
            const { id, column, owner } = action.payload;
            const source = state.dataSources[id];
            const initialWidgets = getLocalStorage(SETS_WIDGETS_SIDEBAR_KEY, defaultWidgets);

            // Need to delete the filter
            if (source && source.filters && source.filters[column]) {
                const typeToDelete = Object.entries(source.filters[column])
                    // @ts-ignore
                    .filter(([_, filter]) => filter && filter?.owner === owner)
                    .map(([filterType]) => filterType);

                typeToDelete.forEach((type) => delete source?.filters?.[column]?.[type]);

                if (!owner || Object.keys(source.filters[column]).length === 0) {
                    delete source.filters[column];
                }
            }

            // Need to update the widget to its original state
            if (histogramIds.includes(column)) {
                const originalWidgetState = initialWidgets.find((widget) => {
                    return column.includes(widget.id);
                });

                if (originalWidgetState) {
                    state.widgets = state.widgets.map((widget) => {
                        if (column.includes(widget.id)) {
                            return originalWidgetState;
                        }
                        return widget;
                    });
                }
            }
        },
        clearFilters: (state, action: PayloadAction<string>) => {
            const id = action.payload;
            const source = state.dataSources[id];
            const currentSource = current(state).dataSources[id];

            const initialWidgets = getLocalStorage(SETS_WIDGETS_SIDEBAR_KEY, defaultWidgets);
            // Need to update the widget to its original state
            if (currentSource && currentSource.filters) {
                Object.keys(currentSource.filters).forEach((column) => {
                    const originalWidgetState = initialWidgets.find((widget) => column.includes(widget.id));
                    if (originalWidgetState) {
                        state.widgets = state.widgets.map((widget) => {
                            if (column.includes(widget.id)) {
                                return originalWidgetState;
                            }
                            return widget;
                        });
                    }
                });
                // Need to delete the filters
                delete source.filters;
            }
        },
        setListing: (
            state,
            action: PayloadAction<{
                source?: string;
                properties: SetListingItem;
                type: string;
                id: number;
                layer: Layer;
                geometry: Geometry;
            } | null>,
        ) => {
            const listingData = action.payload;
            let listing;

            if (listingData?.type && listingData?.source) {
                const listingSource = current(state).dataSources[listingData?.source];

                listing = listingSource && listingSource.data.features.find((x) => x.properties.listing_id == listingData?.properties.listing_id);
            } else {
                listing = listingData;
            }

            if (!listing) {
                state.listing = null;
            } else {
                state.listing = listing;
            }
        },
        resetMap: (state) => {
            state.boundaryType = null;
            state.feature = {
                type: 'FeatureCollection',
                features: [],
            };
        },
        toggleWidgetConfigurationSidebar: (state) => {
            const { showWidgetConfiguration } = state;

            state.showWidgetConfiguration = !showWidgetConfiguration;
        },

        toggleSavedBoundaries: (state) => {
            const { showSavedBoundaries } = current(state);
            state.showSavedBoundaries = !showSavedBoundaries;
        },
        toggleLayers: (state) => {
            const { showLayers } = current(state);
            state.showLayers = !showLayers;
        },
        togglePinLeftSidebar: (state) => {
            const { pinLeftSidebar } = state;
            state.pinLeftSidebar = !pinLeftSidebar;
        },
        setBoundaryType: (state, action) => {
            state.boundaryType = action.payload;
        },
        resetBoundary: (state) => {
            state.boundaryType = null;
        },
        setLeftSidebarContent: (state, action: PayloadAction<SetsState['leftSidebarContent']>) => {
            state.leftSidebarContent = action.payload;
        },
        setRightSidebarContent: (state, action: PayloadAction<SetsState['rightSidebarContent']>) => {
            state.rightSidebarContent = action.payload;
        },

        openModal: (
            state,
            action: PayloadAction<{
                id: string;
                set?: any;
                modalProps?: { defaultIndex?: number };
            }>,
        ) => {
            const { id, set, modalProps } = action.payload;
            state.modalId = id;
            state.modalSet = set;
            state.modalProps = modalProps;
        },
        closeModal: (state) => {
            state.modalId = null;
            state.modalSet = null;
        },
        openSidebar: (state, action: PayloadAction<string>) => {
            const { sidebars } = state;
            const id = action.payload;
            if (!sidebars.includes(id)) {
                state.sidebars = [...sidebars, id];
            } else {
                state.sidebars = sidebars.filter((x) => x !== id);
            }
        },
        closeSidebar: (state, action: PayloadAction<string>) => {
            const { sidebars } = state;
            const id = action.payload;
            state.sidebars = sidebars.filter((x) => x !== id);
        },
        setWidgets: (state, action: PayloadAction<Widget[]>) => {
            state.widgets = action.payload;

            setLocalStorage(SETS_WIDGETS_SIDEBAR_KEY, action.payload);
        },
        resetWidgets: (state) => {
            state.widgets = defaultWidgets;
            setLocalStorage(SETS_WIDGETS_SIDEBAR_KEY, defaultWidgets);
        },
        saveBoundary: (state, action: PayloadAction<SaveBoundary>) => {
            const existingBoundaries = state.savedBoundaries;
            const isExisting = existingBoundaries.find((x) => x.id === action.payload.id);

            if (isExisting) {
                state.savedBoundaries = existingBoundaries.map((x) => {
                    if (x.id === action.payload.id) {
                        return action.payload;
                    }

                    return x;
                });
            } else {
                state.savedBoundaries = [...existingBoundaries, action.payload];
            }

            setLocalStorage(SETS_SAVED_BOUNDARIES_KEY, state.savedBoundaries);
        },
        deleteBoundary: (state, action: PayloadAction<string>) => {
            const existingBoundaries = state.savedBoundaries;

            state.savedBoundaries = existingBoundaries.filter((x) => x.id !== action.payload);

            setLocalStorage(SETS_SAVED_BOUNDARIES_KEY, state.savedBoundaries);
        },

        setWidgetsExpanded: (state, action: PayloadAction<boolean>) => {
            state.widgetsExpanded = action.payload;
        },
        // SETS UI
        setUISetting: (
            state,
            action: PayloadAction<{
                key: string;
                value: any;
            }>,
        ) => {
            const { key, value } = action.payload;
            state[key] = value;
            setLocalStorage(
                SETS_UI_KEY,
                {
                    [key]: value,
                },
                true,
            );
        },
        setPopupUI: (
            state,
            action: PayloadAction<{
                key: string;
                value: any;
            }>,
        ) => {
            const { key, value } = action.payload;
            state.popupConfiguration[key] = value;

            setLocalStorage(
                SETS_POPUP_KEY,
                {
                    [key]: value,
                },
                true,
            );
        },
        setListingSidebarUI: (
            state,
            action: PayloadAction<{
                key: string;
                value: any;
            }>,
        ) => {
            const { key, value } = action.payload;
            state.sidebarListingConfiguration[key] = value;

            setLocalStorage(
                SETS_LISTING_SIDEBAR_KEY,
                {
                    [key]: value,
                },
                true,
            );
        },
        // Whether to fetch new listings when map moves
        setAutoSearch: (state, action: PayloadAction<boolean>) => {
            state.autoSearch = action.payload;
        },
        toggleLeftSidebar: (state, action: PayloadAction<SetsState['leftSidebarContent']>) => {
            const { showLeftSidebar, leftSidebarContent } = state;
            if (action.payload === leftSidebarContent) {
                state.showLeftSidebar = !showLeftSidebar;
            } else {
                state.showLeftSidebar = true;
                state.leftSidebarContent = action.payload;
            }
        },
        closeLeftSidebar: (state) => {
            state.showLeftSidebar = false;
        },
        setMetricPeriod: (state, action: PayloadAction<'0_365' | '365_0' | '90_0' | '0_90'>) => {
            state.metricPeriod = action.payload;
        },
        toggleRightSidebar: (state) => {
            const { showRightSidebar } = state;

            state.showRightSidebar = !showRightSidebar;
        },
        setBasemap: (state, action: PayloadAction<string>) => {
            state.basemap = action.payload;
        },
        setPreciseLocationEnabled: (state, action) => {
            state.preciseLocationEnabled = action.payload;
        },
        toggleMarkers: (state, action) => {
            const markers = current(state).hiddenMarkers;
            if (markers.includes(action.payload)) {
                state.hiddenMarkers = markers.filter((x) => x !== action.payload);
            } else {
                state.hiddenMarkers = [...markers, action.payload];
            }
        },
        setExaggeration: (state, action) => {
            state.exaggeration = action.payload;
        },
        addSearchMarker: (state, action) => {
            state.searchMarker = action.payload;
        },
        removeSearchMarker: (state) => {
            state.searchMarker = null;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed

        builder
            .addCase(addPotentialListings, (state, action) => {
                const { potential_listings, user_listings } = action.payload;

                const filteredListings = potential_listings.filter((listing) => {
                    return (
                        !state.active.find((active_listing) => active_listing.listing_id === listing.listing_id) &&
                        !state.hidden.find((hidden_listing) => hidden_listing.listing_id === listing.listing_id) &&
                        !state.review.find((review_listing) => review_listing.listing_id === listing.listing_id)
                    );
                });

                const filteredUserListings = user_listings.filter((listing) => {
                    return !state.compare_listing_ids.find((id) => id === listing.listing_id);
                });

                const features = generateListingFeatures(filteredListings);
                state.user_listings = filteredUserListings;
                state.initialFetch = true;
                const viewport = current(state).viewport;
                state.lastFetchViewport = viewport;

                state.dataSources['potential-listing-points'] = {
                    ...state.dataSources['potential-listing-points'],
                    id: state.dataSources['potential-listing-points'].id,
                    data: {
                        type: 'FeatureCollection',
                        features: features,
                    },
                };
                state.isFetchingListings = false;
            })
            .addCase(fetchComparisonListings.fulfilled, (state, action) => {
                state.compare_listing_ids = action.payload?.compare_listing_ids;
                state.compare_listing_data = action.payload?.compare_listing_data;
                state.compare_listing_metrics = action.payload?.compare_listing_metrics;
            })
            .addCase(saveSet.fulfilled, (state, action) => {
                localStorage.removeItem(SETS_DRAFT_KEY);
                state.name = action.payload.name;
                state.description = action.payload.description;
                state.id = action.payload.id;
                state.static = action.payload.static;
            })
            .addCase(initialSaveSet.fulfilled, (state, action) => {
                localStorage.removeItem(SETS_DRAFT_KEY);
                state.name = action.payload.name;
                state.description = action.payload.description;
                state.id = action.payload.id;
                state.static = action.payload.static;
            })
            .addCase(addListingsToSet.fulfilled, (state, action) => {
                const active = action.payload.listings_with_metrics.active;
                state.active = active;
                const review = action.payload.listings_with_metrics.review;
                state.review = review;
                const hidden = action.payload.listings_with_metrics.hidden;
                state.hidden = hidden;

                const inSetFeatures = generateListingFeatures([...active]);
                const reviewFeatures = generateListingFeatures([...review]);
                const hiddenFeatures = generateListingFeatures([...hidden]);

                state.dataSources['set-listing-points'] = {
                    ...state.dataSources['set-listing-points'],
                    id: state.dataSources['set-listing-points'].id,
                    data: {
                        type: 'FeatureCollection',
                        features: inSetFeatures,
                    },
                };

                state.dataSources['review-listing-points'] = {
                    ...state.dataSources['review-listing-points'],
                    id: state.dataSources['review-listing-points'].id,
                    data: {
                        type: 'FeatureCollection',
                        features: reviewFeatures,
                    },
                };

                state.dataSources['hidden-listing-points'] = {
                    ...state.dataSources['hidden-listing-points'],
                    id: state.dataSources['hidden-listing-points'].id,
                    data: {
                        type: 'FeatureCollection',
                        features: hiddenFeatures,
                    },
                };

                const currentState = current(state);

                // filter out listings that are already in the set from data source potential-listing-points
                const potentialListings = currentState.dataSources['potential-listing-points'].data.features.filter((x) => {
                    return ![...inSetFeatures, ...reviewFeatures, ...hiddenFeatures].find((y) => y.id === x.id);
                });

                // filter out listings that are now part of set states
                state.dataSources['potential-listing-points'] = {
                    ...currentState.dataSources['potential-listing-points'],
                    id: currentState.dataSources['potential-listing-points'].id,
                    data: {
                        type: 'FeatureCollection',
                        features: potentialListings,
                    },
                };
            })
            .addCase(deleteListingsFromSet.fulfilled, (state, action) => {
                const active = action.payload.listings_with_metrics.active;
                state.active = active;
                const review = action.payload.listings_with_metrics.review;
                state.review = review;
                const hidden = action.payload.listings_with_metrics.hidden;
                state.hidden = hidden;
                const inSetFeatures = generateListingFeatures([...active]);
                const reviewFeatures = generateListingFeatures([...review]);
                const hiddenFeatures = generateListingFeatures([...hidden]);

                state.dataSources['set-listing-points'] = {
                    ...state.dataSources['set-listing-points'],
                    id: state.dataSources['set-listing-points'].id,
                    data: {
                        type: 'FeatureCollection',
                        features: inSetFeatures,
                    },
                };

                state.dataSources['review-listing-points'] = {
                    ...state.dataSources['review-listing-points'],
                    id: state.dataSources['review-listing-points'].id,
                    data: {
                        type: 'FeatureCollection',
                        features: reviewFeatures,
                    },
                };

                state.dataSources['hidden-listing-points'] = {
                    ...state.dataSources['hidden-listing-points'],
                    id: state.dataSources['hidden-listing-points'].id,
                    data: {
                        type: 'FeatureCollection',
                        features: hiddenFeatures,
                    },
                };
            })

            .addCase(fetchSetById.fulfilled, (state: SetsState, action) => {
                state.id = action.payload.id as number;
                state.name = action.payload?.name;
                state.description = action.payload?.description;
                state.updated_at = action.payload?.updated_at;
                state.changelog = action.payload?.changelog;
                state.kind = action.payload?.kind;

                state.associated_listings = action.payload?.associated_listings;

                const active = action.payload?.listings_with_metrics?.active || [];
                const review = action.payload?.listings_with_metrics?.review || [];
                const hidden = action.payload?.listings_with_metrics?.hidden || [];

                state.active = action.payload.listings_with_metrics.active;
                state.review = action.payload.listings_with_metrics.review;
                state.hidden = action.payload.listings_with_metrics.hidden;

                const inSetFeatures = generateListingFeatures([...active]);
                const reviewFeatures = generateListingFeatures([...review]);
                const hiddenFeatures = generateListingFeatures([...hidden]);

                const boundary = generateBoundary(action?.payload?.boundary);

                const filters = action.payload?.filters || {};

                state.dataSources['set-listing-points'] = {
                    ...state.dataSources['set-listing-points'],
                    id: state.dataSources['set-listing-points'].id,
                    data: {
                        type: 'FeatureCollection',
                        features: inSetFeatures,
                    },
                };

                if (filters) {
                    const modifiedFilters = untransformFilters(filters, state.widgets);

                    state.dataSources['potential-listing-points'] = {
                        ...state.dataSources['set-listing-points'],
                        filters: modifiedFilters,
                    };
                }

                if (boundary) {
                    state.dataSources['potential-listing-points'] = {
                        ...state.dataSources['potential-listing-points'],
                        spatialFilter: boundary,
                    };
                    state.boundaryType = BOUNDARY_TYPE.POLYGON;
                }

                state.dataSources['review-listing-points'] = {
                    ...state.dataSources['review-listing-points'],
                    id: state.dataSources['review-listing-points'].id,
                    data: {
                        type: 'FeatureCollection',
                        features: reviewFeatures,
                    },
                };

                state.dataSources['hidden-listing-points'] = {
                    ...state.dataSources['hidden-listing-points'],
                    id: state.dataSources['hidden-listing-points'].id,
                    data: {
                        type: 'FeatureCollection',
                        features: hiddenFeatures,
                    },
                };
                const widgetsToUpdate = {};

                // Process filters to create properties for each filter
                Object.keys(filters).forEach((key) => {
                    if (histogramIds.includes(key)) {
                        // Using the min and max values, generate a set of ten ticks
                        const ticks: number[] = [];
                        const min = filters[key].lower;
                        const max = filters[key].upper;
                        const step = (max - min) / 10;

                        for (let i = 0; i <= 10; i++) {
                            const tick = min + step * i;
                            ticks.push(tick);
                        }

                        widgetsToUpdate[key] = {
                            min,
                            max,
                        };
                    }
                });

                // Update the widgets in the state
                const updatedWidgets = state.widgets.map((widget) => {
                    // Check if the widget.id matches any key in widgetsToUpdate
                    const matchingKey = Object.keys(widgetsToUpdate).find((key) => {
                        if (key.includes(widget.id)) {
                            return key;
                        } else {
                            return null;
                        }
                    });
                    const widgetToUpdate = matchingKey && widgetsToUpdate[matchingKey];

                    if (widgetToUpdate) {
                        delete widget.ticks;
                        return {
                            ...widget,
                            bins: widget?.bins || 10,
                            min: widgetToUpdate.min,
                            max: widgetToUpdate.max,
                        };
                    } else {
                        return widget; // Return the widget as is if no match
                    }
                });

                // Update the state with the updated widgets
                state.widgets = updatedWidgets;

                const listingCount = [...active, ...review, ...hidden]?.length;

                state.status = state.id ? STATUS.SETUP : listingCount > 0 ? STATUS.CONTINUE : STATUS.LOCATION;
            })
            .addCase(fetchSetById.rejected, (state) => {
                // state.compareInSetListing(state, action) => {
                // state.compareInSetListings = action.payload;
                const localStorageSet = localStorage.getItem(SETS_DRAFT_KEY);
                if (localStorageSet) {
                    const set = JSON.parse(localStorageSet);

                    if (set) {
                        // set the id
                        state.id = set?.id;

                        // set the name
                        state.name = set.name;

                        // set the description
                        state.description = set.description;

                        // set the active flag
                        state.active = set.listings_with_metrics.active;

                        // set the review flag
                        state.review = set.listings_with_metrics.review;

                        // set the hidden flag
                        state.hidden = set.listings_with_metrics.hidden;

                        // if there are inSetListings
                        if (set?.inSetListings?.length > 0) {
                            // set the status to continue
                            state.status = STATUS.CONTINUE;
                        } else {
                            // otherwise set the status to location
                            state.status = STATUS.LOCATION;
                        }

                        // get the spatialFilter
                        const source = state.dataSources['potential-listing-points'];
                        if (source && set?.spatialFilter) {
                            state.dataSources['potential-listing-points'].spatialFilter = set.spatialFilter;
                        } else if (set?.spatialFilter) {
                            state.spatialFilter = set.spatialFilter;
                        }
                    }
                }
            });
    },
});

export const {
    addColorizedWidget,
    addFilter,
    addLayer,
    addSource,
    addSpatialFilter,
    clearFilters,
    closeLeftSidebar,
    closeModal,
    closeSidebar,
    deleteBoundary,
    openModal,
    openSidebar,
    removeColorizedWidget,
    removeFilter,
    removeSpatialFilter,
    resetBoundary,
    resetMap,
    resetState,
    startOver,
    resetWidgets,
    saveBoundary,
    setAutoSearch,
    setBasemap,
    setBoundaryEditing,
    setBoundaryId,
    setBoundaryType,
    setExaggeration,
    setLeftSidebarContent,
    setListing,
    setListingLoading,
    setListingSidebarUI,
    setMapLoaded,
    setMetricPeriod,
    setPopupUI,
    setPostalCodeLocation,
    setPreciseLocationEnabled,
    setPreviewBoundary,
    setRightSidebarContent,
    setSearchLimit,
    setShowIntro,
    setStatus,
    setUISetting,
    setViewPort,
    setWidgets,
    setWidgetsExpanded,
    setWidgetsLoaded,
    toggleLayers,
    toggleLeftSidebar,
    toggleMarkers,
    togglePinLeftSidebar,
    toggleRightSidebar,
    toggleSavedBoundaries,
    toggleWidgetConfigurationSidebar,
    updateDataSourceFeatures,
    updateLayer,
    updateSource,
    addSearchMarker,
    removeSearchMarker,
} = setsSlice.actions;

export const selectSpatialFilter = (state, sourceId) => {
    let spatialFilterGeometry = state.sets.spatialFilter;
    if (spatialFilterGeometry?.properties?.disabled) {
        spatialFilterGeometry = null;
    }
    return sourceId ? state?.sets?.dataSources[sourceId]?.spatialFilter || spatialFilterGeometry : spatialFilterGeometry;
};

const debouncedSetViewPort = debounce((dispatch, setViewPort) => {
    dispatch(setViewPort());
}, 1000);

export const setViewState = (viewState) => {
    return (dispatch, getState) => {
        /**
         * "transition" deck props contain non-serializable values, like:
         *  - transitionInterpolator: instance of LinearInterpolator
         *  - transitionEasing: function
         * To prevent the Redux checker from failing: removing all "transition" properties in the state
         * If need it, transitions should be handled in layer components
         */
        for (const viewProp of NOT_ALLOWED_DECK_PROPS) {
            delete viewState[viewProp];
        }

        dispatch(setsSlice.actions.setViewState(viewState));
        debouncedSetViewPort(dispatch, setsSlice.actions.setViewPort);
    };
};

const NOT_ALLOWED_DECK_PROPS = ['transitionDuration', 'transitionEasing', 'transitionInterpolator', 'transitionInterruption'];

export const fetchComparisonListings = createAsyncThunk<
    {
        compare_listing_data: any[];
        compare_listing_metrics: any;
        compare_listing_ids: number[];
    },
    number[],
    {
        state: {
            sets: SetsState;
        };
    }
>('sets/fetchComparisonListings', async (ids: number[], { dispatch, rejectWithValue }) => {
    // fetch via boundary, viewport, or market

    const response = await fetch(compCandidatesPath(), {
        method: 'POST',
        body: JSON.stringify({
            listing_ids: ids,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(await response.json());
    }

    const data = await response.json();
    const { user_listings } = data;

    const modified_listings = user_listings.map((listing) => {
        const { amenities, ...rest } = listing;
        return {
            amenities: amenities ? listing.amenities.split(',') : [],
            ...rest,
        };
    });

    const metrics = reduceListingsMetrics(modified_listings);

    // Show the comparison listings on the map
    const features = generateListingFeatures(modified_listings);

    dispatch(
        updateSource({
            id: 'comparison-listing-points',
            data: {
                type: 'FeatureCollection',
                features,
            },
        }),
    );

    return {
        compare_listing_data: modified_listings,
        compare_listing_metrics: metrics,
        compare_listing_ids: ids,
    };
});

export const generateListingFeatures = (listings) => {
    if (!listings) {
        return [];
    }
    return listings.map((listing) => {
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [listing.long, listing.lat],
            },
            id: listing.listing_id,
            properties: {
                ...listing,
            },
        };
    });
};

export const generateBoundary = (boundary) => {
    if (!boundary) {
        return null;
    }
    return {
        type: 'Feature',
        properties: {},
        geometry: {
            coordinates: boundary,
            type: 'Polygon',
        },
    };
};

export default setsSlice.reducer;
