import { PayloadAction } from '@reduxjs/toolkit';
import { filterSchema } from 'libs/global/components/Filters/schema';
import type { ActiveFilter, CheckboxFilter, FilterSchema, TagsFilter } from 'libs/types/filters';

export type ListingFilters = {
    staticSchema: Record<string, FilterSchema> & { tags?: TagsFilter };
    active: Record<string, ActiveFilter>;
    activeComparison: Record<string, ActiveFilter>;
    initialFilters?: Record<string, ActiveFilter>;
};

export const defaultListingFilters = {
    staticSchema: filterSchema,
    active: {},
    activeComparison: {},
    initialFilters: undefined,
};

export type ActiveFilterId = 'active' | 'activeComparison';

export const listingFilterReducers = {
    updateStaticSchema: (state: { filters: ListingFilters }, action: PayloadAction<Record<string, any>>) => {
        Object.entries(action.payload).forEach(([key, value]) => {
            (state.filters.staticSchema[key] as CheckboxFilter).options = value;
        });
    },
    addFilters: (state: { filters: ListingFilters }, action: PayloadAction<{ filterId?: ActiveFilterId; filters: Record<string, ActiveFilter> }>) => {
        const filterId = action.payload.filterId || 'active';
        state.filters[filterId] = {
            ...state.filters[filterId],
            ...action.payload.filters,
        };
    },
    updateFilter: (
        state: { filters: ListingFilters },
        action: PayloadAction<{ filters: Record<string, ActiveFilter>; replace: string; filterId?: ActiveFilterId }>,
    ) => {
        const filterId = action.payload.filterId || 'active';
        const { filters, replace } = action.payload;
        delete state.filters[filterId][replace];
        state.filters[filterId] = {
            ...state.filters[filterId],
            ...filters,
        };
    },
    clearFilters: (state: { filters: ListingFilters }, action: PayloadAction<ActiveFilterId>) => {
        const filterId = action.payload || 'active';
        state.filters[filterId] = {};
    },
    restoreFilters: (
        state: { filters: ListingFilters },
        action: PayloadAction<{ defaultFilters: Record<string, ActiveFilter>; filterId?: ActiveFilterId }>,
    ) => {
        const { defaultFilters, filterId = 'active' } = action.payload;
        state.filters[filterId] = { ...defaultFilters };
    },
    removeFilter: (state: { filters: ListingFilters }, action: PayloadAction<{ property: string; filterId?: ActiveFilterId }>) => {
        const filterId = action.payload.filterId || 'active';
        delete state.filters[filterId][action.payload.property];
    },
    setInitialFilters: (state: { filters: ListingFilters }, action: PayloadAction<Record<string, ActiveFilter>>) => {
        state.filters.initialFilters = action.payload;
        state.filters.active = action.payload;
    },
};
