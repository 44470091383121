import { useAppSelector } from 'libs/global/hooks';

export const useRemainingCredit = () => {
    const account = useAppSelector(({ billing_account_info }) => billing_account_info);
    if (!account) {
        return 0;
    }

    // Credit is in cents.
    const credit = (account.credit || 0) / 100.0;
    return Math.max(credit, 0);
};

export const useUserLevel = () => {
    const user = useAppSelector(({ logged_in_as }) => logged_in_as);
    return user ? user.user_level : 0;
};

export const useUserFullName = () => {
    const user = useAppSelector(({ user }) => user);
    return user ? [user.first_name, user.last_name].join(' ') : '';
};

export const useIsAdmin = () => {
    const loggedInAs = useAppSelector(({ logged_in_as }) => logged_in_as);
    return loggedInAs ? loggedInAs.is_admin : false;
};
