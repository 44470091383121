import React from 'react';

const metricDefinitionsUnique = {
    calendar_nights: {
        name: 'Calendar nights',
        description: 'Number of nights - irrespective of listing status - that are in a timeframe.',
    },
    bookable_nights: {
        name: 'Bookable nights',
        description: 'Number of nights that can be booked by a guest, i.e excluding blocked nights.',
    },
    blocked_nights: {
        name: 'Blocked nights',
        description: 'Number of nights that are blocked off by the host.',
    },
    booked_nights: {
        name: 'Booked nights',
        description: 'Number of nights that are booked by a guest.',
    },
    available_nights: {
        name: 'Available nights',
        description: 'Number of nights that are still available to be booked by a guest.',
    },
    asking_rate: {
        name: 'Asking Rate',
        description: 'Average nightly price of bookable nights, i.e. excluding blocked nights, in a time-frame.',
    },
    openness: {
        name: 'Openness',
        description: 'Percentage of bookable nights in a time-frame: bookable nights / calendar nights',
    },
    occupancy: {
        name: 'Occupancy',
        description:
            'Percentage of nights booked out of total nights in a time-frame: booked nights / calendar nights. This means that “Occupancy” = “Adjusted Occupancy” * “Openness”.',
    },
    adjusted_occupancy: {
        name: 'Adjusted Occupancy',
        short_name: 'Adj. Occupancy',
        description: 'Percentage of nights booked out of bookable nights, i.e. excluding blocked nights, in a time-frame: booked nights / bookable nights',
    },
    nightly_revenue: {
        name: 'Nightly Revenue',
        description: 'Sum of nightly prices of booked nights in a time-frame.',
    },
    nightly_revenue30: {
        name: '30-day Nightly Revenue',
        description: 'Sum of nightly prices of booked nights within 30 days.',
    },
    total_revenue: {
        name: 'Total Revenue',
        short_name: 'Tot. Revenue',
        description:
            'Sum of total revenue from reservations (incl. fees) in a time-frame. Reservations that cross time-frame boundaries are split up by number of days.',
    },
    average_nightly_rate: {
        name: 'Average Nightly Rate',
        short_name: 'Avg. Nightly Rate',
        description: 'Average nightly price of booked nights in a time-frame.',
    },
    average_daily_rate: {
        name: 'Average Daily Rate',
        short_name: 'Avg. Daily Rate',
        description: 'Total revenue in a time-frame divided by number of booked nights in the time-frame.',
    },
    revpar: {
        name: 'RevPAR',
        description: 'Total revenue in a time-frame divided by number of calendar nights in the time-frame. Equals ADR * Occupancy.',
    },
    adjusted_revpar: {
        name: 'Adjusted RevPAR',
        short_name: 'Adj. RevPAR',
        description: 'Total revenue in a time-frame divided by number of bookable nights in the time-frame. Equals ADR * Adjusted Occupancy.',
    },
    nightly_revpar: {
        name: 'Nightly RevPAR',
        description: 'Nightly revenue in a time-frame divided by number of calendar nights in the time-frame. Equals ANR * Occupancy.',
    },
    adjusted_nightly_revpar: {
        name: 'Adjusted Nightly RevPAR',
        short_name: 'Adj. Nightly RevPAR',
        description: 'Nightly revenue in a time-frame divided by number of bookable nights in the time-frame. Equals ANR * Adjusted Occupancy.',
    },
    lead_time: {
        name: 'Lead Time',
        description: 'Average number of days between booking date and check in date for reservations in a time-frame.',
    },
    length_of_stay: {
        name: 'Length of Stay',
        description: 'Average length (number of days between check-in and check-out) for reservations in a time-frame.',
    },
};

const metricDefinitions = {
    ...metricDefinitionsUnique,
    // Copies based on different keys
    occupancy_open: metricDefinitionsUnique.adjusted_occupancy,
    aar: metricDefinitionsUnique.asking_rate,
    anr: metricDefinitionsUnique.average_nightly_rate,
    nightly_rate: metricDefinitionsUnique.average_nightly_rate,
    nrevpar_open: metricDefinitionsUnique.adjusted_nightly_revpar,
    nrevpar: metricDefinitionsUnique.nightly_revpar,
    los: metricDefinitionsUnique.length_of_stay,
    available_rooms: metricDefinitionsUnique.bookable_nights,
    nrevenue: metricDefinitionsUnique.nightly_revenue,
    nrevenue30: metricDefinitionsUnique.nightly_revenue30,
    apr: metricDefinitionsUnique.asking_rate,
    revpar_non_blocked: metricDefinitionsUnique.adjusted_revpar,
};

export const metricName = (metric_id: string, abbr = false) => {
    const { name, short_name }: { name: string; short_name: string } = metricDefinitions[metric_id] || {};
    return abbr ? short_name || name : name;
};

export const metricDescription = (metric_id: string): string | undefined => {
    return metricDefinitions[metric_id]?.description;
};

export const metricDescriptionCombined = (metric_ids: string[]) => {
    return {
        name: metricDefinitions[metric_ids[0]].name,
        description: (
            <div>
                {metric_ids.map((metric_id) => {
                    const { name, description } = metricDefinitions[metric_id];
                    return (
                        <div key={metric_id} style={{ marginTop: 10 }}>
                            <div>{name}</div>
                            <div>{description}</div>
                        </div>
                    );
                })}
            </div>
        ),
    };
};

export default metricDefinitions;
