import calendarUi from '../slices/calendarUi';
import { combineReducers } from 'redux';
import compState from '../slices/compState';
import { PayloadAction } from '@reduxjs/toolkit';
import dailySyncCount from '../slices/dailySyncCount';
import dashboardUi from '../slices/dashboardUi';
import guide from '../slices/guide';
import marketsState from '../slices/marketsState';
import pricingTiers from '../slices/pricingTiers';
import pricingChart from '../slices/pricingChart';
import raw from '../slices/raw';
import recentListings from '../slices/recentListings';
import settings from '../slices/settings';
import ui from '../slices/ui';
import server_ui from '../slices/serverUi';
import listingReservationUi from '../slices/listingReservationUi';
import bookingUi from '../slices/bookingUi';
import proState from '../slices/proState';
import user from '../slices/user';
import sets from '../slices/sets';
import userFlags from '../slices/userFlags';
import { User } from 'libs/types/user';
import type { Reducer } from 'redux';
import { BillingAccountInfo, BillingInfo, BillingUserInfo, SubscriptionInfo } from 'libs/types/billing';
import { PricingTierDiscount } from 'libs/types/pricingTier';
import { Listing } from 'libs/types/listing';
import { ChannelAccount } from 'libs/types/channelAccount';
import { Market } from 'libs/types/market';
import { Stats } from 'libs/types/stats';
import { RatePeriodState } from 'libs/types/ratePeriod';
import { BaseCalendarDay } from 'libs/types/calendarDay';
import subscriptionsUi from '../slices/subscriptionsUi';
import assignListingsToSetUi from '../slices/assignListingsToSetUi';
import { ajaxUpdate } from './ajaxUpdate';

const reducers = {
    assignListingsToSetUi,
    billing_account_info: raw('billing_account_info') as Reducer<BillingAccountInfo>,
    billing_info: raw('billing_info') as Reducer<BillingInfo>,
    billing_user_info: raw('billing_user_info') as Reducer<BillingUserInfo>,
    bookingUi,
    calendar: raw('calendar', {}) as Reducer<Record<string, BaseCalendarDay>>,
    calendarUi,
    channel_account: raw('channel_account') as Reducer<ChannelAccount>,
    compState,
    dailySyncCount,
    dashboardUi,
    guide,
    has_referred_promo_waiting: raw('has_referred_promo_waiting', false) as Reducer<boolean>,
    listingReservationUi,
    listing: raw('listing') as Reducer<Listing>,
    listing_synced_at: raw('listing_synced_at') as Reducer<string>,
    logged_in_as: raw('logged_in_as') as Reducer<User>,
    market: raw('market') as Reducer<Market>,
    marketsState,
    pricingChart,
    pricingTiers, // Used to store UI state of pricing tier operations.
    pricing_tier_discounts: raw('pricing_tier_discounts', []) as Reducer<PricingTierDiscount[]>,
    proState,
    rate_periods: raw('rate_periods', { current: [], dirty: [] }) as Reducer<RatePeriodState>,
    recentListings,
    server_ui,
    settings,
    stats: raw('stats') as Reducer<Stats>,
    subscription_info: raw('subscription_info') as Reducer<SubscriptionInfo>,
    subscriptionsUi,
    ui,
    user,
    user_flags: userFlags,
    sets,
};

const wrappedReducers = {} as typeof reducers;
Object.keys(reducers).forEach((key) => {
    wrappedReducers[key] = (state: any, action: PayloadAction<any>) => {
        switch (action.type) {
            case ajaxUpdate.toString():
                if (action.payload && key in action.payload) {
                    return action.payload[key];
                } else {
                    return state;
                }
            default:
                return reducers[key](state, action);
        }
    };
});

export default combineReducers(wrappedReducers);
