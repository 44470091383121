export const baseSizes = {
    px: '1px',
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '0.625rem',
    4: '0.75rem',
    5: '1rem',
    6: '1.25rem',
    8: '1.5rem',
    10: '2rem',
    12: '2.5rem',
    16: '3rem',
    20: '4rem',
    24: '5rem',
    32: '6rem',
    40: '8rem',
    48: '10rem',
    56: '12rem',
    64: '14rem',
    72: '16rem',
};

const largeSizes = {
    full: '100%',
    '3xs': '14rem',
    '2xs': '16rem',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
    '8xl': '96rem',
    '9xl': '112rem',
};

const containers = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1440px',
};

const sizes = {
    ...baseSizes,
    ...largeSizes,
    containers,
};

export default sizes;
