import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    chakra,
    Checkbox,
    Flex,
    Grid,
    Icon,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Tab,
    TabIndicator,
    Table,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useCheckbox,
    useCheckboxGroup,
} from '@chakra-ui/react';
import { Check, Plus, X } from 'phosphor-react';
import { isNumber } from 'lodash';
import { useRouter } from 'next/router';
import ReactPaginate from 'react-paginate';

import { closeModal, fetchSetById } from 'libs/global/slices/sets';
import { ImageWithFallback, ModalTitle } from '../components';
import { useAppDispatch, useAppSelector } from 'libs/global/hooks';
import { CompSet, SetListingItem } from 'libs/types/set';
import { ListingV2 } from 'libs/types/listingV2';
import { useAssignListingsToSet, useSetId, useUnassignListingsToSet } from 'libs/global/hooks/useSets';
import { Filters } from '../../Filters';
import { addFilters, removeFilter, updateFilter } from 'libs/global/slices/assignListingsToSetUi';
import { useAssignUserListingsToSet, usePortfolioStaticSchema } from 'libs/global/hooks/useAssignUserListingsToSet';
import { actions } from 'libs/global/slices/compState';
import { useIsAdmin } from 'libs/global/hooks/user';

export const ManageAssignedModalId = 'manage';

const ListingLoadingSkeleton = () => {
    return (
        <Tr>
            <Td py={6} pl={6} width="full">
                <Skeleton startColor="gray.100" endColor="gray.100A" height="5" />
            </Td>
            <Td py={6} width="full">
                <Skeleton startColor="gray.100" endColor="gray.100A" height="5" />
            </Td>
            <Td py={6} width="full">
                <Skeleton startColor="gray.100" endColor="gray.100A" height="5" />
            </Td>
            <Td py={6} width="full">
                <Skeleton startColor="gray.100" endColor="gray.100A" height="5" />
            </Td>
            <Td py={6} width="full">
                <Skeleton startColor="gray.100" endColor="gray.100A" height="5" />
            </Td>
            <Td py={6} width="full">
                <Skeleton startColor="gray.100" endColor="gray.100A" height="5" />
            </Td>
            <Td py={6} width="full">
                <Skeleton startColor="gray.100" endColor="gray.100A" height="5" />
            </Td>
            <Td py={6} width="full">
                <Skeleton startColor="gray.100" endColor="gray.100A" height="5" />
            </Td>
        </Tr>
    );
};

function CustomCheckbox({ isDisabled, ...props }) {
    const { state, getCheckboxProps, getInputProps, htmlProps } = useCheckbox(props);

    if (isDisabled) {
        return <Checkbox size="lg" isDisabled={true} isChecked={state.isChecked} />;
    }

    return (
        <chakra.label cursor="pointer" {...htmlProps}>
            <input {...getInputProps()} hidden={true} />
            <Flex
                alignItems="center"
                justifyContent="center"
                border="1px solid"
                borderColor={state.isChecked ? 'purple.500' : 'gray.300A'}
                bg={state.isChecked ? 'purple.500' : 'whiteAlpha.900'}
                rounded="sm"
                w={5}
                h={5}
                {...getCheckboxProps()}>
                {state.isChecked && <Check width="14px" height="14px" color="white" />}
            </Flex>
            {props.children}
        </chakra.label>
    );
}

const AssignedPanel = ({ set }: { set?: CompSet }) => {
    const router = useRouter();
    const { query } = router;
    const dispatch = useAppDispatch();
    const associatedListings = set?.associated_listings;

    const modalSet = useAppSelector((state) => state.sets.modalSet);
    const { data: otherSetIdentifier } = useSetId(modalSet?.id);
    const setId = set?.id || otherSetIdentifier?.id;

    const { mutateAsync: unAssignListingsToSet, isPending: isSaving } = useUnassignListingsToSet();

    const { value, getCheckboxProps, setValue } = useCheckboxGroup({
        defaultValue: [],
    });

    const [isAllSelected, setIsAllSelected] = useState(false);

    const checkAllValues = () => {
        if (associatedListings) {
            const allValues = associatedListings.map((listing) => listing.listing_id);
            setValue(allValues);
        }
    };

    useEffect(() => {
        if (associatedListings?.length && value?.length === associatedListings?.length) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const unassignListings = async (listingId?: string) => {
        const listingIds = listingId ? [parseInt(listingId)] : value.map((value) => (isNumber(value) ? value : parseInt(value)));
        const id = modalSet?.id || setId;
        if (!id) {
            return;
        }

        unAssignListingsToSet({ listingIds, id }).then(() => {
            setValue([]);
            if (query?.uid?.[0]) {
                dispatch(fetchSetById(query?.uid?.[0]));
            }
        });
    };

    if (!associatedListings || associatedListings?.length == 0) {
        return (
            <TabPanel overflow="auto" p={0}>
                <Grid w="full" h="full" placeItems="center" placeContent="center">
                    <Box mt={8} p={8} border="1px" borderColor="gray.100A" rounded="lg">
                        <Text fontSize="md" color="gray.700">
                            No listings assigned to this set
                        </Text>
                    </Box>
                </Grid>
            </TabPanel>
        );
    }

    return (
        <>
            <TabPanel overflow="auto" p={0}>
                <Flex
                    position="sticky"
                    top={0}
                    px={6}
                    py={2}
                    borderBottom="1px"
                    borderColor="gray.100A"
                    justifyContent="space-between"
                    alignItems="center"
                    zIndex={2}
                    bg="white">
                    <Flex gap={2} alignItems="center">
                        <Text color="gray.500">{associatedListings?.length} listings</Text>
                    </Flex>

                    <Button onClick={() => unassignListings()} size="sm" variant="dark" isLoading={isSaving} isDisabled={value?.length == 0}>
                        {value?.length >= 1 ? `Unassign ${value?.length} listing${value?.length > 1 ? 's' : ''}` : 'Unassign from set'}
                    </Button>
                </Flex>
                <Box overflow="hidden">
                    <Box flex={1} overflow="auto" position="relative">
                        <Box overflowX="auto" w="full" className="horizontal-scrollbar">
                            <ListingsTable
                                type="assigned"
                                isLoading={false}
                                listings={associatedListings}
                                isAllSelected={isAllSelected}
                                value={value}
                                setValue={setValue}
                                checkAllValues={checkAllValues}
                                getCheckboxProps={getCheckboxProps}
                                action={unassignListings}
                                isSaving={isSaving}
                            />
                        </Box>
                    </Box>
                </Box>
            </TabPanel>
        </>
    );
};

const listingIdToString = (listing: SetListingItem) => {
    return listing.listing_id.toString();
};

const ComparePanel = ({ set }: { set: CompSet }) => {
    const dispatch = useAppDispatch();
    const compareListings = useAppSelector((state) => state.compState.compareListings);
    const associatedListings = set.associated_listings;

    const { value, getCheckboxProps, setValue } = useCheckboxGroup({
        defaultValue: compareListings.map(listingIdToString),
    });

    const checkAllValues = () => {
        setValue(associatedListings.map(listingIdToString));
    };

    const onCompare = () => {
        const compareListings = associatedListings.filter((l) => value.includes(listingIdToString(l)));
        dispatch(actions.setCompareListings(compareListings));
        dispatch(closeModal());
    };

    if (!associatedListings.length) {
        return (
            <TabPanel overflow="auto" p={0}>
                <Grid w="full" h="full" placeItems="center" placeContent="center">
                    <Box mt={8} p={8} border="1px" borderColor="gray.100A" rounded="lg">
                        <Text fontSize="md" color="gray.700">
                            No listings assigned to this set
                        </Text>
                    </Box>
                </Grid>
            </TabPanel>
        );
    }

    return (
        <>
            <TabPanel overflow="auto" p={0}>
                <Flex
                    position="sticky"
                    top={0}
                    px={6}
                    py={2}
                    borderBottom="1px"
                    borderColor="gray.100A"
                    justifyContent="space-between"
                    alignItems="center"
                    zIndex={2}
                    bg="white">
                    <Flex gap={2} alignItems="center">
                        <Text color="gray.500">{associatedListings.length} listings</Text>
                    </Flex>

                    <Button onClick={onCompare} size="sm" variant="dark" isDisabled={!associatedListings.length}>
                        {value?.length >= 1 ? `Compare ${value?.length} listing${value?.length > 1 ? 's' : ''}` : 'Do not compare'}
                    </Button>
                </Flex>
                <Box overflow="hidden">
                    <Box flex={1} overflow="auto" position="relative">
                        <Box overflowX="auto" w="full" className="horizontal-scrollbar">
                            <ListingsTable
                                type="compare"
                                isLoading={false}
                                listings={associatedListings}
                                isAllSelected={value.length === associatedListings.length}
                                value={value}
                                setValue={setValue}
                                checkAllValues={checkAllValues}
                                getCheckboxProps={getCheckboxProps}
                                isSaving={false}
                            />
                        </Box>
                    </Box>
                </Box>
            </TabPanel>
        </>
    );
};

const AssignPanel = ({ set }) => {
    usePortfolioStaticSchema();
    const router = useRouter();
    const { query } = router;
    const dispatch = useAppDispatch();

    const [pageIndex, setPageIndex] = useState(0);
    const perPage = 25;
    const { data, isLoading } = useAssignUserListingsToSet(set, perPage, pageIndex + 1);
    const handleClick = (s) => {
        setPageIndex(s.selected);
    };
    const listings = data?.items || [];

    const { mutateAsync: assignListingsToSet, isPending: isSaving } = useAssignListingsToSet();
    const modalSet = useAppSelector((state) => state.sets.modalSet);
    const { data: otherSetIdentifier } = useSetId(modalSet?.id);
    const setId = set?.id || otherSetIdentifier?.id;
    const showPagination = data?.pageCount && data?.pageCount > 0 && data.itemCount && data.itemCount > perPage;

    const { value, getCheckboxProps, setValue } = useCheckboxGroup({
        defaultValue: [],
    });

    const [isAllSelected, setIsAllSelected] = useState(false);

    const checkAllValues = () => {
        if (listings) {
            const allValues = listings?.map((listing) => JSON.stringify(listing.id));
            setValue(allValues);
        }
    };

    useEffect(() => {
        if (listings?.length > 0 && value?.length === listings?.length) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const addListing = async (listingId?: string) => {
        const listingIds = listingId ? [parseInt(listingId)] : value.map((value) => (isNumber(value) ? value : parseInt(value)));
        const id = modalSet?.id || setId;

        if (!id) {
            return;
        }

        assignListingsToSet({ listingIds, id }).then(() => {
            setValue([]);
            if (query?.uid?.[0]) {
                dispatch(fetchSetById(query?.uid?.[0]));
            }
        });
    };

    return (
        <TabPanel p={0}>
            <Flex
                position="sticky"
                top={0}
                px={6}
                py={2}
                borderBottom="1px"
                borderColor="gray.100A"
                justifyContent="space-between"
                alignItems="center"
                zIndex={2}
                bg="white">
                <Flex gap={2} alignItems="center">
                    {data ? (
                        <>
                            <Text color="gray.500">
                                {data.items?.length} of {data.itemCount} listings
                            </Text>
                            <FilterSet />
                        </>
                    ) : null}
                </Flex>

                <Button onClick={() => addListing()} size="sm" variant="dark" isLoading={isSaving} isDisabled={value?.length == 0}>
                    {value?.length >= 1 ? `Assign ${value?.length} listing${value?.length > 1 ? 's' : ''}` : 'Assign to set'}
                </Button>
            </Flex>

            {!isLoading && listings.length === 0 ? (
                <Grid w="full" h="full" placeItems="center" placeContent="center">
                    <Box mt={8} p={8} border="1px" borderColor="gray.100A" rounded="lg">
                        <Text fontSize="md" color="gray.700">
                            No listings to display
                        </Text>
                    </Box>
                </Grid>
            ) : (
                <Box overflow="hidden">
                    <Box flex={1} overflow="auto" position="relative">
                        <Box overflowX="auto" w="full" className="horizontal-scrollbar">
                            <ListingsTable
                                type="assign"
                                isLoading={isLoading}
                                listings={listings}
                                isAllSelected={isAllSelected}
                                value={value}
                                setValue={setValue}
                                checkAllValues={checkAllValues}
                                getCheckboxProps={getCheckboxProps}
                                action={addListing}
                                isSaving={isSaving}
                            />
                        </Box>
                        {showPagination ? (
                            <div className="reactPaginate">
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    forcePage={pageIndex}
                                    breakClassName={'break-me'}
                                    pageLinkClassName="listing-table-pagination-anchor"
                                    previousLinkClassName="listing-table-pagination-next-anchor"
                                    nextLinkClassName="listing-table-pagination-previous-anchor"
                                    pageCount={data?.pageCount}
                                    renderOnZeroPageCount={() => null}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={7}
                                    onPageChange={(e) => handleClick(e)}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        ) : null}
                    </Box>
                </Box>
            )}
        </TabPanel>
    );
};

// : TableProps
const ListingsTable = ({
    listings,
    isLoading,
    isAllSelected,
    value,
    setValue,
    checkAllValues,
    action,
    getCheckboxProps,
    isSaving,
    type,
    ...props
}: {
    listings: ListingV2[] | CompSet['associated_listings'];
    isLoading: boolean;
    isAllSelected: boolean;
    value: (string | number)[];
    setValue: (v: string[]) => void;
    checkAllValues: () => void;
    action?: (listingId?: string) => void;
    getCheckboxProps: any;
    isSaving: boolean;
    type: 'assign' | 'assigned' | 'compare';
}) => {
    return (
        <Table size="sm" {...props}>
            <Thead>
                <Tr>
                    <Th position={'sticky'} left={'0'} color="gray.400" textTransform="inherit" fontSize="12px" letterSpacing="-0.05px" pl={6} w={50}>
                        <Skeleton startColor="gray.100" endColor="gray.100A" isLoaded={!isLoading}>
                            <Checkbox
                                size="lg"
                                isChecked={isAllSelected ? true : false}
                                isIndeterminate={value.length > 0 && !isAllSelected}
                                isDisabled={isSaving}
                                onChange={() => (isAllSelected ? setValue([]) : checkAllValues())}
                            />
                        </Skeleton>
                    </Th>
                    <Th color="gray.400" textTransform="inherit" fontSize="12px" letterSpacing="-0.05px">
                        <Skeleton startColor="gray.100" endColor="gray.100A" isLoaded={!isLoading} />
                    </Th>

                    <Th color="gray.400" textTransform="inherit" fontSize="12px" letterSpacing="-0.05px" isTruncated={true}>
                        <Skeleton startColor="gray.100" endColor="gray.100A" isLoaded={!isLoading}>
                            Name
                        </Skeleton>
                    </Th>
                    <Th color="gray.400" textTransform="inherit" fontSize="12px" letterSpacing="-0.05px" isTruncated={true}>
                        <Skeleton startColor="gray.100" endColor="gray.100A" isLoaded={!isLoading}>
                            Nickname
                        </Skeleton>
                    </Th>
                    <Th color="gray.400" textTransform="inherit" fontSize="12px" letterSpacing="-0.05px" isTruncated={true}>
                        <Skeleton startColor="gray.100" endColor="gray.100A" isLoaded={!isLoading}>
                            Address
                        </Skeleton>
                    </Th>
                    <Th color="gray.400" textTransform="inherit" fontSize="12px" letterSpacing="-0.05px">
                        <Skeleton startColor="gray.100" endColor="gray.100A" isLoaded={!isLoading}>
                            Beds
                        </Skeleton>
                    </Th>
                    <Th color="gray.400" textTransform="inherit" fontSize="12px" letterSpacing="-0.05px">
                        <Skeleton startColor="gray.100" endColor="gray.100A" isLoaded={!isLoading}>
                            Bths
                        </Skeleton>
                    </Th>
                    <Th color="gray.400" textTransform="inherit" fontSize="12px" letterSpacing="-0.05px">
                        <Skeleton startColor="gray.100" endColor="gray.100A" isLoaded={!isLoading}>
                            Brs
                        </Skeleton>
                    </Th>
                    <Th />
                </Tr>
            </Thead>

            <Tbody>
                {isLoading &&
                    [1, 2, 3, 4, 5, 6, 7, 8].map((id) => {
                        return <ListingLoadingSkeleton key={id} />;
                    })}
                {listings.map((listing: ListingV2 | CompSet['associated_listings'][0]) => {
                    const id = 'id' in listing ? listing.id : listing.listing_id;
                    return (
                        <Item type={type} action={action} key={id} isSaving={isSaving} listing={listing} {...getCheckboxProps({ value: JSON.stringify(id) })} />
                    );
                })}
            </Tbody>
        </Table>
    );
};

const Item = ({
    listing,
    isSaving,
    action,
    type,
    ...props
}: {
    listing: ListingV2 | CompSet['associated_listings'][0];
    isSaving: boolean;
    action?: (listingId?: number) => void;
    type: 'assign' | 'assigned' | 'compare';
}) => {
    const id = 'id' in listing ? listing.id : listing.listing_id;
    const nickname = 'nickname' in listing ? listing?.preferences?.nickname || listing?.display_nickname || listing?.nickname : null;

    return (
        <Tr key={id}>
            <Td position={'sticky'} left={'0'} pl={6}>
                <CustomCheckbox isDisabled={isSaving} {...props} />
            </Td>
            <Td pl={1} pr={1}>
                {listing?.thumb_url ? (
                    <Box overflow="hidden" rounded="md" flexShrink={0} position="relative" h="20px" w="20px">
                        <ImageWithFallback
                            quality={100}
                            alt="Listing image"
                            fill={true}
                            style={{ objectFit: 'cover' }}
                            sizes="(max-width: 20px)"
                            src={listing?.thumb_url}
                            fallbackSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNct2JdPQAGlQKFS+8MHQAAAABJRU5ErkJggg=="
                            placeholder="blur"
                            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNct2JdPQAGlQKFS+8MHQAAAABJRU5ErkJggg=="
                        />
                    </Box>
                ) : (
                    <Box overflow="hidden" rounded="md" flexShrink={0} position="relative" bg="gray.100A" h="20px" w="20px" />
                )}
            </Td>
            <Td maxW={260}>
                <Box>
                    <Tooltip label={listing.title}>
                        <Text fontSize="13px" fontWeight={500} isTruncated={true} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                            {listing.title}
                        </Text>
                    </Tooltip>
                </Box>
            </Td>
            <Td maxW={250}>
                {nickname ? (
                    <Box>
                        <Tooltip label={nickname}>
                            <Text fontSize="13" color="gray.500" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                                {nickname}
                            </Text>
                        </Tooltip>
                    </Box>
                ) : (
                    <Box />
                )}
            </Td>
            <Td maxW={225}>
                <Box>
                    <Tooltip label={listing.address}>
                        <Text fontSize="13" color="gray.500" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                            {listing.address}
                        </Text>
                    </Tooltip>
                </Box>
            </Td>
            <Td>
                <Text fontSize="sm" color="gray.500">
                    {listing.beds}
                </Text>
            </Td>
            <Td>
                <Text fontSize="sm" color="gray.500">
                    {listing.bathrooms}
                </Text>
            </Td>
            <Td>
                <Text fontSize="sm" color="gray.500">
                    {listing.bedrooms}
                </Text>
            </Td>
            <Td>
                {action ? (
                    <Tooltip placement="left" label={type == 'assign' ? 'Assign listing to this set' : 'Unassign listing from this set'}>
                        <IconButton
                            onClick={() => action(id)}
                            variant="secondary"
                            isLoading={isSaving}
                            icon={<Icon as={type == 'assign' ? Plus : X} />}
                            size="xs"
                            aria-label={type == 'assign' ? 'Assign listing to this set' : 'Unassign listing from this set'}
                        />
                    </Tooltip>
                ) : null}
            </Td>
        </Tr>
    );
};

const TabSetup = ({ assignedCount }: { assignedCount: number | undefined }) => {
    const router = useRouter();
    const isAdmin = useIsAdmin();
    const isMetricsPage = !!router.query.compId;
    return (
        <Flex borderBottom="1px" borderColor="gray.100A" position="relative">
            <TabList pl={6} gap={4}>
                <Tab
                    fontWeight="medium"
                    pt={1}
                    pb={3}
                    pl={1}
                    fontSize="13px"
                    _selected={{
                        color: 'primary.500',
                    }}>
                    Assign listings to set
                </Tab>
                <Tab
                    fontWeight="medium"
                    fontSize="13px"
                    pt={1}
                    pb={3}
                    pl={1}
                    _selected={{
                        color: 'primary.500',
                    }}>
                    <Flex gap={1} alignItems="end">
                        Listings assigned to this set
                        <Box px={1} py={0.5} bg="gray.100A" rounded="sm" fontSize="xs" fontWeight="medium">
                            {assignedCount}
                        </Box>
                    </Flex>
                </Tab>
                {isMetricsPage && isAdmin ? (
                    <Tab
                        fontWeight="medium"
                        fontSize="13px"
                        pt={1}
                        pb={3}
                        pl={1}
                        _selected={{
                            color: 'primary.500',
                        }}>
                        <Flex gap={1} alignItems="end">
                            Listings compared to this set
                            <Box px={1} py={0.5} bg="gray.100A" rounded="sm" fontSize="xs" fontWeight="medium">
                                {assignedCount}
                            </Box>
                        </Flex>
                    </Tab>
                ) : null}
            </TabList>
            <TabIndicator bottom={0} mt="-1.5px" height="2px" bg="primary.200" borderRadius="1px" />
        </Flex>
    );
};

const FilterSet = () => {
    const staticSchema = useAppSelector(({ assignListingsToSetUi }) => assignListingsToSetUi.filters.staticSchema);
    const active = useAppSelector(({ assignListingsToSetUi }) => assignListingsToSetUi.filters.active);

    return (
        <Filters
            categoryStyleProps={{
                popoverBody: {
                    height: '400px',
                },
            }}
            addFilters={addFilters}
            removeFilter={removeFilter}
            updateFilter={updateFilter}
            staticSchema={staticSchema}
            activeFilters={active}
        />
    );
};

const ManageAssignedModal = ({ isOpen, set, defaultIndex = 0 }: { isOpen: boolean; set?: CompSet; defaultIndex?: number }) => {
    const dispatch = useAppDispatch();
    const isAdmin = useIsAdmin();

    return (
        <Modal scrollBehavior="inside" motionPreset="slideInBottom" isOpen={isOpen} onClose={() => dispatch(closeModal())} isCentered={true} size="5xl">
            <ModalOverlay />
            <Tabs variant="unstyled" defaultIndex={defaultIndex}>
                <ModalContent h="full" display="flex" flexDirection="column">
                    <ModalHeader>
                        <ModalTitle fontSize="lg">Manage assigned listings</ModalTitle>
                        <ModalCloseButton onClick={() => dispatch(closeModal())} />
                    </ModalHeader>
                    <TabSetup assignedCount={set?.associated_listings?.length} />
                    <ModalBody position="relative" p={0}>
                        <TabPanels h="full">
                            <AssignPanel set={set} />
                            <AssignedPanel set={set} />
                            {set && isAdmin && <ComparePanel set={set} />}
                        </TabPanels>
                    </ModalBody>
                </ModalContent>
            </Tabs>
        </Modal>
    );
};

export default ManageAssignedModal;
