import { LISTING_FILTER } from 'libs/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const SORT_COLUMNS = {
    LISTING: 'listing',
    BASE_PRICE: 'base_price',
    PRICING: 'pricing',
    REV_SCORE: 'rev_score',
    CHANNEL: 'source',
    COMP_SET: 'competitive_set',
    CREATED_AT: 'created_at',
    UNITS: 'units',
};

export interface DashboardUiState {
    query: string;
    channel: string | null;
    listingFilter: string;
    market: string | null;
    bedrooms: string | null;
    wheelhousePricingStatus: boolean | null;
    wheelhousePricingStatusSelect?: string;
    sort: string;
    sortAscending: boolean;
    tier?: string;
}

const initialState = {
    query: '',
    channel: null,
    listingFilter: LISTING_FILTER.ACTIVE.filter_key,
    market: null,
    bedrooms: null,
    wheelhousePricingStatus: null,
    sort: SORT_COLUMNS.CREATED_AT,
    sortAscending: false,
} as DashboardUiState;

const DEFAULT_SORT = true;

const resetToDefault = (state: DashboardUiState, action: PayloadAction<string>) => {
    // Return true if clikcing a column for the third time
    return action.payload == state.sort && state.sortAscending != DEFAULT_SORT;
};

const sortBy = (state: DashboardUiState, action: PayloadAction<string>) => {
    const reset = resetToDefault(state, action);

    // Clicking a column for the third time
    if (reset) {
        return { sort: initialState.sort, sortAscending: initialState.sortAscending };
    }

    // Clicking a column for the second time
    if (action.payload == state.sort) {
        return { sort: action.payload, sortAscending: !state.sortAscending };
    }

    // Clicking a column for the first time
    return { sort: action.payload, sortAscending: DEFAULT_SORT };
};

const dashboardUiSlice = createSlice({
    name: 'dashboardUi',
    initialState,
    reducers: {
        setQuery: (state, action: PayloadAction<string>) => {
            state.query = action.payload;
        },
        setChannel: (state, action: PayloadAction<string>) => {
            state.channel = action.payload;
        },
        setListingFilter: (state, action: PayloadAction<string>) => {
            state.listingFilter = action.payload;
        },
        setMarket: (state, action: PayloadAction<string>) => {
            state.market = action.payload;
        },
        setBedrooms: (state, action: PayloadAction<string>) => {
            state.bedrooms = action.payload;
        },
        setWPStatus: (state, action: PayloadAction<string>) => {
            // The new UI sends a different type so need to make an adjustment here
            let truthy_payload: boolean | null = null;
            if (typeof action.payload === 'string') {
                if (action.payload === 'true') {
                    truthy_payload = true;
                } else if (action.payload === 'false') {
                    truthy_payload = false;
                }
            }
            state.wheelhousePricingStatus = truthy_payload;
            state.wheelhousePricingStatusSelect = action.payload;
        },
        setSort: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                ...sortBy(state, action),
            };
        },
        setTier: (state, action: PayloadAction<string>) => {
            state.tier = action.payload;
        },
    },
});

export const { setQuery, setChannel, setListingFilter, setMarket, setBedrooms, setWPStatus, setSort, setTier } = dashboardUiSlice.actions;

export default dashboardUiSlice.reducer;
