import React, { FC, useState } from 'react';

import {
    Box,
    Button,
    ButtonProps,
    chakra,
    CheckboxProps,
    Divider,
    Flex,
    Icon,
    IconButton,
    IconButtonProps,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    shouldForwardProp,
    Stack,
    Text,
    TextProps,
    Tooltip,
    useCheckbox,
} from '@chakra-ui/react';

import Image, { ImageProps } from 'next/image';
import { ArrowUpRight, CaretDown, Check, IconProps, Minus, Question, SortAscending, SortDescending, X } from 'phosphor-react';
import { AnimatePresence, isValidMotionProp, motion } from 'framer-motion';
import { metrics_label_dict } from './constants';
import { useAppDispatch, useAppSelector } from 'libs/global/hooks';
import { setListing } from 'libs/global/slices/sets';
import { FeatureListing, SetListingItem } from 'libs/types/set';
import { Geometry } from 'geojson';
import { Marker, useMap } from 'react-map-gl';

export function CheckboxSimple(props: CheckboxProps) {
    const { state, getInputProps, getCheckboxProps } = useCheckbox(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Box as="label">
            <input {...input} />
            <Flex
                {...checkbox}
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                borderWidth="1px"
                borderRadius="md"
                _checked={{
                    color: 'purple.400',
                    borderColor: 'purple.100',
                }}
                _indeterminate={{
                    color: 'purple.300',
                    borderColor: 'purple.100A',
                }}
                h="15px"
                w="15px">
                {state.isChecked && <Icon w={3} h={3} weight="bold" as={Check} />}
                {state.isIndeterminate && <Icon w={3} h={3} weight="bold" as={Minus} />}
            </Flex>
        </Box>
    );
}

interface DefaultButtonProps extends ButtonProps {
    leftIcon?: any;
    rightIcon?: any;
}

const DefaultButton = ({ leftIcon, rightIcon, ...props }: DefaultButtonProps) => {
    return (
        <Button
            variant="dark"
            size="sm"
            rounded="sm"
            fontWeight={400}
            fontSize="13px"
            {...(leftIcon ? { leftIcon: <Icon mx={-1} as={leftIcon} /> } : {})}
            {...(rightIcon ? { rightIcon: <Icon mx={-1} as={rightIcon} /> } : {})}
            {...props}
        />
    );
};

const DefaultIconButton = (props: IconButtonProps) => {
    return <IconButton size="sm" variant="dark" borderColor="gray.100A" minW="24px" {...props} />;
};

const BodyText = (props: TextProps) => <Text fontWeight="normal" fontSize="13px" color="gray.800" {...props} />;
const BodyTextBold = (props: TextProps) => <Text fontWeight="semibold" fontSize="13px" color="gray.800" {...props} />;
const BodyTextSecondary = (props: TextProps) => <Text fontWeight="normal" fontSize="13px" color="gray.400" {...props} />;
const BodyTextSecondaryBold = (props: TextProps) => <Text fontSize="13px" fontWeight="medium" color="gray.400" {...props} />;
const BodyCaps = (props: TextProps) => <Text fontSize="10px" letterSpacing="0.2px" fontWeight={450} textTransform="uppercase" {...props} />;
const ModalTitle = (props: TextProps) => <Text fontSize="2xl" fontWeight={550} color="gray.800" {...props} />;

const SidebarSubtitle = (props: TextProps) => {
    return <Text fontSize="13px" color="gray.800" fontWeight={550} {...props} />;
};

const SidebarTitle = (props: TextProps) => {
    return <Text fontSize="lg" fontWeight={550} color="gray.800" {...props} />;
};

const SidebarText = (props: TextProps) => {
    return <Text fontSize="13px" color="gray.800" {...props} />;
};

const SidebarSubText = (props: TextProps) => {
    return <Text fontSize="13px" color="gray.400" {...props} />;
};

interface ImageWithFallbackProps extends ImageProps {
    fallbackSrc?: string;
}

const ImageWithFallback = (props: ImageWithFallbackProps) => {
    const { src, fallbackSrc, ...rest } = props;
    const [imgSrc, setImgSrc] = useState(src);

    return (
        <Image
            {...rest}
            src={imgSrc}
            onError={(e) => {
                if (fallbackSrc) {
                    setImgSrc(fallbackSrc);
                } else {
                    console.error(e.target);
                }
            }}
        />
    );
};

const OptimizedImageWithFallback = ({ src, alt }: { src: string; alt: string }) => {
    const [imageError, setImageError] = useState(false);

    if (imageError) {
        return <Box mx="auto" rounded="full" bg="gray.100A" w={8} h={8} />;
    }

    return (
        <Flex position="relative" width="24px" height="24px" mx="auto" rounded="full" overflow="hidden" alignItems="center" justifyContent="center">
            <Image src={src} alt={alt} fill={true} objectFit="cover" onError={() => setImageError(true)} />
        </Flex>
    );
};

const popoutStyles = (side = 'right') => ({
    initial: { x: side == 'right' ? 100 : -100, opacity: 0 },
    position: 'absolute',
    top: 4,
    width: 375,
    shadow: '2xl',
    rounded: 'md',
    ...(side == 'right' && { right: 4 }),
    ...(side == 'left' && { left: 4 }),
    bottom: 4,
    transition: { duration: 0.2, ease: 'easeIn' },
    animate: {
        x: 0,
        opacity: 1,
    },
    exit: {
        x: side == 'right' ? 375 : -375,
        opacity: 0,
        transition: { delay: 0.05, duration: 0.3 },
    },
});

const ChakraBox: FC<any> = chakra
    ? chakra(motion.div, {
          shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
      })
    : Box;

const SidebarContainer = ({
    title,
    isOpen,
    onClose,
    zIndex,
    side,
    ...rest
}: {
    title?: string;
    isOpen?: boolean;
    onClose?: () => void;
    zIndex?: number;
    side?: string;
    children: any;
}) => {
    return (
        <AnimatePresence initial={false}>
            {isOpen && (
                <ChakraBox bg="white" key="modal" zIndex={zIndex} {...popoutStyles(side)}>
                    <Flex px={6} h="48px" gap={4} alignItems="center" position="static" borderBottom="1px" borderColor="gray.100A">
                        <Box flex={1}>
                            <Text textTransform="capitalize" fontSize="xl" fontWeight="semibold">
                                {title}
                            </Text>
                        </Box>
                        <IconButton
                            aria-label="close"
                            icon={<Icon as={X} />}
                            rounded="full"
                            h="28px"
                            w="28px"
                            variant="secondary"
                            onClick={onClose}
                            minW="auto"
                            borderColor="gray.100A"
                        />
                    </Flex>
                    <Box overflowY="auto" h="calc(100% - 48px)" className="scroll-update" {...rest} />
                </ChakraBox>
            )}
        </AnimatePresence>
    );
};

const DummyMetric = () => {
    return (
        <Stack py={6} spacing={6} divider={<Divider borderColor="gray.100" />}>
            <Stack spacing={4} px={4}>
                <Box h={3} w={36} bg="gray.100A" />
                <Box h={2} w={28} bg="gray.100A" />
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                </Stack>
            </Stack>
            <Stack spacing={4} px={4}>
                <Box h={3} w={36} bg="gray.100A" />
                <Box h={2} w={28} bg="gray.100A" />
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                </Stack>
            </Stack>
            <Stack spacing={4} px={4}>
                <Box h={3} w={36} bg="gray.100A" />
                <Box h={2} w={28} bg="gray.100A" />
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                </Stack>
            </Stack>
            <Stack spacing={4} px={4}>
                <Box h={3} w={36} bg="gray.100A" />
                <Box h={2} w={28} bg="gray.100A" />
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                    <Stack spacing={1}>
                        <Flex w="full" justifyContent="space-between">
                            <Box h={1} w={6} bg="gray.100A" />
                            <Box h={1} w={6} bg="gray.100A" />
                        </Flex>
                        <Box h={2} w="full" bg="gray.100A" />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

const Sort = ({
    sortBy,
    setSortBy,
    setSortOrder,
    sortOrder = 'desc',
}: {
    sortBy: string;
    setSortBy: (value: string) => void;
    setSortOrder: (value: string) => void;
    sortOrder?: string;
}) => {
    return (
        <Tooltip placement="bottom-start" shouldWrapChildren={true} label="Sort by">
            <Menu closeOnSelect={false}>
                <Flex alignItems="center">
                    <MenuButton
                        roundedLeft="none"
                        textTransform="capitalize"
                        leftIcon={<Icon mx={-1} as={sortOrder == 'desc' ? SortAscending : SortDescending} />}
                        rightIcon={<Icon mx={-1} as={CaretDown} />}
                        as={Button}
                        size="sm"
                        fontSize="12px"
                        borderColor="gray.100A"
                        variant="secondary">
                        {metrics_label_dict[sortBy]}
                    </MenuButton>
                </Flex>
                <MenuList py={2} minW="170px">
                    <MenuOptionGroup onChange={(value: string) => setSortOrder(value)} defaultValue={sortOrder} type="radio" title="Order">
                        <MenuItemOption fontSize="13px" py={1.5} value="asc">
                            Ascending
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="desc">
                            Descending
                        </MenuItemOption>
                    </MenuOptionGroup>
                    <MenuOptionGroup onChange={(value: string) => setSortBy(value)} defaultValue={sortBy} type="radio" title="Sort attribute">
                        <MenuItemOption fontSize="13px" py={1.5} value="apr">
                            Asking rate
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="anr">
                            Avg. nightly rate
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="nrevenue">
                            Nightly revenue
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="nrevpar">
                            Nightly revpar
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="occupancy">
                            Occupancy
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="open_occupancy">
                            Adjusted occupancy
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="available_nights">
                            Available nights
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="blocked_nights">
                            Blocked nights
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="bookable_nights">
                            Bookable nights
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="booked_nights">
                            Booked nights
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="openness">
                            Openess
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="review_count">
                            Review count
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="star_rating">
                            Star rating
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="los">
                            Length of stay
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="lead_time">
                            Lead time
                        </MenuItemOption>
                        <MenuItemOption fontSize="13px" py={1.5} value="cleaning_fee">
                            Cleaning fee
                        </MenuItemOption>
                    </MenuOptionGroup>
                </MenuList>
            </Menu>
        </Tooltip>
    );
};

const EditingOverlay = ({ mode }: { mode: string }) => {
    const isEditingBoundary = useAppSelector((state) => state.sets.isEditingBoundary);

    const label = {
        simple_select: 'Click boundaries to edit shape. Or, select boundary & click trash icon to delete.',
        draw_polygon: 'Click map to start drawing boundary. Click on first point to finish.',
        draw_rectangle: 'Click on the map to start rectangle. Move across map and click to finish.',
    };

    return (
        <Box top={4} position="absolute" left={4} zIndex={11}>
            <AnimatePresence>
                {isEditingBoundary ? (
                    <ChakraBox
                        initial={{ y: -100, opacity: 0 }}
                        transition={{ duration: 0.4, ease: 'easeIn' }}
                        animate={{
                            y: 0,
                            opacity: 1,
                        }}
                        exit={{
                            y: -100,
                            opacity: 0,
                            transition: { delay: 0.05, duration: 0.3, ease: 'anticipate' },
                        }}>
                        <Flex w="full" alignItems="center" justifyContent="space-between" px={4} py={2} shadow="md" rounded="md" bg="whiteAlpha.900" gap={2}>
                            <Icon as={Question} color="gray.400" boxSize={4} />
                            <Box>
                                <BodyText>{label[mode]}</BodyText>
                            </Box>
                        </Flex>
                    </ChakraBox>
                ) : null}
            </AnimatePresence>
        </Box>
    );
};

const ViewGoogleMaps = (props) => {
    const { feature, ...rest } = props;
    console.log('here is the feature: ', feature);
    const map = useMap();

    if (!map) {
        return null;
    }

    const goToGoogle = (e) => {
        e.stopPropagation();
        //get latlng
        const lngLat = feature?._geometry?.coordinates || [feature?.long, feature?.lat];

        const urls = ['https://www.google.com/maps/@?api=1&map_action=pano&viewpoint='.concat(lngLat[1], ',').concat(lngLat[0])];
        window.open(urls[0], '_blank');
    };

    return (
        <Tooltip label="Google Street view" placement="top">
            <Button onClick={goToGoogle} size="xs" h={8} borderColor="gray.50" minW="auto" px={1} variant="secondary" {...rest}>
                <svg
                    style={{
                        width: '14px',
                        height: '14px',
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 92.3 132.3">
                    <path fill="#1a73e8" d="M60.2 2.2C55.8.8 51 0 46.1 0 32 0 19.3 6.4 10.8 16.5l21.8 18.3L60.2 2.2z" />
                    <path fill="#ea4335" d="M10.8 16.5C4.1 24.5 0 34.9 0 46.1c0 8.7 1.7 15.7 4.6 22l28-33.3-21.8-18.3z" />
                    <path
                        fill="#4285f4"
                        d="M46.2 28.5c9.8 0 17.7 7.9 17.7 17.7 0 4.3-1.6 8.3-4.2 11.4 0 0 13.9-16.6 27.5-32.7-5.6-10.8-15.3-19-27-22.7L32.6 34.8c3.3-3.8 8.1-6.3 13.6-6.3"
                    />
                    <path
                        fill="#fbbc04"
                        d="M46.2 63.8c-9.8 0-17.7-7.9-17.7-17.7 0-4.3 1.5-8.3 4.1-11.3l-28 33.3c4.8 10.6 12.8 19.2 21 29.9l34.1-40.5c-3.3 3.9-8.1 6.3-13.5 6.3"
                    />
                    <path
                        fill="#34a853"
                        d="M59.1 109.2c15.4-24.1 33.3-35 33.3-63 0-7.7-1.9-14.9-5.2-21.3L25.6 98c2.6 3.4 5.3 7.3 7.9 11.3 9.4 14.5 6.8 23.1 12.8 23.1s3.4-8.7 12.8-23.2"
                    />
                </svg>
            </Button>
        </Tooltip>
    );
};

const ViewDetailsButton = ({
    feature,
    status,
}: {
    feature: {
        source?: string | undefined;
        properties: SetListingItem;
        type: string;
        id: number;
        layer: mapboxgl.Layer;
        geometry: Geometry;
    } | null;
    status: string | undefined;
}) => {
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(setListing(feature));
    };

    return (
        <Tooltip label="View details" placement="top">
            <Button
                aria-label="View details of listing"
                flexGrow={status ? 1 : 0}
                transition="all .2s ease-in-out"
                borderColor="gray.50"
                _hover={{
                    bgColor: 'gray.50A',
                }}
                bg="gray.25A"
                color="gray.500"
                variant="secondary"
                fontSize="11px"
                size="xs"
                onClick={handleClick}
                minH="auto"
                h={8}
                alignItems="center"
                borderWidth="1px">
                Details
            </Button>
        </Tooltip>
    );
};

const ExternalURLBadge = (props: { url: string | undefined | null; [x: string]: any }) => {
    const { url, ...rest } = props;
    let urlToUse: string | null | undefined = url;
    // make sure url doesn't contain the word wheelhouse, and it it does set url to null
    if (url && url.includes('wheelhouse')) {
        urlToUse = null;
    }

    return (
        <Tooltip label={urlToUse ? 'Visit external listing' : 'No URL available'} placement="top">
            <Button
                target="_blank"
                as="a"
                href={urlToUse ? urlToUse : '#'}
                variant="unstyled"
                display="flex"
                minH="auto"
                h={8}
                isDisabled={!urlToUse}
                alignItems="center"
                gap={0.5}
                lineHeight={0}
                flexShrink={0}
                justifySelf="end"
                px={1}
                py={0.5}
                borderColor="purple.100A"
                transition={'all .2s ease-in-out'}
                _hover={{
                    textDecoration: 'none',
                    bg: 'primary.50A',
                }}
                borderWidth="1px"
                {...rest}>
                <BodyTextSecondary
                    position="relative"
                    top="1px"
                    color="primary.500"
                    fontWeight={500}
                    letterSpacing="wide"
                    textTransform="uppercase"
                    fontSize="9.5px"
                    lineHeight="1.1">
                    OTA
                </BodyTextSecondary>
                <Icon as={ArrowUpRight} color="purple.400" boxSize={2.5} />
            </Button>
        </Tooltip>
    );
};

const SearchMarker = ({
    color,
    icon,
    weight,
    boxSize,
    latitude,
    longitude,
    draggable,
}: {
    weight?: IconProps['weight'];
    color: string;
    boxSize?: number;
    icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
    latitude: number;
    longitude: number;
    draggable?: boolean;
}) => {
    return (
        <Marker latitude={latitude} longitude={longitude} draggable={draggable}>
            <Flex position="relative" w={6} h={6} bg={color} rounded="full" px={2} py={0.5} shadow="md" alignItems="center" justifyContent="center">
                <Box position="absolute" bottom={-2} h={2} w={0.5} bg={color} roundedBottom="full" />
                <Icon boxSize={boxSize || 5} weight={weight || 'regular'} as={icon} color="white" />
            </Flex>
        </Marker>
    );
};

const MarkerSymbol = ({
    color,
    icon,
    weight,
    boxSize,
    l,
}: {
    weight?: IconProps['weight'];
    color: string;
    boxSize?: number;
    icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
    l;
}) => {
    const listing = l as unknown as FeatureListing;
    return (
        <Marker key={listing.properties.listing_id} latitude={listing.geometry.coordinates[1]} longitude={listing.geometry.coordinates[0]}>
            <Flex position="relative" w={6} h={6} bg={color} rounded="full" px={2} py={0.5} shadow="md" alignItems="center" justifyContent="center">
                <Box position="absolute" bottom={-2} h={2} w={0.5} bg={color} roundedBottom="full" />
                <Icon boxSize={boxSize || 5} weight={weight || 'regular'} as={icon} color="white" />
            </Flex>
        </Marker>
    );
};

export {
    EditingOverlay,
    OptimizedImageWithFallback,
    DummyMetric,
    SidebarContainer,
    DefaultButton,
    SidebarSubtitle,
    SidebarText,
    SidebarTitle,
    SidebarSubText,
    ImageWithFallback,
    BodyText,
    BodyTextBold,
    BodyTextSecondary,
    BodyTextSecondaryBold,
    BodyCaps,
    ModalTitle,
    Sort,
    DefaultIconButton,
    ChakraBox,
    ExternalURLBadge,
    ViewDetailsButton,
    MarkerSymbol,
    SearchMarker,
    ViewGoogleMaps,
};
