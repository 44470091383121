import { EditingRatePeriod, RatePeriod } from 'libs/types/ratePeriod';
import { groupBy, sum } from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment as any);

const DAY_OF_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export function getRate(rate: Partial<RatePeriod>) {
    return {
        defaultPrice: rate?.defaultPrice,
        monday: rate.monday || rate.defaultPrice,
        tuesday: rate.tuesday || rate.defaultPrice,
        wednesday: rate.wednesday || rate.defaultPrice,
        thursday: rate.thursday || rate.defaultPrice,
        friday: rate.friday || rate.defaultPrice,
        saturday: rate.saturday || rate.defaultPrice,
        sunday: rate.sunday || rate.defaultPrice,
    } as RatePeriod;
}

export const getDaysForRange = (startDate: string | Moment.Moment, endDate: string | Moment.Moment) => {
    startDate = moment(startDate);
    endDate = moment(endDate);
    const range = moment.range(startDate, endDate);
    const days = Array.from(range.by('days')).slice(0, 7);
    return days.map((day: Moment.Moment) => {
        return {
            rateKey: day.format('dddd').toLowerCase(),
            label: day.format('dddd'),
        };
    });
};

export const priceForDay = (rate: RatePeriod | EditingRatePeriod, day: string | Date | Moment.Moment | undefined) => {
    switch (moment(day).format('e')) {
        case '0':
            return rate.sunday;
        case '1':
            return rate.monday;
        case '2':
            return rate.tuesday;
        case '3':
            return rate.wednesday;
        case '4':
            return rate.thursday;
        case '5':
            return rate.friday;
        case '6':
            return rate.saturday;
    }
    return null;
};

const getNumberOf = (startDate: string | Moment.Moment, endDate: string | Moment.Moment, granularity: Moment.unitOfTime.Diff) => {
    return moment.utc(endDate).diff(moment.utc(startDate), granularity) + 1;
};

export const getNumberOfDays = (startDate: string | Moment.Moment, endDate: string | Moment.Moment) => {
    return getNumberOf(startDate, endDate, 'days');
};

export const getNumberOfWeeks = (startDate: string | Moment.Moment, endDate: string | Moment.Moment) => {
    return getNumberOf(startDate, endDate, 'weeks');
};

export const getNumberOfMonths = (startDate: string | Moment.Moment, endDate: string | Moment.Moment) => {
    return getNumberOf(startDate, endDate, 'months');
};

const getWeeklyFixedRate = ({ rate, currency, weeklyRate }: { rate: RatePeriod; currency: string; weeklyRate: number }) => {
    const defaultPricePerDay = parseInt((weeklyRate / 7).toString());
    const diffTotal = parseInt(weeklyRate.toString()) - 7 * defaultPricePerDay;

    if (diffTotal == 0) {
        return getRate({ defaultPrice: defaultPricePerDay });
    }

    const getPriceAdditionPerWeekday = (numberOfWeekdays: number) => {
        const pricePerWeekday = parseInt((diffTotal / numberOfWeekdays).toString());
        return pricePerWeekday;
    };

    const getSumOfPriceAddition = (numberOfWeekdays: number) => {
        return getPriceAdditionPerWeekday(numberOfWeekdays) * numberOfWeekdays;
    };

    let numberOfWeekdays = 1;
    const daysIncluded: number[] = [];
    while (getSumOfPriceAddition(numberOfWeekdays) <= diffTotal) {
        daysIncluded.push(numberOfWeekdays - 1);
        numberOfWeekdays += 1;
    }

    const addition = getPriceAdditionPerWeekday(daysIncluded.length);
    const rates = daysIncluded.reduce(
        (obj, dow) => {
            obj[DAY_OF_WEEK[dow]] = defaultPricePerDay + addition;
            return obj;
        },
        {} as Record<string, number>,
    );

    return {
        ...rate,
        currency,
        ...getRate({ defaultPrice: defaultPricePerDay, ...rates }),
    } as RatePeriod;
};

const getMonthlyFixedRate = ({ rate, currency, month, monthlyRate }: { rate: RatePeriod; currency: string; month: Moment.Moment; monthlyRate: number }) => {
    const daysInMonth = month.daysInMonth();
    const defaultPricePerDay = parseInt((monthlyRate / daysInMonth).toString());
    const diffTotal = monthlyRate - daysInMonth * defaultPricePerDay;

    if (diffTotal == 0) {
        return { ...rate, currency, ...getRate({ defaultPrice: defaultPricePerDay }) } as RatePeriod;
    }

    const getPriceAdditionPerDate = (numberOfWeekdays) => {
        const pricePerWeekday = parseInt((diffTotal / numberOfWeekdays).toString());
        return pricePerWeekday;
    };

    const getSumOfPriceAddition = (numberOfWeekdays) => {
        return getPriceAdditionPerDate(numberOfWeekdays) * numberOfWeekdays;
    };

    const dateRange = Array.from(moment.range(month, month.clone().endOf('month')).by('days'));
    const datesInMonthByWeekday = groupBy(dateRange, (d) => d.day());
    const getNumberOfDaysInMonthForDow = (dow: number) => {
        return datesInMonthByWeekday[dow].length;
    };

    let numberOfDays = 0;
    const daysIncluded: number[] = [];
    while (!numberOfDays || getSumOfPriceAddition(numberOfDays) <= diffTotal) {
        const dow = daysIncluded.length;
        numberOfDays += getNumberOfDaysInMonthForDow(dow);
        daysIncluded.push(dow);
    }

    // Remove last element and find number of sum of weekdays included
    daysIncluded.pop();
    numberOfDays = sum(daysIncluded.map(getNumberOfDaysInMonthForDow));

    const addition = getPriceAdditionPerDate(numberOfDays);
    const rates = daysIncluded.reduce(
        (obj, dow) => {
            obj[DAY_OF_WEEK[dow]] = defaultPricePerDay + addition;
            return obj;
        },
        {} as Record<string, number>,
    );

    return {
        ...rate,
        currency,
        ...getRate({ defaultPrice: defaultPricePerDay, ...rates }),
    } as RatePeriod;
};

const getMonthlyFixedRates = ({ rate, currency, monthlyRate }: { rate: RatePeriod; currency: string; monthlyRate: number }) => {
    const months = Array.from(moment.range(moment.utc(rate.start_date), moment.utc(rate.end_date)).by('months')).map((m) => m.startOf('month'));

    return months.map((m) => {
        return getMonthlyFixedRate({
            rate: {
                ...rate,
                start_date: moment.max(m, moment.utc(rate.start_date)).format('YYYY-MM-DD'),
                end_date: moment.min(m.clone().endOf('month'), moment.utc(rate.end_date)).format('YYYY-MM-DD'),
            },
            month: m,
            currency,
            monthlyRate,
        });
    });
};

export const getWeeklyOrMonthlyFixedRate = ({
    rate,
    currency,
    granularity,
    defaultPrice,
}: {
    rate: RatePeriod;
    currency: string;
    granularity: string;
    defaultPrice: number;
}) => {
    if (granularity == 'weekly') {
        return getWeeklyFixedRate({ rate, currency, weeklyRate: defaultPrice });
    } else if (granularity == 'monthly') {
        return getMonthlyFixedRates({ rate, currency, monthlyRate: defaultPrice });
    }
};
