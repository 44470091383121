import { FilterCategoryTypes } from './meta';

interface FilterBase {
    defaultValue?: any;
    categoryId: FilterCategoryTypes;
}

export interface RadioFilter extends FilterBase {
    comparator: {
        single?: string;
        multiple?: string;
    };
    icon: any;
    label: string;
    options: {
        name: string;
        value: string | number;
    }[];
    paramKey: string;
    property: string;
    type: 'radio';
    paramName: string;
    paramValue: string;
}

export interface NumberFilter extends FilterBase {
    icon: any;
    isPercent?: boolean;
    label: string;
    leftAddon?: string;
    paramKey: string;
    parent: string;
    property: string;
    rightAddon?: string;
    shortLabel?: string;
    type: 'number';
    paramName: string;
}

export interface ParentRadioFilter extends FilterBase {
    children: NumberFilter[];
    comparator?: {
        single?: string;
        multiple?: string;
    };
    icon: any;
    label: string;
    name?: string;
    options: {
        name: string;
        value: string | number;
    }[];
    paramKey: (p: number | string) => string;
    property?: string;
    tooltipLabel: string;
    type: 'radio';
}

export const isRadioFilter = (filter: FilterSchema): filter is RadioFilter => {
    return filter.type === 'radio' && !filter.hasOwnProperty('children');
};

export const isParentRadioFilter = (filter: FilterSchema): filter is ParentRadioFilter => {
    return filter.type === 'radio' && filter.hasOwnProperty('children');
};

export const isChildFilter = (filter: FilterSchema): filter is NumberFilter => {
    return filter.hasOwnProperty('parent');
};

export interface CheckboxFilter extends FilterBase {
    comparator: {
        single: string | Record<string, string>;
        multiple: string | Record<string, string>;
    };
    icon: any;
    isAcronym?: boolean;
    isEmptyMessage?: string;
    isInteger?: boolean;
    label: string;
    name?: string;
    options?: {
        description?: string;
        id: number;
        name: string;
        value: string | number;
    }[];
    paramKey: string;
    property: string;
    type: 'checkbox';
    paramName: string;
}

export const isCheckboxFilter = (filter: FilterSchema): filter is CheckboxFilter => {
    return filter.type === 'checkbox';
};

export interface TagsFilter extends CheckboxFilter {
    name: 'tags';
    comparator: {
        single: Record<string, string>;
        multiple: Record<string, string>;
    };
}

export const isTagsFilter = (filter: FilterSchema): filter is TagsFilter => {
    return (filter as TagsFilter).name === 'tags';
};

export interface TextFilter extends FilterBase {
    comparator: {
        contains: string;
        notContains: string;
    };
    filterOptions: {
        value: string;
        label: string;
    }[];
    icon: any;
    label: string;
    name: string;
    paramKey: string;
    property: string;
    type: 'text';
    valueProp: string;
    paramName: string;
}

export type FilterSchema = TextFilter | CheckboxFilter | RadioFilter | ParentRadioFilter | NumberFilter | TagsFilter;

export interface ActiveFilter {
    filter?: string | number;
    filterTo?: string | number;
    filterType?: string;
    type?: string;
    values?: string[];
}

export interface TextActiveFilter extends ActiveFilter {
    filterType?: 'text';
    type: 'contains' | 'notContains';
    filter: string;
}

export const isTextActiveFilter = (filter: ActiveFilter, filterSchema: FilterSchema): filter is TextActiveFilter => {
    return filter.filterType === 'text' || filterSchema.type === 'text';
};

export interface CheckboxActiveFilter extends ActiveFilter {
    filterType?: 'checkbox';
    type: 'set';
    values: string[];
}

export const isCheckboxActiveFilter = (filter: ActiveFilter, filterSchema: FilterSchema): filter is CheckboxActiveFilter => {
    return filter.filterType === 'checkbox' || isCheckboxFilter(filterSchema);
};

export interface RadioActiveFilter extends ActiveFilter {
    filterType?: 'radio';
    type: 'set';
    values: string[];
}

export const isRadioActiveFilter = (filter: ActiveFilter, filterSchema: FilterSchema): filter is RadioActiveFilter => {
    return filter.filterType === 'radio' || filterSchema.type === 'radio';
};

export interface NumberActiveFilter extends ActiveFilter {
    filterType?: 'number';
    type: 'inRange' | 'greaterThanOrEqual' | 'lessThanOrEqual';
    filter: number;
    filterTo?: number;
}

export const isNumberActiveFilter = (filter: ActiveFilter, filterSchema: FilterSchema): filter is NumberActiveFilter => {
    return filter.filterType === 'number' || filterSchema.type === 'number';
};

export type FilterSchemaWithId = FilterSchema & { id: string };
export type FilterSchemaWithIdByCategoryId = Record<FilterCategoryTypes, FilterSchemaWithId[]>;
