import React from 'react';
import {
    Button,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    UnorderedList,
} from '@chakra-ui/react';

import { closeModal } from 'libs/global/slices/sets';
import { useAppDispatch } from 'libs/global/hooks';
import { CompSet } from 'libs/types/set';
import { BodyText } from '../components';
import { LIMITED_DATA_COUNT } from '../constants';

export const LimitedDataModalId = 'limited';

const LimitedDataModal = ({ isOpen, set }: { isOpen: boolean; set?: CompSet }) => {
    const dispatch = useAppDispatch();

    return (
        <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={() => dispatch(closeModal())} isCentered={true} size="md">
            <ModalOverlay bg="blackAlpha.300" />
            <ModalContent>
                <ModalHeader>Limited data</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Stack>
                        <BodyText fontSize="md" mb={1}>
                            This set has {set?.listings_with_metrics?.active?.length} listings, which is greater than or equal to the max ({LIMITED_DATA_COUNT})
                            listing count for full data. What does this mean?
                        </BodyText>
                        <UnorderedList spacing={1.5}>
                            <ListItem>
                                <BodyText fontSize="md">We'll only provide aggregated metrics when comparing your portfolio against this set.</BodyText>
                            </ListItem>
                            <ListItem>
                                <BodyText fontSize="md">This impacts the in-context chart and set metric views.</BodyText>
                            </ListItem>
                        </UnorderedList>
                        <BodyText fontSize="md" mb={1}>
                            If you'd like to view all metrics, you can remove listings from this set, or, create a new set comprising a subset of these
                            listings.
                        </BodyText>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => dispatch(closeModal())} variant="dark">
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default LimitedDataModal;
