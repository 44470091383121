import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FullListingPricingTier } from 'libs/types/pricingTier';

interface PricingTiersState {
    listingsToEdit: number[];
    upcomingTier: FullListingPricingTier | undefined;
    setsToEdit: {
        set_ids: number[];
        offer_id: number | null;
        type: 'upgrade' | 'downgrade' | 'cancel' | undefined;
    };
}

export const initialState = {
    listingsToEdit: [],
    upcomingTier: undefined,
    setsToEdit: {
        set_ids: [],
        offer_id: null,
        type: undefined,
    },
} as PricingTiersState;

const pricingTiersSlice = createSlice({
    name: 'pricingTiers',
    initialState,
    reducers: {
        setlistingsToEdit: (state, action: PayloadAction<number[]>) => {
            state.listingsToEdit = action.payload;
        },
        setUpcomingTierForEdit: (state, action: PayloadAction<FullListingPricingTier | undefined>) => {
            state.upcomingTier = action.payload;
        },
        setSetsToEdit: (
            state,
            action: PayloadAction<{
                set_ids: number[];
                offer_id: number | null;
                type: 'upgrade' | 'downgrade' | 'cancel' | undefined;
            }>,
        ) => {
            state.setsToEdit = action.payload;
        },
        clearSetsToEdit: (state, action: PayloadAction) => {
            state.setsToEdit = initialState.setsToEdit;
        },
    },
});

export const { setlistingsToEdit, setUpcomingTierForEdit, setSetsToEdit, clearSetsToEdit } = pricingTiersSlice.actions;

export default pricingTiersSlice.reducer;
