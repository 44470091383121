export function getPixelToRem(px) {
    const fontBase = 16;
    const rem = (1 / fontBase) * px + 'rem';

    return rem;
}

const fontWeights = {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
};

const fontSizes = {
    xxs: '0.5rem',
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
    '2xl': '1.25rem',
    '3xl': '1.5rem',
    '4xl': '1.875rem',
    '5xl': '2.25rem',
    '6xl': '3rem',
    '7xl': '4rem',
    '8xl': '5rem',
    '9xl': '6rem',
    '10xl': '7rem',
    '11xl': '8rem',
    '12xl': '16rem',
};

const lineHeights = {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
};

const letterSpacings = {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
};

const textStyles = {
    caps: {
        textTransform: 'uppercase',
        letterSpacing: '0.3px',
    },
    italic: {
        fontStyle: 'italic',
    },
    setsCaption: {
        fontFamily: 'Inter, sans-serif',
        fontWeight: 400,
        fontSize: getPixelToRem(11),
        lineHeight: 1.454,
        letterSpacing: '0.2px',
    },
};

export { letterSpacings, lineHeights, fontSizes, fontWeights, textStyles };
