'use client';
import React, { useState } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Stack } from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import type { Stripe, StripeElements } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!);

interface StripePaymentFormProps {
    onSuccess: (paymentMethod: any) => void;
    buttonText?: string;
    style?: React.CSSProperties;
    elements?: StripeElements | null;
    stripe?: Stripe | null;
}

const StripePaymentForm = (props: StripePaymentFormProps) => {
    const [error, setError] = useState<string | null>(null);
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        // Block native form submission.
        event.preventDefault();
        setError(null);

        const { stripe, elements, onSuccess } = props;
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement)!;
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[error]', error);
            setError(error.message || 'Sorry, something went wrong.');
        } else {
            onSuccess(paymentMethod);
        }
    };

    const { buttonText, style } = props;

    return (
        <form onSubmit={handleSubmit} style={style}>
            <Stack spacing={5}>
                {error ? (
                    <Alert mb={4} status="error" bg="red.100A" borderRadius={4}>
                        <AlertIcon />
                        <AlertTitle lineHeight={1.5} fontSize="sm" color="red.800" mr={2}>
                            Card could not be added.
                        </AlertTitle>
                        <AlertDescription lineHeight={1.5} fontSize="sm" color="red.800">
                            {error}
                        </AlertDescription>
                    </Alert>
                ) : null}
                <Box w="full" maxW="400px">
                    <CardElement options={{ style: { base: { fontSize: '14px', '::placeholder': {} } } }} />
                </Box>
                <Button variant="dark" width="100%" size="md" type="submit" maxW="400px">
                    {buttonText ? buttonText : 'Purchase'}
                </Button>
                {/*
                TODO - Add Apple/Google Pay support
                <PaymentRequestButtonElement options={{}} /> */}
            </Stack>
        </form>
    );
};

interface InjectedStripePaymentFormProps {
    onSuccess: (paymentMethod: any) => void;
    buttonText?: string;
    style?: React.CSSProperties;
}

const InjectedStripePaymentForm = ({ onSuccess, buttonText, style }: InjectedStripePaymentFormProps) => {
    return (
        <Elements stripe={stripePromise}>
            <ElementsConsumer>
                {({ elements, stripe }) => (
                    <StripePaymentForm elements={elements} stripe={stripe} onSuccess={onSuccess} buttonText={buttonText} style={style} />
                )}
            </ElementsConsumer>
        </Elements>
    );
};

export default InjectedStripePaymentForm;
