import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { post, request } from 'libs/requests';
import { marketApiPath, marketDataPath } from 'libs/routes';
import { Market, MarketWithStats } from 'libs/types/market';
import fetcher from 'libs/utils/fetcher';
import { useAppSelector } from 'libs/global/hooks';

interface SubscribeParams {
    searchedForMarket: MarketWithStats;
    market_ids: number[];
}

interface UnsubscribeParams {
    market_ids: number[];
}

const queryKey = (userId: number) => ['markets', userId];

const getUrl = (marketId?: number) => {
    return marketDataPath(marketId?.toString());
};

export const useUserMarkets = () => {
    const userId = useAppSelector(({ user }) => user?.id);
    return useQuery({
        queryKey: queryKey(userId),
        queryFn: async () => {
            const data: { market: MarketWithStats[] } = await fetcher(`${marketDataPath()}?user_id=${userId}`);
            return data.market;
        },
    });
};

export const useSubscribe = () => {
    const queryClient = useQueryClient();
    const userId = useAppSelector(({ user }) => user?.id);
    const qk = queryKey(userId);

    return useMutation({
        mutationFn: (newSubscription: SubscribeParams) => post(getUrl(), newSubscription),
        onMutate: (newSubscription) => {
            const currentSubscriptions = queryClient.getQueryData(qk) as any;
            queryClient.setQueryData(qk, [newSubscription.searchedForMarket, ...currentSubscriptions]);
            return () => queryClient.setQueryData(qk, currentSubscriptions);
        },
        onError: (_error, _newSubscription, rollback) => {
            rollback!();
        },
        onSettled: (_data, _error, _newSubscription) => {
            // Invalidate the returned response from onMutate in the cache
            queryClient.invalidateQueries({
                queryKey: qk,
            });
        },
    });
};

export const useUnsubscribe = () => {
    const queryClient = useQueryClient();
    const userId = useAppSelector(({ user }) => user?.id);
    const qk = queryKey(userId);

    return useMutation({
        mutationFn: (variables: UnsubscribeParams) => request(getUrl(), 'delete', undefined, variables),
        onMutate: (variables) => {
            // Grab the previous cache value
            const previousMarkets = queryClient.getQueryData(qk) as any;

            // Optimistically update the cache
            queryClient.setQueryData(
                qk,
                previousMarkets.filter((e: Market) => !variables.market_ids.includes(e.id)),
            );

            // Return snapshotted value for onError
            return () => queryClient.setQueryData(qk, previousMarkets);
        },
        onError: (_error, _variables, rollback) => {
            rollback!();
        },
        onSettled: (_data, _error, _variables) => {
            // Invalidates the returned response in the cache
            queryClient.invalidateQueries({
                queryKey: qk,
            });
        },
    });
};

export const useMarket = (marketId: number | undefined): Market | undefined => {
    const { data } = useQuery({
        queryKey: [marketApiPath(marketId)],
        queryFn: async () => {
            const data: Market = await fetcher(marketApiPath(marketId!));
            return data;
        },
        enabled: !!marketId,
        staleTime: Infinity,
    });

    return data;
};

export const useIsOutOfMarket = (marketId: number | undefined): boolean => {
    const market = useMarket(marketId);

    if (!marketId || (market && market.status !== 'A')) {
        return true;
    }

    // Default to the market being in market while the market is loading.
    return false;
};
