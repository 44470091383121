import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import savedViews from 'json/data.json';
import { getLocalStorage, setLocalStorage } from 'libs/utils/getLocalStorageItem';

export interface View {
    id: string;
    name: string;
    description: string;
    visible_attributes: string[];
    isDefault: boolean;
    isVisible?: boolean;
    isSystem?: boolean;
}

export interface InContextChartType {
    hoverDate?: number | null | undefined;
    currentView: string | null;
    currentViewData: View;
    firstDate: string | null;
    lastDate: string | null;
    tooltipType: string;
    displayBreakdown: boolean;
    displayLegend: boolean;
    displayMetaStats: boolean;
    selectedSeries: string | null;
    showBlockedBands: boolean;
    showBookedBands: boolean;
    views: View[];
    compSetId?: number | string | null | undefined;
    visibility: {
        visibleItems?: (string | undefined)[];
        visible: number;
        total: number;
        isNew?: number;
    };
}

const initialState = {
    currentView: null,
    firstDate: null,
    lastDate: null,
    selectedSeries: null,
    showBlockedBands: true,
    showBookedBands: true,
    displayBreakdown: false,
    displayLegend: false,
    displayMetaStats: false,
    compSetId: null,
    tooltipType: 'group',
    views: [],
    series: [],
    visibility: {
        visibleItems: [],
        visible: 0,
        total: 0,
        isNew: 0,
    },
    hoverDate: null,
};

const inContextChartSlice = createSlice({
    name: 'inContextChart',
    initialState: (): InContextChartType => {
        let visibility, tooltipType, displayBreakdown, displayLegend, displayMetaStats, views, currentView, currentViewData;
        try {
            displayBreakdown = getLocalStorage('displayChartBreakdown', false);
            displayLegend = getLocalStorage('displayChartLegend', false);
            displayMetaStats = getLocalStorage('displayChartMetaStats', false);
            views = getLocalStorage('in-context-views', savedViews);
            currentViewData = views.find((v) => v.isDefault);
            currentView = currentViewData?.id;

            visibility = {
                visible: currentViewData?.visible_attributes?.length,
                total: currentViewData?.visible_attributes?.length,
                new: 0,
            };
        } catch (error) {
            console.log('error: ', error);
        }

        return {
            ...initialState,
            tooltipType,
            displayBreakdown,
            displayLegend,
            displayMetaStats,
            views,
            currentView,
            currentViewData,
            visibility,
        };
    },
    reducers: {
        setCurrentView(state, action: PayloadAction<string | null>) {
            state.currentView = action.payload;
        },
        setCurrentViewData(state, action: PayloadAction<View>) {
            state.currentViewData = action.payload;
        },
        setFirstChartDate(state, action: PayloadAction<string | null>) {
            state.firstDate = action.payload;
        },
        setLastChartDate(state, action: PayloadAction<string | null>) {
            state.lastDate = action.payload;
        },
        setSelectedSeries(state, action: PayloadAction<string | null>) {
            state.selectedSeries = action.payload;
        },

        setShowBlockedBands(state, action: PayloadAction<boolean>) {
            state.showBlockedBands = action.payload;
        },
        setShowBookedBands(state, action: PayloadAction<boolean>) {
            state.showBookedBands = action.payload;
        },
        setViews(state, action: PayloadAction<View[]>) {
            state.views = action.payload;
            setLocalStorage('in-context-views', action.payload);
        },
        setVisibility(state, action: { payload: { visibleItems?: (string | undefined)[]; visible: number; total: number; new?: number } }) {
            state.visibility = action.payload;
        },
        setTooltipType(state, action: PayloadAction<string>) {
            state.tooltipType = action.payload;
            setLocalStorage('chartTooltipType', action.payload);
        },
        setDisplayBreakdown(state, action: PayloadAction<boolean>) {
            state.displayBreakdown = action.payload;
            setLocalStorage('displayChartBreakdown', action.payload);
        },
        setDisplayLegend(state, action: PayloadAction<boolean>) {
            state.displayLegend = action.payload;
            setLocalStorage('displayChartLegend', action.payload);
        },
        setDisplayMetaStats(state, action: PayloadAction<boolean>) {
            state.displayMetaStats = action.payload;
            setLocalStorage('displayChartMetaStats', action.payload);
        },
        saveNewView(state, action: PayloadAction<View>) {
            const { name, description, visible_attributes, id, isDefault } = action.payload;
            const newView = { name, description, visible_attributes, id, isDefault, isVisible: true };
            const newViewDefault = isDefault;
            let existingViews = state.views;
            if (newViewDefault) {
                existingViews = existingViews.map((v) => ({ ...v, isDefault: false }));
            }
            const newViews = [...existingViews, newView];
            state.views = newViews;
            setLocalStorage('in-context-views', newViews);
        },
        setViewVisibility: (state, action: PayloadAction<{ id: string; isVisible: boolean }>) => {
            const { id, isVisible } = action.payload;
            const newViews = state.views.map((v) => (v.id === id ? { ...v, isVisible } : v));
            state.views = newViews;
            setLocalStorage('in-context-views', newViews);
        },
        setAsDefault: (state, action: PayloadAction<{ id: string }>) => {
            const { id } = action.payload;
            const newViews = state.views.map((v) => (v.id === id ? { ...v, isDefault: true, isVisible: true } : { ...v, isDefault: false }));
            state.views = newViews;
            setLocalStorage('in-context-views', newViews);
        },
        deleteView: (state, action: PayloadAction<{ id: string }>) => {
            const { id } = action.payload;
            const newViews = state.views.filter((v) => v.id !== id);
            state.views = newViews;
            setLocalStorage('in-context-views', newViews);
        },
        setCompSetId: (state, action: PayloadAction<number | string | null>) => {
            state.compSetId = action.payload;
        },
        setViewFromDropdown: (state, action: PayloadAction<{ view: View }>) => {
            const { view } = action.payload;
            const visibleItems = view.visible_attributes;
            state.currentView = view.id;
            state.currentViewData = view;
            state.visibility = { visibleItems, visible: view.visible_attributes?.length, total: view.visible_attributes?.length, isNew: 0 };
        },
        setJourney(state, action: PayloadAction<{ currentView: string; currentViewData: View; views?: View[] }>) {
            const { currentView, currentViewData, views } = action.payload;
            state.currentView = currentView;
            state.currentViewData = currentViewData;
            if (views) {
                state.views = views;
            }
        },
        setHoverDate: (state, action: PayloadAction<number | undefined>) => {
            state.hoverDate = action.payload || null;
        },
    },
});

export const {
    setCurrentView,
    setCurrentViewData,
    setFirstChartDate,
    setLastChartDate,
    setSelectedSeries,
    setShowBlockedBands,
    setShowBookedBands,
    setViews,
    setVisibility,
    setTooltipType,
    setDisplayBreakdown,
    setDisplayLegend,
    setCompSetId,
    setDisplayMetaStats,
    setJourney,
    saveNewView,
    setViewVisibility,
    deleteView,
    setAsDefault,
    setViewFromDropdown,
    setHoverDate,
} = inContextChartSlice.actions;

export default inContextChartSlice.reducer;
