import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Fade,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Textarea,
} from '@chakra-ui/react';

import { closeModal } from 'libs/global/slices/sets';
import { BodyText, BodyTextBold, BodyTextSecondary, ImageWithFallback } from '../components';
import { useAppDispatch } from 'libs/global/hooks';
import { useDuplicateSet } from 'libs/global/hooks/useSets';

export const DuplicateModalId = 'duplicate';

const Listing = ({ listing }) => {
    return (
        <Flex alignItems="center" py={2} gap={2} pr={2}>
            <Flex gap={2} alignItems="start" w="full">
                <Checkbox
                    value={JSON.stringify(listing.listing_id)}
                    size="lg"
                    zIndex={10}
                    bg="white"
                    colorScheme="gray"
                    outline="3px solid"
                    outlineColor="white"
                    rounded="sm"
                    iconSize="12px"
                    sx={{
                        '> span': {
                            borderColor: 'gray.200A',
                            borderWidth: '1px',
                        },
                    }}
                />
                <Box w="full">
                    <BodyText noOfLines={1} lineHeight={1.25}>
                        {listing?.title}
                    </BodyText>
                    <BodyTextSecondary noOfLines={1} maxW="45ch">
                        {listing?.address}
                    </BodyTextSecondary>
                </Box>
            </Flex>

            {listing?.thumb_url ? (
                <Box overflow="hidden" rounded="md" flexShrink={0} position="relative" h="30px" w="30px">
                    <ImageWithFallback
                        quality={60}
                        alt="Listing image"
                        fill={true}
                        style={{ objectFit: 'cover' }}
                        sizes="(max-width: 30px)"
                        src={listing?.thumb_url}
                        fallbackSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNct2JdPQAGlQKFS+8MHQAAAABJRU5ErkJggg=="
                        placeholder="blur"
                        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNct2JdPQAGlQKFS+8MHQAAAABJRU5ErkJggg=="
                    />
                </Box>
            ) : (
                <Box overflow="hidden" rounded="md" flexShrink={0} position="relative" bg="gray.100A" h="34px" w="34px" />
            )}
        </Flex>
    );
};

const DuplicateModal = ({ isOpen, set }) => {
    const dispatch = useAppDispatch();
    const { mutateAsync: duplicateSet, isPending: isSaving } = useDuplicateSet();
    const [name, setName] = useState(`${set.name} (copy)`);
    const [description, setDescription] = useState('');
    const [values, setValues] = React.useState(set?.listings_with_metrics?.active?.map((listing) => JSON.stringify(listing.listing_id)));
    const [, setIsAllSelected] = React.useState(false);

    useEffect(() => {
        if (set?.listings_with_metrics?.active && values?.length === set?.listings_with_metrics?.active?.length) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const duplicate = () => {
        duplicateSet({
            name,
            description,
            active: values.map((value) => parseInt(value)),
        }).then(() => {
            dispatch(closeModal());
        });
    };

    return (
        <Modal motionPreset="slideInBottom" isOpen={isOpen} onClose={() => dispatch(closeModal())} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader color="gray.800">Duplicate modal</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={5}>
                        <FormControl isRequired={true}>
                            <FormLabel mb={1} color="gray.800" fontWeight="medium">
                                Name*
                            </FormLabel>
                            <Input
                                isDisabled={isSaving}
                                fontSize="md"
                                size="lg"
                                borderColor="gray.100"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <FormHelperText color="gray.400" mt={1.5}>
                                The more specific to your use case the better!
                            </FormHelperText>
                        </FormControl>
                        <FormControl id="description">
                            <FormLabel mb={1} color="gray.800" fontWeight="medium">
                                Description
                            </FormLabel>

                            <Textarea
                                isDisabled={isSaving}
                                rows={2}
                                borderColor="gray.100"
                                resize="none"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <FormHelperText color="gray.400" mt={1.5}>
                                Write a brief description
                            </FormHelperText>
                        </FormControl>
                        {set?.listings_with_metrics?.active?.length && (
                            <Box h="full" overflow="hidden">
                                <Stack h="full" flex={1}>
                                    <BodyTextBold>Edit competitors</BodyTextBold>
                                    <Box maxHeight={175} overflowY="auto" h="full" className="scroll-update">
                                        {set && set?.listings_with_metrics && Array.isArray(set?.listings_with_metrics?.active) ? (
                                            <Fade in={set}>
                                                <CheckboxGroup
                                                    defaultValue={values}
                                                    value={values}
                                                    onChange={(v) => {
                                                        setValues(v);
                                                    }}>
                                                    <Stack spacing={0}>
                                                        {set?.listings_with_metrics?.active.map((listing) => (
                                                            <Listing listing={listing} key={listing.listing_id} />
                                                        ))}
                                                    </Stack>
                                                </CheckboxGroup>
                                            </Fade>
                                        ) : null}
                                    </Box>
                                </Stack>
                            </Box>
                        )}
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button isDisabled={isSaving} borderColor="gray.100A" variant="secondary" mr={3} onClick={() => dispatch(closeModal())}>
                        Cancel
                    </Button>
                    <Button isLoading={isSaving} onClick={duplicate} variant="dark">
                        Duplicate
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DuplicateModal;
