import { createSlice } from '@reduxjs/toolkit';
import { User } from 'libs/types/user';

export const initialState = {} as User;

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUserSlice: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const { updateUserSlice } = userSlice.actions;

export default userSlice.reducer;
