import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dateFilterReducers, DateFilters, defaultDateFilters } from './shared/dateFilters';
import { ActiveFilterId, defaultListingFilters, listingFilterReducers, ListingFilters } from './shared/listingFilters';
import { defaultSegmentState, Segment, segmentReducers } from './shared/segments';

export const MAX_COMP_SETS_LISTINGS = 250;

export type BucketTypes = 'portfolio' | 'segments' | 'assignedListings' | 'compSets' | 'yoy';

export type Metric = 'revenue' | 'occupancy' | 'bookings' | 'adr' | 'lead_time';

export type ExtendedMetric = Metric | 'revpar' | 'bookable_nights';

export interface OverlayMetric {
    id: ExtendedMetric;
    isYoY?: boolean;
}

const metrics: Metric[] = ['revenue', 'occupancy', 'bookings', 'adr', 'lead_time'];
export const metricsScale: Metric[] = ['revenue', 'bookings'];

export const isMetric = (value: string): value is Metric => metrics.includes(value as Metric);
export const isMetricScale = (value: string): value is Metric => metricsScale.includes(value as Metric);

export interface BucketTypeWithLabel {
    type: BucketTypes;
    label: string;
}

export interface Bucket {
    type: BucketTypes;
    label: string;
    compId?: number;
    segmentId?: number;
    name?: string;
    isComparison: boolean;
    isYoY?: boolean;
    filterId: ActiveFilterId;
    bucketId: string;
}

export type BucketWithoutStaticItems = Omit<Bucket, 'bucketId' | 'filterId' | 'isComparison'>;

export interface BookingState extends Segment {
    dateFilters: DateFilters;
    filters: ListingFilters;
    fees: boolean;
    metric: Metric;
    avgMetricPerListing: boolean;
    overlayMetrics: OverlayMetric[];
    currency: string;
    comparisonMode: boolean;
    control: Bucket;
    comparison: Bucket;
}

export const initialState: BookingState = {
    dateFilters: defaultDateFilters,
    filters: defaultListingFilters,
    ...defaultSegmentState,
    fees: true,
    metric: 'revenue',
    avgMetricPerListing: false,
    overlayMetrics: [],
    currency: 'USD',
    comparisonMode: false,
    control: {
        type: 'portfolio',
        label: 'Portfolio',
        filterId: 'active',
        isComparison: false,
        bucketId: 'control',
    },
    comparison: {
        type: 'portfolio',
        label: 'Portfolio',
        filterId: 'activeComparison',
        isComparison: true,
        bucketId: 'comparison',
    },
};

const bookingUiSlice = createSlice({
    name: 'bookingUi',
    initialState,
    reducers: {
        ...dateFilterReducers,
        ...listingFilterReducers,
        ...segmentReducers,
        setToggleComparisonMode: (state, action: PayloadAction<boolean | undefined>) => {
            if (action.payload !== undefined) {
                state.comparisonMode = action.payload;
            } else {
                const { comparisonMode, comparison } = state;
                if (!comparisonMode && comparison.isYoY) {
                    state.comparison = comparison.isYoY
                        ? { ...comparison, type: state.control.type }
                        : { ...state.control, label: 'Same time last year', type: 'yoy' };
                }
                state.comparisonMode = !comparisonMode;
            }
        },
        setCurrency: (state, action: PayloadAction<string>) => {
            state.currency = action.payload;
        },
        setMetric: (state, action: PayloadAction<Metric>) => {
            state.metric = action.payload;
        },
        setOverlayMetrics: (state, action: PayloadAction<OverlayMetric[]>) => {
            const overlayMetrics = state.overlayMetrics;
            const [overlayMetric] = action.payload.slice(-1);
            if (!overlayMetric) {
                // Clearing overlay metrics
                state.overlayMetrics = [];
            } else if (!overlayMetrics.length) {
                // Adding first overlay metric
                state.overlayMetrics = [overlayMetric];
            } else {
                state.overlayMetrics = [...overlayMetrics.filter((m) => m.id == overlayMetric.id), overlayMetric];
            }
        },
        setFees: (state, action: PayloadAction<boolean>) => {
            state.fees = action.payload;
        },
        setBucket: (state, action: PayloadAction<{ bucketId: string; bucket: BucketWithoutStaticItems }>) => {
            const { filterId, isComparison } = state[action.payload.bucketId];
            state[action.payload.bucketId] = {
                ...action.payload.bucket,
                filterId,
                isComparison,
                bucketId: action.payload.bucketId,
                isYoY: action.payload.bucket.type === 'yoy',
            };
        },
        setYoYComparison: (state) => {
            state.comparison = {
                ...state.control,
                bucketId: state.comparison.bucketId,
                filterId: state.comparison.filterId,
                isComparison: true,
                isYoY: true,
                label: 'Same time last year',
                type: 'yoy',
            };
        },
        setToggleAvgMetricPerListing: (state) => {
            state.avgMetricPerListing = !state.avgMetricPerListing;
        },
    },
});

export const actions = bookingUiSlice.actions;

export default bookingUiSlice.reducer;
