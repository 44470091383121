import { PayloadAction } from '@reduxjs/toolkit';
import type { ActiveFilter } from 'libs/types/filters';

export type Segment = {
    modalId: string | undefined;
    segmentId: number | undefined;
    modalSegmentId: number | undefined;
    modalSegmentFilters: Record<string, ActiveFilter> | undefined;
};

export const defaultSegmentState = {
    modalId: undefined,
    segmentId: undefined,
    modalSegmentId: undefined,
    modalSegmentFilters: undefined,
};

export const segmentReducers = {
    closeSegmentModal: (state: Segment) => {
        state.modalId = undefined;
        state.modalSegmentId = undefined;
    },
    openSegmentModal: (state: Segment, action: PayloadAction<{ id: string; segmentId?: number; filters?: Record<string, ActiveFilter> }>) => {
        state.modalId = action.payload.id;
        state.modalSegmentId = action.payload.segmentId;
        state.modalSegmentFilters = action.payload.filters;
    },
    updateSegmentId: (state: Segment, action: PayloadAction<number | undefined>) => {
        state.segmentId = action.payload;
    },
};
