import React, { useEffect, useRef, useState } from 'react';
import * as turf from '@turf/turf';
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Fade,
    Flex,
    FormControl,
    Grid,
    Input,
    InputGroup,
    InputRightAddon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    StackDivider,
    Text,
} from '@chakra-ui/react';

import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { debounce } from 'lodash';
import { listingBulkPathV2 } from 'libs/routes';

import { closeModal, fetchComparisonListings } from 'libs/global/slices/sets';
import { fetchV2API } from 'libs/utils/fetcher';
import { ImageWithFallback, SidebarSubText } from '../components';
import { filterableURL } from 'libs/global/selectors/dashboard';
import { useAppDispatch, useAppSelector } from 'libs/global/hooks';
import { ListingV2 } from 'libs/types/listingV2';
import { shallowEqual } from 'react-redux';
import { loadToastError } from 'libs/global/components/CompNext/Modals/utils';

export const CompareModalId = 'compare';

const SEARCH_COUNT = 50;

const ListingRow = ({ listing }: { listing: ListingV2 }) => {
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const { latitude, longitude } = useAppSelector(
        (state) => ({ latitude: state.sets?.viewState?.latitude, longitude: state.sets?.viewState?.longitude }),
        shallowEqual,
    );
    const compare_listing_ids = useAppSelector((state) => state.sets.compare_listing_ids);
    const nickname = listing?.preferences?.nickname || listing?.display_nickname || listing?.nickname;
    const title = listing?.display_name || listing?.title;

    const from = turf.point([listing?.longitude, listing?.latitude]);
    const to = turf.point([longitude, latitude]);

    const options = { units: 'miles' };
    // @ts-ignore
    const distance = turf.distance(from, to, options);

    const compareListing = async () => {
        setIsSaving(true);

        const response = await dispatch(fetchComparisonListings([...compare_listing_ids, listing.id]));

        if (fetchComparisonListings.fulfilled.match(response)) {
            toast.success('Listing added to comparison.');
            setIsSaving(false);
        } else {
            loadToastError();
            setIsSaving(false);
        }
    };

    return (
        <Flex justifyContent="space-between" minH="60px" alignItems="center" px={6} py={3} gap={4} border="1px" borderColor="gray.100A" rounded="md">
            <Flex alignItems="center" gap={4} w="37%">
                {listing?.thumb_url ? (
                    <Box overflow="hidden" rounded="md" flexShrink={0} position="relative" h="28px" w="28px">
                        <ImageWithFallback
                            quality={100}
                            alt="Listing image"
                            fill={true}
                            style={{ objectFit: 'cover' }}
                            sizes="(max-width: 28px)"
                            src={listing?.thumb_url}
                            fallbackSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNct2JdPQAGlQKFS+8MHQAAAABJRU5ErkJggg=="
                            placeholder="blur"
                            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNct2JdPQAGlQKFS+8MHQAAAABJRU5ErkJggg=="
                        />
                    </Box>
                ) : (
                    <Box overflow="hidden" rounded="md" flexShrink={0} position="relative" bg="gray.100A" h="34px" w="34px" />
                )}

                <Box>
                    <Text fontSize="md" color="gray.800" noOfLines={1} fontWeight="semibold" lineHeight={1.25}>
                        {nickname || title}
                    </Text>
                    {nickname && (title || listing?.address) ? (
                        <SidebarSubText noOfLines={1} maxW="45ch">
                            {title || listing?.address}
                        </SidebarSubText>
                    ) : null}
                </Box>
            </Flex>

            <Text fontSize="13px" color="gray.400">
                {distance.toFixed(1)} mi from map center
            </Text>

            <Button
                isDisabled={!!compare_listing_ids?.find((compare_id) => compare_id === listing.id)}
                variant="dark"
                size="sm"
                onClick={compareListing}
                isLoading={isSaving}>
                Compare
            </Button>
        </Flex>
    );
};

const CompareModal = ({ isOpen }) => {
    const dispatch = useAppDispatch();

    const initialRef = useRef(null);
    const [filterByDistance, setFilterByDistance] = useState(false);
    const [page, setPage] = useState(1);
    const [distance, setDistance] = useState(10);
    const { latitude, longitude } = useAppSelector((state) => ({
        latitude: state.sets.viewState.latitude,
        longitude: state.sets.viewState.longitude,
    }));
    const [selectMultiple] = useState(false);
    const [values, setValues] = useState<string[]>([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    const [searchValue, setSearchValue] = React.useState('');

    const checkAllValues = () => {
        const allValues = data?.items?.map((listing) => JSON.stringify(listing.id))!;
        setValues(allValues);
    };

    // Fetching listings
    const { data, isFetching } = useQuery({
        queryKey: [listingBulkPathV2(), searchValue, filterByDistance, distance, page],
        queryFn: () => fetchV2API<ListingV2[]>(generateURL()),
        gcTime: 0,
    });

    useEffect(() => {
        if (data?.items && values?.length === data?.items?.length) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);
    const generateURL = () => {
        return filterableURL(listingBulkPathV2(), {
            params: {
                ...(filterByDistance && distance && { latlong_distance_from_lte: `${latitude},${longitude},${distance * 1609}` }),
                'listing_views.non_test_hypothetical': '',
                'listing_views.searchable': searchValue,
            },
            ...{ per_page: SEARCH_COUNT, page: page },
        });
    };

    const debounceSearch = debounce((e) => {
        setSearchValue(e.target.value);
    }, 400);

    const getLabel = () => {
        if (isFetching) {
            return `Searching ${searchValue?.length > 0 ? `for ${searchValue}...` : '...'}`;
        }

        if (data && data?.items?.length === 0) {
            return `No results ${searchValue?.length > 0 ? `for ${searchValue}` : ''}`;
        }
        if (data === undefined && !isFetching) {
            return 'No results';
        } else if (data && data?.items && Array.isArray(data?.items)) {
            return `${data?.items.length === SEARCH_COUNT ? 'Top' : ''} ${data?.items.length} result${data?.items.length > 1 ? 's' : ''}`;
        } else {
            ('Results');
        }
    };

    return (
        <Modal
            initialFocusRef={initialRef}
            isOpen={isOpen}
            onClose={() => dispatch(closeModal())}
            isCentered={true}
            scrollBehavior="inside"
            motionPreset="slideInBottom"
            size="2xl">
            <ModalOverlay />
            <ModalContent height={500}>
                <ModalHeader p={0}>
                    <Flex py={4} px={6} display="flex" alignItems="center" justifyContent="space-between" borderBottom="1px" borderColor="gray.100A">
                        <Box>
                            <Text fontSize="lg">Compare your listings</Text>
                            <Text fontWeight="normal" color="gray.400">
                                When previewing listings, we'll display side-by-side comparison of your listings.
                            </Text>
                        </Box>
                    </Flex>
                    <Box px={6} py={3}>
                        <FormControl>
                            <Input
                                defaultValue=""
                                onChange={(e) => {
                                    debounceSearch(e);
                                }}
                                size="lg"
                                fontSize="md"
                                sx={{
                                    '::placeholder': {
                                        color: 'gray.300',
                                    },
                                }}
                                borderColor="gray.100"
                                placeholder="Search for a listing"
                                roundedBottom="none"
                                ref={initialRef}
                            />
                        </FormControl>
                        <Flex
                            py={2}
                            border="1px"
                            borderTop="none"
                            borderColor="gray.100A"
                            fontWeight={400}
                            display="flex"
                            w="full"
                            fontSize="md"
                            justifyContent="space-between"
                            alignItems="center"
                            px={3}
                            bg="gray.25A">
                            <Flex gap={2} alignItems="center">
                                <Checkbox
                                    onChange={() => setFilterByDistance(!filterByDistance)}
                                    isChecked={filterByDistance}
                                    colorScheme="gray"
                                    sx={{
                                        '.chakra-checkbox__control': {
                                            borderWidth: '1px',
                                        },
                                    }}
                                    size="md"
                                />

                                <Text fontSize="13px" color="gray.500">
                                    Filter by distance from map center
                                </Text>
                            </Flex>
                            {filterByDistance ? (
                                <InputGroup rounded="sm" w="auto">
                                    <Input
                                        defaultValue={distance}
                                        isDisabled={!filterByDistance}
                                        w={24}
                                        size="md"
                                        bg="white"
                                        borderColor="gray.100"
                                        onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            setDistance(value);
                                        }}
                                    />
                                    <InputRightAddon
                                        borderColor="gray.100"
                                        flexShrink={0}
                                        w="auto"
                                        defaultValue={10}
                                        bg="tranparent"
                                        color="gray.400A"
                                        fontSize="11px"
                                        fontWeight={450}
                                        textTransform="uppercase"
                                        letterSpacing="wide">
                                        Miles
                                    </InputRightAddon>
                                </InputGroup>
                            ) : null}
                        </Flex>
                        <Flex
                            py={2}
                            border="1px"
                            borderTop="none"
                            borderColor="gray.100A"
                            roundedTop="none"
                            h="auto"
                            fontWeight={400}
                            display="flex"
                            w="full"
                            fontSize="md"
                            justifyContent="space-between"
                            alignItems="center"
                            px={3}
                            bg="gray.25A"
                            color="gray.400"
                            roundedBottom="md">
                            <Text fontSize="13px" color="gray.400">
                                {getLabel()}
                            </Text>
                        </Flex>

                        {!isFetching && data?.items?.length !== 0 && !Array.isArray(data) && selectMultiple ? (
                            <Flex mt={4} alignItems="center" justifyContent="space-between">
                                <Flex gap={2}>
                                    <Checkbox
                                        value={isAllSelected ? 'true' : undefined}
                                        isIndeterminate={values.length > 0 && !isAllSelected}
                                        onChange={() => (isAllSelected ? setValues([]) : checkAllValues())}
                                        size="lg"
                                        zIndex={10}
                                        bg="white"
                                        colorScheme="gray"
                                        outline="3px solid"
                                        outlineColor="white"
                                        sx={{
                                            '> span': {
                                                borderColor: 'gray.200A',
                                                borderWidth: '1px',
                                            },
                                        }}
                                    />
                                    <Text fontSize="13px" color="gray.400">
                                        Select all
                                    </Text>
                                </Flex>
                            </Flex>
                        ) : null}
                    </Box>
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody className="scroll-update" minH={300} px={0}>
                    <Grid h="full" gap={4}>
                        <Box>
                            <Box h="full" overflow="hidden">
                                <Box h="full" flex={1}>
                                    <Grid overflowY="auto" h="full" className="scroll-update">
                                        {data && data?.items && Array.isArray(data?.items) ? (
                                            <Fade in={!!data}>
                                                <Box h="full">
                                                    <CheckboxGroup
                                                        colorScheme="gray"
                                                        defaultValue={values}
                                                        value={values}
                                                        onChange={(v: any) => {
                                                            setValues(v);
                                                        }}>
                                                        <Stack
                                                            px={selectMultiple ? 0 : 6}
                                                            spacing={selectMultiple ? 2 : 4}
                                                            divider={selectMultiple ? <StackDivider borderColor="gray.100A" /> : undefined}>
                                                            {data?.items?.map((listing) => <ListingRow listing={listing} key={listing.id} />)}
                                                        </Stack>
                                                    </CheckboxGroup>
                                                </Box>
                                            </Fade>
                                        ) : null}
                                        <Flex p={6} alignSelf="end" alignItems="center" justifyContent="flex-end" gap={2}>
                                            <Button
                                                isDisabled={page === 1}
                                                onClick={() => {
                                                    setPage(page - 1);
                                                }}
                                                isLoading={isFetching}
                                                variant="secondary"
                                                borderColor="gray.100A"
                                                size="sm">
                                                Previous
                                            </Button>
                                            <Button
                                                isDisabled={data?.items?.length! < SEARCH_COUNT}
                                                onClick={() => {
                                                    setPage(page + 1);
                                                }}
                                                isLoading={isFetching}
                                                variant="dark"
                                                size="sm">
                                                Next
                                            </Button>
                                        </Flex>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CompareModal;
