import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DailySyncCount {
    count: number;
    lastSync: number;
}

export const initialState = {} as Record<number, DailySyncCount>;

const dailySyncCountSlice = createSlice({
    name: 'dailySyncCount',
    initialState,
    reducers: {
        setSyncCount: (state, action: PayloadAction<{ listing_id: number; count: number }>) => {
            state[action.payload.listing_id] = {
                count: action.payload.count,
                lastSync: new Date().getTime(),
            };
        },
    },
});

export const { setSyncCount } = dailySyncCountSlice.actions;

export default dailySyncCountSlice.reducer;
