'use client';

import React from 'react';
import { error, success } from 'libs/utils/colors';
import { Box } from '@chakra-ui/react';

interface NotificationProps {
    successMsg?: string | null;
    errorMsg?: string | React.ReactNode;
    close: () => void;
}

const Notification = (props: NotificationProps) => {
    const styles = {
        base: {
            position: 'fixed' as React.CSSProperties['position'],
            top: 32,
            right: 44,
            left: 44,
            zIndex: 99999,
            borderRadius: 6,
            padding: '10px 18px',
            opacity: 0.9,
        },
        success: {
            backgroundColor: success,
        },
        error: {
            backgroundColor: error,
        },
    };

    return (
        <Box
            onClick={props.close}
            style={{
                ...styles.base,
                ...(props.successMsg ? styles.success : null),
                ...(props.errorMsg ? styles.error : null),
            }}>
            <Box
                position="absolute"
                fontSize="24px"
                color="#fff"
                cursor="pointer"
                fontWeight="bold"
                right="25px"
                top="4px"
                zIndex="1"
                _hover={{
                    opacity: 1,
                }}>
                ×
            </Box>
            <div
                data-testid="notification-text"
                style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: '#fff',
                    paddingRight: 30,
                }}>
                {props.successMsg || props.errorMsg}
            </div>
        </Box>
    );
};

export default Notification;
