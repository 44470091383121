import React from 'react';
import { HStack, Icon, Text, TextProps, Tooltip } from '@chakra-ui/react';
import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { capitalize } from 'lodash';
import moment from 'moment';
import { metricName } from 'libs/utils/metricDefinitions';
import { currencyConverter, percentageValueFormatter } from './overviewColumnDefs';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { FilterCategoryTypes } from 'libs/types/meta';
import { HISTORICAL_PERIODS, MONTH_PERIODS, PERIODS } from './listingStatColumnDefsConstants';

const columnParams: Partial<ColDef> = {
    width: 65,
    suppressHeaderMenuButton: true,
    sortable: true,
    hide: true,
    pinned: true,
    type: 'numericColumn',
};

const css = {
    '::-webkit-scrollbar': {
        height: '1px',
        width: '8px',
    },

    '::-webkit-scrollbar-thumb': {
        background: '#222',
        borderRadius: '0px',
    },

    '::-webkit-scrollbar-thumb:hover': {
        background: '#222',
    },

    '::-webkit-scrollbar-track': {
        background: '#fff',
    },
};

interface HeaderComponentProps {
    name: string;
    label: string;
    isHistorical?: boolean;
    fontSize?: TextProps['fontSize'];
    hideIcon?: boolean;
}
const HeaderComponent = ({ name, label, isHistorical, fontSize, hideIcon }: HeaderComponentProps) => {
    return (
        <Tooltip label={capitalize(label)}>
            <HStack overflowX="scroll" css={css}>
                {!hideIcon ? <Icon as={isHistorical ? ArrowLeft : ArrowRight} /> : null}
                <Text fontSize={fontSize || 'sm'} color="gray.500">
                    {name}
                </Text>
            </HStack>
        </Tooltip>
    );
};

interface GroupParamsProps {
    metric_id: string;
    headerName?: string;
    isHistorical?: boolean;
    headerTooltip?: string;
    groupId?: string;
    suppressColumnsToolPanel?: boolean;
    categoryId?: FilterCategoryTypes;
}

const groupParams = ({ metric_id, headerName, isHistorical, headerTooltip, groupId, ...rest }: GroupParamsProps) => {
    const name = metricName(metric_id, true) || headerName;
    return {
        headerName: name,
        headerGroupComponent: HeaderComponent,
        headerGroupComponentParams: {
            name,
            isHistorical,
            label: headerTooltip || name + ` ${isHistorical ? 'last' : 'next'} x days`,
        },
        groupId: groupId || metric_id,
        categoryId: isHistorical ? 'historical_metrics' : 'future_metrics',
        ...rest,
    };
};

const listingStatsColumns: (ColGroupDef | ColDef)[] = [
    {
        field: 'stat.last_booked_at',
        colId: 'stat.last_booked_at',
        paramKey: 'listing_stats.last_booked_at',
        headerName: 'Last Booked',
        headerTooltip: 'Time from now since last booking',
        valueFormatter: ({ value }) => {
            if (!value) {
                return '-';
            } else {
                const daysAgo = moment.utc().diff(moment.utc(value), 'days');
                if (daysAgo === 1) {
                    return '1 day ago';
                } else {
                    return `${daysAgo} days ago`;
                }
            }
        },
        cellStyle: () => {
            return { display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-end' };
        },
        ...columnParams,
        categoryId: 'historical_metrics',
        width: 100,
    } as ColDef,
    {
        ...groupParams({ metric_id: 'adjusted_occupancy' }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.open_occupancy_0_${period}`;
            return {
                field: `stat.occupancy.0_${period}`,
                colId: `stat.occupancy.0_${period}`,
                paramKey: paramKey,
                headerName: `${period} days`,
                valueFormatter: percentageValueFormatter,
                cellStyle: () => {
                    return { display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-end' };
                },
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({ metric_id: 'adjusted_occupancy', isHistorical: true, groupId: 'adjusted_occupancy_historical' }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.open_occupancy_${period}_0`;
            return {
                field: `stat.occupancy.${period}_0`,
                colId: `stat.occupancy.${period}_0`,
                paramKey: paramKey,
                headerName: `${period} days`,
                valueFormatter: percentageValueFormatter,
                cellStyle: () => {
                    return { display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-end' };
                },
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({ metric_id: 'average_nightly_rate' }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.adr_0_${period}`;
            return {
                field: `stat.adr.0_${period}`,
                colId: `stat.adr.0_${period}`,
                paramKey: paramKey,
                headerName: `${period} days`,
                cellRenderer: 'revenueMetricCellRenderer',
                valueGetter: (params) => currencyConverter(params),
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({ metric_id: 'average_nightly_rate', isHistorical: true, groupId: 'average_nightly_rate_historical' }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.adr_${period}_0`;
            return {
                field: `stat.adr.${period}_0`,
                colId: `stat.adr.${period}_0`,
                paramKey: paramKey,
                headerName: `${period} days`,
                cellRenderer: 'revenueMetricCellRenderer',
                valueGetter: (params) => currencyConverter(params),
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({ metric_id: 'adjusted_nightly_revpar' }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.revpar_non_blocked_0_${period}`;
            return {
                field: `stat.revpar.0_${period}`,
                colId: `stat.revpar.0_${period}`,
                paramKey: paramKey,
                headerName: `${period} days`,
                cellRenderer: 'revenueMetricCellRenderer',
                valueGetter: (params) => currencyConverter(params),
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({
            metric_id: 'adjusted_nightly_revpar',
            isHistorical: true,
            groupId: 'adjusted_nightly_revpar_historical',
        }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.revpar_non_blocked_${period}_0`;
            return {
                field: `stat.revpar.${period}_0`,
                colId: `stat.revpar.${period}_0`,
                paramKey: paramKey,
                headerName: `${period} days`,
                cellRenderer: 'revenueMetricCellRenderer',
                valueGetter: (params) => currencyConverter(params),
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({ metric_id: 'nightly_revenue' }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.revenue_0_${period}`;
            return {
                field: `stat.revenue.0_${period}`,
                colId: `stat.revenue.0_${period}`,
                paramKey: paramKey,
                headerName: `${period} days`,
                cellRenderer: 'revenueMetricCellRenderer',
                valueGetter: (params) => currencyConverter(params),
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({ metric_id: 'nightly_revenue', isHistorical: true, groupId: 'nightly_revenue_historical' }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.revenue_${period}_0`;
            return {
                field: `stat.revenue.${period}_0`,
                colId: `stat.revenue.${period}_0`,
                paramKey: paramKey,
                headerName: `${period} days`,
                cellRenderer: 'revenueMetricCellRenderer',
                valueGetter: (params) => currencyConverter(params),
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({ metric_id: 'apr' }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.apr_0_${period}`;
            return {
                field: `stat.apr.0_${period}`,
                colId: `stat.apr.0_${period}`,
                paramKey: paramKey,
                headerName: `${period} days`,
                cellRenderer: 'revenueMetricCellRenderer',
                valueGetter: (params) => currencyConverter(params),
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({ metric_id: 'apr', isHistorical: true, groupId: 'apr_historical' }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.apr_${period}_0`;
            return {
                field: `stat.apr.${period}_0`,
                colId: `stat.apr.${period}_0`,
                paramKey: paramKey,
                headerName: `${period} days`,
                cellRenderer: 'revenueMetricCellRenderer',
                valueGetter: (params) => currencyConverter(params),
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({ metric_id: 'available_nights' }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.available_nights_0_${period}`;
            return {
                field: `stat.available_nights.0_${period}`,
                colId: `stat.available_nights.0_${period}`,
                paramKey: paramKey,
                headerName: `${period} days`,
                valueFormatter: ({ value }) => (Number.isFinite(value) ? value : '-'),
                cellStyle: () => {
                    return { display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-end' };
                },
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({ metric_id: 'blocked_nights' }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.blocked_nights_0_${period}`;
            return {
                field: `stat.blocked_nights.0_${period}`,
                colId: `stat.blocked_nights.0_${period}`,
                paramKey: paramKey,
                headerName: `${period} days`,
                valueFormatter: ({ value }) => (Number.isFinite(value) ? value : '-'),
                cellStyle: () => {
                    return { display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-end' };
                },
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({ metric_id: 'min_price_occurrence', headerName: 'Min. Price Occurrence' }),
        children: PERIODS.map((period) => {
            const paramKey = `listing_stats.min_price_occurrence_0_${period}`;
            return {
                field: `stat.min_price_occurrence.0_${period}`,
                colId: `stat.min_price_occurrence.0_${period}`,
                paramKey: paramKey,
                headerName: `${period} days`,
                valueFormatter: ({ value }) => (Number.isFinite(value) ? value : '-'),
                cellStyle: () => {
                    return { display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-end' };
                },
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({
            metric_id: 'booked_in',
            headerName: 'Booked nights in',
            isHistorical: true,
            headerTooltip: 'Number of nights booked in last x days',
        }),
        children: HISTORICAL_PERIODS.map((period) => {
            const paramKey = `listing_stats.booked_in_${period}_0`;
            return {
                field: `stat.booked_in.${period}_0`,
                colId: `stat.booked_in.${period}_0`,
                paramKey: paramKey,
                headerName: `${period} days`,
                headerTooltip: `Number of nights booked in last ${period} days`,
                valueFormatter: ({ value }) => (Number.isFinite(value) ? value : '-'),
                cellStyle: () => {
                    return { display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-end' };
                },
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({
            metric_id: 'revenue_score',
            headerName: 'Revenue Score',
            headerTooltip: 'Revenue score for the next x days',
        }),
        children: MONTH_PERIODS.map((period, index) => {
            const startPeriod = index === 0 ? 0 : MONTH_PERIODS[index - 1] + 1;
            const endPeriod = period;
            const paramKey = `listing_stats.revenue_score_${startPeriod}_${endPeriod}`;
            return {
                field: `stat.revenue_scores.${startPeriod}_${endPeriod}`,
                colId: `stat.revenue_scores.${startPeriod}_${endPeriod}`,
                paramKey: paramKey,
                headerName: `${period} days`,
                headerTooltip: `Revenue score for the next ${startPeriod}-${endPeriod} days`,
                valueFormatter: ({ value }) => (Number.isFinite(value) ? value : '-'),
                cellStyle: () => {
                    return { display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-end' };
                },
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({
            metric_id: 'min_price',
            headerName: 'Lowest price',
            isHistorical: true,
        }),
        children: [365].map((period) => {
            const paramKey = `listing_stats.min_price_${period}_0`;
            return {
                field: `stat.min_price.${period}_0`,
                colId: `stat.min_price.${period}_0`,
                paramKey: paramKey,
                headerName: `${period} days`,
                cellRenderer: 'revenueMetricCellRenderer',
                valueGetter: (params) => currencyConverter(params),
                ...columnParams,
            };
        }),
    },
    {
        ...groupParams({
            metric_id: 'max_price',
            headerName: 'Highest price',
            isHistorical: true,
        }),
        children: [365].map((period) => {
            const paramKey = `listing_stats.max_price_${period}_0`;
            return {
                field: `stat.max_price.${period}_0`,
                colId: `stat.max_price.${period}_0`,
                paramKey: paramKey,
                headerName: `${period} days`,
                cellRenderer: 'revenueMetricCellRenderer',
                valueGetter: (params) => currencyConverter(params),
                ...columnParams,
            };
        }),
    },
];

export default listingStatsColumns;
