import { ComponentStyleConfig } from '@chakra-ui/react';
import colors, { colorStyles } from './colors';
import buttons from './buttons';
import sizes, { baseSizes } from './sizes';
import { fontSizes, fontWeights, letterSpacings, lineHeights, textStyles } from './typography';

import { cssVar } from '@chakra-ui/theme-tools';

const space = baseSizes;

const breakpoints = {
    sm: '20em',
    md: '30em',
    lg: '48em',
    xl: '62em',
    '2xl': '75em',
    '3xl': '90em',
    '4xl': '105em',
    '5xl': '120em',
};

const mediaQueries = {
    3: `@media screen and (min-width: ${breakpoints.sm})`,
    4: `@media screen and (min-width: ${breakpoints.md})`,
    7: `@media screen and (min-width: ${breakpoints.lg})`,
    9: `@media screen and (min-width: ${breakpoints.xl})`,
    12: `@media screen and (min-width: ${breakpoints['2xl']})`,
    14: `@media screen and (min-width: ${breakpoints['3xl']})`,
    16: `@media screen and (min-width: ${breakpoints['4xl']})`,
    19: `@media screen and (min-width: ${breakpoints['5xl']})`,
};

const radii = {
    none: '0',
    sm: '0.125rem',
    md: '0.25rem',
    lg: '0.5rem',
    xl: '1rem',
    full: '9999px',
};

const borders = {
    none: 0,
    '1px': '1px solid',
    '2px': '2px solid',
    '4px': '4px solid',

    neutral: `1px solid ${colors.gray[500]}`,
    neutralDouble: `2px solid ${colors.gray[500]}`,
    neutralLight: `1px solid ${colors.gray[200]}`,
    neutralLightDouble: `2px solid ${colors.gray[200]}`,
    neutralDark: `1px solid ${colors.gray[900]}`,
    neutralDoubleDark: `2px solid ${colors.gray[900]}`,
    neutralTint: `1px solid ${colors.gray['50A']}`,
    neutralTintDouble: `2px solid ${colors.gray['50A']}`,

    neutralDotted: `1px dotted ${colors.gray[500]}`,
    neutralDoubleDotted: `2px dotted ${colors.gray[500]}`,
    neutralLightDotted: `1px dotted ${colors.gray[200]}`,
    neutralLightDoubleDotted: `2px dotted ${colors.gray[200]}`,
    neutralDarkDotted: `1px dotted ${colors.gray[900]}`,
    neutralDoubleDarkDotted: `2px dotted ${colors.gray[900]}`,
    neutralTintDotted: `1px dotted ${colors.gray['50A']}`,
    neutralTintDoubleDotted: `2px dotted ${colors.gray['50A']}`,

    primary: `1px solid ${colors.primary[500]}`,
    primaryDouble: `2px solid ${colors.primary[500]}`,
    primaryLight: `1px solid ${colors.primary[200]}`,
    primaryLightDouble: `2px solid ${colors.primary[200]}`,
    primaryDark: `1px solid ${colors.primary[900]}`,
    primaryDoubleDark: `2px solid ${colors.primary[900]}`,
    primaryTint: `1px solid ${colors.primary['50A']}`,
    primaryTintDouble: `2px solid ${colors.primary['50A']}`,

    secondary: `1px solid ${colors.secondary[500]}`,
    secondaryDouble: `2px solid ${colors.secondary[500]}`,
    secondaryLight: `1px solid ${colors.secondary[200]}`,
    secondaryLightDouble: `2px solid ${colors.secondary[200]}`,
    secondaryDark: `1px solid ${colors.secondary[900]}`,
    secondaryDoubleDark: `2px solid ${colors.secondary[900]}`,
    secondaryTint: `1px solid ${colors.secondary['50A']}`,
    secondaryTintDouble: `2px solid ${colors.secondary['50A']}`,

    tertiary: `1px solid ${colors.tertiary[500]}`,
    tertiaryDouble: `2px solid ${colors.tertiary[500]}`,
    tertiaryLight: `1px solid ${colors.tertiary[200]}`,
    tertiaryLightDouble: `2px solid ${colors.tertiary[200]}`,
    tertiaryDark: `1px solid ${colors.tertiary[900]}`,
    tertiaryDoubleDark: `2px solid ${colors.tertiary[900]}`,
    tertiaryTint: `1px solid ${colors.tertiary['50A']}`,
    tertiaryTintDouble: `2px solid ${colors.tertiary['50A']}`,

    destructive: `1px solid ${colors.destructive[500]}`,
    destructiveDouble: `2px solid ${colors.destructive[500]}`,
    destructiveLight: `1px solid ${colors.destructive[200]}`,
    destructiveLightDouble: `2px solid ${colors.destructive[200]}`,
    destructiveDark: `1px solid ${colors.destructive[900]}`,
    destructiveDoubleDark: `2px solid ${colors.destructive[900]}`,
    destructiveTint: `1px solid ${colors.destructive['50A']}`,
    destructiveTintDouble: `2px solid ${colors.destructive['50A']}`,

    success: `1px solid ${colors.success[500]}`,
    successDouble: `2px solid ${colors.success[500]}`,
    successLight: `1px solid ${colors.success[200]}`,
    successLightDouble: `2px solid ${colors.success[200]}`,
    successDark: `1px solid ${colors.success[900]}`,
    successDoubleDark: `2px solid ${colors.success[900]}`,
    successTint: `1px solid ${colors.success['50A']}`,
    successTintDouble: `2px solid ${colors.success['50A']}`,

    warning: `1px solid ${colors.warning[500]}`,
    warningDouble: `2px solid ${colors.warning[500]}`,
    warningLight: `1px solid ${colors.warning[200]}`,
    warningLightDouble: `2px solid ${colors.warning[200]}`,
    warningDark: `1px solid ${colors.warning[900]}`,
    warningDoubleDark: `2px solid ${colors.warning[900]}`,
    warningTint: `1px solid ${colors.warning['50A']}`,
    warningTintDouble: `2px solid ${colors.warning['50A']}`,
};

const borderStyles = {
    none: 'none',
    solid: 'solid',
    dashed: 'dashed',
    dotted: 'dotted',
};

const borderWidths = [0.5, 1, 2, 4, 8, 16];

const shadows = {
    sm: '0 1px 3px 0 rgba(22,29,37,0.05), 0 1px 2px 0 rgba(22,29,37,0.05)',
    md: '0 4px 6px -1px rgba(6,44,82,0.1),0 2px 4px -1px rgba(33,43,54,0.08)',
    lg: '0 10px 15px -3px rgba(32,42,53,0.2),0 4px 16px -2px rgba(32,42,54,0.08)',
    xl: '0 20px 25px -5px rgba(32,42,53,0.2),0 10px 10px -5px rgba(32,42,54,0.08)',
    '2xl': '0 20px 50px -12px rgba(32,42,53,0.2)',
    outline: '0 0 0 3px rgba(32,42,53,0.2)',
    inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
    none: 'none',
    popover: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
};

const zIndices = {
    hide: -1,
    auto: 'auto',
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
};

const opacity = {
    0: '0',
    '20%': '0.2',
    '40%': '0.4',
    '60%': '0.6',
    '80%': '0.8',
    '100%': '1',
};

const $tooltip_bg = cssVar('tooltip-bg');
const $tooltip_fg = cssVar('tooltip-fg');
const $tooltip_arrowBg = cssVar('popper-arrow-bg');

// New theme styling for individual components.
const components: Record<string, ComponentStyleConfig> = {
    Popover: {
        parts: ['popper', 'content', 'header', 'arrow', 'body'],
        baseStyle: {
            popper: {},
            body: {
                px: 0,
            },
            content: {
                borderWidth: '1px',
                boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                borderColor: 'purple.100',
                borderRadius: '6px',
                fontSize: '14px',
                py: 0,
            },
            header: {
                borderBottomColor: colors.gray[50],
                px: 4,
            },
            arrow: {
                borderWidth: 0,
                boxShadow: 'none !important',
            },
        },
    },
    Select: {
        parts: ['field', 'icon'],
        baseStyle: {
            field: {
                fontWeight: 400,
                borderRadius: 4,
                borderWidth: 1,
                _focus: {
                    borderColor: 'purple.200',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.0.35)',
                    bg: 'white',
                },
                _focusVisible: {
                    borderColor: 'purple.200 !important',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.0.35)',
                    bg: 'white',
                },
                _active: {
                    borderColor: 'purple.200',
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.0.35)',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    color: 'purple.300',
                    transform: 'scaleX(.985) scaleY(.985) translateY(.5px)',
                },
                _invalid: {
                    borderColor: 'red.300',
                    boxShadow: '0 0 0 2px red.100A',
                    _hover: {
                        borderColor: 'red.400',
                    },
                },
            },
        },
        sizes: {
            md: {
                field: {
                    height: '36px',
                },
            },
        },
        variants: {
            outline: {
                field: {
                    color: 'rgba(0,0,0,0.60)',
                    border: '1px solid',
                    borderColor: 'gray.200',
                    _focus: {
                        borderColor: 'purple.200',
                        outlineColor: 'purple.100',
                        outlineWidth: '1px',
                        outlineOffset: 0,
                        boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                        bg: 'white',
                    },
                    _focusVisible: {
                        borderColor: 'purple.200 !important',
                        outlineColor: 'purple.100',
                        outlineWidth: '1px',
                        outlineOffset: 0,
                        boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                        bg: 'white',
                    },
                    _active: {
                        borderColor: 'purple.200',
                        boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                        outlineColor: 'purple.100',
                        outlineWidth: '1px',
                        outlineOffset: 0,
                        color: 'purple.300',
                        transform: 'scaleX(.985) scaleY(.985) translateY(.5px)',
                    },
                    _invalid: {
                        borderColor: 'red.300',
                        boxShadow: '0 0 0 2px red.100A',
                        _hover: {
                            borderColor: 'red.400',
                        },
                    },
                },
                icon: {
                    color: 'gray.400',
                },
            },
            brand: {
                field: {
                    color: 'purple.500',
                    border: '1px solid',
                    backgroundColor: 'transparent',
                    borderColor: 'purple.100',
                    _focus: {
                        borderColor: 'purple.500',
                    },
                },
                icon: {
                    color: 'purple.500',
                },
            },
        },
        defaultProps: {
            variants: 'outline',
        },
    },
    Switch: {
        sizes: {
            xxs: {
                container: {
                    width: '14px',
                    height: '10px',
                },
                track: {
                    width: '14px',
                    height: '6px',
                },
                thumb: {
                    width: '6px',
                    height: '6px',
                    _checked: {
                        transform: 'translateX(8px)',
                    },
                },
            },
            xs: {
                container: {
                    width: '18px',
                    height: '12px',
                },
                track: {
                    width: '18px',
                    height: '8px',
                },
                thumb: {
                    width: '8px',
                    height: '8px',
                    _checked: {
                        transform: 'translateX(10px)',
                    },
                },
            },
        },
    },
    Kbd: {
        baseStyle: {
            bg: 'gray.100A',
            borderColor: 'gray.50A',
            color: 'gray.500',
            fontWeight: 400,
            py: 0.5,
            fontSize: 'xs',
            borderBottomWidth: '1px',
        },
    },
    Menu: {
        parts: ['button', 'list', 'item', 'groupTitle', 'command', 'divider'],
        baseStyle: {
            list: {
                rounded: 'md',
                paddingTop: 0,
                paddingBottom: 0,
                borderWidth: '1px',
                boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                borderColor: 'purple.100',
                color: 'gray.500',
            },
            item: {
                fontSize: 'md',
                alignItems: 'center',
                py: 1,
                _hover: {
                    backgroundColor: 'purple.50A',
                },
                _focus: {
                    backgroundColor: 'purple.50A',
                },
            },
            groupTitle: {
                fontSize: 'md',
                fontWeight: 400,
            },
        },
        sizes: {
            sm: {
                list: {
                    minWidth: '',
                },
                item: {
                    fontSize: 'sm',
                    paddingTop: 1,
                    paddingBottom: 1,
                },
            },
        },
        variants: {
            purple: {
                item: {
                    color: 'purple',
                },
            },
        },
    },
    Button: {
        // The styles all button have in common
        baseStyle: {
            letterSpacing: '-0.01px',
            fontWeight: 450,
            lineHeight: 1,
            _disabled: {
                cursor: 'not-allowed',
                border: '1px solid',
                borderColor: 'gray.100',
                bg: 'gray.100A',
                color: 'gray.400',
            },
            _hover: {
                _disabled: {
                    bg: 'gray.100A',
                    color: 'gray.400',
                },
            },
            _focus: {
                outlineColor: 'purple.100',
                outlineWidth: '1px',
                outlineOffset: 0,
                boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
            },
            _active: {
                boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                outlineWidth: '1px',
                outlineOffset: 0,
            },
        },
        sizes: {
            sm: {
                px: 3,
                py: 3,
            },
            md: {
                fontSize: 'md',
                px: 4,
                py: 4,
                height: '30px',
            },
            lg: {
                fontSize: 'lg',
                px: 6,
                py: 8,
            },
        },
        variants: {
            radioGroup: {
                py: 1,
                px: 3,
                borderRadius: '4px',
                color: 'gray.700',
                bg: 'transparent',
                borderWidth: '1px',
                borderColor: 'gray.100',
            },
            solid: {
                bg: 'purple.400',
                color: 'white',
                lineHeight: 1,
                _disabled: {
                    cursor: 'not-allowed',
                    border: '1px solid',
                    borderColor: 'gray.100',
                    bg: 'gray.100A',
                    color: 'gray.400',
                },
                _hover: {
                    color: 'white',
                    bg: 'purple.500',
                    _disabled: {
                        bg: 'gray.100A',
                        color: 'gray.400',
                    },
                },
                _focus: {
                    borderColor: 'purple.200',
                },
                _active: {
                    borderColor: 'purple.200',
                    outlineColor: 'purple.100',
                },
            },
            outline: {
                color: 'purple.500',
                border: '1px solid',
                borderColor: 'purple.100',
                bg: 'purple.50',
                fontWeight: 400,
                lineHeight: 1,
                _disabled: {
                    cursor: 'not-allowed',
                    border: '1px solid',
                    borderColor: 'gray.100',
                    bg: 'gray.100A',
                    color: 'gray.400',
                },
                _hover: {
                    bg: 'purple.100A',
                    _disabled: {
                        bg: 'gray.100A',
                    },
                },
                _focus: {
                    borderColor: 'purple.200',
                    outlineColor: 'purple.100',
                },
                _active: {
                    borderColor: 'purple.200',
                    outlineColor: 'purple.100',
                    color: 'purple.500',
                },
            },
            ghost: {
                color: 'purple.500',
                _disabled: {
                    border: 'none',
                },
                _hover: {
                    color: 'purple.600',
                    bg: 'purple.50A',
                    _disabled: {
                        color: 'gray.400',
                    },
                },
                _focus: {
                    borderColor: 'purple.200',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                },
                _active: {
                    borderColor: 'purple.200',
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    color: 'purple.500',
                },
            },
            dark: {
                color: 'white',
                border: '1px solid',
                borderRadius: '4px',
                borderColor: 'gray.800',
                bg: 'gray.800',
                fontWeight: 400,
                lineHeight: 1,
                _disabled: {
                    cursor: 'not-allowed',
                    border: '1px solid',
                    borderColor: 'gray.100',
                    bg: 'gray.100A',
                    color: 'gray.400',
                },
                _hover: {
                    color: 'white',
                    bg: 'gray.900',
                    _disabled: {
                        bg: 'gray.100A',
                        color: 'gray.400',
                    },
                },
                _focus: {
                    borderColor: 'purple.200',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                },
                _active: {
                    borderColor: 'purple.200',
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    color: 'white',
                },
            },
            secondary: {
                color: 'gray.500',
                border: '1px solid',
                borderRadius: '4px',
                borderColor: 'gray.150',
                backgroundColor: 'whites.0',
                fontWeight: 400,
                lineHeight: 1,
                _disabled: {
                    cursor: 'not-allowed',
                    border: '1px solid',
                    borderColor: 'gray.100',
                    bg: 'gray.100A',
                    color: 'gray.400',
                },
                _hover: {
                    color: 'gray.800',
                    bg: 'whites.0',
                    _disabled: {
                        bg: 'gray.100A',
                        color: 'gray.400',
                    },
                },
                _focus: {
                    borderColor: 'purple.200',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                },
                _active: {
                    borderColor: 'purple.200',
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    color: 'purple.500',
                },
            },
            cancel: {
                bg: 'gray.100',
                color: 'gray.800',
                _hover: {
                    bg: 'gray.200',
                    _disabled: {
                        bg: 'gray.100',
                    },
                },
            },
            delete: {
                color: 'red.500',
                border: '1px solid',
                borderRadius: '4px',
                borderColor: 'red.100',
                bg: 'red.50A',
                fontWeight: 400,
                lineHeight: 1,
                _hover: {
                    color: 'red.800',
                    bg: 'red.50A',
                    _disabled: {
                        bg: 'gray.100A',
                        color: 'gray.400',
                    },
                },
                _focus: {
                    borderColor: 'red.200',
                    outlineColor: 'red.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    boxShadow: '0 0 0 3px rgba(231, 100, 100, 0.1)',
                },
                _active: {
                    borderColor: 'red.200',
                    boxShadow: '0 0 0 3px rgba(231, 100, 100, 0.1)',
                    outlineColor: 'red.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    color: 'red.500',
                },
            },
            delete_solid: {
                color: 'white',
                border: '1px solid',
                borderRadius: '4px',
                borderColor: 'red.600',
                bg: 'red.500',
                fontWeight: 400,
                lineHeight: 1,
                _hover: {
                    color: 'white',
                    bg: 'red.600',
                    _disabled: {
                        bg: 'red.100A',
                        color: 'red.400',
                    },
                },
                _focus: {
                    borderColor: 'red.600',
                    outlineColor: 'red.700',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    boxShadow: '0 0 0 3px rgba(231, 100, 100, 0.1)',
                },
                _active: {
                    borderColor: 'red.600',
                    boxShadow: '0 0 0 3px rgba(231, 100, 100, 0.1)',
                    outlineColor: 'red.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    color: 'white',
                },
            },
            navigation: {
                _hover: {
                    bg: 'purple.100',
                    _disabled: {
                        bg: 'gray.100A',
                    },
                },
                _focus: {
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                },
                _active: {
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    color: 'purple.500',
                },
            },
            // Style of select menu button (outline)
            select: {
                color: 'rgba(0,0,0,0.60)',
                border: '1px solid',
                borderColor: 'gray.200',
                borderRadius: 4,
                _focus: {
                    borderColor: 'purple.400',
                },
            },
            setting: {
                color: 'gray.800',
                border: '1px solid',
                borderColor: 'gray.100',
                bg: 'gray.50A',
                fontWeight: 400,
                _hover: {
                    color: 'white',
                    border: '1px solid',
                    borderColor: 'purple.500',
                    bg: 'purple.500',
                    _disabled: {
                        color: 'gray.800',
                        border: '1px solid',
                        borderColor: 'gray.100',
                        bg: 'gray.50A',
                        fontWeight: 400,
                    },
                },
            },
            filter: {
                color: 'gray.500',
                border: '1px solid',
                borderRadius: '4px',
                borderColor: 'gray.150',
                bg: 'gray.25A',
                _disabled: {
                    cursor: 'not-allowed',
                    opacity: 0.3,
                },
                _hover: {
                    color: 'gray.800',
                    bg: 'gray.50A',
                },
                _focus: {
                    borderColor: 'purple.200',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                },
                _active: {
                    borderColor: 'purple.200',
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    color: 'purple.500',
                },
            },
            topNavigation: {
                color: 'gray.500',
                fontWeight: 450,
                fontFamily: 'body',

                border: 'none',
                letterSpacing: '-0.15px',

                bg: 'gray.25A',
                _disabled: {
                    cursor: 'not-allowed',
                    opacity: 0.3,
                },
                _hover: {
                    color: 'gray.800',
                    bg: 'gray.50A',
                    textDecoration: 'none',
                },
                _focus: {
                    borderColor: 'none',
                    outlineColor: 'none',
                    outlineWidth: 0,
                    outlineOffset: 0,
                    color: 'gray.800',
                    boxShadow: 'none',
                },
                _active: {
                    borderColor: 'none',
                    boxShadow: 'none',
                    outlineColor: 'none',
                    outlineWidth: 0,
                    outlineOffset: 0,
                    color: 'purple.500',
                },
            },
            settingList: {
                color: 'gray.500',
                border: '1px solid',
                borderRadius: '4px',
                borderColor: 'transparent',
                bg: 'transparent',
                fontWeight: 400,
                lineHeight: 1,
                _hover: {
                    color: 'gray.800',
                    bg: 'purple.50A',
                },
                _focus: {
                    borderColor: 'purple.200',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                    bg: 'white',
                },
                _active: {
                    borderColor: 'purple.200',
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    color: 'purple.300',
                    transform: 'scaleX(.985) scaleY(.985) translateY(.5px)',
                },
            },
            link: {
                _disabled: {
                    cursor: 'not-allowed',
                    border: 'none',
                    borderColor: 'gray.100',
                    bg: 'none',
                    color: 'gray.400',
                    _hover: {
                        bg: 'none',
                    },
                },
                _hover: {
                    textDecoration: 'none',
                },
            },
        },
        // The default size and variant values
        defaultProps: {
            size: 'sm',
            variant: 'solid',
            colorScheme: 'purple',
            rounded: 'md',
        },
    },
    Input: {
        parts: ['field', 'addon'],
        baseStyle: {
            field: {
                color: 'gray.800',
            },
        },
        variants: {
            outline: (props) => {
                const { theme } = props;
                return {
                    field: {
                        _focus: {
                            borderColor: 'purple.200',
                            outlineColor: 'purple.100',
                            outlineWidth: '1px',
                            outlineOffset: 0,
                            boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                            bg: 'white',
                        },
                        _active: {
                            borderColor: 'purple.200',
                            boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                            outlineColor: 'purple.100',
                            outlineWidth: '1px',
                            outlineOffset: 0,
                            color: 'purple.300',
                            transform: 'scaleX(.985) scaleY(.985) translateY(.5px)',
                        },
                        _invalid: {
                            borderColor: 'red.300',
                            boxShadow: `0 0 0 2px ${theme.colors.red['100A']}`,
                            _hover: {
                                borderColor: 'red.400',
                            },
                        },
                    },
                };
            },
        },
        defaultProps: {
            size: 'md',
            focusBorderColor: 'purple.400',
        },
    },

    NumberInput: {
        parts: ['field'],
        variants: {
            outline: (props) => {
                const { theme } = props;
                return {
                    field: {
                        _invalid: {
                            borderColor: 'red.300',
                            boxShadow: `0 0 0 2px ${theme.colors.red['100A']}`,
                            color: 'red.500',
                            _hover: {
                                borderColor: 'red.400',
                            },
                        },
                        _focus: {
                            borderColor: 'purple.200',
                            outlineColor: 'purple.100',
                            outlineWidth: '1px',
                            outlineOffset: 0,
                            boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                            bg: 'white',
                        },
                        _active: {
                            borderColor: 'purple.200',
                            boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                            outlineColor: 'purple.100',
                            outlineWidth: '1px',
                            outlineOffset: 0,
                            color: 'purple.300',
                            transform: 'scaleX(.985) scaleY(.985) translateY(.5px)',
                        },
                    },
                };
            },
        },
    },
    Checkbox: {
        parts: ['label', 'input', 'control', 'icon'],
        baseStyle: {
            label: {
                color: 'gray.500',
                ml: 2,
            },
            control: {
                borderWidth: '1px',
            },
        },
        sizes: {
            md: {
                icon: {
                    fontSize: '8px',
                },
            },
        },
        defaultProps: {
            size: 'md',
            colorScheme: 'purple',
        },
    },
    Radio: {
        parts: ['label', 'control'],
        baseStyle: {
            control: {
                borderWidth: '1px',
            },
        },
        sizes: {
            md: {
                control: {
                    w: '15px',
                    h: '15px',
                    flexShrink: 0,
                },
            },
        },
        defaultProps: {
            size: 'md',
            colorScheme: 'purple',
        },
    },
    Badge: {
        baseStyle: {
            fontWeight: 'medium',
            paddingTop: '3px',
            paddingBottom: '3px',
            paddingLeft: '10px',
            paddingRight: '10px',

            borderRadius: '999px',
            textTransform: 'camelCase',
            fontSize: '12px',
        },
        variants: {
            default: {
                bg: 'gray.50',
                color: 'gray.700',
            },
            beta: {
                bg: 'gray.50',
                color: 'gray.700',
                rounded: 'full',
            },
        },
    },
    Text: {
        baseStyle: {
            fontSize: '14px',
        },
        variants: {
            label: {
                color: 'gray.400',
                textTransform: 'uppercase',
                fontSize: 'xs',
                letterSpacing: 'wide',
                fontWeight: 500,
            },
            title: {
                fontSize: '3xl',
                fontWeight: 600,
                letterSpacing: 'tight',
                lineHeight: 1.3,
            },
            marketingLabel: {
                color: 'primary.500',
                textTransform: 'uppercase',
                letterSpacing: 'wide',
                fontSize: 'sm',
                fontWeight: 500,
            },
        },
    },
    Textarea: {
        variants: {
            outline: (props) => ({
                _placeholder: {
                    color: 'gray.200',
                },
                color: 'gray.800',
                _focus: {
                    borderColor: 'purple.200',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                    bg: 'white',
                },
                _active: {
                    borderColor: 'purple.200',
                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                    outlineColor: 'purple.100',
                    outlineWidth: '1px',
                    outlineOffset: 0,
                },
                _invalid: {
                    borderColor: 'red.300',
                    boxShadow: `0 0 0 2px ${props.theme.colors.red['100A']}`,
                    _hover: {
                        borderColor: 'red.400',
                    },
                },
            }),
        },
    },
    Alert: {
        baseStyle: {
            fontSize: '100px',
        },
        variants: {
            purple: {
                container: {
                    backgroundColor: 'purple.100A',
                    color: 'purple.700',
                },
            },
        },
    },
    Table: {
        variants: {
            simple: {
                th: {
                    borderColor: 'gray.100A',
                    fontWeight: 450,
                    fontSize: '11px',
                },
                td: {
                    borderColor: 'gray.100A',
                    color: 'gray.800',
                },
            },
        },
        sizes: {
            md: {
                td: {
                    fontSize: 'md',
                },
            },
        },
    },
    Tooltip: {
        baseStyle: {
            lineHeight: 1.3,
            py: 1,
        },
        variants: {
            purple: {
                bg: 'white',
                color: 'purple.500',
                [$tooltip_bg.variable]: 'white',
                [$tooltip_fg.variable]: 'purple.500',
                [$tooltip_arrowBg.variable]: $tooltip_bg.reference,
                border: '1px solid',
                borderColor: 'gray.200',
            },
        },
    },
    Tabs: {
        parts: ['tablist', 'tab', 'tabpanel'],
        variants: {
            sidebar: {
                tablist: {
                    gap: '6px',
                },
                tab: {
                    color: 'gray.400',
                    fontWeight: 600,
                    border: '1px solid',
                    borderRadius: '4px',
                    borderColor: 'gray.50A',
                    bg: 'gray.25A',

                    lineHeight: 1,
                    _disabled: {
                        cursor: 'not-allowed',
                        opacity: 0.3,
                    },
                    _hover: {
                        color: 'gray.600',
                        bg: 'gray.100A',
                    },
                    _focus: {
                        borderColor: 'gray.200',
                        outlineColor: 'gray.100',
                        outlineWidth: '1px',
                        outlineOffset: 0,
                        boxShadow: '0 0 0 3px rgba(0, 0, 0, 0.05)',
                    },
                    _active: {
                        borderColor: 'gray.200',
                        boxShadow: '0 0 0 3px rgba(0, 0, 0, 0.05)',
                        outlineColor: 'gray.100',
                        outlineWidth: '1px',
                        outlineOffset: 0,
                        color: 'purple.500',
                    },
                    _selected: {
                        borderColor: 'transparent',
                        backgroundColor: 'gray.100A',
                        color: 'gray.800',
                    },
                },
            },
            sets: {
                tablist: {
                    borderBottomWidth: '1px',
                    borderColor: 'gray.100',
                },
                tab: {
                    color: 'gray.400',
                    fontSize: '13px',
                    py: 4,
                    position: 'relative',
                    top: '1px',

                    _selected: {
                        borderColor: 'gray.800',
                        border: 'none',
                        borderBottom: '1px',

                        color: 'gray.800',
                    },
                },
            },
            calendarAdjustments: {
                tablist: {
                    gap: '6px',
                },
                tab: {
                    color: 'gray.400',
                    fontWeight: 600,
                    border: '1px solid',
                    borderRadius: '4px',
                    borderColor: 'gray.50A',
                    bg: 'gray.25A',

                    lineHeight: 1,
                    _disabled: {
                        cursor: 'not-allowed',
                        opacity: 0.3,
                    },
                    _hover: {
                        color: 'purple.600',
                        bg: 'purple.100A',
                    },
                    _focus: {
                        borderColor: 'purple.200',
                        outlineColor: 'purple.100',
                        outlineWidth: '1px',
                        outlineOffset: 0,
                        boxShadow: '0 0 0 3px rgba(245, 9, 217, 0.05)',
                    },
                    _active: {
                        borderColor: 'gray.200',
                        boxShadow: '0 0 0 3px rgba(245, 9, 217, 0.05)',
                        outlineColor: 'purple.300',
                        outlineWidth: '1px',
                        outlineOffset: 0,
                        color: 'purple.500',
                    },
                    _selected: {
                        borderColor: 'purple.200A',
                        backgroundColor: 'purple.50A',
                        color: 'purple.500',
                    },
                },
            },
        },
    },
};

const initialColorMode = 'light';

export {
    initialColorMode,
    breakpoints,
    mediaQueries,
    space,
    sizes,
    fontSizes,
    fontWeights,
    lineHeights,
    letterSpacings,
    colors,
    radii,
    borders,
    borderStyles,
    borderWidths,
    shadows,
    zIndices,
    buttons,
    colorStyles,
    opacity,
    textStyles,
    components,
};
