import { createSlice } from '@reduxjs/toolkit';
import { defaultListingFilters, listingFilterReducers } from './shared/listingFilters';

const initialState = {
    filters: defaultListingFilters,
};
const assignListingsToSetUi = createSlice({
    name: 'assignListingsToSetUi',
    initialState,
    reducers: {
        ...listingFilterReducers,
    },
});

export const { updateStaticSchema, addFilters, updateFilter, clearFilters, restoreFilters, removeFilter } = assignListingsToSetUi.actions;
export default assignListingsToSetUi.reducer;
