import { listingBulkPathV2 } from 'libs/routes';
import { useAppDispatch, useAppSelector } from '../hooks';
import { formatFiltersForApi } from '../components/Filters/formatFilters';
import { ListingV2 } from 'libs/types/listingV2';
import queryString from 'query-string';
import { usePortfolioFilter } from './usePortfolioFilter';
import { useEffect } from 'react';
import { updateStaticSchema } from '../slices/assignListingsToSetUi';
import { CompSet } from 'libs/types/set';
import { fetchV2API } from 'libs/utils/fetcher';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export const useAssignUserListingsToSet = (set: CompSet, per_page = 10, page = 1) => {
    const url = listingBulkPathV2();
    const userId = useAppSelector(({ user }) => user?.id);
    const active = useAppSelector(({ assignListingsToSetUi }) => assignListingsToSetUi.filters.active);
    const listings = set?.associated_listings.map((l) => l.listing_id);
    const params = {
        'listing_views.non_test_hypothetical': '',
        'listings.id_not_in': listings,
        per_page,
        page,
        ...formatFiltersForApi(active, userId),
    };

    const paramResults = queryString.stringify(params, { arrayFormat: 'bracket' });
    const { data, isFetching, isLoading, isPending } = useQuery({
        queryKey: [url, paramResults],
        queryFn: (p) => {
            return fetchV2API<ListingV2[]>(`${url}?${paramResults}`);
        },
        placeholderData: keepPreviousData,
    });

    const itemCount = data?.itemCount;

    return {
        data,
        itemCount,
        isPending,
        isFetching,
        isLoading,
    };
};

export const usePortfolioStaticSchema = () => {
    const dispatch = useAppDispatch();
    const { data: filterData } = usePortfolioFilter();
    useEffect(() => {
        if (filterData) {
            dispatch(updateStaticSchema(filterData));
        }
    }, [filterData, dispatch]);
};
