import { createSlice } from '@reduxjs/toolkit';
import { dateFilterReducers, defaultDateFilters } from './shared/dateFilters';

const initialState = {
    dateFilters: defaultDateFilters,
};

const listingReservationUiSlice = createSlice({
    name: 'listingReservationUi',
    initialState,
    reducers: {
        ...dateFilterReducers,
    },
});

export const actions = listingReservationUiSlice.actions;

export default listingReservationUiSlice.reducer;
