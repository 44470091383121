import type { EnhancedStore } from '@reduxjs/toolkit';
import analytics from 'libs/utils/analytics';
import LogRocket from 'logrocket';

// A store observer that emits analytics events on page change.
class AnalyticsObserver {
    private lastUser: any;

    constructor(store: EnhancedStore) {
        this.lastUser;

        store.subscribe(() => {
            this.trackUser(store);
        });
    }

    trackUser(store: EnhancedStore) {
        const state = store.getState();
        const currentUser = state && state.logged_in_as;

        if (this.lastUser != currentUser) {
            if (currentUser == null) {
                return;
            }

            const segmentTraits = {
                ...currentUser,
                createdAt: currentUser.created_at,
            };
            analytics.identify(currentUser.id, segmentTraits);

            const logRocketTraits = {
                ...currentUser,
                name: `${currentUser.first_name || ''} ${currentUser.last_name || ''}`,
            };
            LogRocket.identify(currentUser.id, logRocketTraits);
        }

        this.lastUser = currentUser;
    }
}

export default AnalyticsObserver;
