export const Methods = Object.freeze({
    TILE_FEATURES: 'tileFeatures',
    FEATURES_FORMULA: 'featuresFormula',
    FEATURES_HISTOGRAM: 'featuresHistogram',
    FEATURES_CATEGORY: 'featuresCategory',
    FEATURES_SCATTERPLOT: 'featuresScatterPlot',
    FEATURES_TIME_SERIES: 'featuresTimeSeries',
    FEATURES_RAW: 'featuresRawFeatures',
    FEATURES_RANGE: 'featuresRange',
    LOAD_GEOJSON_FEATURES: 'loadGeoJSONFeatures',
    GEOJSON_FEATURES: 'featuresGeoJSON',
});
