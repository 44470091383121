import fetcher from 'libs/utils/fetcher';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { concat } from 'lodash';
import { request } from 'libs/requests';
import { listingsFilteredPathV2, listingsMetaPathV2 } from 'libs/routes';
import { FilterEnumList, FilterEnumObject, FilterIn, FilterNumberRange, MetaResults } from 'libs/types/meta';
import { useAppSelector } from 'libs/global/hooks';
import { Tag } from 'libs/types/tag';
import type { ActiveFilter } from 'libs/types/filters';
import { formatFiltersForApi } from 'libs/global/components/Filters/formatFilters';
import { sortByName } from 'libs/utils/sorting';

const PORTFOLIO_FILTER_QUERY_KEY = [listingsMetaPathV2(), 'listingMeta'];

const objectify = (data: (string | number | null)[]) => {
    return data?.map((d) => ({
        value: d,
        name: d,
    }));
};

const queryFn = async (): Promise<MetaResults> => fetcher(listingsMetaPathV2());

const getEnumOptions = (filters: MetaResults['filters'], name: string) => {
    const options = (filters.find((n) => n.name == name) as FilterEnumObject).options;
    const optionsCopy = [...options];
    return optionsCopy.sort(sortByName);
};

const selectFn = (data: MetaResults) => {
    const filters = data.filters;

    // Bedrooms is a range
    const bedroomsRange = (filters.find((n) => n.name == 'bedrooms') as FilterNumberRange).options;
    const bedrooms = new Set<{ value: number; name: string }>();
    if (bedroomsRange) {
        for (let i = bedroomsRange[0]; i <= bedroomsRange[1]; i++) {
            bedrooms.add({
                value: i,
                name: JSON.stringify(i),
            });
        }
    }

    const bathroomsRange = (filters.find((n) => n.name == 'bathrooms') as FilterNumberRange).options;
    const bathrooms = new Set<{ value: number; name: string }>();
    if (bathroomsRange) {
        for (let i = bathroomsRange[0]; i <= bathroomsRange[1]; i++) {
            bathrooms.add({
                value: i,
                name: JSON.stringify(i),
            });
        }
    }

    const bedsRange = (filters.find((n) => n.name == 'beds') as FilterNumberRange).options;
    const beds = new Set<{ value: number; name: string }>();
    if (bedsRange) {
        for (let i = bedsRange[0]; i <= bedsRange[1]; i++) {
            beds.add({
                value: i,
                name: JSON.stringify(i),
            });
        }
    }

    const sleepsRange = (filters.find((n) => n.name == 'sleeps') as FilterNumberRange).options;
    const sleeps = new Set<{ value: number; name: string }>();
    if (sleepsRange) {
        for (let i = sleepsRange[0]; i <= sleepsRange[1]; i++) {
            sleeps.add({
                value: i,
                name: JSON.stringify(i),
            });
        }
    }
    const unitsRange = (filters.find((n) => n.name == 'number_of_units') as FilterNumberRange).options;
    const units = new Set<{ value: number; name: string }>();
    if (unitsRange) {
        for (let i = unitsRange[0]; i <= unitsRange[1]; i++) {
            units.add({
                value: i,
                name: JSON.stringify(i),
            });
        }
    }

    return {
        source: getEnumOptions(filters, 'source'),
        currency: objectify((filters.find((n) => n.name == 'listings.currency') as FilterEnumList).options),
        'market.name': getEnumOptions(filters, 'market_id'),
        number_of_units: Array.from(units),
        bedrooms: Array.from(bedrooms),
        bathrooms: Array.from(bathrooms),
        sleeps: Array.from(sleeps),
        beds: Array.from(beds),
        tags: getEnumOptions(filters, 'tags.id').map((tag): Tag => ({ ...tag, id: tag.value })),
        sub_users: concat(
            getEnumOptions(filters, 'users.id').map((sub_user) => ({ ...sub_user, id: sub_user.value })),
            { id: -1, value: -1, name: 'Unassigned' },
        ),
        'channel_accounts.status': (filters.find((n) => n.name == 'channel_accounts.status') as FilterEnumObject).options,
        'pricing_tier.name': objectify((filters.find((n) => n.name == 'pricing_tiers.name') as FilterEnumList).options),
        clean_room_type: objectify((filters.find((n) => n.name == 'clean_room_type') as FilterEnumList).options),
        'listings.operating_user_id': (filters.find((n) => n.name == 'listings.operating_user_id') as FilterEnumObject).options,
        'listings.id': objectify([...(filters.find((n) => n.name == 'listings.id' && n.type == 'In') as FilterIn).options].sort((a, b) => a - b)),
    };
};

export const usePortfolioFilter = () => {
    return useQuery({
        queryKey: PORTFOLIO_FILTER_QUERY_KEY,
        queryFn,
        select: selectFn,
    });
};

export const usePrefetchPortfolioFilter = () => {
    const userId = useAppSelector(({ user }) => user?.id);
    const queryClient = useQueryClient();

    if (!userId) {
        return;
    }

    return queryClient.prefetchQuery({
        queryKey: PORTFOLIO_FILTER_QUERY_KEY,
        queryFn,
    });
};

// Return a function to force refetch of meta.
export const useRefreshListingsMeta = () => {
    const queryClient = useQueryClient();
    return () => {
        queryClient.invalidateQueries({
            queryKey: PORTFOLIO_FILTER_QUERY_KEY,
        });
    };
};

export const useFilteredListingIds = (filters: Record<string, ActiveFilter> | undefined, preset?: string) => {
    const userId = useAppSelector(({ user }) => user?.id);
    const url = listingsFilteredPathV2();
    const params = formatFiltersForApi(filters, userId);
    return useQuery({
        queryKey: [url, userId, params, preset],
        queryFn: () => request(url, 'get', undefined, { user_id: userId, preset, ...params }),
        select: (data) => data?.data as number[],
        enabled: !!userId && !!filters,
    });
};
