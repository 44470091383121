'use client';

import React from 'react';
import { Flex, Image } from '@chakra-ui/react';
const Visa = '/icons/icn-cc-visa.svg';
const Mastercard = '/icons/icn-cc-mastercard.svg';
const Discover = '/icons/icn-cc-discover.svg';
const Amex = '/icons/icn-cc-amex.svg';

const Cards = [
    {
        brand: 'visa',
        icon: Visa,
    },
    {
        brand: 'mastercard',
        icon: Mastercard,
    },
    {
        brand: 'discover',
        icon: Discover,
    },
    {
        brand: 'amex',
        icon: Amex,
    },
];

const CreditCard = (props: { style?: React.CSSProperties; brand: string }) => {
    return (
        <Flex style={props.style}>
            {Cards.map((card) => {
                const selected = props.brand == 'unknown' || props.brand.toLowerCase() == card.brand;
                if (!selected) {
                    return null;
                }

                const style = {
                    height: 17.3,
                    opacity: selected ? 1 : 0.25,
                };
                return <Image border={0} key={card.brand} style={style} src={card.icon} />;
            })}
        </Flex>
    );
};

export default CreditCard;
