import { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

export type DateFilters = {
    asOfDate?: moment.Moment;
    period?: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
    minDate: moment.Moment;
    maxDate: moment.Moment;
    ranges: string[];
    granularity: moment.unitOfTime.DurationConstructor;
};

export const defaultDateFilters = {
    asOfDate: moment(),
    period: '1 year',
    startDate: moment().startOf('month').startOf('day'),
    endDate: moment().add(11, 'months').endOf('month').startOf('day'),
    minDate: moment().startOf('month').subtract(15, 'months').startOf('day'),
    maxDate: moment().add(1, 'year').endOf('year').startOf('day'),
    ranges: ['3 months', '6 months', '1 year', 'Current year'],
    granularity: 'month' as moment.unitOfTime.DurationConstructor,
};

export const dateFilterReducers = {
    setGranularity: (state: { dateFilters: DateFilters }, action: PayloadAction<{ granularity: moment.unitOfTime.DurationConstructor }>) => {
        state.dateFilters.granularity = action.payload.granularity;
    },
    setDateFilters: (state: { dateFilters: DateFilters }, action: PayloadAction<{ dateFilters: Partial<DateFilters> }>) => {
        state.dateFilters = { ...state.dateFilters, ...action.payload.dateFilters };
    },
};
