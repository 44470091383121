import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { Listing } from 'libs/types/listing';

export interface RecentListing {
    id: number;
    name: string;
}

export const initialState: RecentListing[] = [];

const recentListingsSlice = createSlice({
    name: 'recentListings',
    initialState,
    reducers: {
        addRecentListing: (state, action: PayloadAction<{ listing: Listing }>) => {
            let listings: RecentListing[] = state ? [...state] : [];
            const idx = findIndex(listings, { id: action.payload.listing.id });
            if (idx > -1) {
                listings.splice(idx, 1);
            }
            listings.unshift({ id: action.payload.listing.id, name: action.payload.listing.nickname || action.payload.listing.title });
            listings = listings.slice(0, 5);
            return listings;
        },
    },
});

export const { addRecentListing } = recentListingsSlice.actions;
export default recentListingsSlice.reducer;
