import { HOMEAWAY_CLIENT_ID, TRIPADVISOR_CLIENT_ID } from './constants';
import { getCSRFToken } from 'libs/requests';
import { PROXY_API_PREFIX } from 'libs/constants';
import { Channel } from 'libs/types/channel';

const appServer = process.env.NEXT_PUBLIC_APP_SERVER;

/*****************************/
// Pages Routes
/*****************************/
export const homePath = () => process.env.NEXT_PUBLIC_MARKETING_SITE + '/';

export const dashboardPath = () => '/u/dashboard';
export const performancePath = () => '/u/performance';
export const compSetsPath = () => '/u/comp_sets';
export const marketsDashboardPath = () => '/u/markets';

// Comp set PATHS
export const compSetsOverviewPath = () => '/u/sets/overview';
export const compSetsSetPath = (id: number | string) => `/u/sets/${id}`;
export const compSetsSetSetupPath = (id: number) => `/u/sets/setup/${id}`;
export const compSetsSetOverviewPath = (id: number) => `/u/sets/${id}/overview`;
export const compSetsDraftPath = () => '/u/sets/setup';
export const compSetReservationsPath = (id: number | undefined) => PROXY_API_PREFIX + `/rest_api/v1/listings/reservations/compset/${id}`;

// Portfolio PATHS
export const portfolioSettingsPath = () => '/pricing/settings';
export const portfolioCalendarPath = () => '/pricing/calendar';

export const portfolioPerformancePath = () => '/pricing/metrics';
export const portfolioCalendarDayHistory = (listingId: string | number, date: string) => `/insights/${listingId}/calendar_day_history/${date}`;

// ACCOUNT PATHS
export const cancelAccountPath = () => '/u/account/cancel';
export const accountSettingsPath = () => '/u/account/settings';
export const accountConnectedPath = () => '/u/account/connected';
export const notificationsPath = () => '/u/account/notifications';
export const referrals = () => '/u/account/billing/referrals';
export const billingSubscription = () => '/u/account/billing/subscription';
export const billingPayment = () => '/u/account/billing/payment';
export const billingBill = () => '/u/account/billing/current';
export const billingInvoices = () => '/u/account/billing/invoices';
export const billingInvoice = (source: string, id: string | number) => `/u/account/billing/invoice/${source}/${id}`;
export const billingInvoiceCsv = (id: string | number) => PROXY_API_PREFIX + `/rest_api/v2/invoice/${id}.csv`;

export const billingSetsBill = () => '/u/account/billing/sets/current';
export const billingSetsInvoices = () => '/u/account/billing/sets/invoices';
export const billingSetInvoice = (id: string | number) => `/u/account/billing/sets/invoice/${id}`;

export const getInvoicesV2 = () => PROXY_API_PREFIX + '/rest_api/v2/invoice_v2s';
export const getInvoiceV2Current = () => PROXY_API_PREFIX + '/rest_api/v2/invoice_v2s/current';
export const getInvoiceV2Upcoming = () => PROXY_API_PREFIX + '/rest_api/v2/invoice_v2s/upcoming';
export const getInvoiceV2 = (id: string | number) => PROXY_API_PREFIX + `/rest_api/v2/invoice_v2s/${id}`;
export const getInvoicesV2Past = () => PROXY_API_PREFIX + '/rest_api/v2/invoice_v2s?closed=true';

export const getApiKey = () => '/u/account/api_token';
export const notificationSettingsPath = () => '/u/account/notifications/settings';
export const teamOverviewPath = () => '/u/account/teams';

export const subusersPath = (userId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/subusers`;
export const membershipsPath = (userId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/memberships`;
export const notificationSettingsDataPath = (userId: number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/notification_settings`;
export const notificationDataPath = (userId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/notifications`;
export const hypotheticalPath = (userId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/hypothetical`;
export const hypotheticalOutOfMarketPath = (userId: string | number) => hypotheticalPath(userId) + '/market_test';

export const mainPagePath = () => '/main';
export const reviewsPath = () => '/real-reviews';
export const dynamicPricingPagePath = () => '/dynamic-pricing';
export const pricingPath = () => '/pricing';
export const contactPagePath = () => '/contact';
export const aboutPagePath = () => '/about-us';
export const careersPath = () => '/careers';
export const apiDocsPath = () => '/api-docs';

// Research paths
export const researchMain = () => '/research-main';
export const researchPricingEngine = () => '/research-pricing-engine';
export const researchCompSets = () => '/research-comp-sets';
export const researchPerformance = () => '/research-performance';
export const researchMetricLexicon = () => '/research-metrics';

export const loginPath = (redirect?: string) => (redirect ? `/u/login?redirect_to=${redirect}` : '/u/login');
export const forgotPasswordPath = () => '/u/account/forgot_password';
export const registerPath = (redirect?: string) => {
    return redirect ? `/u/account/register?redirect_to=${redirect}` : '/u/account/register';
};
export const registerTierPath = (tier?: string) => {
    return tier ? `/u/account/register?tier=${tier}` : '/u/account/register';
};
export const reactivateEmailPath = () => '/u/account/reactivate_email';
export const getIntegrationApiKey = () => '/u/account/integration_token';

export const settingsBasePricePath = (id: string | number) => `${listingSettingsPath(id)}/base_price`;
export const settingsMaximumPricePath = (id: string | number) => `${listingSettingsPath(id)}/maximum_price`;
export const settingsMinimumPricePath = (id: string | number) => `${listingSettingsPath(id)}/minimum_price`;
export const settingsWeekendAdjustment = (id: string | number) => `${listingSettingsPath(id)}/weekend_adjustment`;
export const settingsSeasonalityAdjustment = (id: string | number) => `${listingSettingsPath(id)}/seasonality_adjustment`;
export const settingsGapNight = (id: string | number) => `${listingSettingsPath(id)}/gap_night`;
export const settingsDemandSensitivity = (id: string | number) => `${listingSettingsPath(id)}/demand_sensitivity`;
export const settingsHistoricalAnchoring = (id: string | number) => `${listingSettingsPath(id)}/historical_anchoring`;
export const settingsMinimumStaysPath = (id: string | number) => `${listingSettingsPath(id)}/minimum_stays`;
export const settingsDiscountsPath = (id: string | number) => `${listingSettingsPath(id)}/discounts`;
export const settingsLastMinuteDiscountsPath = (id: string | number) => `${listingSettingsPath(id)}/last_minute_discounts`;
export const settingsFarFuturePremiumPath = (id: string | number) => `${listingSettingsPath(id)}/far_future_premium`;
export const settingsCheckinCheckoutPath = (id: string | number) => `${listingSettingsPath(id)}/checkin_checkout`;
export const settingsOccupancyPacingPath = (id: string | number) => `${listingSettingsPath(id)}/occupancy_pacing`;
export const settingsRevenuePacingPath = (id: string | number) => `${listingSettingsPath(id)}/revenue_pacing`;

export const listingCalendarPath = (id: string | number) => `/l/${id}/calendar`;
export const listingCompPath = (listingId: string | number, compId: string | number) => `/l/${listingId}/comps/v1/${compId}`;
export const listingSettingsPath = (id: string | number) => `/l/${id}/settings`;
export const listingReservationsPath = (id: string | number) => `/l/${id}/reservations`;
export const listingPerformancePath = (id: string | number) => `/l/${id}/performance`;
export const listingInsightsPath = (id: string | number) => `/l/${id}/insights`;
export const listingChangelogPath = (id: string | number) => `/l/${id}/changelog`;
export const listingChangelogDataPath = (listingId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/listings/${listingId}/changelog`;
export const listingChangelogCacheDataPath = (listingId: number) => PROXY_API_PREFIX + `/rest_api/v1/listings/${listingId}/changelog_cache`;
export const listingNotesPath = (id: string | number) => `/l/${id}/notes`;
export const listingNotesDataPath = (listingId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/listings/${listingId}/notes`;
export const listingSingleNoteDataPath = (listingId: number, id: number) => `${listingNotesDataPath(listingId)}/${id}`;

export const customRatesPath = (id: string | number) => `${listingCalendarPath(id)}/custom_rates`;
export const customRatePath = (id: string | number, rateId: string | number) => `${listingCalendarPath(id)}/custom_rate/${rateId}`;

export const publicCompSetsPath = () => '/comp-sets';
export const compCandidatesPath = () => PROXY_API_PREFIX + '/rest_api/v2/comp_sets/candidates';
export const compCreatePath = () => PROXY_API_PREFIX + '/rest_api/v2/comp_sets';
export const compSetPath = () => PROXY_API_PREFIX + '/rest_api/v2/comp_sets';
export const compSetIdPath = (id) => PROXY_API_PREFIX + `/rest_api/v2/comp_sets/${id}`;
export const compSetAssociatePath = (id) => PROXY_API_PREFIX + `/rest_api/v2/comp_sets/${id}/associated_listings`;
export const compSetAddListingsPath = (id) => PROXY_API_PREFIX + `/rest_api/v2/comp_sets/${id}/listings`;
// These are temporary fill-ins for current features in the application
export const compSetsLegacyPath = (listingId: number) => PROXY_API_PREFIX + `/rest_api/v2/comp_sets_legacy?listing_id=${listingId}`;
export const compSetLegacyPath = (id: string) => PROXY_API_PREFIX + `/rest_api/v2/comp_sets_legacy/${id}`;
export const compSetReportPath = (id: number | string) => PROXY_API_PREFIX + `/rest_api/v2/comp_sets/${id}/report`;
export const compSetGenerateReportPath = (id: string) => PROXY_API_PREFIX + `/rest_api/v2/comp_sets/${id}/report/generate`;
export const downloadSetCalendar = (id: number | string) => PROXY_API_PREFIX + `/rest_api/v2/comp_sets/${id}/calendars`;

//Admin
export const promosPath = () => '/admin/promos';
export const designPath = () => '/admin/design';

// Market Report Paths
export const marketReportPath = (id: string | number) => `/markets/v1/${id}`;
export const marketReportOverviewPath = (id: string | number) => `/markets/v1/${id}/overview`;

// Channel account paths
export const linkChannelPath = (channel_id_name?: string) => {
    return `/ca/link${channel_id_name ? `/${channel_id_name}` : ''}`;
};
// Hard-coded since both Airbnb v1 and v2 are the same channel.
export const linkAirbnbLegacyPath = () => '/ca/link/airbnb_legacy';

/*****************************/
// Public api routes
// These routes do not need to be proxied and do not require user auth.
// They may be used for static page generation
/*****************************/

export const pricingTiersPath = () => PROXY_API_PREFIX + '/rest_api/v1/pricing_tiers';
export const RecommendationApiDocsPath = () => '/wheelhouse_pro_api';
export const CompSetApiDocsPath = () => '/comp_set_api';
export const DemandApiDocsPath = () => '/demand_api';
export const JitApiDocsPath = () => '/wheelhouse_lite_api';

export const logoutPath = () => PROXY_API_PREFIX + '/logout';
export const experimentsPath = () => appServer + '/experiments';

/*****************************/
// Proxied api routes
/*****************************/

export const loginAPI = () => PROXY_API_PREFIX + '/u/login';
export const userData = () => PROXY_API_PREFIX + '/u/user';
export const userDataV2 = () => PROXY_API_PREFIX + '/rest_api/v2/user';

// Bulk Dashboard API
export const listingBulkPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings';
export const listingBulkPathV2 = () => PROXY_API_PREFIX + '/rest_api/v2/listings';
export const listingsMetaPathV2 = () => PROXY_API_PREFIX + '/rest_api/v2/listings/meta';
export const listingsFilteredPathV2 = () => PROXY_API_PREFIX + '/rest_api/v2/listings/ids';
export const listingMinimalBulkPathV2 = () => PROXY_API_PREFIX + '/rest_api/v2/listings/minimal';
/**
 * @deprecated This API is bloated and may be slow.  Listing and Calendar data should be fetched independently.
 * TODO: Add new calendar endpoint to warning.
 **/
export const listingBulkCalendarsPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/calendars';
export const listingBulkMngListingsPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/managed_listings';
export const listingBulkRatesPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/rates';
export const listingBulkAdjustRatesPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/bulk_adjustment';
export const listingBulkAdjustRatesPathV2 = () => PROXY_API_PREFIX + '/rest_api/v1/listings/bulk_adjustment_v2';
export const listingBulkPreferencesPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/preferences';
export const listingBulkPreferencesBoundsPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/preferences/bounds';
export const listingBulkPreferencesUniquePath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/preferences/identical';
export const listingBulkNeedsUpgradeBeforeAutomationPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/preferences/automation';
export const listingBulkReservationsPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/reservations';
export const listingBulkBudgetsPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/budgets';
export const listingBulkTagsPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/bulk_tags';
export const listingTags = (listingId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/listings/${listingId}/tags`;
export const editTag = () => PROXY_API_PREFIX + '/rest_api/v1/listings/tags';
export const accountsBulkPath = () => PROXY_API_PREFIX + '/rest_api/v2/accounts';
export const accountsConnectPath = (id: string | number) => PROXY_API_PREFIX + `/rest_api/v2/accounts/${id}/connect`;
export const listingPathV2 = (listingId: string | number) => PROXY_API_PREFIX + `/rest_api/v2/listings/${listingId}`;
export const listingCalendarPathV2 = (listingId: string | number) => PROXY_API_PREFIX + `/rest_api/v2/listings/${listingId}/calendar`;
export const listingAmenitiesPathV2 = (listingId: string | number) => PROXY_API_PREFIX + `/rest_api/v2/listings/${listingId}/amenities`;
export const listingUnautomatePath = (id: string | number) => PROXY_API_PREFIX + `/l/${id}/unautomate`;
export const inviteSubUserPath = (userId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/invite_sub_user`;
export const listingBulkPacingPath = () => PROXY_API_PREFIX + '/rest_api/v2/listings/pacing';
export const listingBulkBookableNightsPath = () => listingBulkPacingPath() + '/bookable_nights';
export const listingBulkBlockedNightsPath = () => listingBulkPacingPath() + '/blocked_nights';

// Pricing Tier API
export const userListingPricingTierPath = (userId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/listing_pricing_tiers`;
export const usersPricingTierSubscriptionsPath = (userId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/pricing_tiers_by_listing`;
export const userProPricingTiersCount = (userId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/pro_pricing_tiers/count`;
export const listingPricingTierPath = (listingId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/listings/${listingId}/pricing_tiers`;
export const updateListingPricingTierPath = (listingId: string | number, tierLevel: number) =>
    PROXY_API_PREFIX + `/rest_api/v1/listings/${listingId}/pricing_tiers?tier_level=${tierLevel}`;
export const updateListingsPricingTierPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/pricing_tiers';
export const listingUpgradeCostBreakdownPath = (listingId: string | number, tierLevel: string) =>
    PROXY_API_PREFIX + `/rest_api/v1/listings/${listingId}/${tierLevel}/upgrade_bill`;
export const listingsUpgradeCostBreakdownPath = () => PROXY_API_PREFIX + '/rest_api/v1/listings/upgrade_tiers';

// Sets Pricing API
export const setsPricingOffers = () => PROXY_API_PREFIX + '/rest_api/v1/products/comp_sets/offers';
export const setsSubscriptionProposal = () => PROXY_API_PREFIX + '/rest_api/v1/products/comp_sets/purchase/propose';
export const setsSubscriptionPurchase = () => PROXY_API_PREFIX + '/rest_api/v1/products/comp_sets/purchase/finalize';
export const setsSubscriptions = () => PROXY_API_PREFIX + '/rest_api/v1/products/comp_sets/subscriptions';

export const freeBookingApiPath = (id: string | number) => PROXY_API_PREFIX + `/promo/${id}`;
export const addPromoApiPath = () => PROXY_API_PREFIX + '/promo/add';
export const deletePromoApiPath = (id: string | number) => PROXY_API_PREFIX + `/promo/${id}/delete`;
export const marketsTestPath = () => PROXY_API_PREFIX + '/markets_data/test';
export const marketApiPath = (id: string | number | undefined) => PROXY_API_PREFIX + `/rest_api/v2/markets/${id}`;
export const channelsApiPath = () => PROXY_API_PREFIX + '/channels';

// Calendar rule paths
export const calendarRulesPreviewPath = () => PROXY_API_PREFIX + '/rest_api/v2/calendar_rules/preview';

// Advanced preference rule paths
export const gapNightRulesPreviewPath = () => PROXY_API_PREFIX + '/rest_api/v2/gap_night_rules/preview';
export const pricingPreviewPath = (id: string | number) => PROXY_API_PREFIX + `/rest_api/v1/listings/${id}/price_recommendations`;

// Market Report Paths
export const marketDataPath = (id?: string | number) => PROXY_API_PREFIX + `/rest_api/v1/markets/${id || ''}`;
export const marketSearchPath = (term?: string) => PROXY_API_PREFIX + `/rest_api/v1/markets/search?query=${term}`;
export const marketReportsOffersPath = (marketId?: string | number, productCode?: string) =>
    PROXY_API_PREFIX + `/rest_api/v1/products/market_reports/offers?market_id=${marketId}&product_code=${productCode}`;
export const marketReportsSubscriptionsPath = () => PROXY_API_PREFIX + '/rest_api/v1/subscriptions';
export const marketReportsSubscriptionsCancelPath = (id: string | number) => PROXY_API_PREFIX + `/rest_api/v1/subscriptions/${id}/cancel`;
export const marketReportsSubscriptionsRefundPath = (id: string | number) => PROXY_API_PREFIX + `/rest_api/v1/subscriptions/${id}/refund`;

export const listingDataPath = (id: string | number) => PROXY_API_PREFIX + `/l/${id}`;
export const listingRevertPreferencesPath = (id: string | number) => PROXY_API_PREFIX + `/l/${id}/revert_preferences`;
export const listingRevertRatesPath = (id: string | number) => PROXY_API_PREFIX + `/l/${id}/revert_rates`;
export const listingToggleHiddenPath = (id: string | number) => PROXY_API_PREFIX + `/l/${id}/toggle_hidden`;
export const listingRateInitializationPath = (id: string | number) => PROXY_API_PREFIX + `/l/${id}/initialize_custom_rates`;

//  API routes
export const saveI18nDataAPI = () => '/someAPIPATH()'; // JUAN add path
export const cancelAccountAPI = () => PROXY_API_PREFIX + '/u/cancel';
export const updateUserAPI = () => PROXY_API_PREFIX + '/u/update';
export const updateUserAdminAPI = (id: string | number) => PROXY_API_PREFIX + `/u/${id}/admin_update`;
export const updateUserReferralProgramAPI = (id: string | number) => PROXY_API_PREFIX + `/u/${id}/referral_program_update`;
export const enableTrialAdminAPI = (id: string | number) => PROXY_API_PREFIX + `/u/${id}/admin_enable_trial`;
export const updateUserPasswordAPI = () => PROXY_API_PREFIX + '/u/update_password';

export const airbnbLinkAccountAPI = () => PROXY_API_PREFIX + '/inbound/airbnb';
export const airbnbLegacyLinkAccountAPI = () => PROXY_API_PREFIX + '/inbound/airbnb_legacy';
export const hostfullyAgencyAPI = () => PROXY_API_PREFIX + '/inbound/hostfully_agency';

export const registerAPI = () => PROXY_API_PREFIX + '/users';
export const forgotPasswordAPI = () => PROXY_API_PREFIX + '/users/password';
export const sendReactivateEmailAPI = () => PROXY_API_PREFIX + '/u/account/reactivate_email';
export const reactivateMainAccountAPI = () => PROXY_API_PREFIX + '/u/reactivate';

export const addRateAPI = (id: string | number) => PROXY_API_PREFIX + `/l/${id}/add_rate`;
export const addRatePreviewAPI = (id: string | number) => PROXY_API_PREFIX + `/l/${id}/add_rate/preview`;
export const editRateAPI = (id: string | number) => PROXY_API_PREFIX + `/l/${id}/edit_rate`;
export const deleteRatesAPI = (id: number) => PROXY_API_PREFIX + `/l/${id}/delete_rates`;
export const deleteRatesPreviewAPI = (id: string | number) => PROXY_API_PREFIX + `/l/${id}/delete_rates/preview`;
export const bulkAdjustmentPreviewAPI = (id: string | number) => PROXY_API_PREFIX + `/rest_api/v1/listings/${id}/bulk_adjustment_preview`;
export const writeRatesAPI = (id: string | number) => PROXY_API_PREFIX + `/l/${id}/write_rates`;
export const automateDaysAPI = (id: string | number) => PROXY_API_PREFIX + `/l/${id}/automate_days`;

export const updateUserFlagsAPI = () => PROXY_API_PREFIX + '/u/user_flags';
export const changePasswordAPI = () => PROXY_API_PREFIX + '/users/password';
export const getReferralStats = () => PROXY_API_PREFIX + '/u/referral_stats';
export const updateBillingInfo = () => PROXY_API_PREFIX + '/u/update_billing_info';
export const getSubscriptionInfo = () => PROXY_API_PREFIX + '/u/subscription';
export const getBillingInfo = () => PROXY_API_PREFIX + '/u/billing_info';
export const getInvoices = () => PROXY_API_PREFIX + '/u/invoices';
export const getInvoice = (source: string, id: string | number) => PROXY_API_PREFIX + `/u/invoices/${source}/${id}`;
export const getCurrentBillInfo = () => PROXY_API_PREFIX + '/u/current_bill';

// Admin
export const allPromosDataPath = () => PROXY_API_PREFIX + '/promos';

export const channelAccountGetPath = (id?: string) => PROXY_API_PREFIX + `/ca/${id}`;
export const channelAccountsChoosePath = () => PROXY_API_PREFIX + '/ca/choose';
export const channelAccountTransferPath = () => PROXY_API_PREFIX + '/ca/transfer';
export const channelAccountSyncListingsPath = (id: string | number) => PROXY_API_PREFIX + `/ca/${id}/sync_listings`;
export const channelAccountSyncCalendarsPath = (id: string | number) => PROXY_API_PREFIX + `/ca/${id}/sync_calendars`;
export const channelAccountDeactivatePath = (id: string | number) => PROXY_API_PREFIX + `/ca/${id}/deactivate`;

// Listing paths
export const userAccountSyncListingsPath = (id: string | number) => PROXY_API_PREFIX + `/ua/${id}/sync_listings`;
export const listingSyncPath = (id: string | number) => PROXY_API_PREFIX + `/l/${id}/sync`;

export const recommendationsPath = () => PROXY_API_PREFIX + '/recommendations';
export const listingReservationsDataPath = (id: number) => PROXY_API_PREFIX + `/rest_api/v1/listings/${id}/reservations`;
export const listingBudgetDataPath = (id: string | number) => PROXY_API_PREFIX + `/rest_api/v1/listings/${id}/budgets`;
export const portfolioSegmentPath = (userId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/portfolio_segment`;
export const portfolioFiltersPath = (userId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/filters`;
export const userApiTokenPath = (userId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/api_token`;
export const integrationApiTokenPath = (userId: string | number) => PROXY_API_PREFIX + `/rest_api/v1/users/${userId}/integration_token`;

/*****************************/
// External paths
/*****************************/

export const helpCenterPath = () => 'http://help.usewheelhouse.com/';
export const facebookPath = () => 'https://www.facebook.com/usewheelhouse';
export const twitterPath = () => 'https://twitter.com/UseWheelhouse';
export const instagramPath = () => 'https://www.instagram.com/use_wheelhouse';
export const linkedinPath = () => 'https://www.linkedin.com/company/usewheelhouse';
export const youtubePath = () => 'https://www.youtube.com/c/Wheelhouse';
export const airbnbSecretsPath = () => 'http://www.airbnbsecrets.com/';
export const jobsPath = () => '/careers';
export const blogPath = () => 'https://www.usewheelhouse.com/blog/';
export const termsOfUsePath = () => 'http://help.usewheelhouse.com/privacy-and-terms/terms-of-service';
export const privacyPolicyPath = () => 'http://help.usewheelhouse.com/privacy-and-terms/privacy-policy-cookie-policy';
export const billingAndRefundPolicyPath = () => 'https://help.usewheelhouse.com/en/articles/6016157-billing-and-refund-policy';
export const gettingStartedPath = () =>
    'http://help.usewheelhouse.com/getting-started-on-wheelhouse/setting-up-your-wheelhouse-account/how-do-i-get-started-on-wheelhouse';
export const airbnbResetPasswordUrl = () => 'https://www.airbnb.com/help/article/76/how-do-i-reset-my-password';
export const airbnbLoginUrl = () => 'https://www.airbnb.com/login';

/*****************************/
// Other
/*****************************/

// This function relies on values from the configured CHANNELS
// It supports EXTREMELY stupid variable substitution for these strings
//   * "$HOST" - replaced by the current window.location.host
//   * "$HOMEAWAY_CLIENT_ID" - replaced by the HOMEAWAY_CLIENT_ID
//   * "$TRIPADVISOR_CLIENT_ID" - replaced by the HOMEAWAY_CLIENT_ID
export const channelRedirectUrl = (channel?: Channel) => {
    if (!channel || !channel.auth_redirect_url) {
        throw new Error('Invalid channel specified for redirect path!');
    }

    return channel.auth_redirect_url
        .replace('$HOST', window.location.host)
        .replace('$HOMEAWAY_CLIENT_ID', HOMEAWAY_CLIENT_ID)
        .replace('$TRIPADVISOR_CLIENT_ID', TRIPADVISOR_CLIENT_ID)
        .replace('$CSRF_TOKEN', getCSRFToken());
};
