import React from 'react';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import fetcher, { fetchV2API, postWithBody } from 'libs/utils/fetcher';
import * as UrlParser from 'url';
import { flatten } from 'lodash';
import { toast } from 'sonner';
import { CompSet } from 'libs/types/set';
import { useAppSelector } from 'libs/global/hooks';
import { compCreatePath, compSetAssociatePath, compSetIdPath, compSetPath, listingBulkPathV2 } from 'libs/routes';
import { del, put, request } from 'libs/requests';
import { loadToastError } from 'libs/global/components/CompNext/Modals/utils';
import { ErrorResponse } from 'libs/types/errorResponse';
import { wrapSinglePolygonAsMulti } from '../components/CompNext/utils';

export const useSets = (ids?: number[]) => {
    const userId = useAppSelector(({ user }) => user?.id);
    const url = compSetPath();
    const params = { ids, user_id: userId };

    return useQuery<CompSet[]>({
        queryKey: [url, params],
        queryFn: async () => {
            const { data } = await request(url, 'GET', undefined, params);
            return data;
        },
        enabled: !!userId && (!ids || ids.length > 0),
    });
};

const useSetIdPlaceholderData = (id: string | number | undefined | null) => {
    const queryClient = useQueryClient();
    return flatten(
        queryClient
            .getQueriesData({
                predicate: ({ queryKey }) => {
                    return !!(queryKey[0] && typeof queryKey[0] == 'string' && queryKey[0].startsWith(compSetPath()));
                },
            })
            .map(([_, r]) => r)
            .filter((r) => (r as any)?.items)
            .map(({ items }) => items),
    ).find((set) => set.id == parseInt(id as string));
};

export const useSetId = (id: string | number | undefined | null) => {
    const userId = useAppSelector(({ user }) => user?.id);
    const url = compSetIdPath(id);
    const placeholderData = useSetIdPlaceholderData(id);

    return useQuery<CompSet>({
        queryKey: [url],
        queryFn: async () => {
            const data = await fetcher(url);
            return data;
        },
        placeholderData: placeholderData,
        enabled: !!userId && !!id,
    });
};

export const useSet = (id: number | null | undefined) => {
    const { data: sets, isLoading } = useSets();
    return React.useMemo(() => {
        return { data: sets?.find((set) => set.id === id), isLoading };
    }, [sets, id, isLoading]);
};

const deleteSet = async (id: number) => {
    const url = compSetIdPath(id);
    const data = await del(url);
    return data;
};

export const useDeleteSet = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteSet,
        onMutate: (variables: number) => {
            const previousCompSets: CompSet[] | undefined = queryClient.getQueryData([compSetPath()]);

            queryClient.setQueryData([compSetPath()], (old: CompSet[] | undefined) => {
                return old?.filter((set: CompSet) => set.id !== variables);
            });

            return () => queryClient.setQueryData([compSetPath()], previousCompSets);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [compSetPath()],
            });
            toast.success('Set successfully deleted');
        },
        onError: (error: ErrorResponse, _variables, rollback) => {
            loadToastError(error);
            rollback?.();
        },
    });
};

const duplicateSet = async ({ name, description, active }: { name: string; description?: string; active: number[] }) => {
    const url = compCreatePath();
    const data = await postWithBody([
        url,
        {
            name,
            description,
            listing_ids: {
                active,
                hidden: [],
                review: [],
            },
        },
    ]);
    return data;
};

export const useDuplicateSet = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: duplicateSet,
        onSuccess: () => {
            toast.success('Set duplicated successfully');
            queryClient.invalidateQueries({
                queryKey: [compSetPath()],
            });
        },
        onError: (error: ErrorResponse, _variables) => {
            loadToastError(error);
        },
    });
};

const assignListingsToSet = async ({ id, listingIds, isDefault }: { id: number; listingIds?: number[]; isDefault?: boolean }) => {
    const url = compSetAssociatePath(id);
    const data = await put(url, {
        listing_ids: listingIds,
        ...(isDefault && { is_default: isDefault }),
    });
    return data;
};

export const useAssignListingsToSet = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: assignListingsToSet,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: [compSetPath()],
            }),
                queryClient.invalidateQueries({
                    queryKey: [compSetIdPath(variables?.id)],
                }),
                queryClient.invalidateQueries({
                    queryKey: [listingBulkPathV2()],
                }),
                toast.success('Listings added successfully');
        },
        onError: (error: ErrorResponse, _variables, rollback) => {
            loadToastError(error);
        },
    });
};

const unassignListingsToSet = async ({ id, listingIds }: { id: number; listingIds?: number[] }) => {
    const url = compSetAssociatePath(id);
    const data = await del(url, {
        listing_ids: listingIds,
    });
    return data;
};

export const useUnassignListingsToSet = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: unassignListingsToSet,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: [compSetPath()],
            });
            queryClient.invalidateQueries({
                queryKey: [compSetIdPath(variables?.id)],
            });
            queryClient.invalidateQueries({
                queryKey: [listingBulkPathV2()],
            });
            toast.success('Listings successfully removed');
        },
        onError: (error: ErrorResponse, _variables, rollback) => {
            loadToastError(error);
        },
    });
};

const updateSetDetails = async ({ id, details }: { id: number; details: { name: string; kind: string; description?: string } }) => {
    const url = compSetIdPath(id);
    const data = await put(url, details);
    return data;
};

const updateSetBoundary = async ({ id, boundary }: { id: string; boundary?: number[][][] | null }) => {
    const url = compSetIdPath(id);
    const configuredBoundary = wrapSinglePolygonAsMulti(boundary);

    const data = await put(url, { boundary: configuredBoundary });

    return data;
};

export const useUpdateSetBoundary = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateSetBoundary,
        onSuccess: () => {
            toast.success('Boundary updated');
            queryClient.invalidateQueries({
                queryKey: [compSetPath()],
            });
        },
        onError: (error: ErrorResponse, _variables, rollback) => {
            loadToastError(error);
        },
    });
};

export const useUpdateSetDetails = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateSetDetails,
        onMutate: (variables) => {
            const previousCompSets: CompSet[] | undefined = queryClient.getQueryData([compSetPath()]);

            queryClient.setQueryData([compSetPath()], (old: CompSet[] | undefined) => {
                return old?.map((obj) => {
                    if (obj.id === variables.id) {
                        return { ...obj, ...variables.details };
                    }
                    return obj;
                });
            });
            return () => queryClient.setQueryData([compSetPath()], previousCompSets);
        },
        onSuccess: (_data, variables) => {
            toast.success('Set updated successfully');
            queryClient.invalidateQueries({
                queryKey: [compSetPath()],
            });
            queryClient.invalidateQueries({
                queryKey: [compSetIdPath(variables.id)],
            });
        },

        onError: (error: ErrorResponse, _variables, rollback) => {
            loadToastError(error);
            rollback?.();
        },
    });
};

export const useUpdateDefaultSet = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: assignListingsToSet,
        // TODO: Add onMutate to avoid loading state
        onSuccess: () => {
            toast.success('Default set updated successfully');
            queryClient.invalidateQueries({
                queryKey: [compSetPath()],
            });
        },
        onError: (error: ErrorResponse, _variables, rollback) => {
            loadToastError(error);
        },
    });
};

export const usePaginatedSets = <DataType extends unknown>(path: string, per_page: number, page: number, search: string) => {
    const getUrl = () => {
        const url = `${path}?per_page=${per_page}&page=${page}&name=${search}`;
        const urlObj = UrlParser.parse(url, true);
        urlObj.search = null;
        urlObj.query.page = page.toString();
        return UrlParser.format(urlObj);
    };
    const { data, isFetching, isLoading, isPending } = useQuery({
        queryKey: [path, page, search],
        queryFn: (p) => {
            return fetchV2API<DataType[]>(getUrl());
        },
        placeholderData: keepPreviousData,
    });

    const itemCount = data?.itemCount;

    return {
        data,
        itemCount,
        isPending,
        isFetching,
        isLoading,
    };
};

export const useSubscriptionStatus = (set?: CompSet) => {
    if (!set || !set.subscription) {
        return {
            name: 'free',
            is_free: true,
            is_legacy: false,
            valid_delete: true,
            valid_until: null,
            valid_default: false,
            is_upgrading: false,
        };
    }

    const { product_offer, valid_until } = set.subscription;
    const upcoming = set.upcoming_subscription?.product_offer;

    const subscriptionLevel = product_offer?.level;
    const upcomingLevel = upcoming?.level;

    const isFree = subscriptionLevel === 0;
    const isPaid = subscriptionLevel === 1;
    const isLegacy = subscriptionLevel === 2;

    const isUpgrading = (isFree || isLegacy) && upcomingLevel === 1;
    const canDelete = (isFree && !upcoming) || (isLegacy && !upcoming) || upcomingLevel === 0;
    const canDefault = isPaid || isLegacy;

    return {
        name: isFree ? 'free' : isPaid ? 'paid' : isLegacy ? 'legacy' : 'free',
        is_free: isFree,
        is_legacy: isLegacy,
        valid_delete: canDelete,
        valid_until: valid_until || null,
        valid_default: canDefault,
        is_upgrading: isUpgrading,
    };
};
