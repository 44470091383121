import moment from 'moment';
import { get } from 'lodash';
import { metricName } from 'libs/utils/metricDefinitions';
import { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import { formatY } from 'libs/global/components/Markets/shared';

export const COMP_TOTAL_FLAT = [
    'stat.comp_set_revenue.0_7',
    'stat.comp_set_revenue.0_30',
    'stat.comp_set_revenue.0_60',
    `stats_monthly.${moment().format('YYYY-MM')}-01.comp_set_revenue`,
    `stats_monthly.${moment().add(1, 'M').format('YYYY-MM')}-01.comp_set_revenue`,
    `stats_monthly.${moment().add(2, 'M').format('YYYY-MM')}-01.comp_set_revenue`,
    'stat.comp_set_occupancy.0_7',
    'stat.comp_set_occupancy.0_30',
    'stat.comp_set_occupancy.0_60',
    `stats_monthly.${moment().format('YYYY-MM')}-01.comp_set_occupancy`,
    `stats_monthly.${moment().add(1, 'M').format('YYYY-MM')}-01.comp_set_occupancy`,
    `stats_monthly.${moment().add(2, 'M').format('YYYY-MM')}-01.comp_set_occupancy`,
];

export const COMP_DIFF_FLAT = [
    'stat.comparison.revenue.0_7',
    'stat.comparison.revenue.0_30',
    'stat.comparison.revenue.0_60',
    `stats_monthly.${moment().format('YYYY-MM')}-01.comparison_revenue`,
    `stats_monthly.${moment().add(1, 'M').format('YYYY-MM')}-01.comparison_revenue`,
    `stats_monthly.${moment().add(2, 'M').format('YYYY-MM')}-01.comparison_revenue`,
    'stat.comparison.occupancy.0_7',
    'stat.comparison.occupancy.0_30',
    'stat.comparison.occupancy.0_60',
    `stats_monthly.${moment().format('YYYY-MM')}-01.comparison_occupancy`,
    `stats_monthly.${moment().add(1, 'M').format('YYYY-MM')}-01.comparison_occupancy`,
    `stats_monthly.${moment().add(2, 'M').format('YYYY-MM')}-01.comparison_occupancy`,
];

export const currencyConverter = (params: ValueGetterParams) => {
    const { data, colDef } = params;
    const value = get(data, colDef.field!);
    if (data?.currency_factor && Number.isFinite(value)) {
        return data.currency_factor * value;
    } else {
        return value;
    }
};

export const percentageValueFormatter = ({ value }: { value: number }) => {
    return Number.isFinite(value) ? formatY('percent', value * 100) : '-';
};

const compSetStyling = {
    backgroundColor: 'rgba(20, 15, 20, .02)',
    paddingLeft: 0,
    paddingRight: '4px',
    fontVariantNumeric: 'tabular-nums !important',
};

const perfHeaders = {
    listing: '',
    comp: 'comp',
    comparison: '% diff',
};

const defaultColDefs = {
    enableRowGroup: false,
    enablePivot: false,
    filter: false,
    suppressHeaderMenuButton: true,
    type: 'numericColumn',
    suppressSpanHeaderHeight: true,
};

const columns: ColGroupDef[] = [
    {
        headerName: metricName('adjusted_occupancy', true) + ' next x days',
        suppressColumnsToolPanel: true,
        children: [
            {
                headerName: '7 days',
                children: [
                    {
                        field: 'stat.occupancy.0_7',
                        paramKey: 'listing_stats.occupancy_0_7',
                        width: 65,
                        headerName: perfHeaders.listing,
                        sortable: true,
                        valueFormatter: percentageValueFormatter,
                        ...defaultColDefs,
                    } as ColDef & { paramKey: string },
                    {
                        field: 'stat.comp_set_occupancy.0_7',
                        paramKey: 'listing_stats.comp_set_occupancy_0_7',
                        width: 65,
                        headerName: perfHeaders.comp,
                        sortable: true,
                        hide: true,
                        valueFormatter: percentageValueFormatter,
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    } as ColDef & { paramKey: string },
                    {
                        field: 'stat.comparison.occupancy.0_7',
                        width: 65,
                        headerName: perfHeaders.comparison,
                        sortable: false,
                        hide: true,
                        valueFormatter: (params) => (params.value ? params.value : '-'),
                        cellRenderer: 'compSetComparisonCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                ],
            },
            {
                headerName: '30 days',
                children: [
                    {
                        field: 'stat.occupancy.0_30',
                        paramKey: 'listing_stats.occupancy_0_30',
                        width: 65,
                        headerName: perfHeaders.listing,
                        sortable: true,
                        valueFormatter: percentageValueFormatter,
                        ...defaultColDefs,
                    } as ColDef & { paramKey: string },
                    {
                        field: 'stat.comp_set_occupancy.0_30',
                        paramKey: 'listing_stats.comp_set_occupancy_0_30',
                        width: 65,
                        headerName: perfHeaders.comp,
                        sortable: true,
                        hide: true,
                        valueFormatter: percentageValueFormatter,
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    } as ColDef & { paramKey: string },
                    {
                        field: 'stat.comparison.occupancy.0_30',
                        width: 65,
                        headerName: perfHeaders.comparison,
                        sortable: false,
                        hide: true,
                        valueFormatter: (params) => (params.value ? params.value : '-'),
                        cellRenderer: 'compSetComparisonCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                ],
            },
            {
                headerName: '60 days',
                children: [
                    {
                        field: 'stat.occupancy.0_60',
                        paramKey: 'listing_stats.occupancy_0_60',
                        width: 65,
                        headerName: perfHeaders.listing,
                        sortable: true,
                        valueFormatter: percentageValueFormatter,
                        ...defaultColDefs,
                    } as ColDef & { paramKey: string },
                    {
                        field: 'stat.comp_set_occupancy.0_60',
                        paramKey: 'listing_stats.comp_set_occupancy_0_60',
                        width: 65,
                        headerName: perfHeaders.comp,
                        sortable: true,
                        hide: true,
                        valueFormatter: percentageValueFormatter,
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    } as ColDef & { paramKey: string },
                    {
                        field: 'stat.comparison.occupancy.0_60',
                        width: 65,
                        headerName: perfHeaders.comparison,
                        sortable: false,
                        hide: true,
                        valueFormatter: (params) => (params.value ? params.value : '-'),
                        cellRenderer: 'compSetComparisonCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                ],
            },
        ],
    },
    {
        headerName: metricName('nightly_revenue') + ' next x days',
        suppressColumnsToolPanel: true,
        children: [
            {
                headerName: '7 days',
                children: [
                    {
                        field: 'stat.revenue.0_7',
                        paramKey: 'listing_stats.revenue_0_7',
                        width: 65,
                        headerName: perfHeaders.listing,
                        sortable: true,
                        cellRenderer: 'revenueMetricCellRenderer',
                        valueGetter: (params) => currencyConverter(params),
                        ...defaultColDefs,
                    },
                    {
                        field: 'stat.comp_set_revenue.0_7',
                        paramKey: 'listing_stats.comp_set_revenue_0_7',
                        width: 65,
                        headerName: perfHeaders.comp,
                        sortable: true,
                        hide: true,
                        valueGetter: (params) => currencyConverter(params),
                        valueFormatter: (params) => (params.value ? `${params.value}` : '-'),
                        cellRenderer: 'compSetRevenueMetricCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                    {
                        field: 'stat.comparison.revenue.0_7',
                        width: 65,
                        headerName: perfHeaders.comparison,
                        sortable: false,
                        hide: true,
                        valueFormatter: (params) => (params.value ? params.value : '-'),
                        cellRenderer: 'compSetRevenueComparisonCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                ],
            },
            {
                headerName: '30 days',
                children: [
                    {
                        field: 'stat.revenue.0_30',
                        paramKey: 'listing_stats.revenue_0_30',
                        width: 65,
                        headerName: perfHeaders.listing,
                        sortable: true,
                        cellRenderer: 'revenueMetricCellRenderer',
                        valueGetter: (params) => currencyConverter(params),
                        ...defaultColDefs,
                    },
                    {
                        field: 'stat.comp_set_revenue.0_30',
                        paramKey: 'listing_stats.comp_set_revenue_0_30',
                        width: 65,
                        headerName: perfHeaders.comp,
                        sortable: true,
                        hide: true,
                        cellRenderer: 'compSetRevenueMetricCellRenderer',
                        valueGetter: (params) => currencyConverter(params),
                        valueFormatter: (params) => (params.value ? `${params.value}` : '-'),
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                    {
                        field: 'stat.comparison.revenue.0_30',
                        width: 65,
                        headerName: perfHeaders.comparison,
                        sortable: false,
                        hide: true,
                        valueFormatter: (params) => (params.value ? params.value : '-'),
                        cellRenderer: 'compSetRevenueComparisonCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                ],
            },
            {
                headerName: '60 days',
                children: [
                    {
                        field: 'stat.revenue.0_60',
                        paramKey: 'listing_stats.revenue_0_60',
                        width: 65,
                        headerName: perfHeaders.listing,
                        sortable: true,
                        cellRenderer: 'revenueMetricCellRenderer',
                        valueGetter: (params) => currencyConverter(params),
                        ...defaultColDefs,
                    },
                    {
                        field: 'stat.comp_set_revenue.0_60',
                        paramKey: 'listing_stats.comp_set_revenue_0_60',
                        width: 65,
                        headerName: perfHeaders.comp,
                        sortable: true,
                        hide: true,
                        cellRenderer: 'compSetRevenueMetricCellRenderer',
                        valueGetter: (params) => currencyConverter(params),
                        valueFormatter: (params) => (params.value ? `${params.value}` : '-'),
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                    {
                        field: 'stat.comparison.revenue.0_60',
                        width: 65,
                        headerName: perfHeaders.comparison,
                        sortable: false,
                        hide: true,
                        valueFormatter: (params) => (params.value ? params.value : '-'),
                        cellRenderer: 'compSetRevenueComparisonCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                ],
            },
        ],
    },
    {
        headerName: metricName('adjusted_occupancy', true),
        suppressColumnsToolPanel: true,
        children: [
            {
                headerName: moment().format('MMM, YYYY'),
                children: [
                    {
                        field: `stats_monthly.${moment().format('YYYY-MM')}-01.occupancy`,
                        colId: `stats_monthly.${moment().format('YYYY-MM')}-01.occupancy`,
                        headerName: perfHeaders.listing,
                        width: 70,
                        sortable: false,
                        valueFormatter: percentageValueFormatter,
                        ...defaultColDefs,
                    },
                    {
                        field: `stats_monthly.${moment().format('YYYY-MM')}-01.comp_set_occupancy`,
                        width: 65,
                        headerName: perfHeaders.comp,
                        sortable: false,
                        hide: true,
                        valueFormatter: percentageValueFormatter,
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                    {
                        field: `stats_monthly.${moment().format('YYYY-MM')}-01.comparison_occupancy`,
                        width: 65,
                        headerName: perfHeaders.comparison,
                        sortable: false,
                        hide: true,
                        valueFormatter: (params) => (params.value ? params.value : '-'),
                        cellRenderer: 'compSetComparisonCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                ],
            },
            {
                headerName: moment().add(1, 'M').format('MMM, YYYY'),
                children: [
                    {
                        field: `stats_monthly.${moment().add(1, 'M').format('YYYY-MM')}-01.occupancy`,
                        colId: `stats_monthly.${moment().add(1, 'M').format('YYYY-MM')}-01.occupancy`,
                        headerName: perfHeaders.listing,
                        width: 70,
                        sortable: false,
                        valueFormatter: percentageValueFormatter,
                        ...defaultColDefs,
                    },
                    {
                        field: `stats_monthly.${moment().add(1, 'M').format('YYYY-MM')}-01.comp_set_occupancy`,
                        width: 65,
                        headerName: perfHeaders.comp,
                        sortable: false,
                        hide: true,
                        valueFormatter: percentageValueFormatter,
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                    {
                        field: `stats_monthly.${moment().add(1, 'M').format('YYYY-MM')}-01.comparison_occupancy`,
                        width: 65,
                        headerName: perfHeaders.comparison,
                        sortable: false,
                        hide: true,
                        valueFormatter: (params) => (params.value ? params.value : '-'),
                        cellRenderer: 'compSetComparisonCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                ],
            },
            {
                headerName: moment().add(2, 'M').format('MMM, YYYY'),
                children: [
                    {
                        field: `stats_monthly.${moment().add(2, 'M').format('YYYY-MM')}-01.occupancy`,
                        colId: `stats_monthly.${moment().add(2, 'M').format('YYYY-MM')}-01.occupancy`,
                        headerName: perfHeaders.listing,
                        width: 70,
                        sortable: false,
                        valueFormatter: percentageValueFormatter,
                        ...defaultColDefs,
                    },
                    {
                        field: `stats_monthly.${moment().add(2, 'M').format('YYYY-MM')}-01.comp_set_occupancy`,
                        width: 65,
                        headerName: perfHeaders.comp,
                        sortable: false,
                        hide: true,
                        valueFormatter: percentageValueFormatter,
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                    {
                        field: `stats_monthly.${moment().add(2, 'M').format('YYYY-MM')}-01.comparison_occupancy`,
                        width: 65,
                        headerName: perfHeaders.comparison,
                        sortable: false,
                        hide: true,
                        valueFormatter: (params) => (params.value ? params.value : '-'),
                        cellRenderer: 'compSetComparisonCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                ],
            },
        ],
    },
    {
        headerName: metricName('nightly_revenue'),
        suppressColumnsToolPanel: true,
        children: [
            {
                headerName: moment().format('MMM, YYYY'),
                children: [
                    {
                        field: `stats_monthly.${moment().format('YYYY-MM')}-01.revenue`,
                        colId: `stats_monthly.${moment().format('YYYY-MM')}-01.revenue`,
                        headerName: perfHeaders.listing,
                        width: 70,
                        sortable: false,
                        valueGetter: (params) => currencyConverter(params),
                        cellRenderer: 'revenueMetricCellRenderer',
                        ...defaultColDefs,
                    },
                    {
                        field: `stats_monthly.${moment().format('YYYY-MM')}-01.comp_set_revenue`,
                        width: 65,
                        headerName: perfHeaders.comp,
                        sortable: false,
                        hide: true,
                        cellRenderer: 'compSetRevenueMetricCellRenderer',
                        valueGetter: (params) => currencyConverter(params),
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                    {
                        field: `stats_monthly.${moment().format('YYYY-MM')}-01.comparison_revenue`,
                        width: 65,
                        headerName: perfHeaders.comparison,
                        sortable: false,
                        hide: true,
                        valueFormatter: (params) => (params.value ? params.value : '-'),
                        cellRenderer: 'compSetRevenueComparisonCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                ],
            },
            {
                headerName: moment().add(1, 'M').format('MMM, YYYY'),
                children: [
                    {
                        field: `stats_monthly.${moment().add(1, 'M').format('YYYY-MM')}-01.revenue`,
                        colId: `stats_monthly.${moment().add(1, 'M').format('YYYY-MM')}-01.revenue`,
                        headerName: perfHeaders.listing,
                        width: 70,
                        sortable: false,
                        cellRenderer: 'revenueMetricCellRenderer',
                        valueGetter: (params) => currencyConverter(params),
                        ...defaultColDefs,
                    },
                    {
                        field: `stats_monthly.${moment().add(1, 'M').format('YYYY-MM')}-01.comp_set_revenue`,
                        width: 65,
                        headerName: perfHeaders.comp,
                        sortable: false,
                        hide: true,
                        cellRenderer: 'compSetRevenueMetricCellRenderer',
                        valueGetter: (params) => currencyConverter(params),
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                    {
                        field: `stats_monthly.${moment().add(1, 'M').format('YYYY-MM')}-01.comparison_revenue`,
                        width: 65,
                        headerName: perfHeaders.comparison,
                        sortable: false,
                        hide: true,
                        valueFormatter: (params) => (params.value ? params.value : '-'),
                        cellRenderer: 'compSetRevenueComparisonCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                ],
            },
            {
                headerName: moment().add(2, 'M').format('MMM, YYYY'),
                children: [
                    {
                        field: `stats_monthly.${moment().add(2, 'M').format('YYYY-MM')}-01.revenue`,
                        colId: `stats_monthly.${moment().add(2, 'M').format('YYYY-MM')}-01.revenue`,
                        headerName: perfHeaders.listing,
                        width: 70,
                        sortable: false,
                        cellRenderer: 'revenueMetricCellRenderer',
                        valueGetter: (params) => currencyConverter(params),
                        ...defaultColDefs,
                    },
                    {
                        field: `stats_monthly.${moment().add(2, 'M').format('YYYY-MM')}-01.comp_set_revenue`,
                        width: 65,
                        headerName: perfHeaders.comp,
                        sortable: false,
                        hide: true,
                        cellRenderer: 'compSetRevenueMetricCellRenderer',
                        valueGetter: (params) => currencyConverter(params),
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                    {
                        field: `stats_monthly.${moment().add(2, 'M').format('YYYY-MM')}-01.comparison_revenue`,
                        width: 65,
                        headerName: perfHeaders.comparison,
                        sortable: false,
                        hide: true,
                        valueFormatter: (params) => (params.value ? params.value : '-'),
                        cellRenderer: 'compSetRevenueComparisonCellRenderer',
                        cellStyle: function () {
                            return compSetStyling;
                        },
                        ...defaultColDefs,
                    },
                ],
            },
        ],
    },
];

export default columns;
