import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

// LogRocket types response as a LR.IResponse for responseSanitizer.
// That type doesn't export url, even those the response object has it,
// so we have to create our own type.
interface LogRocketResponse {
    body: string;
    headers: any;
    method: string;
    reqId: string;
    status: number;
    url: string;
}

// Disable logrocket for local dev
if (typeof window !== 'undefined' && window.location.hostname !== 'localhost') {
    LogRocket.init('wheelhouse/wheelhouse', {
        console: {
            isEnabled: true,
        },
        network: {
            responseSanitizer: (response: LogRocketResponse) => {
                // Filter out responses from the bulk API.
                // They can be quite large and contribute to long LogRocket responses.
                if (response.url && response.url.toLowerCase().indexOf('rest_api/v1/listings') !== -1) {
                    // scrub out the body
                    response.body = 'REMOVED FOR OPTIMIZATION - See LogRocket.init';
                }
                return response;
            },
        },
    });

    LogRocket.getSessionURL((sessionURL) => {
        Sentry.setTag('sessionURL', sessionURL);
    });
}

export default LogRocket;
