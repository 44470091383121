import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const setRawReducerState = createAction<any>('raw/setRawReducerState');

const raw = (key: string, initialState: any = null) => {
    const rawSlice = createSlice({
        name: key,
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder.addCase(setRawReducerState, (state, action: PayloadAction<any>) => {
                if (action.payload[key]) {
                    return {
                        ...state,
                        ...action.payload[key],
                    };
                } else {
                    return state;
                }
            });
        },
    });

    return rawSlice.reducer;
};

export default raw;
