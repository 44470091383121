'use client';

import React from 'react';
import { random, sortBy } from 'lodash';
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Flex,
    HStack,
    Input,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    Tag,
    TagLabel,
    Text,
    Tooltip,
    useBoolean,
    VStack,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useRefreshListingsMeta } from 'libs/global/hooks/usePortfolioFilter';
import { Tag as TagType } from 'libs/types/tag';
import { useAppDispatch, useAppSelector } from 'libs/global/hooks';
import { actions } from 'libs/global/slices/proState';
import type { CheckboxFilter } from 'libs/types/filters';

interface TagListCheckboxProps {
    tags: TagType[];
    allTags: TagType[];
    updateTags: (ids: string[]) => void;
}
const TagListCheckbox = ({ tags, allTags, updateTags }: TagListCheckboxProps) => {
    const sortedTagsByCheckedAndName = sortBy(allTags, (tag) => {
        const checked = tags.find(({ id }) => id == tag.id);
        const name = tag.name;
        return [!checked, name];
    });
    return (
        <CheckboxGroup value={tags.map(({ id }) => id.toString())} onChange={(e: string[]) => updateTags(e)}>
            <VStack align="flex-start" py={allTags.length ? 4 : 0} px={4} spacing={2}>
                {sortedTagsByCheckedAndName.map(({ id, name }) => {
                    return (
                        <HStack spacing={2} key={id}>
                            <Checkbox value={id.toString()}>{name}</Checkbox>
                        </HStack>
                    );
                })}
            </VStack>
        </CheckboxGroup>
    );
};

interface AddNewTagProps {
    filteredTags: TagType[];
    search: string | null;
    validSearch: boolean;
    onClick: () => void;
}
export const AddNewTag = ({ filteredTags, search, validSearch, onClick }: AddNewTagProps) => {
    const isEmpty = filteredTags?.length === 0;
    const noExactMatch = !filteredTags.some(({ name }) => name == search);

    return validSearch && (isEmpty || noExactMatch) ? (
        <Box width="100%">
            <Button leftIcon={<AddIcon color="gray.500" />} variant="ghost" width="100%" py={6} onClick={onClick} justifyContent="flex-start">
                <Flex justify="flex-start">
                    <Text color="gray.500">Create new tag: "{search}"</Text>
                </Flex>
            </Button>
        </Box>
    ) : null;
};

interface ListingTagProps {
    value: TagType[];
    saveEdit: (tags: TagType[]) => void;
}
export const ListingTag = ({ value, saveEdit }: ListingTagProps) => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(({ proState }) => proState.filters);
    const refreshMeta = useRefreshListingsMeta();
    const allTags = (filters.staticSchema.tags as CheckboxFilter).options as TagType[];
    const [tags, setTags] = React.useState(value);
    const [outOfSync, setOutOfSync] = useBoolean();
    const [isOpen, setIsOpen] = React.useState(false);
    const [search, setSearch] = React.useState<string | null>(null);
    const [filteredTags, setFilteredTags] = React.useState(allTags);
    const initialRef = React.useRef(null);
    const numberOfTags = tags?.length;

    const validId = (id: number) => id > 0;
    const tagIds = () => tags.map(({ id }) => id).filter((id) => validId(id));
    const validSearch = () => !!(search && search.length > 1);
    const missingIds = () => tags.some(({ id }) => !validId(id));

    const tagNameById = (id: number) => {
        return allTags.find((tag) => tag.id == id)?.name;
    };

    const tagDescriptionById = (id: number) => {
        return allTags.find((tag) => tag.id == id)?.description;
    };

    const open = () => {
        if (!isOpen) {
            setIsOpen(true);
        }
    };

    const close = () => {
        setIsOpen(false);
        setSearch(null);
        setFilteredTags(allTags);
    };

    const onCancel = () => {
        close();
        setTags(value);
        setOutOfSync.off();
    };

    const onSave = () => {
        close();
        if (outOfSync) {
            dispatch(actions.saveTags({ ids: tagIds(), refresh: false }));
            setOutOfSync.off();
            saveEdit(tags);
        }
    };

    const updateSelectedTags = (ids: string[]) => {
        const selectedTags = allTags.filter(({ id }) => ids.includes(id.toString()));
        setTags(selectedTags);
        setOutOfSync.on();
    };

    const onAddNewTag = () => {
        // Update state of filters
        const newTag: TagType = { id: random(-10000, -1), name: search! };

        // Update listing state
        setTags([...tags, newTag]);

        // Save tags
        dispatch(actions.saveTags({ name: search, ids: tagIds(), refresh: true })).then(() => {
            // Refresh filters
            refreshMeta();
        });

        // Close modal and reset states
        close();
    };

    React.useEffect(() => {
        if (!search || search?.length <= 1) {
            setFilteredTags(allTags);
        } else {
            setFilteredTags(allTags.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase())));
        }
    }, [search, allTags]);

    React.useEffect(() => {
        // If any are missing id, update tag with newly created tag id
        if (missingIds()) {
            const validTags = tags.map((tag) => {
                if (validId(tag.id)) {
                    return tag;
                } else {
                    return allTags.find(({ name }) => name == tag.name)!;
                }
            });
            setTags(validTags);
            saveEdit(validTags);
            setOutOfSync.off();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allTags]);

    return (
        <Box
            as={Button}
            variant="filter"
            borderColor="transparent"
            overflow="auto hidden"
            position="relative"
            w="full"
            px={0}
            py={1}
            display="block"
            height="100%"
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            onClick={open}>
            <Popover isOpen={isOpen} onClose={onSave} initialFocusRef={initialRef} isLazy={true} placement="right">
                <PopoverTrigger>
                    <Flex alignSelf="center" gap={1}>
                        {numberOfTags ? (
                            tags.map(({ id, name, description }) => {
                                return (
                                    <Tooltip key={id} label={description || tagDescriptionById(id)}>
                                        <Tag flexShrink={0} size="sm" borderRadius="sm" px={1} color="purple.700" bg="purple.100A" overflow="auto">
                                            <TagLabel>{name || tagNameById(id)}</TagLabel>
                                        </Tag>
                                    </Tooltip>
                                );
                            })
                        ) : (
                            // We're setting opacity equals 0 so that the tag is present, but not visible
                            <Tag size="sm" borderRadius="full" variant="subtle" colorScheme="purple" opacity={0}>
                                <TagLabel>No tags</TagLabel>
                            </Tag>
                        )}
                    </Flex>
                </PopoverTrigger>
                <Portal>
                    <PopoverContent>
                        <PopoverHeader pt={3}>
                            <Input
                                ref={initialRef}
                                placeholder={allTags?.length ? 'Change tags...' : 'Add your first tag...'}
                                variant="unstyled"
                                width="auto"
                                value={search || ''}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </PopoverHeader>
                        <PopoverCloseButton onClick={onCancel} />
                        {filteredTags ? (
                            <PopoverBody p={0}>
                                <VStack align="flex-start" spacing={0}>
                                    <Flex maxHeight="210px" overflow="auto" width="100%">
                                        <TagListCheckbox tags={tags} allTags={filteredTags} updateTags={updateSelectedTags} />
                                    </Flex>
                                    <AddNewTag filteredTags={filteredTags} search={search} validSearch={validSearch()} onClick={() => onAddNewTag()} />
                                </VStack>
                            </PopoverBody>
                        ) : null}
                    </PopoverContent>
                </Portal>
            </Popover>
        </Box>
    );
};

export default ListingTag;
