import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { dateFilterReducers, DateFilters } from 'libs/global/slices/shared/dateFilters';
import { SetListingItem } from 'libs/types/set';

export interface CompState {
    selectedIds: number[];
    removedIds: number[];
    selectedListings: any[];
    compareListings: SetListingItem[];
    setId: string | number | undefined;
    performanceView: string;
    dateFilters: DateFilters;
}

const defaultDateFilters = {
    period: '3 months',
    startDate: moment.utc().startOf('month').startOf('day'),
    endDate: moment.utc().add(2, 'months').endOf('month').startOf('day'),
    minDate: moment().subtract(3, 'months').startOf('month').startOf('day'),
    maxDate: moment().add(1, 'year').endOf('year').startOf('day'),
    ranges: ['3 months', '6 months', '1 year'],
    granularity: 'day',
};

const initialState = {
    selectedIds: [],
    removedIds: [],
    selectedListings: [],
    compareListings: [],
    setId: undefined,
    performanceView: 'revpar',
    dateFilters: defaultDateFilters,
} as CompState;

const compStateSlice = createSlice({
    name: 'compState',
    initialState,
    reducers: {
        ...dateFilterReducers,
        setPerformanceView: (state, action: PayloadAction<string>) => {
            state.performanceView = action.payload;
        },
        setSetId: (state, action: PayloadAction<string | number | undefined>) => {
            state.setId = action.payload;
        },
        setSelectedIds: (state, action: PayloadAction<number[]>) => {
            state.selectedIds = action.payload;
        },
        setCompareListings: (state, action: PayloadAction<SetListingItem[]>) => {
            state.compareListings = action.payload;
        },
    },
});

export default compStateSlice.reducer;
export const actions = compStateSlice.actions;
