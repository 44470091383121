import {
    AlignCenterHorizontalSimple,
    ArrowLineDown,
    ArrowLineUp,
    Bathtub,
    Bed,
    Calendar,
    CircleNotch,
    Coin,
    Coins,
    CoinVertical,
    CurrencyDollarSimple,
    Door,
    EyeClosed,
    GlobeSimple,
    GridFour,
    IdentificationBadge,
    Layout,
    Lightning,
    LockSimple,
    LockSimpleOpen,
    MagnifyingGlass,
    MapPin,
    Money,
    RocketLaunch,
    SquaresFour,
    Stack,
    Tag,
    TagSimple,
    TerminalWindow,
    TextT,
    ToggleLeft,
    ToggleRight,
    User,
    UserGear,
    Users,
    X,
} from 'phosphor-react';

import {
    HISTORICAL_METRIC_OPTIONS,
    HISTORICAL_PERIODS,
    METRIC_OPTIONS,
    metricOptions,
    PERIODS,
} from 'libs/global/components/Pro/settings-next/performance/listingStatColumnDefsConstants';
import { FilterCategoryTypes } from 'libs/types/meta';
import { map } from 'lodash';
import { CheckboxFilter, FilterSchema, FilterSchemaWithId, NumberFilter, ParentRadioFilter, RadioFilter, TagsFilter, TextFilter } from 'libs/types/filters';

export const categoryTitleByCategoryId = {
    listing_features: 'Listing Features',
    listing_status: 'Listing Status',
    future_metrics: 'Future Metrics',
    historical_metrics: 'Historical Metrics',
} as Record<FilterCategoryTypes, string>;

const textTypeProps = {
    type: 'text',
    valueProp: 'filter',
    comparator: {
        contains: 'contains',
        notContains: 'does not contain',
    },
    filterOptions: [
        { value: 'contains', label: 'contains' },
        { value: 'notContains', label: 'not contains' },
    ],
};

export const filterSchema: Record<string, FilterSchema> = {
    'listings.id': {
        type: 'checkbox',
        label: 'ID',
        property: 'listings.id',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        icon: IdentificationBadge,
        paramName: 'listings.id_in',
        categoryId: 'listing_features',
    } as CheckboxFilter,
    title: {
        name: 'name',
        paramKey: 'title',
        property: 'title',
        label: 'Display name',
        icon: TextT,
        paramName: 'listings.title',
        categoryId: 'listing_features',
        ...textTypeProps,
    } as TextFilter,
    display_nickname: {
        name: 'nickname',
        property: 'display_nickname',
        paramKey: 'listing_views.display_nickname',
        label: 'Nickname',
        icon: TextT,
        paramName: 'listing_views.display_nickname',
        categoryId: 'listing_features',
        ...textTypeProps,
    } as TextFilter,
    searchable: {
        name: 'Search',
        property: 'searchable',
        label: 'Search',
        icon: MagnifyingGlass,
        paramName: 'listing_views.searchable',
        categoryId: 'listing_features',
        ...textTypeProps,
    } as TextFilter,
    source_address: {
        name: 'address',
        paramKey: 'source_address',
        property: 'source_address',
        label: 'Address',
        icon: AlignCenterHorizontalSimple,
        paramName: 'source_address',
        categoryId: 'listing_features',
        ...textTypeProps,
    } as TextFilter,
    currency: {
        type: 'checkbox',
        label: 'Currency',
        property: 'currency',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        icon: CurrencyDollarSimple,
        paramName: 'listings.currency',
        categoryId: 'listing_features',
    } as CheckboxFilter,
    tags: {
        name: 'tags',
        type: 'checkbox',
        paramKey: 'tags',
        property: 'tags',
        comparator: {
            single: {
                or: 'include',
                not: 'do not include',
            },
            multiple: {
                or: 'includes any of',
                and: 'includes all of',
                not: 'exclude if any of',
                andNot: 'exclude if all of',
            },
        },
        label: 'Tag',
        options: [],
        icon: Tag,
        isAcronym: false,
        isInteger: false,
        isEmptyMessage: 'There are no tags associated with your listings.',
        paramName: 'tags.id',
        categoryId: 'listing_features',
    } as TagsFilter,
    access_level: {
        type: 'radio',
        property: 'access_level',
        label: 'Your Access',
        comparator: {
            single: 'is',
        },
        options: [
            {
                name: 'Admin',
                value: 'admin',
            },
            {
                name: 'Editor',
                value: 'editor',
            },
            {
                name: 'Viewer',
                value: 'viewer',
            },
        ],
        icon: UserGear,
        paramName: 'listings.operating_user_id',
        categoryId: 'listing_status',
    } as RadioFilter,
    sub_users: {
        name: 'sub_users',
        type: 'checkbox',
        paramKey: 'sub_users',
        property: 'sub_users',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        label: 'Team Member',
        options: [],
        icon: Users,
        isAcronym: true,
        isInteger: true,
        isEmptyMessage: 'There are no team members associated with your account.',
        paramName: 'users.id',
        categoryId: 'listing_status',
    } as CheckboxFilter,
    'listings.operating_user_id': {
        name: 'listings.operating_user_id',
        type: 'checkbox',
        paramKey: 'listings.operating_user_id',
        property: 'listings.operating_user_id',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        label: 'Admin of Listing',
        options: [],
        icon: User,
        isAcronym: true,
        isInteger: true,
        paramName: 'listings.operating_user_id',
        categoryId: 'listing_status',
    } as CheckboxFilter,
    clean_room_type: {
        name: 'room type',
        type: 'checkbox',
        property: 'clean_room_type',
        paramKey: 'clean_room_type',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        label: 'Room type',
        options: [],
        icon: Layout,
        paramName: 'clean_room_type',
        categoryId: 'listing_features',
    } as CheckboxFilter,
    source: {
        type: 'checkbox',
        paramKey: 'source',
        property: 'source',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        label: 'Channel',
        options: [],
        icon: SquaresFour,
        isInteger: false,
        isAcronym: false,
        paramName: 'source',
        categoryId: 'listing_status',
    } as CheckboxFilter,
    'pricing_tier.name': {
        type: 'checkbox',
        property: 'pricing_tier.name',
        paramKey: 'pricing_tiers.name',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        label: 'Pricing Tier',
        options: [],
        icon: Stack,
        isAcronym: false,
        isInteger: false,
        paramName: 'pricing_tiers.name',
        categoryId: 'listing_status',
    } as CheckboxFilter,
    'channel_accounts.status': {
        type: 'checkbox',
        property: 'channel_accounts.status',
        paramKey: 'channel_accounts.status',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        label: 'Account Status',
        icon: TagSimple,
        options: [],
        isAcronym: true,
        paramName: 'channel_accounts.status',
        categoryId: 'listing_status',
    } as CheckboxFilter,
    'market.name': {
        type: 'checkbox',
        property: 'market.name',
        paramKey: 'market.name',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        label: 'Market',
        icon: MapPin,
        options: [],
        isAcronym: false,
        isInteger: false,
        paramName: 'market_id',
        categoryId: 'listing_status',
    } as CheckboxFilter,
    number_of_units: {
        type: 'checkbox',
        label: 'Number of Units',
        property: 'number_of_units',
        paramKey: 'number_of_units',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        icon: Door,
        paramName: 'number_of_units',
        categoryId: 'listing_features',
    } as CheckboxFilter,
    bedrooms: {
        type: 'checkbox',
        label: 'Bedrooms',
        paramKey: 'bedrooms',
        property: 'bedrooms',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        icon: GridFour,
        paramName: 'bedrooms',
        categoryId: 'listing_features',
    } as CheckboxFilter,
    bathrooms: {
        type: 'checkbox',
        label: 'Bathrooms',
        property: 'bathrooms',
        paramKey: 'bathrooms',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        icon: Bathtub,
        paramName: 'bathrooms',
        categoryId: 'listing_features',
    } as CheckboxFilter,
    sleeps: {
        type: 'checkbox',
        label: 'Sleeps',
        property: 'sleeps',
        paramKey: 'sleeps',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        icon: EyeClosed,
        paramName: 'sleeps',
        categoryId: 'listing_features',
    } as CheckboxFilter,
    beds: {
        type: 'checkbox',
        label: 'Beds',
        property: 'beds',
        paramKey: 'beds',
        comparator: {
            single: 'is',
            multiple: 'is one of',
        },
        options: [],
        min: 1,
        max: 14,
        icon: Bed,
        paramName: 'beds',
        categoryId: 'listing_features',
    } as CheckboxFilter,
    'listing_preference.automatic_rate_posting_enabled': {
        type: 'radio',
        label: 'Automation',
        property: 'listing_preference.automatic_rate_posting_enabled',
        paramKey: 'listing_preferences.automatic_rate_posting_enabled',
        comparator: {
            single: 'is',
            multiple: 'is',
        },
        options: [
            {
                name: 'Active',
                value: 'on',
            },
            {
                name: 'Inactive',
                value: 'off',
            },
        ],
        icon: Lightning,
        paramName: 'listing_preferences.automatic_rate_posting_enabled',
        paramValue: 'on',
        categoryId: 'listing_status',
    } as RadioFilter,
    'listing_views.in_market': {
        type: 'radio',
        label: 'Market Status',
        property: 'listing_views.in_market',
        comparator: {
            single: 'is',
            multiple: 'is',
        },
        options: [
            {
                name: 'Active',
                value: 'active',
            },
            {
                name: 'Inactive',
                value: 'inactive',
            },
        ],
        icon: GlobeSimple,
        paramName: 'listing_views.in_market',
        paramValue: 'active',
        categoryId: 'listing_status',
    } as RadioFilter,
    is_active: {
        type: 'radio',
        property: 'is_active',
        paramKey: 'listings.is_active',
        label: 'Listing Status',
        comparator: {
            single: 'is',
            multiple: 'is',
        },
        options: [
            {
                name: 'Active',
                value: 'active',
            },
            {
                name: 'Inactive',
                value: 'inactive',
            },
        ],
        icon: ToggleRight,
        paramName: 'listings.is_active',
        paramValue: 'active',
        categoryId: 'listing_status',
    } as RadioFilter,
    removed_by_owner: {
        type: 'radio',
        label: 'Listing Hidden',
        property: 'removed_by_owner',
        paramKey: 'listings.user_removed',
        comparator: {
            single: 'is',
            multiple: 'is',
        },
        options: [
            {
                name: 'Hidden',
                value: 'hidden',
            },
            {
                name: 'Not hidden',
                value: 'not hidden',
            },
        ],
        icon: ToggleLeft,
        paramName: 'user_removed',
        paramValue: 'hidden',
        categoryId: 'listing_status',
    } as RadioFilter,
    is_hypothetical: {
        type: 'radio',
        label: 'Hypothetical',
        paramKey: 'is_hypothetical',
        property: 'is_hypothetical',
        comparator: {
            single: 'is',
            multiple: 'is',
        },
        options: [
            {
                name: 'Show',
                value: 'true',
            },
            {
                name: 'Hide',
                value: 'false',
            },
        ],
        icon: TerminalWindow,
        paramName: 'listing_views.non_test_hypothetical',
        paramValue: 'true',
        categoryId: 'listing_status',
    } as RadioFilter,
    'stat.last_booked_at': {
        type: 'number',
        label: 'Days since last booking',
        property: 'stat.last_booked_at',
        paramKey: 'listing_stats.last_booked_at',
        icon: Calendar,
        paramName: 'listing_stats.last_booked_at',
        categoryId: 'historical_metrics',
    } as NumberFilter,
    revenue: {
        type: 'radio',
        label: 'Revenue',
        paramKey: (p: number | string) => `listing_stats.revenue_0_${p}`,
        options: METRIC_OPTIONS,
        icon: Money,
        categoryId: 'future_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Revenue next ${p} days`,
            shortLabel: `next ${p} days`,
            property: `stat.revenue.0_${p}`,
            paramKey: `listing_stats.revenue_0_${p}`,
            icon: Money,
            paramName: `listing_stats.revenue_0_${p}`,
            categoryId: 'future_metrics',
        })),
    } as ParentRadioFilter,
    revenue_historical: {
        type: 'radio',
        label: 'Revenue',
        paramKey: (p: number | string) => `listing_stats.revenue_${p}_0`,
        options: METRIC_OPTIONS,
        icon: Money,
        categoryId: 'historical_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Revenue last ${p} days`,
            shortLabel: `last ${p} days`,
            property: `stat.revenue.${p}_0`,
            paramKey: `listing_stats.revenue_${p}_0`,
            icon: Money,
            paramName: `listing_stats.revenue_${p}_0`,
            categoryId: 'historical_metrics',
        })),
    } as ParentRadioFilter,
    apr: {
        type: 'radio',
        label: 'Asking Rate',
        paramKey: (p: number | string) => `listing_stats.apr_0_${p}`,
        options: METRIC_OPTIONS,
        icon: CoinVertical,
        categoryId: 'future_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Asking Rate next ${p} days`,
            shortLabel: `next ${p} days`,
            property: `stat.apr.0_${p}`,
            paramKey: `listing_stats.apr_0_${p}`,
            icon: CoinVertical,
            paramName: `listing_stats.apr_0_${p}`,
            categoryId: 'future_metrics',
        })),
    } as ParentRadioFilter,
    apr_historical: {
        type: 'radio',
        label: 'Asking Rate',
        paramKey: (p: number | string) => `listing_stats.apr_${p}_0`,
        options: METRIC_OPTIONS,
        icon: CoinVertical,
        categoryId: 'historical_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Asking Rate last ${p} days`,
            shortLabel: `last ${p} days`,
            property: `stat.apr.${p}_0`,
            paramKey: `listing_stats.apr_${p}_0`,
            icon: CoinVertical,
            paramName: `listing_stats.apr_${p}_0`,
            categoryId: 'historical_metrics',
        })),
    } as ParentRadioFilter,
    adjusted_occupancy: {
        type: 'radio',
        label: 'Adj. Occupancy',
        paramKey: (p: number | string) => `listing_stats.open_occupancy_0_${p}`,
        options: METRIC_OPTIONS,
        icon: CircleNotch,
        categoryId: 'future_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Adj. Occupancy next ${p} days`,
            shortLabel: `next ${p} days`,
            property: `stat.occupancy.0_${p}`,
            paramKey: `listing_stats.open_occupancy_0_${p}`,
            icon: CircleNotch,
            rightAddon: '%',
            paramName: `listing_stats.open_occupancy_0_${p}`,
            isPercent: true,
            categoryId: 'future_metrics',
        })),
    } as ParentRadioFilter,
    adjusted_occupancy_historical: {
        type: 'radio',
        label: 'Adj. Occupancy',
        paramKey: (p: number | string) => `listing_stats.open_occupancy_${p}_0`,
        options: METRIC_OPTIONS,
        icon: CircleNotch,
        categoryId: 'historical_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Adj. Occupancy last ${p} days`,
            shortLabel: `last ${p} days`,
            property: `stat.occupancy.${p}_0`,
            paramKey: `listing_stats.open_occupancy_${p}_0`,
            icon: CircleNotch,
            rightAddon: '%',
            paramName: `listing_stats.open_occupancy_${p}_0`,
            isPercent: true,
            categoryId: 'historical_metrics',
        })),
    } as ParentRadioFilter,
    adr: {
        type: 'radio',
        label: 'Avg. Daily Rate',
        paramKey: (p: number | string) => `listing_stats.adr_0_${p}`,
        options: METRIC_OPTIONS,
        icon: Coins,
        categoryId: 'future_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Avg. Daily Rate next ${p} days`,
            shortLabel: `next ${p} days`,
            property: `stat.adr.0_${p}`,
            paramKey: `listing_stats.adr_0_${p}`,
            icon: Coins,
            paramName: `listing_stats.adr_0_${p}`,
            categoryId: 'future_metrics',
        })),
    } as ParentRadioFilter,
    adr_historical: {
        type: 'radio',
        label: 'Avg. Daily Rate',
        paramKey: (p: number | string) => `listing_stats.adr_${p}_0`,
        options: METRIC_OPTIONS,
        icon: Coins,
        categoryId: 'historical_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Avg. Daily Rate last ${p} days`,
            shortLabel: `last ${p} days`,
            property: `stat.adr.${p}_0`,
            paramKey: `listing_stats.adr_${p}_0`,
            icon: Coins,
            paramName: `listing_stats.adr_${p}_0`,
            categoryId: 'historical_metrics',
        })),
    } as ParentRadioFilter,
    revpar: {
        type: 'radio',
        label: 'Adj. RevPAR',
        paramKey: (p: number | string) => `listing_stats.revpar_non_blocked_0_${p}`,
        options: METRIC_OPTIONS,
        icon: Coin,
        categoryId: 'future_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Adj. RevPAR next ${p} days`,
            shortLabel: `next ${p} days`,
            property: `stat.revpar.0_${p}`,
            paramKey: `listing_stats.revpar_non_blocked_0_${p}`,
            icon: Coin,
            paramName: `listing_stats.revpar_non_blocked_0_${p}`,
            categoryId: 'future_metrics',
        })),
    } as ParentRadioFilter,
    revpar_historical: {
        type: 'radio',
        label: 'Adj. RevPAR',
        paramKey: (p: number | string) => `listing_stats.revpar_non_blocked_${p}_0`,
        options: METRIC_OPTIONS,
        icon: Coin,
        categoryId: 'historical_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Adj. RevPAR last ${p} days`,
            shortLabel: `last ${p} days`,
            property: `stat.revpar.${p}_0`,
            paramKey: `listing_stats.revpar_non_blocked_${p}_0`,
            icon: Coin,
            paramName: `listing_stats.revpar_non_blocked_${p}_0`,
            categoryId: 'historical_metrics',
        })),
    } as ParentRadioFilter,
    available_nights: {
        type: 'radio',
        label: 'Available Nights',
        paramKey: (p: number | string) => `listing_stats.available_nights_0_${p}`,
        options: METRIC_OPTIONS,
        icon: LockSimpleOpen,
        categoryId: 'future_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Available nights next ${p} days`,
            shortLabel: `next ${p} days`,
            property: `stat.available_nights.0_${p}`,
            paramKey: `listing_stats.available_nights_0_${p}`,
            icon: LockSimpleOpen,
            paramName: `listing_stats.available_nights_0_${p}`,
            categoryId: 'future_metrics',
        })),
    } as ParentRadioFilter,
    blocked_nights: {
        type: 'radio',
        label: 'Blocked Nights',
        paramKey: (p: number | string) => `listing_stats.blocked_nights_0_${p}`,
        options: METRIC_OPTIONS,
        icon: LockSimple,
        categoryId: 'future_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Blocked nights next ${p} days`,
            shortLabel: `next ${p} days`,
            property: `stat.blocked_nights.0_${p}`,
            paramKey: `listing_stats.blocked_nights_0_${p}`,
            icon: LockSimple,
            paramName: `listing_stats.blocked_nights_0_${p}`,
            categoryId: 'future_metrics',
        })),
    } as ParentRadioFilter,
    booked_in: {
        type: 'radio',
        label: 'Booked nights in',
        tooltipLabel: 'Number of nights booked in last x days',
        paramKey: (p: number | string) => `listing_stats.booked_in_${p}_0`,
        options: HISTORICAL_METRIC_OPTIONS,
        icon: RocketLaunch,
        categoryId: 'historical_metrics',
        children: HISTORICAL_PERIODS.map((p) => ({
            type: 'number',
            label: `Booked nights in last ${p} days`,
            shortLabel: `last ${p} days`,
            property: `stat.booked_in.${p}_0`,
            paramKey: `listing_stats.booked_in_${p}_0`,
            icon: RocketLaunch,
            paramName: `listing_stats.booked_in_${p}_0`,
            categoryId: 'historical_metrics',
        })),
    } as ParentRadioFilter,
    min_price: {
        type: 'radio',
        label: 'Min. Price',
        paramKey: (p: number | string) => `listing_stats.min_price_${p}_0`,
        options: metricOptions([365]),
        icon: ArrowLineDown,
        categoryId: 'historical_metrics',
        children: [365].map((p) => ({
            type: 'number',
            label: `Minimum price last ${p} days`,
            shortLabel: `last ${p} days`,
            property: `stat.min_price.${p}_0`,
            paramKey: `listing_stats.min_price_${p}_0`,
            icon: ArrowLineDown,
            paramName: `listing_stats.min_price_${p}_0`,
            categoryId: 'historical_metrics',
        })),
    } as ParentRadioFilter,
    max_price: {
        type: 'radio',
        label: 'Max. Price',
        paramKey: (p: number | string) => `listing_stats.max_price_${p}_0`,
        options: metricOptions([365]),
        icon: ArrowLineUp,
        categoryId: 'historical_metrics',
        children: [365].map((p) => ({
            type: 'number',
            label: `Maximum price last ${p} days`,
            shortLabel: `last ${p} days`,
            property: `stat.max_price.${p}_0`,
            paramKey: `listing_stats.max_price_${p}_0`,
            icon: ArrowLineUp,
            paramName: `listing_stats.max_price_${p}_0`,
            categoryId: 'historical_metrics',
        })),
    } as ParentRadioFilter,
    min_price_occurrence: {
        type: 'radio',
        label: 'Min. Price Occurrence',
        paramKey: (p: number | string) => `listing_stats.min_price_occurrence_0_${p}`,
        options: METRIC_OPTIONS,
        icon: X,
        categoryId: 'future_metrics',
        children: PERIODS.map((p) => ({
            type: 'number',
            label: `Occurences of minimum price next ${p} days`,
            shortLabel: `next ${p} days`,
            property: `stat.min_price_occurrence.0_${p}`,
            paramKey: `listing_stats.min_price_occurrence_0_${p}`,
            icon: X,
            leftAddon: '#',
            paramName: `listing_stats.min_price_occurrence_0_${p}`,
            categoryId: 'future_metrics',
        })),
    } as ParentRadioFilter,
};

export const schemaToArray = (schema: Record<string, FilterSchema>) => {
    return map(schema, (filter, filterKey) => ({ ...filter, id: filterKey })) as FilterSchemaWithId[];
};
