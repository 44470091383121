import { isEmpty } from 'lodash';
import { CalendarRule, PositiveCalendarRule, Settings } from 'libs/types/settings';
import { PERSONALIZATION_TYPES } from 'libs/constants';

export const formatCalendarRulesForApi = <Type extends CalendarRule = CalendarRule>(rules: Type[], allowZero?: boolean, setting?: string): Type[] => {
    return rules
        ?.filter((r) => {
            // for bulk min/max price adjustments we allow for negative and positive numbers.
            if (r.adjustment_type) {
                return Number.isFinite(r?.value);
            }

            if (r.day_of_week_values) {
                if (r.months && !r.months.some((v) => Number.isFinite(v))) {
                    return false;
                }
                return r.day_of_week_values.some((v) => Number.isFinite(v));
            }

            if (r.months && !r.months.some((v) => Number.isFinite(v))) {
                return false;
            }

            if (allowZero) {
                return Number.isFinite(r?.value) && (r.value as number) >= 0;
            }
            return Number.isFinite(r?.value) && (r.value as number) > 0;
        })
        .map((r) => {
            const r_cp = { ...r };
            if (Number.isFinite(r_cp?.value)) {
                if (setting != 'last_minute_discount' && setting != 'far_future_premium') {
                    r_cp.value = Math.round(r_cp.value as number);
                }
            }
            if (r_cp.day_of_week_values) {
                if (setting != 'last_minute_discount' && setting != 'far_future_premium') {
                    r_cp.day_of_week_values = r_cp.day_of_week_values.map((v) => (Number.isFinite(v) ? Math.round(v as number) : v));
                }
                r_cp.value = undefined;
            }

            if (r_cp.hasOwnProperty('months') && r_cp.months == null) {
                r_cp.months = undefined;
            }

            if (r_cp.hasOwnProperty('day_of_week_values') && r_cp.day_of_week_values == null) {
                r_cp.day_of_week_values = undefined;
            }
            return r_cp;
        });
};

export const formatSettingsForApi = (settings: Partial<Settings>) => {
    if (settings?.hasOwnProperty('base_price') || settings?.hasOwnProperty('base_price_adjustment')) {
        if (settings?.base_price_adjustment) {
            settings = {
                ...settings,
                base_price: null,
            };
        } else if (settings?.base_price) {
            settings = {
                ...settings,
                base_price_adjustment: null,
            };
        }
    }

    if (settings?.seasonality_adjustment?.custom) {
        const custom = settings?.seasonality_adjustment?.custom;
        settings = {
            ...settings,
            seasonality_adjustment: {
                ...settings.seasonality_adjustment,
                custom: custom.map((factor: number) => {
                    return Number.isFinite(factor) ? factor : 1.0;
                }),
            },
        };
    }

    if (settings.last_minute_discount) {
        if (settings.last_minute_discount.rules) {
            // Don't include empty settings.
            settings = {
                ...settings,
                last_minute_discount: {
                    ...settings.last_minute_discount,
                    rules: settings.last_minute_discount.rules
                        ? settings.last_minute_discount.rules
                              .filter((row: CalendarRule) => {
                                  return row.days_before && (Number.isFinite(row.value) || row?.day_of_week_values?.some((v) => Number.isFinite(v)));
                              })
                              .map((row: CalendarRule) => {
                                  if (!row.day_of_week_values) {
                                      return { ...row, day_of_week_values: undefined };
                                  }
                                  const { day_of_week_values, ...rest } = row;
                                  if (isEmpty(day_of_week_values)) {
                                      return rest;
                                  }
                                  if (day_of_week_values.every((v) => !Number.isFinite(v))) {
                                      return rest;
                                  }

                                  return {
                                      ...rest,
                                      value: undefined,
                                      day_of_week_values: day_of_week_values.map((v) => (Number.isFinite(v) ? v : 1.0)),
                                  };
                              })
                              .sort((a: CalendarRule, b: CalendarRule) => a.days_before! - b.days_before!)
                        : [],
                },
            };
        }
    }

    if (settings.far_future_premium) {
        if (settings.far_future_premium.rules) {
            // Don't include empty settings.
            settings = {
                ...settings,
                far_future_premium: {
                    ...settings.far_future_premium,
                    rules: settings.far_future_premium.rules
                        ? settings.far_future_premium.rules
                              .filter((row: CalendarRule) => {
                                  return row.days_after && (Number.isFinite(row.value) || row?.day_of_week_values?.some((v) => Number.isFinite(v)));
                              })
                              .map((row: CalendarRule) => {
                                  if (!row.day_of_week_values) {
                                      return { ...row, day_of_week_values: undefined };
                                  }
                                  const { day_of_week_values, ...rest } = row;
                                  if (isEmpty(day_of_week_values)) {
                                      return rest;
                                  }
                                  if (day_of_week_values.every((v) => !Number.isFinite(v))) {
                                      return rest;
                                  }

                                  return {
                                      ...rest,
                                      value: undefined,
                                      day_of_week_values: day_of_week_values.map((v) => (Number.isFinite(v) ? v : 1.0)),
                                  };
                              })
                              .sort((a: CalendarRule, b: CalendarRule) => a.days_after! - b.days_after!)
                        : [],
                },
            };
        }
    }

    if (settings.weekend_factor) {
        if (settings.weekend_factor.type != PERSONALIZATION_TYPES.CUSTOM_TYPE) {
            // Don't include custom.
            settings = {
                ...settings,
                weekend_factor: {
                    type: settings.weekend_factor.type,
                },
            };
        }
    }

    if (settings.gap_night) {
        if (settings.gap_night.type != PERSONALIZATION_TYPES.CUSTOM_TYPE) {
            // Don't include custom.
            settings = {
                ...settings,
                gap_night: {
                    type: settings.gap_night.type,
                },
            };
        } else {
            settings = {
                ...settings,
                gap_night: {
                    type: settings.gap_night.type,
                    custom: settings.gap_night?.custom?.filter((r) => r?.gap > 0 && Number.isFinite(r?.adjustment)),
                },
            };
        }
    }

    if (settings.demand_sensitivity_rules) {
        settings = {
            ...settings,
            demand_sensitivity_rules: formatCalendarRulesForApi(settings.demand_sensitivity_rules, true),
        };
    }

    if (settings.historical_anchoring_rules) {
        settings = {
            ...settings,
            historical_anchoring_rules: formatCalendarRulesForApi(settings.historical_anchoring_rules, true),
        };
    }

    if (settings.minimum_stay_rules_v3) {
        settings = {
            ...settings,
            minimum_stay_rules_v3: formatCalendarRulesForApi<PositiveCalendarRule>(settings.minimum_stay_rules_v3, false),
        };
    }

    if (settings.minimum_price_rules_v3) {
        settings = {
            ...settings,
            minimum_price_rules_v3: formatCalendarRulesForApi<PositiveCalendarRule>(settings.minimum_price_rules_v3, false),
        };
    }

    if (settings.maximum_price_rules_v3) {
        settings = {
            ...settings,
            maximum_price_rules_v3: formatCalendarRulesForApi<PositiveCalendarRule>(settings.maximum_price_rules_v3, false),
        };
    }

    return settings;
};
