'use client';

import React, { useEffect, useState } from 'react';
import { ajaxUpdate } from 'libs/global/reducers/ajaxUpdate';
import fetcher from 'libs/utils/fetcher';
import { userData } from 'libs/routes';
import { useStore } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useChannels } from '../hooks/useChannels';
import { usePricingTiers } from '../hooks/usePricingTiers';

const userFetching = {
    userFetched: false,
};

export const Context = React.createContext(userFetching);

export const RequiredDataFetching = ({ children }) => {
    const [userFetched, setUserFetched] = useState(false);
    const { data } = useQuery({
        queryKey: [userData()],
        queryFn: () => fetcher(userData()),
        refetchOnReconnect: false,
    });
    const channels = useChannels();
    const pricingTiers = usePricingTiers();

    const store = useStore();
    useEffect(() => {
        if (data && channels.length && pricingTiers.length) {
            store.dispatch(ajaxUpdate(data));
            // Update fetch status after dispatch is finished.
            window.setTimeout(() => setUserFetched(true), 0);
        }
    }, [data, channels, pricingTiers, store]);

    return <Context.Provider value={{ userFetched }}>{children}</Context.Provider>;
};
