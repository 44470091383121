import React, { useState } from 'react';
import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Stack,
    Textarea,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { closeModal, fetchSetById } from 'libs/global/slices/sets';
import { CompSet } from 'libs/types/set';
import { useAppDispatch } from 'libs/global/hooks';
import { useUpdateSetDetails } from 'libs/global/hooks/useSets';
import { SET_KINDS } from '../constants';
import { upperFirst } from 'lodash';

export const DetailsModalId = 'details';

interface Props {
    isOpen: boolean;
    set?: CompSet;
}

const DetailsModal = ({ isOpen, set }: Props) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const { query } = router;

    const { mutateAsync: updateDetails, isPending: isSaving } = useUpdateSetDetails();
    const [name, setName] = useState(set?.name || '');
    const [kind, setKind] = useState(set?.kind || 'other');
    const [description, setDescription] = useState(set?.description);

    if (!set) {
        return null;
    }

    const resultAction = () => {
        updateDetails({
            id: set?.id,
            details: {
                name,
                description,
                kind,
            },
        }).then(() => {
            dispatch(closeModal());
            // This is to deal with the redux state when in setup view
            if (query?.uid?.[0]) {
                dispatch(fetchSetById(query?.uid?.[0]));
            }
        });
    };

    return (
        <Modal motionPreset="slideInBottom" isOpen={isOpen} onClose={() => dispatch(closeModal())} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader color="gray.800">Edit set</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={6}>
                        <FormControl isRequired={true}>
                            <FormLabel mb={1} color="gray.800" fontWeight="medium">
                                Name
                            </FormLabel>
                            <Input
                                isDisabled={isSaving}
                                fontSize="md"
                                size="lg"
                                borderColor="gray.100"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <FormHelperText color="gray.400" mt={1.5}>
                                The more specific to your use case the better!
                            </FormHelperText>
                        </FormControl>
                        <FormControl id="kind">
                            <FormLabel mb={1} color="gray.800" fontWeight="medium">
                                Category
                            </FormLabel>
                            <Select value={kind} borderColor="gray.100" onChange={(e) => setKind(e.target.value)}>
                                {SET_KINDS.map((kind) => {
                                    return (
                                        <option key={kind} value={kind}>
                                            {upperFirst(kind)}
                                        </option>
                                    );
                                })}
                            </Select>
                            <FormHelperText color="gray.400" mt={1.5}>
                                Select one of our pre-defined categories.
                            </FormHelperText>
                        </FormControl>
                        <FormControl id="description">
                            <FormLabel mb={1} color="gray.800" fontWeight="medium">
                                Description
                            </FormLabel>

                            <Textarea
                                isDisabled={isSaving}
                                rows={2}
                                borderColor="gray.100"
                                resize="none"
                                value={description || ''}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <FormHelperText color="gray.400" mt={1.5}>
                                Write a brief description
                            </FormHelperText>
                        </FormControl>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button isDisabled={isSaving} variant="secondary" mr={3} onClick={() => dispatch(closeModal())} borderColor="gray.100A">
                        Close
                    </Button>
                    <Button isLoading={isSaving} variant="dark" onClick={resultAction}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DetailsModal;
