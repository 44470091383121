import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import type { TypedStartListening } from '@reduxjs/toolkit';
import {
    addFilter,
    addPotentialListings,
    clearFilters,
    fetchListingsButtonClicked,
    removeFilter,
    resetLocalStorage,
    setListingLoading,
    setPostalCodeLocation,
    setViewPort,
} from './slices/sets';
import { fetchListings, fetchLocation, multipleFetchListings, saveFilters, transformFilters } from './components/CompNext/utils';
import { selectDatasourceFilter, selectLatitudeLongitude, selectViewport } from './selectors/sets';
import type { AppDispatch, RootState } from './store';
import { SETS_DRAFT_KEY, SOURCE } from './components/CompNext/constants';

export const setsListenerMiddleware = createListenerMiddleware();

type AppStartListening = TypedStartListening<RootState, AppDispatch>;

const startAppListening = setsListenerMiddleware.startListening as AppStartListening;

startAppListening({
    actionCreator: resetLocalStorage,
    effect: async (action, listenerApi) => {
        localStorage.removeItem(SETS_DRAFT_KEY);
    },
});

// If no autoSearch, fetch listings when user clicks button
startAppListening({
    actionCreator: fetchListingsButtonClicked,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(setListingLoading(true));
        const state = listenerApi.getState();
        const user_id = state?.user.id;
        const search_limit = state?.sets?.search_limit;

        const viewport = selectViewport(listenerApi.getState());

        if (!viewport) {
            return;
        }

        const previousLocation = state.sets.postalCodeLocation;
        const coordinates = selectLatitudeLongitude(listenerApi.getState());
        const location = await fetchLocation({
            latitude: coordinates?.latitude,
            longitude: coordinates?.longitude,
        });

        if (previousLocation !== location) {
            listenerApi.dispatch(setPostalCodeLocation(null));
        }
        const data = await fetchListings('viewport', viewport, {
            ...(search_limit ? { limit: search_limit, user_id } : { user_id }),
        });

        if (data) {
            listenerApi.dispatch(addPotentialListings(data));
        }
        listenerApi.dispatch(setPostalCodeLocation(location));
    },
});

startAppListening({
    actionCreator: setViewPort,
    effect: async (action, listenerApi) => {
        const { sets, user } = listenerApi.getState();
        const { autoSearch, initialFetch, boundaryType, search_limit } = sets;
        const user_id = user?.id;

        const filter = selectDatasourceFilter(listenerApi.getState(), 'potential-listing-points');
        const shouldNotFetch = (initialFetch === true && autoSearch === false) || !!boundaryType || filter;

        if (shouldNotFetch) {
            return;
        }

        const viewport = selectViewport(listenerApi.getState());
        listenerApi.dispatch(setPostalCodeLocation(null));
        listenerApi.dispatch(setListingLoading(true));

        const data = await fetchListings('viewport', viewport, {
            ...(search_limit ? { limit: search_limit, user_id } : { user_id }),
        });
        const coordinates = selectLatitudeLongitude(listenerApi.getState());
        const location = await fetchLocation({
            latitude: coordinates?.latitude,
            longitude: coordinates?.longitude,
        });

        if (data) {
            listenerApi.dispatch(addPotentialListings(data));
        }
        listenerApi.dispatch(setPostalCodeLocation(location));
    },
});

// middleware that listens for the addSpatialFilter action and then fetches listings
startAppListening({
    predicate: (action, currentState: RootState, previousState: RootState) => {
        return (
            currentState.sets.dataSources?.['potential-listing-points']?.spatialFilter !==
            previousState.sets.dataSources?.['potential-listing-points']?.spatialFilter
        );
    },
    effect: async (action, listenerApi) => {
        const { sets, user } = listenerApi.getState();
        const { search_limit } = sets;
        const user_id = user?.id;
        const filter = (action?.payload as any)?.filter?.geometry?.coordinates;
        const viewport = selectViewport(listenerApi.getState());
        listenerApi.dispatch(setPostalCodeLocation(null));
        listenerApi.dispatch(setListingLoading(true));

        // Don't try to fetch if there's no coordinates
        if (!filter && !viewport) {
            return;
        }

        const data = await multipleFetchListings(viewport, filter, {
            ...(search_limit ? { limit: search_limit, user_id } : { user_id }),
        });
        const coordinates = selectLatitudeLongitude(listenerApi.getState());

        const location = await fetchLocation({
            latitude: coordinates?.latitude,
            longitude: coordinates?.longitude,
        });

        // We let the addCase reducer handle a few more things
        if (data) {
            listenerApi.dispatch(addPotentialListings(data));
        }
        listenerApi.dispatch(setPostalCodeLocation(location));
    },
});

startAppListening({
    matcher: isAnyOf(addFilter, clearFilters, removeFilter),
    effect: async (action, listenerApi) => {
        const { sets, logged_in_as } = listenerApi.getState();
        const { id, dataSources } = sets;
        const filters = dataSources?.[SOURCE.POTENTIAL_LISTINGS_SOURCE]?.filters;
        const transformedFilters = transformFilters(filters);
        const is_admin = logged_in_as?.is_admin;

        if (!id || !is_admin) {
            return;
        }

        await saveFilters(id, { filters: transformedFilters });
    },
});
