import { createSlice } from '@reduxjs/toolkit';
import { defaultListingFilters, listingFilterReducers } from './shared/listingFilters';

const initialState = {
    filters: defaultListingFilters,
};
const subscriptionsUiSlice = createSlice({
    name: 'subscriptionsUi',
    initialState,
    reducers: {
        ...listingFilterReducers,
    },
});

export const { updateStaticSchema, addFilters, updateFilter, clearFilters, restoreFilters, removeFilter } = subscriptionsUiSlice.actions;
export default subscriptionsUiSlice.reducer;
