import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put } from 'libs/requests';
import { updateUserFlagsAPI } from 'libs/routes';

export const updateUserFlags = createAsyncThunk('userFlags/updateUserFlags', async (flags: object, _thunkAPI) => {
    const response = await put(updateUserFlagsAPI(), flags);
    return response.data;
});

const initialState: Record<string, any> = {};

const userFlagSlice = createSlice({
    name: 'user_flags',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(updateUserFlags.fulfilled, (_state, action: PayloadAction<object>) => {
            return action.payload;
        });
    },
});

export default userFlagSlice.reducer;
