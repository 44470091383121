import { flatten } from 'lodash';
import listingStatsColumns from './performance/listingStatColumnDefs';
import CustomTagHeader from './BulkListingTag';
import NameHeader from './NameHeader';
import CheckBoxSelection from './CheckBoxSelection';
import accessColumnDefs from './accessColumnDefs';
import { CellStyle, ColDef, ICellRendererParams, ProcessCellForExportParams } from 'ag-grid-community';
import moment from 'moment';
import { FilterCategoryTypes } from 'libs/types/meta';
import { ActiveFilter } from 'libs/types/filters';
import { PortfolioListingRow, PortfolioTableContext } from 'libs/types/portfolioTable';
import { actions } from 'libs/global/slices/proState';

export const marketStringToMarketId = (market: string) => {
    const regExp = /\(([^)]+)\)/g;
    let arr: RegExpExecArray | null, id: string | undefined;
    while ((arr = regExp.exec(market)) !== null) {
        [, id] = arr;
    }
    return id;
};

export function accountStatusRenderer(
    params: ProcessCellForExportParams<PortfolioListingRow, PortfolioTableContext> | ICellRendererParams<PortfolioListingRow, any, PortfolioTableContext>,
) {
    const dict = {
        A: 'Active',
        E: 'Empty',
        F: 'Failed',
        D: 'Disabled',
        P: 'Pending',
        O: 'Invalid Auth',
    };
    return dict[params.value] || '';
}

export const tagsFilter = (filter: ActiveFilter, includeTagNames: boolean = false) => {
    let comparator = 'or';
    if (filter.values?.some((v) => v.endsWith('_not'))) {
        comparator = 'not';
    } else if (filter.values?.some((v) => v.endsWith('_and'))) {
        comparator = 'and';
    } else if (filter.values?.some((v) => v.endsWith('_andNot'))) {
        comparator = 'andNot';
    }
    const tags = includeTagNames
        ? filter.values
        : filter.values?.map((tag) => {
              const regExp = /\(([^)]+)\)/g;
              let arr: RegExpExecArray | null, id: string | undefined;
              while ((arr = regExp.exec(tag)) !== null) {
                  [, id] = arr;
              }

              return id;
          });
    if (comparator == 'or') {
        return { 'tags.id': tags };
    } else if (comparator == 'not') {
        return { 'tags.id_ne': tags };
    } else if (comparator == 'and') {
        return { 'tags.id_and': tags };
    } else if (comparator == 'andNot') {
        return { 'tags.id_and_ne': tags };
    }
};

type CustomColDef = ColDef & { paramKey?: string; sortKey?: string; categoryId?: FilterCategoryTypes };

export const COLUMN_DEFS: CustomColDef[] = [
    {
        headerName: '#',
        colId: '#',
        initialWidth: 45,
        headerComponent: CheckBoxSelection,
        checkboxSelection: true,
        sortable: false,
        suppressHeaderMenuButton: true,
        pinned: true,
        suppressColumnsToolPanel: true,
        suppressMovable: true,
        suppressFillHandle: true,
        cellStyle: () => {
            return { border: 'none' };
        },
    },
    {
        field: 'id',
        colId: 'listings.id',
        headerName: 'ID',
        paramKey: 'listings.id_in',
        sortKey: 'listings.id',
        initialWidth: 75,
        pinned: true,
        hide: true,
        categoryId: 'listing_features',
    },
    {
        field: 'property_id',
        colId: 'property_id',
        headerName: 'Property ID',
        headerTooltip: 'Unique identifier on Channel/PMS',
        tooltipField: 'property_id',
        initialWidth: 75,
        pinned: true,
        hide: true,
        categoryId: 'listing_features',
    },
    {
        field: 'created_at',
        colId: 'created_at',
        headerName: 'Creation Date',
        sortKey: 'listings.created_at',
        initialWidth: 100,
        pinned: true,
        hide: true,
        categoryId: 'listing_features',
        valueFormatter: (params) => {
            return moment(params.value).format('YYYY-MM-DD');
        },
    },
    {
        field: 'searchable',
        colId: 'searchable',
        headerName: 'Search',
        initialWidth: 150,
        pinned: true,
        hide: true,
        lockVisible: true,
        suppressColumnsToolPanel: true,
        categoryId: 'listing_features',
    },
    {
        field: 'title',
        colId: 'title',
        headerName: 'Name',
        initialWidth: 150,
        cellRenderer: 'titleCellRenderer',
        pinned: true,
        sortable: true,
        categoryId: 'listing_features',
        headerComponent: NameHeader,
    },
    {
        field: 'display_nickname',
        colId: 'display_nickname',
        paramKey: 'listing_views.display_nickname',
        headerName: 'Nickname',
        initialWidth: 100,
        pinned: true,
        editable: true,
        hide: false,
        categoryId: 'listing_features',
        onCellValueChanged: (params) => {
            const context: PortfolioTableContext = params.context;
            if (params.oldValue !== params.newValue) {
                context.dispatch(
                    actions.updateSetting({
                        setting: 'nickname',
                        value: params.newValue,
                    }),
                );
                context.dispatch(actions.saveSettingsBulk());
            }
        },

        valueSetter: (params) => {
            // Data is frozen for an unknown reason, so create a copy before editing.
            // Documentation says returning true to update the value, but it seems to be broken.
            // setData is what's required to make sure onCellValueChanged is called and the value is updated.
            params.node?.setData({ ...params.data, display_nickname: params.newValue });
            return true;
        },
    },
    {
        field: 'currency',
        colId: 'currency',
        paramKey: 'listings.currency',
        headerName: 'Currency',
        initialWidth: 75,
        pinned: true,
        hide: true,
        categoryId: 'listing_features',
    },
    {
        colId: 'tags',
        headerName: 'Tag',
        headerComponent: CustomTagHeader,
        headerComponentParams: {},
        initialWidth: 100,
        pinned: true,
        hide: false,
        sortable: false,
        cellRenderer: 'tagCellRenderer',
        cellClass: 'tags',
        cellStyle: () => ({
            padding: '0px 4px',
        }),
        categoryId: 'listing_features',
        valueGetter: (params) => {
            return params.data.tags;
        },
    },
    {
        field: 'source_address',
        colId: 'source_address',
        headerName: 'Address',
        initialWidth: 150,
        pinned: true,
        hide: true,
        categoryId: 'listing_features',
    },
    {
        field: 'number_of_units',
        colId: 'number_of_units',
        headerName: 'Units',
        headerTooltip: 'Number of units',
        tooltipField: 'number_of_units',
        initialWidth: 75,
        pinned: true,
        hide: true,
        categoryId: 'listing_features',
    },
    {
        field: 'beds',
        colId: 'beds',
        headerName: 'Beds',
        headerTooltip: 'Number of beds listed',
        tooltipField: 'beds',
        initialWidth: 75,
        pinned: true,
        hide: true,
        categoryId: 'listing_features',
    },
    {
        field: 'bedrooms',
        colId: 'bedrooms',
        headerName: 'Bedrooms',
        headerTooltip: 'Number of bedrooms listed',
        tooltipField: 'bedrooms',
        initialWidth: 75,
        pinned: true,
        hide: true,
        categoryId: 'listing_features',
    },
    {
        field: 'bathrooms',
        colId: 'bathrooms',
        headerName: 'Bathrooms',
        initialWidth: 75,
        pinned: true,
        hide: true,
        categoryId: 'listing_features',
    },
    {
        field: 'sleeps',
        colId: 'sleeps',
        headerName: 'Sleeps',
        initialWidth: 75,
        pinned: true,
        hide: true,
        categoryId: 'listing_features',
    },
    {
        field: 'clean_room_type',
        colId: 'clean_room_type',
        headerName: 'Room Type',
        initialWidth: 75,
        pinned: true,
        hide: true,
        categoryId: 'listing_features',
    },
    {
        colId: 'channel_accounts.status',
        field: 'channel_account_status',
        headerName: 'Account Status',
        cellRenderer: 'accountStatusCellRenderer',
        initialWidth: 50,
        pinned: true,
        hide: true,
        categoryId: 'listing_status',
    },
    {
        field: 'market.name',
        colId: 'market.name',
        headerName: 'Market',
        sortKey: 'listings.market_id',
        initialWidth: 100,
        pinned: true,
        hide: false,
        categoryId: 'listing_status',
    },
    {
        field: 'source',
        colId: 'source',
        headerName: 'Channel',
        initialWidth: 60,
        cellRenderer: 'sourceCellRenderer',
        pinned: true,
        categoryId: 'listing_status',
    },
    {
        // This column is only used for filtering by sub-users
        colId: 'sub_users',
        headerName: 'Edit Access',
        initialWidth: 60,
        hide: true,
        pinned: true,
        editable: false,
        sortable: false,
        chartDataType: 'excluded',
        suppressHeaderMenuButton: true,
        suppressColumnsToolPanel: true,
    },
    {
        field: 'pricing_tier.name',
        colId: 'pricing_tier.name',
        paramKey: 'pricing_tiers.name',
        headerName: 'Pricing Tier',
        initialWidth: 75,
        cellRenderer: 'pricingTierCellRenderer',
        pinned: true,
        categoryId: 'listing_status',
    },
    {
        field: 'listing_preference.automatic_rate_posting_enabled',
        colId: 'listing_preference.automatic_rate_posting_enabled',
        paramKey: 'listing_preferences.automatic_rate_posting_enabled',
        headerName: 'Automation',
        initialWidth: 55,
        headerTooltip: 'Status of pricing automation',
        pinned: true,
        categoryId: 'listing_status',
        valueFormatter: (params) => {
            return params.value === true || params.value === 'on' ? 'On' : 'Off';
        },
        cellStyle: (params): CellStyle | undefined => {
            if (params.value) {
                return { color: '#D22CC3' };
            }
        },
    },
    {
        field: 'is_active',
        colId: 'is_active',
        paramKey: 'listings.is_active',
        headerName: 'Listing Status',
        initialWidth: 100,
        pinned: true,
        headerTooltip: 'Whether the listing is active on the channel.',
        hide: true,
        categoryId: 'listing_status',
    },
    {
        field: 'removed_by_owner',
        paramKey: 'listings.user_removed',
        colId: 'removed_by_owner',
        headerName: 'Listing Hidden',
        initialWidth: 100,
        pinned: true,
        headerTooltip: 'Whether the listing is marked as hidden.',
        hide: true,
        categoryId: 'listing_status',
    },
    {
        colId: 'listing_views.in_market',
        field: 'market.status',
        headerName: 'Market Status',
        pinned: true,
        hide: true,
        categoryId: 'listing_status',
        valueFormatter: ({ value }) => (value == 'A' ? 'Active' : 'Inactive'),
    },
    {
        colId: 'is_hypothetical',
        field: 'is_hypothetical',
        headerName: 'Hypothetical',
        pinned: true,
        hide: true,
        suppressColumnsToolPanel: true,
    },
    {
        field: 'display_base_price',
        colId: 'display_base_price',
        paramKey: 'listing_views.display_base_price',
        headerName: 'Base Price',
        initialWidth: 85,
        minWidth: 85,
        cellRenderer: 'basePriceCellRenderer',
        type: 'numericColumn',
        pinned: true,
        categoryId: 'listing_status',
        valueFormatter: (params) => `$${params.value}`,
    },
    ...accessColumnDefs,
    ...listingStatsColumns,
];

export const getFilterTagValuesWithComparator = (values: string[]) => {
    return flatten(values.map((v) => [v + '_or', v + '_not', v + '_and', v + '_andNot']));
};

export default COLUMN_DEFS;
