'use client';

import React from 'react';
import { useToast } from '@chakra-ui/react';

export const useIntercom = () => {
    const toast = useToast();
    return () => {
        if (window.Intercom) {
            window.Intercom('show');
        } else {
            toast({
                title: 'Chat could not load.',
                description: (
                    <span>
                        Sometimes adblock or anti-tracking software can block our chat widget. If you have one, please turn it off, reload the page, and try
                        again or email{' '}
                        <a href="mailto:hello@usewheelhouse.com" target="_blank" rel="noreferrer">
                            hello@usewheelhouse.com
                        </a>
                    </span>
                ),
                status: 'warning',
                isClosable: true,
            });
        }
    };
};
