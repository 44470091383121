import { configureStore, Dispatch, Middleware, UnknownAction } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import LogRocket from 'logrocket';

import reducer from 'libs/global/reducers';
import AnalyticsObserver from 'libs/utils/AnalyticsObserver';

import { addRecentListing } from './slices/recentListings';
import { setSyncCount } from './slices/dailySyncCount';
import { setsListenerMiddleware } from './setsListenerMiddleware';

const localStorageMiddleware: Middleware<{}, RootState> = ({ getState, dispatch }) => {
    return (next: Dispatch) => (action: UnknownAction) => {
        const result = next(action);

        if (([addRecentListing.type, setSyncCount.type] as string[]).includes(result.type)) {
            localStorage.setItem(
                'partialAppState',
                JSON.stringify(
                    pick(getState(), [
                        // We only save some keys
                        'recentListings',
                        'dailySyncCount',
                    ]),
                ),
            );
        }

        return result;
    };
};

// Load localStorage into initial state.
let preloadedState = undefined;
if (typeof window !== 'undefined') {
    const local = localStorage.getItem('partialAppState');
    if (local) {
        preloadedState = JSON.parse(local);
    }
}

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(LogRocket.reduxMiddleware(), localStorageMiddleware)
            .prepend(setsListenerMiddleware.middleware),
    preloadedState,
});
// Watch state changes for relevant analytics events (e.g. route change)
new AnalyticsObserver(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
