import React, { useState } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Textarea,
    useDisclosure,
} from '@chakra-ui/react';

import { closeModal } from 'libs/global/slices/sets';
import { useAppDispatch } from 'libs/global/hooks';
import { Plus } from 'phosphor-react';

export const FilterSetModalId = 'filter_set';

const SaveFilterSetModal = ({ isOpen }: { isOpen: boolean }) => {
    const dispatch = useAppDispatch();
    const { isOpen: isDescriptionOpen, onOpen: onDescriptionOpen } = useDisclosure();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const save = () => {
        // TODO: Save filter set

        // Once saved
        setName('');
        setDescription('');
        dispatch(closeModal());
    };

    return (
        <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={() => dispatch(closeModal())} isCentered={true} size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create filter set</ModalHeader>
                <ModalBody>
                    <Flex display="flex" direction="column" gap={4}>
                        <Stack pb={6} w="full" spacing={4}>
                            <FormControl>
                                <FormLabel mb={1} color="gray.600" fontWeight="normal">
                                    Name
                                </FormLabel>
                                <Input fontSize="md" size="lg" borderColor="gray.100" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                <FormHelperText color="gray.400" mt={1.5}>
                                    You can change this later.
                                </FormHelperText>
                            </FormControl>
                            {isDescriptionOpen ? (
                                <FormControl id="description">
                                    <FormLabel mb={1} color="gray.600" fontWeight="normal">
                                        Description
                                    </FormLabel>
                                    <Textarea
                                        rows={2}
                                        borderColor="gray.100"
                                        resize="none"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    <FormHelperText color="gray.400" mt={1.5}>
                                        Write a brief description
                                    </FormHelperText>
                                </FormControl>
                            ) : (
                                <Button
                                    color="primary.400"
                                    fontSize="13px"
                                    alignSelf="start"
                                    variant="unstyled"
                                    rightIcon={<Icon position="relative" top="1px" mx={-1} as={Plus} />}
                                    onClick={onDescriptionOpen}>
                                    Add description
                                </Button>
                            )}
                            <Box />
                        </Stack>
                    </Flex>
                </ModalBody>
                <ModalFooter justifyContent="end" gap={3}>
                    <Button onClick={() => dispatch(closeModal())} variant="secondary">
                        Cancel
                    </Button>
                    <Button isDisabled={name?.length === 0} onClick={save} variant="dark">
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SaveFilterSetModal;
