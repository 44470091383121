import { createSlice } from '@reduxjs/toolkit';

interface ServerUiState {
    errorMsg?: string | null;
}

const serverUiSlice = createSlice({
    name: 'server_ui',
    initialState: {} as ServerUiState,
    reducers: {
        clearUiMessages: (state) => {
            state.errorMsg = null;
        },
    },
});

export const { clearUiMessages } = serverUiSlice.actions;

export default serverUiSlice.reducer;
