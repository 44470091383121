import React, { useState } from 'react';
import {
    Button,
    Flex,
    GridItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Spinner,
    Stack,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import moment from 'moment';
import Image from 'next/image';

import StripePaymentForm from 'libs/global/components/StripePaymentForm';
import { useAppDispatch, useAppSelector } from 'libs/global/hooks';
import { useSetsSubscriptionFinalize, useSetsSubscriptionPropose, useSetsToEdit } from 'libs/global/hooks/useSetsBilling';
import { BodyText } from '../components';
import { CompSet } from 'libs/types/set';
import CentsToDollarsFormatter from 'libs/utils/CentsToDollarsFormatter';
import CreditCard from '../../CreditCard';
import { SetsInvoiceItem, SetsOffer } from 'libs/types/billing';
import { saveBilling } from 'libs/global/actions/billing';
import { clearSetsToEdit } from 'libs/global/slices/pricingTiers';

const selectedListingsTitle = (set_ids) => {
    const count = set_ids.length;
    const suffix = count === 1 ? ' set' : ' sets';
    return count + suffix;
};

export const formatCost = (valSet?: number) => {
    if (valSet === undefined) {
        return '';
    }
    return `$${CentsToDollarsFormatter(valSet)}`;
};

export const LoadingModal = ({ isOpen }) => {
    return (
        <Modal onClose={() => null} autoFocus={false} closeOnOverlayClick={false} isOpen={isOpen} isCentered={true} size="2xl">
            <ModalOverlay bg="blackAlpha.300" />

            <ModalContent w={200} h={200}>
                <Flex w={200} h={200} alignItems="center" justifyContent="center">
                    <Spinner size="xl" />
                </Flex>
            </ModalContent>
        </Modal>
    );
};

const SetsStack = ({ sets, offer }) => {
    if (sets?.length === 0) {
        return null;
    }

    return (
        <Stack>
            <Text fontWeight="semibold" letterSpacing="snug" color="gray.800">
                Sets
            </Text>
            <Stack maxH={140} overflowY="auto" className="scroll-update">
                {sets.map((set: CompSet) => {
                    return (
                        <Flex pr={2} alignItems="center" justifyContent="space-between" key={set.id}>
                            <Text fontSize="md">{set.name}</Text>

                            <Text fontSize="md" color="gray.800">
                                {formatCost(offer?.monthly_fee_cents)}
                            </Text>
                        </Flex>
                    );
                })}
            </Stack>
        </Stack>
    );
};

interface BillingDetailsProps {
    type: string;
    invoice_item: SetsInvoiceItem;
    setsCount: number;
}

const BillingDetails = ({ type, invoice_item, setsCount }: BillingDetailsProps) => {
    const { amount_in_cents, discount, prorated_in_cents, discount_in_cents, invoice_period_start, total_in_cents } = invoice_item;
    const prorated_days_count = moment().diff(moment().startOf('month'), 'days');

    return (
        <Stack w="full">
            <Flex gap={1}>
                <Text fontSize="md" fontWeight="semibold" letterSpacing="snug" color="gray.800">
                    {type === 'current' ? 'Due now' : 'Next invoice'}
                </Text>
                {type !== 'current' && (
                    <Text fontSize="md" letterSpacing="snug" color="gray.400">
                        ({moment(invoice_period_start).format('MMM DD, YYYY')})
                    </Text>
                )}
            </Flex>
            <Stack spacing={2}>
                <Stack>
                    <Flex width="100%" justifyContent="space-between">
                        <Text fontSize="13px" color="gray.800">
                            Price
                        </Text>
                        <Text fontSize="13px" color="gray.800">
                            {formatCost(amount_in_cents * setsCount)}
                        </Text>
                    </Flex>

                    {prorated_in_cents && (
                        <Flex width="100%" justifyContent="space-between">
                            <Text fontSize="13px" color="gray.800">
                                Prorated ({prorated_days_count} days)
                            </Text>
                            <Text fontSize="13px" color="gray.800">
                                - {formatCost(prorated_in_cents * setsCount)}
                            </Text>
                        </Flex>
                    )}
                    {discount && discount_in_cents && (
                        <Flex width="100%" justifyContent="space-between">
                            <Text fontSize="13px" color="gray.800">
                                Discount of {discount.basis_points / 100}%
                            </Text>
                            <Text fontSize="13px" color="gray.800">
                                {formatCost(discount_in_cents)}
                            </Text>
                        </Flex>
                    )}
                </Stack>
                <Flex width="100%" justifyContent="space-between">
                    <Text fontSize="13px" color="gray.800" fontWeight={600}>
                        Total
                    </Text>
                    <Text fontSize="13px" color="gray.800">
                        <strong>{formatCost(total_in_cents * setsCount)}</strong>
                    </Text>
                </Flex>
            </Stack>
        </Stack>
    );
};

export const PaymentForm = ({ showCCForm, billingInfo, setShowCCForm, stripeUpdateSuccess }) => {
    const isValidPaymentMethod = billingInfo?.card_type && !showCCForm;
    return isValidPaymentMethod ? (
        <Flex direction="column" justifyContent="space-between" h="full" width="100%">
            <Stack>
                <Flex width="100%" alignItems="center" justifyContent="space-between" gap={2}>
                    <Text fontSize="md" fontWeight="semibold" letterSpacing="snug" color="gray.800">
                        Payment method
                    </Text>
                    <Image style={{ opacity: 0.7 }} width={90} height={20} src="/stripe-logo.svg" alt="Powered by Stripe" />
                </Flex>
                <Flex
                    w="full"
                    justifyContent="space-between"
                    fontSize="13px"
                    alignItems="center"
                    borderColor="gray.100A"
                    bg="gray.50A"
                    borderWidth="1px"
                    px={3}
                    py={2}
                    borderRadius="6px">
                    <Flex alignItems="center" color="gray.500">
                        <CreditCard
                            brand={billingInfo.card_type ? billingInfo.card_type : 'unknown'}
                            style={{ display: 'inline-block', marginRight: '12px' }}
                        />
                        ending in
                        <Text as="span" fontWeight="semibold" style={{ marginLeft: '5px', marginRight: '5px' }}>
                            {billingInfo.last_four}
                        </Text>
                    </Flex>
                    <Button
                        fontWeight={500}
                        variant="secondary"
                        border="transparent"
                        color="gray.800"
                        size="sm"
                        fontSize="13px"
                        onClick={() => setShowCCForm(!showCCForm)}>
                        Change card
                    </Button>
                </Flex>
            </Stack>
        </Flex>
    ) : (
        <Stack width="100%" spacing={4}>
            <Flex width="100%" justifyContent="space-between">
                <Text fontSize="14px" fontWeight={600} letterSpacing="tight" color="gray.800">
                    Payment method
                </Text>
                <Image style={{ opacity: 0.7 }} width={90} height={20} src="/stripe-logo.svg" alt="Powered by Stripe" />
            </Flex>
            {isValidPaymentMethod && showCCForm ? (
                <p style={{ fontSize: '14px', marginBottom: '20px', fontStyle: 'italic', color: 'gray.400' }}>
                    Updating your card info will move all your subscriptions to your new card.
                </p>
            ) : null}
            <StripePaymentForm buttonText="Add card" onSuccess={stripeUpdateSuccess} />
            {billingInfo && billingInfo.card_type && showCCForm ? (
                <Button
                    color="gray.500"
                    mt={2}
                    fontWeight={500}
                    letterSpacing="tight"
                    variant="filter"
                    borderColor="gray.100"
                    w="full"
                    onClick={() => setShowCCForm(!showCCForm)}>
                    Cancel card change
                </Button>
            ) : null}
        </Stack>
    );
};

const UpgradeForm = ({
    invoice_item,
    upcoming_invoice_item,
    setsCount,
}: {
    invoice_item: SetsInvoiceItem;
    upcoming_invoice_item: SetsInvoiceItem;
    setsCount: number;
}) => {
    const dispatch = useAppDispatch();

    const billingInfo = useAppSelector(({ billing_info }) => billing_info);
    const [showCCForm, setShowCCForm] = useState(false);

    const stripeUpdateSuccess = (payment_method: string) => {
        dispatch(
            saveBilling(payment_method, (success: boolean) => {
                if (success) {
                    setShowCCForm(false);
                }
            }),
        );
    };

    return (
        <Stack spacing={8}>
            <BillingDetails setsCount={setsCount} type="current" invoice_item={invoice_item} />
            <BillingDetails setsCount={setsCount} type="upcoming" invoice_item={upcoming_invoice_item} />
            <PaymentForm showCCForm={showCCForm} billingInfo={billingInfo} setShowCCForm={setShowCCForm} stripeUpdateSuccess={stripeUpdateSuccess} />
        </Stack>
    );
};

const Upgrade = ({
    handleCancel,
    handleChange,
    isSaving,
    invoice_item,
    offer,
    upcoming_invoice_item,
}: {
    handleCancel: () => void;
    handleChange: () => void;
    isSaving: boolean;
    invoice_item: SetsInvoiceItem;
    offer: SetsOffer;
    upcoming_invoice_item: SetsInvoiceItem;
}) => {
    const billingInfo = useAppSelector(({ billing_info }) => billing_info);
    const { sets, set_ids } = useSetsToEdit();
    const isValidPaymentMethod = billingInfo?.card_type;

    return (
        <Modal autoFocus={false} closeOnOverlayClick={true} isOpen={true} onClose={handleCancel} isCentered={true} size="2xl">
            <ModalOverlay bg="blackAlpha.300" />
            <ModalContent>
                <ModalHeader borderBottom="1px" borderColor="gray.100A" fontSize="2xl">
                    Upgrade {selectedListingsTitle(set_ids)}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody py={6}>
                    <SimpleGrid columns={2} gap={16}>
                        <GridItem>
                            <Stack>
                                <BodyText>Upon clicking confirm, you will be charged the total due today.</BodyText>
                                <BodyText>While this Dynamic Set is active, you will be charged $12.99 at the start of each month.</BodyText>
                                <BodyText>
                                    If you choose to downgrade or delete a set, you will no longer be billed. You will maintain access for the set until month's
                                    end.
                                </BodyText>
                                <SetsStack sets={sets} offer={offer} />
                            </Stack>
                        </GridItem>
                        <GridItem>
                            <UpgradeForm invoice_item={invoice_item} upcoming_invoice_item={upcoming_invoice_item} setsCount={set_ids.length} />
                        </GridItem>
                    </SimpleGrid>
                </ModalBody>
                <ModalFooter display="flex" justifyContent="space-between" borderTop="1px" borderColor="gray.100A">
                    <Button fontSize="13px" onClick={handleCancel} variant="secondary">
                        Cancel
                    </Button>
                    <Tooltip shouldWrapChildren={true} label={!isValidPaymentMethod ? 'Please enter a payment method' : undefined}>
                        <Button isDisabled={!isValidPaymentMethod} isLoading={isSaving} fontSize="13px" onClick={handleChange} variant="dark">
                            Upgrade
                        </Button>
                    </Tooltip>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const Downgrade = ({ handleCancel, handleChange, isSaving }: { handleCancel: () => void; handleChange: () => void; isSaving: boolean }) => {
    const { set_ids } = useSetsToEdit();

    return (
        <Modal autoFocus={false} closeOnOverlayClick={true} isOpen={true} onClose={handleCancel} isCentered={true} size="2xl">
            <ModalOverlay bg="blackAlpha.300" />
            <ModalContent>
                <ModalHeader borderBottom="1px" borderColor="gray.100A" fontSize="2xl">
                    Downgrade {selectedListingsTitle(set_ids)}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody py={6}>
                    <BodyText color="gray.800" fontSize="14px" mb={1}>
                        Upon clicking confirm, you'll be downgraded at the end of the billing cycle.
                    </BodyText>
                </ModalBody>
                <ModalFooter display="flex" justifyContent="space-between" borderTop="1px" borderColor="gray.100A">
                    <Button fontSize="13px" onClick={handleCancel} variant="secondary">
                        Cancel
                    </Button>
                    <Button isLoading={isSaving} fontSize="13px" onClick={handleChange} variant="dark">
                        Downgrade
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const Cancel = ({ handleCancel, handleChange, isSaving }: { handleCancel: () => void; handleChange: () => void; isSaving: boolean }) => {
    const { set_ids } = useSetsToEdit();

    return (
        <Modal autoFocus={false} closeOnOverlayClick={true} isOpen={true} onClose={handleCancel} isCentered={true} size="2xl">
            <ModalOverlay bg="blackAlpha.300" />
            <ModalContent>
                <ModalHeader borderBottom="1px" borderColor="gray.100A" fontSize="2xl">
                    Cancel subscription changes to {selectedListingsTitle(set_ids)}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody py={6}>
                    <BodyText color="gray.800" fontSize="14px" mb={1}>
                        Upon clicking cancel subscription change, you're subscription will be re-activated. Billing will continue at the beginning of each
                        month.
                    </BodyText>
                </ModalBody>
                <ModalFooter display="flex" justifyContent="space-between" borderTop="1px" borderColor="gray.100A">
                    <Button fontSize="13px" onClick={handleCancel} variant="secondary">
                        Cancel
                    </Button>
                    <Button isLoading={isSaving} fontSize="13px" onClick={handleChange} variant="dark">
                        Cancel subscription change
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const SubscriptionModal = () => {
    const dispatch = useAppDispatch();
    const modalTypeToShow = useAppSelector((state) => state.pricingTiers.setsToEdit.type);

    const handleCancel = () => {
        // Close the modal
        dispatch(clearSetsToEdit());
    };

    const { sets } = useSetsToEdit();
    const { mutateAsync: action, isPending: isSaving } = useSetsSubscriptionFinalize();

    const { data } = useSetsSubscriptionPropose();

    if (!modalTypeToShow) {
        return null;
    }

    if (!data) {
        return <LoadingModal isOpen={true} />;
    }

    const { purchases } = data;
    const purchase = purchases[0];
    const { invoice_item, offer, upcoming_invoice_item, upcoming_subscription } = purchase;
    const subscription_id = upcoming_subscription.product_offer.id;

    const handleChange = () => {
        const payload = sets.map((set) => {
            return {
                subscribable_id: set.id,
                offer_id: subscription_id,
            };
        });

        action(payload).then(() => {
            dispatch(clearSetsToEdit());
        });
    };

    // Determine which modal to show
    switch (modalTypeToShow) {
        case 'upgrade':
            return (
                <Upgrade
                    handleCancel={handleCancel}
                    handleChange={handleChange}
                    isSaving={isSaving}
                    invoice_item={invoice_item}
                    offer={offer}
                    upcoming_invoice_item={upcoming_invoice_item}
                />
            );
        case 'downgrade':
            return <Downgrade handleCancel={handleCancel} handleChange={handleChange} isSaving={isSaving} />;
        case 'cancel':
            return <Cancel handleCancel={handleCancel} handleChange={handleChange} isSaving={isSaving} />;
        default:
            return null;
    }
};

export default SubscriptionModal;
