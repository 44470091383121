import React from 'react';
import { PortfolioTableFuctionContext } from 'libs/types/portfolioTable';

const defaultContext: PortfolioTableFuctionContext = {
    deselectAll: () => {},
    getNumberOfSelectableNodes: () => 0,
    selectAll: () => {},
};

export const TableContext = React.createContext(defaultContext);
