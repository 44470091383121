import WHEELHOUSE_CONFIG from '@/wheelhouse/config';
import { blue, greenVivid } from 'libs/utils/colors';

export const HOMEAWAY_CLIENT_ID = WHEELHOUSE_CONFIG.HOMEAWAY_CLIENT_ID;
export const TRIPADVISOR_CLIENT_ID = 'f155ce7202da4d92b950b21a550ae76c';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyC-OlpNnUs43Rd985_4hAGzuvLptAXsaA8';
export const GOOGLE_ANALYTICS_ID = 'UA-58701712-1';
export const MAPBOX_API_KEY = 'pk.eyJ1IjoiZ3JlZy11c2V3aGVlbGhvdXNlIiwiYSI6ImNqMTg4Zm5lNTA2NjUzM3A5c3FucDhrcDgifQ.NJOIwLUEd77WlcyR3Lx-5g';
export const AG_GRID_KEY =
    'Using_this_{AG_Grid}_Enterprise_key_{AG-050502}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Lyric_Hospitality_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Wheelhouse}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Wheelhouse}_need_to_be_licensed___{Wheelhouse}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{9_January_2025}____[v3]_[01]_MTczNjM4MDgwMDAwMA==dbc809274a4a21d0b7b03a955d8abb46';
export const HUBSPOT_MEETING_LINK = 'https://meetings.hubspot.com/amelia-melching/chat-with-wheelhouse';

export const AIRBNB_ACCOUNT_TYPE = 'A';
export const HOSTAWAY_ACCOUNT_TYPE = 'HS';
export const MYVR_ACCOUNT_TYPE = 'MV';

export const HYPOTHETICAL_ACCOUNT_TYPE = 'XX';

export const CHANNEL_ACCOUNT_ACTIVE_STATUS = 'A';
export const CHANNEL_ACCOUNT_DEACTIVATED_STATUS = 'D';
export const CHANNEL_ACCOUNT_OAUTH_STATUS = 'O';
export const CHANNEL_ACCOUNT_EMPTY_STATUS = 'E';
export const CHANNEL_ACCOUNT_PENDING_STATUS = 'P';
export const CHANNEL_ACCOUNT_FAILED_STATUS = 'F';

export const ACCESS_LEVEL_UNKNOWN = 'U';
export const ACCESS_LEVEL_OWNED = 'O';
export const ACCESS_LEVEL_MANAGED = 'M';

export const ACCESS_NAME_BY_LEVEL = {
    [ACCESS_LEVEL_UNKNOWN]: 'Unknown',
    [ACCESS_LEVEL_OWNED]: 'Admin',
    [ACCESS_LEVEL_MANAGED]: 'Manager',
};

export const HGH_DEMAND_COLOR = [15, 121, 219];
export const LOW_DEMAND_COLOR = [219, 15, 52];
export const UNAVAILABLE_COLOR = '#f6f5f7';
export const BOOKED_COLOR = '#f3fffb';

export const AIRBNB_CHANNEL_ID = 'airbnb';
export const HOMEAWAY_CHANNEL_ID = 'homeaway';
export const TRIPADVISOR_CHANNEL_ID = 'tripadvisor';
export const LODGABLE_CHANNEL_ID = 'lodgable';
export const HOSTAWAY_CHANNEL_ID = 'hostaway';
export const SMOOBU_CHANNEL_ID = 'smoobu';
export const TRACK_CHANNEL_ID = 'track';
export const STREAMLINE_CHANNEL_ID = 'streamline';
export const RENTALSUNITED_CHANNEL_ID = 'rentalsunited';
export const RU_VRBO_CHANNEL_ID = 'rentalsunited-vrbo';
export const VRBO_CHANNEL_ID = 'vrbo';
export const BOOKINGSYNC_CHANNEL_ID = 'bookingsync';
export const GUESTY_CHANNEL_ID = 'guesty';
export const WHEELHOUSE_CHANNEL_ID = 'wheelhouse';
export const HOSTFULLY_CHANNEL_ID = 'hostfully';
export const ORBI_RENTAL_CHANNEL_ID = 'orbirental';
export const MYVR_CHANNEL_ID = 'myvr';
export const HYPOTHETICAL_CHANNEL_ID = 'hypothetical';
export const LMPM_CHANNEL_ID = 'lmpm';
export const HOSTEEVA_CHANNEL_ID = 'hosteeva';
export const IGMS_CHANNEL_ID = 'igms';
export const BOOKINGPAL_CHANNEL_ID = 'bookingpalssapi';
export const MADECOMFY_CHANNEL_ID = 'madecomfy';
export const SWEETINN_CHANNEL_ID = 'sweetinn';
export const YOURPORTER_CHANNEL_ID = 'yourporter';
export const ZENS_CHANNEL_ID = 'zens';
export const TOKEET_CHANNEL_ID = 'tokeet';
export const JETSTREAM_CHANNEL_ID = 'jetstream';
export const ESCAPIA_CHANNEL_ID = 'escapia';
export const STAYNTOUCH_CHANNEL_ID = 'stay_n_touch';
export const RABBU_CHANNEL_ID = 'rabbu';
export const OVERMOON_CHANNEL_ID = 'overmoon';
export const NATURALRETREATS_CHANNEL_ID = 'naturalretreats';
export const CIIRUS_CHANNEL_ID = 'ciirus';
export const BESTBEACH_CHANNEL_ID = 'best_beach';
export const GUESTYFORHOSTS_CHANNEL_ID = 'guesty_for_hosts';
export const AVANTSTAY_CHANNEL_ID = 'avantstay';
export const YOURRENTL_CHANNEL_ID = 'yourrentl';
export const BAREFOOT_CHANNEL_ID = 'barefoot';
export const HOSPITABLE_CHANNEL_ID = 'hospitable';
export const OWNERREZ_CHANNEL_ID = 'ownerrez';
export const UPLISTING_CHANNEL_ID = 'uplisting';
export const RNS_CHANNEL_ID = 'rns';
export const DIRECT_SOFTWARE_CHANNEL_ID = 'direct_software';
export const RENTALWISE_CHANNEL_ID = 'rentalwise';

export const WH_CHANNEL_EMAIL = 'tech-support@usewheelhouse.com';
export const BAREFOOT_CHANNEL_EMAIL = 'barefoot@usewheelhouse.com';
export const RNS_CHANNEL_EMAIL = 'rns@usewheelhouse.com';

export const CLEAN_ROOM_TYPES = WHEELHOUSE_CONFIG.CLEAN_ROOM_TYPES;
export const METERS_TO_MILE = WHEELHOUSE_CONFIG.METERS_TO_MILE;
export const MAX_COMP_AUTODETECTED = WHEELHOUSE_CONFIG.MAX_COMP_AUTODETECTED;
export const COMPS_START_DATE = WHEELHOUSE_CONFIG.COMPS_START_DATE;
export const CURRENCY_CODES = WHEELHOUSE_CONFIG.CURRENCY_CODES;
export const SET_IDS = WHEELHOUSE_CONFIG.SET_IDS;
export const SET_AUTODETECTED = WHEELHOUSE_CONFIG.SET_AUTODETECTED;

// Constants related to notes
export const NOTE_STATUS = WHEELHOUSE_CONFIG.NOTE_STATUS;
export const NOTE_ACTIVE_STATUS = WHEELHOUSE_CONFIG.NOTE_ACTIVE_STATUS;
export const NOTE_DELETED_STATUS = WHEELHOUSE_CONFIG.NOTE_DELETED_STATUS;
export const NOTE_ARCHIVED_STATUS = WHEELHOUSE_CONFIG.NOTE_ARCHIVED_STATUS;

// Constants related to notification
export const EVENT_PRICE_POSTING = WHEELHOUSE_CONFIG.EVENT_PRICE_POSTING;
export const EVENT_MARKET_REPORT = WHEELHOUSE_CONFIG.EVENT_MARKET_REPORT;
export const EVENT_COMP_SET = WHEELHOUSE_CONFIG.EVENT_COMP_SET;
export const EVENT_REMINDER = WHEELHOUSE_CONFIG.EVENT_REMINDER;
export const EVENT_TEAMS = WHEELHOUSE_CONFIG.EVENT_TEAMS;
export const EVENT_NEW_LISTING = WHEELHOUSE_CONFIG.EVENT_NEW_LISTING;

export const PERSONALIZATION_TYPES = {
    CONSERVATIVE_TYPE: 'CON',
    RECOMMENDED_TYPE: 'REC',
    AGGRESSIVE_TYPE: 'AGG',
    CUSTOM_TYPE: 'CUS',
};

export const PERSONALIZATION_TYPE_LONGFORM = {
    CON: 'Conservative',
    REC: 'Recommended',
    AGG: 'Aggressive',
    CUS: 'Custom',
};

export const BASE_PRICE_ADJUSTMENTS = {
    CONSERVATIVE_TYPE: 0.9,
    RECOMMENDED_TYPE: 1,
    AGGRESSIVE_TYPE: 1.1,
    CUSTOM_TYPE: null,
};

// Hash of adjustment values to type strings.
const adjustmentToType = {};
Object.entries(BASE_PRICE_ADJUSTMENTS).forEach(([name, adj]) => {
    adjustmentToType[String(adj)] = PERSONALIZATION_TYPES[name];
});

export const BASE_PRICE_ADJUSTMENTS_TO_TYPE = adjustmentToType;

export const PRICING_TIER_FREE_LEVEL = 0;
export const PRICING_TIER_ESSENTIAL_LEVEL = 1;
export const PRICING_TIER_PRO_LEVEL = 2;
export const PRICING_TIER_GROUP_ID_PRO = 1;

export const TIER_STYLES = {
    [PRICING_TIER_FREE_LEVEL]: {
        color: greenVivid,
        themeColor: 'green',
    },
    [PRICING_TIER_ESSENTIAL_LEVEL]: {
        color: blue,
        themeColor: 'blue',
    },
    [PRICING_TIER_PRO_LEVEL]: {
        color: '#AC2C9C',
        themeColor: 'purple',
    },
};
export const PRICING_TIER_LEGACY_START = 'Legacy';
export const MAX_BULK_LISTINGS = 100;

export const SETS_TIER_FREE_LEVEL = 0;
export const SETS_TIER_PAID_LEVEL = 1;
export const SETS_TIER_LEGACY_LEVEL = 2;
export const SETS_TIER_FREE_ID = 1;
export const SETS_TIER_LEGACY_ID = 2;
export const SETS_TIER_PAID_ID = 3;
export const SETS_TIER_ADMIN_ID = 4;

export const SETS_SUBSCRIPTION_ACTION: {
    UPGRADE: 'upgrade';
    DOWNGRADE: 'downgrade';
    CANCEL: 'cancel';
} = {
    UPGRADE: 'upgrade',
    DOWNGRADE: 'downgrade',
    CANCEL: 'cancel',
};

export const FEATURE_MANUAL_CALENDAR_SYNC_ID = 'Daily Manual Calendar Syncs';
export const FEATURE_AUTOMATED_CALENDAR_SYNC_ID = 'Daily Automated Calendar Syncs';
export const FEATURE_BACKWARD_LOOKING_PERFORMANCE_DATA_ID = 'Backward-looking performance data';
export const FEATURE_FUTURE_LOOKING_PERFORMANCE_DATA_ID = 'Future-looking performance data';
export const FEATURE_LAST_MINUTE_DISCOUNTS_ID = 'Last Minute Discounting Strategy';
export const FEATURE_MIN_STAY_DAY_OF_WEEK_ID = 'Day-of-Week Min Stay Strategy';
export const FEATURE_MIN_STAY_DATE_SPECIFIC_ID = 'Date-Specific Min Stay Strategy';
export const FEATURE_MIN_STAY_TIME_BASED_ID = 'Time-Based Min Stay Setting';
export const FEATURE_CUSTOM_RATES_ID = 'Create Custom Rates';
export const FEATURE_LOCAL_EVENTS_ID = 'Show Local Events';
export const FEATURE_LOCAL_EVENT_NAMES_ID = 'Show Local Event Names';
export const FEATURE_VIEW_PORTFOLIO_DASHBOARD = 'View Portfolio Dashboard';
export const FEATURE_BOOKING_SCORE = 'Review Booking Scores';
export const FEATURE_PORTFOLIO_COMP_SET = 'Show Portfolio Comp Set';
export const FEATURE_EDIT_COMP_SET = 'Edit Custom Comp Set';
export const FEATURE_METRICS_COMP_SET = 'View Metrics for Competitors';
export const FEATURE_CALENDAR_COMP_SET = 'View Calendars for Competitors';
export const FEATURE_DAILY_REFRESHED_PRICING_INSIGHTS = 'Daily Refreshed Pricing Insights';
export const FEATURE_VIEW_INSIGHTS = 'Recommendation Insights';
export const FEATURE_NOTES_AND_REMINDERS = 'Notes and Reminders';
export const FEATURE_EXPORT_DATA = 'Ability to Export Data';
export const FEATURE_EXPORT_CHARTS = 'Ability to Export Charts';

const airbnbLogo = '/channels/airbnb-logo.png';
const homeawayLogo = '/channels/homeaway-logo.png';
const tripadvisorLogo = '/channels/tripadvisor-logo.png';

const airbnbIcon = '/channels/channel-icon-airbnb.svg';
const airbnbNameAndLogo = '/channels/channels_airbnb.svg';
const bookingsyncIcon = '/channels/bookingsync-icon.svg';
const bookingsyncNameAndLogo = '/channels/bookingsync.svg';
const homeawayIcon = '/channels/channel-icon-homeaway.svg';
const homeawayNameAndLogo = '/channels/channels_homeAway.svg';
const homeawayName = '/channels/HomeAway.svg';
const tripadvisorIcon = '/channels/channel-icon-tavr.svg';
const tripadvisorName = '/channels/TripAdvisor-Name.svg';
const tripadvisorNameAndLogo = '/channels/channel-tripadvisor-name-and-logo.svg';
const lodgableLogo = '/channels/channel-lodgable-logo.svg';
const lodgableName = '/channels/channel-lodgable-name.svg';
const lodgableNameAndLogo = '/channels/channel-lodgable-name-and-logo.svg';
const hostawayLogo = '/channels/channel-hostaway-logo.png';
const hostawayName = '/channels/channel-hostaway-name.svg';
const hostawayNameAndLogo = '/channels/channel-hostaway-name-and-logo.svg';
const smoobuLogo = '/channels/channel-smoobu-logo.svg';
const smoobuNameAndLogo = '/channels/channel-smoobu-name-and-logo.svg';
const trackLogo = '/channels/channel-track-logo.png';
const trackNameAndLogo = '/channels/channel-track-name-and-logo.png';
const streamlineLogo = '/channels/channel-streamline-logo.svg';
const streamlineName = '/channels/channel-streamline-name.svg';
const streamlineNameAndLogo = '/channels/channel-streamline-name-and-logo.svg';
const rentalsunitedLogo = '/channels/channel-rentalsunited-logo.svg';
const rentalsunitedName = '/channels/channel-rentalsunited-name.svg';
const rentalsunitedNameAndLogo = '/channels/channel-rentalsunited-name-and-logo.svg';
const vrboIcon = '/channels/vrbo-icon.svg';
const vrboLogo = '/channels/vrbo-logo.svg';
const orbiLogo = '/channels/orbi-icon.svg';
const orbiNameAndLogo = '/channels/orbirental_logo_color.svg';
export const ssapiLogo = '/channels/ssapi-logo.svg';
const hostfullyLogo = '/channels/hostfully-logo.svg';
const hostfullyIcon = '/channels/hostfully-icon.svg';
const HypotheticalLogo = '/channels/hypothetical-logo.svg';
const WheelhouseLogo = '/channels/channel-lyric-name-and-logo.svg';
const guestyLogo = '/channels/guesty-logo.svg';
const guestyNameAndLogo = '/channels/guesty-name-and-logo.svg';
const myvrLogo = '/channels/myvr-logo.svg';
const myvrIcon = '/channels/myvr-icon.svg';
const lmpmLogo = '/channels/lmpm-logo.svg';
const lmpmNameAndLogo = '/channels/lmpm-name-and-logo.svg';
const hosteevaLogo = '/channels/hosteeva-logo.svg';
const hosteevaNameAndLogo = '/channels/hosteeva-name-and-logo.svg';
const igmsLogo = '/channels/igms-logo.svg';
const igmsNameAndLogo = '/channels/igms-name-and-logo.svg';
const channelconnectorLogo = '/channels/channelconnector-logo.png';
const channelconnectorNameAndLogo = '/channels/channelconnector-name-and-logo.png';
const madecomfyLogo = '/channels/madecomfy-logo.svg';
const madecomfyNameAndLogo = '/channels/madecomfy-name-and-logo.svg';
const sweetinnLogo = '/channels/sweetinn-logo.png';
const sweetinnNameAndLogo = '/channels/sweetinn-name-and-logo.svg';
const yourporterLogo = '/channels/channel-yourporter-logo.svg';
const yourporterNameAndLogo = '/channels/channel-yourporter-name-and-logo.svg';
const zensLogo = '/channels/zens-logo.png';
const zensNameAndLogo = '/channels/zens-name-and-logo.png';
const tokeetLogo = '/channels/tokeet-logo.png';
const tokeetNameAndLogo = '/channels/tokeet-name-and-logo.png';
const jetstreamLogo = '/channels/jetstream-logo.svg';
const jetstreamNameAndLogo = '/channels/jetstream-name-and-logo.svg';
const escapiaLogo = '/channels/escapia-logo.svg';
const escapiaNameAndLogo = '/channels/escapia-name-and-logo.svg';
const stayNTouchLogo = '/channels/stay-n-touch-logo.png';
const stayNTouchNameAndLogo = '/channels/stay-n-touch-name-and-logo.png';
const rabbuLogo = '/channels/rabbu-logo.svg';
const rabbuNameAndLogo = '/channels/rabbu-name-and-logo.svg';
const naturalretreatsLogo = '/channels/naturalretreats-logo.png';
const naturalretreatsNameAndLogo = '/channels/naturalretreats-name-and-logo.png';
const ciirusLogo = '/channels/ciirus-logo.png';
const ciirusNameAndLogo = '/channels/ciirus-name-and-logo.png';
const bestBeachLogo = '/channels/best-beach-logo.png';
const bestBeachNameAndLogo = '/channels/best-beach-name-and-logo.png';
const guestyForHostsLogo = '/channels/guesty-logo.svg'; // Same as Guesty
const guestyForHostsNameAndLogo = '/channels/guesty-for-hosts-name-and-logo.svg';
const avantstayLogo = '/channels/avantstay-logo.png';
const avantstayNameAndLogo = '/channels/avantstay-name-and-logo.png';
const yourrentlNameAndLogo = '/channels/yourrentl-name-and-logo.png';
const barefootLogo = '/channels/barefoot-logo.png';
const barefootNameAndLogo = '/channels/barefoot-name-and-logo.png';
const hospitableLogo = '/channels/hospitable-logo.png';
const hospitableNameAndLogo = '/channels/hospitable-name-and-logo.png';
const ownerRezLogo = '/channels/ownerrez-logo.png';
const ownerRezNameAndLogo = '/channels/ownerrez-name-and-logo.png';
const uplistingLogo = '/channels/uplisting-logo.png';
const uplistingNameAndLogo = '/channels/uplisting-name-and-logo.png';
const rnsLogo = '/channels/rns-logo.png';
const rnsNameAndLogo = '/channels/rns-name-and-logo.png';
const directLogo = '/channels/direct-logomark.png';
const directNameAndLogo = '/channels/direct-logo.png';
const rentalwiseLogo = '/channels/rentalwise-logo.png';
const rentalwiseNameAndLogo = '/channels/rentalwise-name-and-logo.svg';

export const channelMixins = {
    [AIRBNB_CHANNEL_ID]: {
        logo: airbnbLogo,
        icon: airbnbIcon,
        nameAndLogoIcon: airbnbNameAndLogo,
        color: '#ff5a5f',
    },
    [HOMEAWAY_CHANNEL_ID]: {
        logo: homeawayLogo,
        icon: homeawayIcon,
        nameAndLogoIcon: homeawayNameAndLogo,
        nameIcon: homeawayName,
        color: '#6fa7d4',
    },
    [TRIPADVISOR_CHANNEL_ID]: {
        logo: tripadvisorLogo,
        icon: tripadvisorIcon,
        nameAndLogoIcon: tripadvisorNameAndLogo,
        nameIcon: tripadvisorName,
        color: '#589442',
    },
    [LODGABLE_CHANNEL_ID]: {
        logo: lodgableLogo,
        icon: lodgableLogo,
        nameAndLogoIcon: lodgableNameAndLogo,
        nameIcon: lodgableName,
        color: '#106BA9',
    },
    [HOSTAWAY_CHANNEL_ID]: {
        logo: hostawayLogo,
        icon: hostawayLogo,
        nameAndLogoIcon: hostawayNameAndLogo,
        nameIcon: hostawayName,
        color: '#ff7b56',
    },
    [RENTALSUNITED_CHANNEL_ID]: {
        logo: rentalsunitedLogo,
        icon: rentalsunitedLogo,
        nameAndLogoIcon: rentalsunitedNameAndLogo,
        nameIcon: rentalsunitedName,
        color: '#ff7b56',
    },
    [RU_VRBO_CHANNEL_ID]: {
        logo: vrboLogo,
        icon: vrboIcon,
        nameIcon: vrboLogo,
        nameAndLogoIcon: vrboLogo,
        color: '#1D924C',
    },
    [SMOOBU_CHANNEL_ID]: {
        logo: smoobuLogo,
        icon: smoobuLogo,
        nameAndLogoIcon: smoobuNameAndLogo,
        nameIcon: smoobuNameAndLogo,
        color: '#374AFF',
    },
    [TRACK_CHANNEL_ID]: {
        logo: trackLogo,
        icon: trackLogo,
        nameAndLogoIcon: trackNameAndLogo,
        nameIcon: trackLogo,
        color: '#4f8edc',
    },
    [STREAMLINE_CHANNEL_ID]: {
        logo: streamlineLogo,
        icon: streamlineLogo,
        nameAndLogoIcon: streamlineNameAndLogo,
        nameIcon: streamlineName,
        color: '#1F5F7E',
    },
    [VRBO_CHANNEL_ID]: {
        logo: vrboLogo,
        icon: vrboIcon,
        nameIcon: vrboLogo,
        nameAndLogoIcon: vrboLogo,
        color: '#1D924C',
    },

    [BOOKINGSYNC_CHANNEL_ID]: {
        icon: bookingsyncIcon,
        nameIcon: bookingsyncNameAndLogo,
        nameAndLogoIcon: bookingsyncNameAndLogo,
    },
    [GUESTY_CHANNEL_ID]: {
        icon: guestyLogo,
        nameIcon: guestyLogo,
        nameAndLogoIcon: guestyNameAndLogo,
    },
    [WHEELHOUSE_CHANNEL_ID]: {
        icon: WheelhouseLogo,
    },
    [ORBI_RENTAL_CHANNEL_ID]: {
        icon: orbiLogo,
        nameIcon: orbiLogo,
        nameAndLogoIcon: orbiNameAndLogo,
    },
    [HOSTFULLY_CHANNEL_ID]: {
        icon: hostfullyIcon,
        nameIcon: hostfullyLogo,
        nameAndLogoIcon: hostfullyLogo,
    },
    [MYVR_CHANNEL_ID]: {
        icon: myvrIcon,
        nameIcon: myvrLogo,
        nameAndLogoIcon: myvrLogo,
    },
    [HYPOTHETICAL_CHANNEL_ID]: {
        icon: HypotheticalLogo,
        nameIcon: HypotheticalLogo,
        nameAndLogoIcon: HypotheticalLogo,
    },
    [LMPM_CHANNEL_ID]: {
        icon: lmpmLogo,
        nameIcon: lmpmLogo,
        nameAndLogoIcon: lmpmNameAndLogo,
    },
    [HOSTEEVA_CHANNEL_ID]: {
        icon: hosteevaLogo,
        nameIcon: hosteevaLogo,
        nameAndLogoIcon: hosteevaNameAndLogo,
    },
    [IGMS_CHANNEL_ID]: {
        icon: igmsLogo,
        nameIcon: igmsLogo,
        nameAndLogoIcon: igmsNameAndLogo,
    },
    [BOOKINGPAL_CHANNEL_ID]: {
        icon: channelconnectorLogo,
        nameIcon: channelconnectorLogo,
        nameAndLogoIcon: channelconnectorNameAndLogo,
    },
    [MADECOMFY_CHANNEL_ID]: {
        icon: madecomfyLogo,
        nameIcon: madecomfyLogo,
        nameAndLogoIcon: madecomfyNameAndLogo,
    },
    [SWEETINN_CHANNEL_ID]: {
        icon: sweetinnLogo,
        nameIcon: sweetinnLogo,
        nameAndLogoIcon: sweetinnNameAndLogo,
    },
    [YOURPORTER_CHANNEL_ID]: {
        logo: yourporterLogo,
        icon: yourporterLogo,
        nameAndLogoIcon: yourporterNameAndLogo,
        nameIcon: yourporterNameAndLogo,
        color: '#374AFF',
    },
    [ZENS_CHANNEL_ID]: {
        icon: zensLogo,
        nameIcon: zensLogo,
        nameAndLogoIcon: zensNameAndLogo,
    },
    [TOKEET_CHANNEL_ID]: {
        icon: tokeetLogo,
        nameIcon: tokeetLogo,
        nameAndLogoIcon: tokeetNameAndLogo,
    },
    [JETSTREAM_CHANNEL_ID]: {
        icon: jetstreamLogo,
        nameIcon: jetstreamLogo,
        nameAndLogoIcon: jetstreamNameAndLogo,
    },
    [ESCAPIA_CHANNEL_ID]: {
        icon: escapiaLogo,
        nameIcon: escapiaLogo,
        nameAndLogoIcon: escapiaNameAndLogo,
    },
    [STAYNTOUCH_CHANNEL_ID]: {
        icon: stayNTouchLogo,
        nameIcon: stayNTouchLogo,
        nameAndLogoIcon: stayNTouchNameAndLogo,
    },
    [RABBU_CHANNEL_ID]: {
        icon: rabbuLogo,
        nameIcon: rabbuLogo,
        nameAndLogoIcon: rabbuNameAndLogo,
    },
    [NATURALRETREATS_CHANNEL_ID]: {
        icon: naturalretreatsLogo,
        nameIcon: naturalretreatsLogo,
        nameAndLogoIcon: naturalretreatsNameAndLogo,
    },
    [CIIRUS_CHANNEL_ID]: {
        icon: ciirusLogo,
        nameIcon: ciirusLogo,
        nameAndLogoIcon: ciirusNameAndLogo,
    },
    [BESTBEACH_CHANNEL_ID]: {
        icon: bestBeachLogo,
        nameIcon: bestBeachLogo,
        nameAndLogoIcon: bestBeachNameAndLogo,
    },
    [GUESTYFORHOSTS_CHANNEL_ID]: {
        icon: guestyForHostsLogo,
        nameIcon: guestyForHostsLogo,
        nameAndLogoIcon: guestyForHostsNameAndLogo,
    },
    [AVANTSTAY_CHANNEL_ID]: {
        icon: avantstayLogo,
        nameIcon: avantstayLogo,
        nameAndLogoIcon: avantstayNameAndLogo,
    },
    [YOURRENTL_CHANNEL_ID]: {
        icon: yourrentlNameAndLogo,
        nameIcon: yourrentlNameAndLogo,
        nameAndLogoIcon: yourrentlNameAndLogo,
    },
    [BAREFOOT_CHANNEL_ID]: {
        icon: barefootLogo,
        nameIcon: barefootNameAndLogo,
        nameAndLogoIcon: barefootNameAndLogo,
    },
    [HOSPITABLE_CHANNEL_ID]: {
        icon: hospitableLogo,
        nameIcon: hospitableNameAndLogo,
        nameAndLogoIcon: hospitableNameAndLogo,
    },
    [OWNERREZ_CHANNEL_ID]: {
        icon: ownerRezLogo,
        nameIcon: ownerRezNameAndLogo,
        nameAndLogoIcon: ownerRezNameAndLogo,
    },
    [UPLISTING_CHANNEL_ID]: {
        icon: uplistingLogo,
        nameIcon: uplistingNameAndLogo,
        nameAndLogoIcon: uplistingNameAndLogo,
    },
    [RNS_CHANNEL_ID]: {
        icon: rnsLogo,
        nameIcon: rnsNameAndLogo,
        nameAndLogoIcon: rnsNameAndLogo,
    },
    [DIRECT_SOFTWARE_CHANNEL_ID]: {
        icon: directLogo,
        nameIcon: directNameAndLogo,
        nameAndLogoIcon: directNameAndLogo,
    },
    [RENTALWISE_CHANNEL_ID]: {
        icon: rentalwiseLogo,
        nameIcon: rentalwiseNameAndLogo,
        nameAndLogoIcon: rentalwiseNameAndLogo,
    },
};

export const ERROR_CODES = {
    DEFAULT: 0,
    INVALID_PASSWORD_AIRBNB: 2,
    INVALID_OAUTH_TOKEN: 3,
    INVALID_BILLING: 5,
    SOCIAL_ACCOUNT_EXISTS: 7,
    FAILED_DEPENDENCY: 424,
};

export const ADMIN_USER_LEVEL = 5;
export const BETA_USER_LEVEL = 2;
export const ALL_USER_LEVEL = 0;

export const USER_LEVEL_LONGFORM = {
    [ALL_USER_LEVEL]: 'Default',
    [BETA_USER_LEVEL]: 'Beta',
    [ADMIN_USER_LEVEL]: 'Admin',
};

export const CALENDAR_MODES = {
    CUSTOM_RATES: 'custom_rates',
    CUSTOM_RATE: 'custom_rate',
    DEFAULT: 'default',
};

export const SAVE_STATE = {
    UNCHANGED: 0,
    CHANGED: 1,
    SAVING: 2,
    SAVED: 3,
};

export const LISTING_FILTER = {
    OUT_OF_MARKET: {
        id: 'M',
        filter_key: 'out_of_market',
        name: 'Out of Market',
    },
    INACTIVE: {
        id: 'I',
        filter_key: 'inactive',
        name: 'Inactive',
    },
    ACTIVE: {
        id: 'A',
        filter_key: 'live',
        name: 'Active',
    },
    HIDDEN: {
        id: 'H',
        filter_key: 'hidden',
        name: 'Hidden',
    },
    HYPOTHETICAL: {
        id: 'HY',
        filter_key: 'hypothetical',
        name: 'Hypothetical',
    },
    NEEDS_DATA: {
        id: 'ND',
        filter_key: 'needs_data',
        name: 'Needs Data',
    },
    PENDING: {
        id: 'P',
        filter_key: 'pending',
        name: 'Pending',
    },
    BOOKINGS_PAGE: {
        filter_key: 'bookings_page',
    },
};

export const LISTING_DASHBOARD_STATE = {
    DEFAULT: 'default',
    WHEELHOUSE_PRICING: 'wheelhouse_price',
    PRICING_ELIGIBLE: 'pricing_eligible',
    DISCONNECTED: 'disconnected',
    NO_RECOMMENDATIONS: 'no_recs',
    REQUEST_RECONNECT: 'request_reconnect',
    OUT_OF_MARKET: 'out_of_market',
    INACTIVE: 'inactive',
};

export const PROXY_API_PREFIX = '/api';
export const LEFT_MENU_WIDTH = 230;

export const VIDEO_TUTORIAL_DASHBOARD = 'https://youtu.be/v52S3E_X9GI';
export const VIDEO_TUTORIAL_SETTINGS_BASEPRICE = 'https://www.loom.com/embed/4e654fb09fee4c4b9bb94c9937acd808';
export const VIDEO_TUTORIAL_SETTINGS_MINSTAY = 'https://www.loom.com/embed/1b785014dc67469bbf56f9be704b854b';
export const VIDEO_TUTORIAL_SETTINGS_SEASONALITY = 'https://www.loom.com/embed/4ee949f583614e77911d1172929219ee';
export const VIDEO_TUTORIAL_SETTINGS_WEEKEND = 'https://www.loom.com/embed/9781c208254f460d93068e104ccb1fab';
export const VIDEO_TUTORIAL_SETTINGS_LASTMINUTEDISCOUNTS = 'https://www.loom.com/embed/bb011e9f4ede4ed1b7ab458dbf8ef43e';
export const VIDEO_TUTORIAL_SETTINGS_MINPRICE = 'https://www.loom.com/embed/f6717a34906143ef9f13dcdff880aa45';
export const VIDEO_TUTORIAL_SETTINGS_FARFUTUREPREMIUM = 'https://www.loom.com/embed/4881cb30762a4d44a0fdca5645fb0d04';
export const VIDEO_TUTORIAL_SETTINGS_GAPS = 'https://www.loom.com/embed/3f01579fc4644b4bbc15e2ff37ace421';
export const VIDEO_TUTORIAL_SETTINGS_DEMANDSENSITIVITY = 'https://www.loom.com/embed/4a19d8a48d4a4436ae305c970dd489a6';
export const VIDEO_TUTORIAL_SETTINGS_CHECKIN_CHECKOUT = 'https://www.loom.com/embed/7ed24482f206421aa75ecfc6129b398c';
export const VIDEO_TUTORIAL_SETTINGS_LONGTERMDISCOUNTS = 'https://www.loom.com/embed/2c27ae8c02864e069e2dd0d9748ac1cf';
export const VIDEO_TUTORIAL_SETTINGS_MAXPRICE = 'https://www.loom.com/embed/7f2135d962e14f08bb7c1a4c526cbcd8';
export const VIDEO_TUTORIAL_SETTINGS_OCCUPANCYPACING = 'https://www.loom.com/embed/9d0da10b41294806a5da7857e7f9cbd4';

export const VIDEO_TUTORIAL_PORTFOLIO_SETTINGS = 'https://www.loom.com/share/854b2229a65d42d19805e9925e3fb801';
export const VIDEO_TUTORIAL_PORTFOLIO_CALENDAR = 'https://www.loom.com/share/3392483d62fe447194a44d931298df2d';
export const VIDEO_TUTORIAL_PORTFOLIO_PERFORMANCE = 'https://www.loom.com/share/356aa34ab68a4d21a5d41111265ff304';
export const VIDEO_INTRO_SETS =
    'https://www.loom.com/embed/2cde6a6d776d4df9b742804889ec2982?sid=4b1b88f1-dda3-4cae-a86d-886bf4fecae2?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true.';
export const VIDEO_SETS_OVERVIEW = 'https://www.youtube-nocookie.com/embed/sG-7pzW_hKc?si=1f7SsuSGsVQ0tsAr&amp;controls=0';

export const DEMAND_SENSITIVITY_OPTIONS = {
    CON: 0.9,
    REC: 1.0,
    AGG: 1.1,
};
