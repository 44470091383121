export const blue = '#0584FF';

export const grayLight = '#EEEBF0';
export const grayUltraLight = '#faf8fa';
export const grayFaded = '#D8D1DE';
export const grayMedium = '#756882';
export const grayDark = '#3D2B52';
export const grayDarker = '#261736';

export const pink = '#ff57ff';
export const pinkDark = '#f703cc';
export const pinkLighter = '#fdc5fd';

export const purple = '#D40BCC';
export const purpleLight = '#F650EF';
export const purpleDark = '#7B0575';

export const whiteText = '#ffffff';
export const offWhite = '#FAFAFD';
export const boxShadow = '#e1e4ed';
export const boxShadowLight = 'rgba(72,76,87,0.19)';

export const success = '#0BC397';
export const error = '#ff5a5f';
export const errorLight = '#FFD8DA';
export const warning = '#f3a536';
export const warningBackground = '#FEF6DE';
export const info = '#4e92df';

export const background = '#fafafb';

export const green = '#00cc87';
export const greenVivid = '#19A974';

export const customRateBackground = '#FFFDF7';
export const customRateBorder = '#FFCE00';

export const border = '#b1b6c7';
