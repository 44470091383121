import type { BoundaryType } from 'libs/types/set';
import { mapStyleImages } from './mapStyleImages';
import { Bank, Buildings, Coins, DotsThreeCircle, Gauge, House, PencilSimpleLine, Storefront } from 'phosphor-react';

export const mapboxAccessToken = 'pk.eyJ1IjoicGhpbGtleXMiLCJhIjoiY2owYmFwdTk2MDJ3NjMybzJxNndwbWxyayJ9.snc2_isAmGSK14P2nwD-FA';
// saved in local storage as an array
// when updating widget UI, need to update this to reset everyone
export const SETS_WIDGETS_SIDEBAR_KEY = 'savedWidgets-7-26-2024';
// saved in local storage as object
export const SETS_POPUP_KEY = 'sets-popup-configuration';
// saved in local storage as object
export const SETS_LISTING_SIDEBAR_KEY = 'sets-sidebar-listing-configuration';
export const SETS_SAVED_BOUNDARIES_KEY = 'sets-saved-boundaries';
export const SETS_UI_KEY = 'sets-ui';
export const SETS_DRAFT_KEY = 'sets-draft';
export const FiltersLogicalOperators = {
    AND: 'and',
    OR: 'or',
};

export const LIMITED_DATA_COUNT = 25;
export const MAP_ACTIONS_TOOLBAR_HEIGHT = 34;
export const SEARCH_DEFAULT = 5000;
export const SEARCH_LIMIT = 10000;
export const SET_KINDS = ['competitive', 'market', 'neighborhood', 'marketing', 'merchandising', 'acquisition', 'underwriting', 'other'];
export const ICON_BY_KIND = {
    competitive: Gauge,
    market: Buildings,
    neighborhood: House,
    marketing: Coins,
    merchandising: Storefront,
    acquisition: Bank,
    underwriting: PencilSimpleLine,
    other: DotsThreeCircle,
};
export const COLOR_BY_KIND = {
    competitive: 'blue',
    market: 'green',
    neighborhood: 'red',
    marketing: 'pink',
    merchandising: 'teal',
    acquisition: 'yellow',
    underwriting: 'purple',
    other: 'gray',
};

// Enum for the different types of aggregations available for widgets
export const AggregationTypes = Object.freeze({
    /** Count */
    COUNT: 'count',

    /** Average */
    AVG: 'avg',

    /** Minimum */
    MIN: 'min',

    /** Maximum */
    MAX: 'max',

    /** Sum */
    SUM: 'sum',

    /** Custom aggregation expression */
    CUSTOM: 'custom',
});

export const mapStyles = {
    wheelhouse: {
        name: 'Wheelhouse',
        url: 'mapbox://styles/philkeys/clkk52748009n01pj0slv1bdv',
        image: mapStyleImages.wheelhouse,
    },
    streets: {
        name: 'Streets',
        url: 'mapbox://styles/mapbox/streets-v12',
        image: mapStyleImages.streets,
    },
    outdoors: {
        name: 'Outdoors',
        url: 'mapbox://styles/mapbox/outdoors-v12',
        image: mapStyleImages.outdoors,
    },
    light: {
        name: 'Light',
        url: 'mapbox://styles/mapbox/light-v11',
        image: mapStyleImages.light,
    },
    dark: {
        name: 'Dark',
        url: 'mapbox://styles/mapbox/dark-v11',
        image: mapStyleImages.dark,
    },
    satellite: {
        name: 'Satellite',
        url: 'mapbox://styles/mapbox/satellite-v9',
        image: mapStyleImages.satellite,
    },
    'satellite-streets': {
        name: 'Satellite Streets',
        url: 'mapbox://styles/mapbox/satellite-streets-v12',
        image: mapStyleImages['satellite-streets'],
    },
    'navigation-day': {
        name: 'Navigation Day',
        url: 'mapbox://styles/mapbox/navigation-day-v1',
        image: mapStyleImages['navigation-day'],
    },
    'navigation-night': {
        name: 'Navigation Night',
        url: 'mapbox://styles/mapbox/navigation-night-v1',
        image: mapStyleImages['navigation-night'],
    },
};

export const LAYERS = {
    PREVIEW_BOUNDARY_LAYERID: 'preview-boundary-layer',
    POTENTIAL_LISTINGS_LAYERID: 'potential-listing-points-layer',
    POTENTIAL_LISTINGS_CLUSTERED: 'potential-listing-points-clustered-layer',
    HIDDEN_LISTINGS_LAYERID: 'hidden-listing-points-layer',
    REVIEW_LISTINGS_LAYERID: 'review-listing-points-layer',
    IN_SET_LISTINGS_LAYERID: 'set-listing-points-layer',
    COMPARE_LISTINGS_LAYERID: 'compare-listing-points-layer',
    USER_LISTINGS_LAYERID: 'user-listing-points-layer',
    ASSIGNED_LISTINGS_LAYERID: 'assigned-listing-points-layer',
    SET_LISTINGS_LAYERID: 'set-listing-points-layer',
};

export const SOURCE = {
    POTENTIAL_LISTINGS_SOURCE: 'potential-listing-points',
    HIDDEN_LISTINGS_SOURCE: 'hidden-listing-points',
    REVIEW_LISTINGS_SOURCE: 'review-listing-points',
    IN_SET_LISTINGS_SOURCE: 'set-listing-points',
    COMPARE_LISTINGS_SOURCE: 'compare-listing-points',
    USER_LISTINGS_SOURCE: 'user-listing-points',
    SET_LISTINGS_SOURCE: 'set-listing-points',
};

export const SIDEBAR_CONTENT = {
    DETAILS: 'details',
    CHARTS: 'charts',
    COMPARE: 'compare',
    LISTINGS: 'listings',
    NOTES: 'notes',
    LAYERS: 'layers',
    MAP: 'map',
};

export const LEFT_SIDEBAR_CONTENT = {
    COMPARE: 'compare' as const,
    POTENTIAL: 'potential' as const,
    ACTIVE: 'active' as const,
    HIDDEN: 'hidden' as const,
    REVIEW: 'review' as const,
    RECENT: 'recent' as const,
    NEW: 'new' as const,
};

export const BOUNDARY_TYPE: Record<string, BoundaryType> = {
    MARKET: 'market',
    POLYGON: 'polygon',
    POINT: 'point',
    VIEWPORT: 'viewport',
    LOCATION: 'location',
};

export const SET_TYPE_COLORS = {
    POTENTIAL: '#444',
    ACTIVE: '#D65CD6',
    REVIEW: '#004E65',
    HIDDEN: '#7A3930',
    COMPARE: '#444',
};

export const COLORSX = [
    '#fbb03b',
    '#0CA5F0',
    '#e55e5e',
    '#203779',
    '#EB4899',
    '#805CD6',
    '#19A247',
    '#335CCC',
    '#9C4504',
    '#D65CD6',
    '#FBE146',
    '#DF7D43',
    '#309324',
    '#F3AAE8',
    '#EFBC9F',
    '#AEE3F5',

    '#E0DA92',
    '#AEE9A7',
    '#B2C1EE',
    '#ccc',
];

export const COLORS = [
    '#FBB03C',
    '#0CA5F0',
    '#86C76D',
    '#233B53',
    '#FCE468',
    '#E55D5D',
    '#1E7D3D',
    '#335CCC',
    '#D9B5D5',
    '#AED1E7',
    '#655AA7',
    '#61293D',
    '#9961A8',
    '#174585',

    '#C158A2',
    '#FCE468',
    '#9FA439',
    '#805E5E',
    '#BFB890',
    '#595A5A',
];

export const STATUS = {
    LOADING: 'loading' as const,
    ONBOARDING: 'onboarding' as const,
    CONTINUE: 'continue' as const,
    SETUP: 'setup' as const,
    LOCATION: 'location' as const,
};

const defaultWidgetProperties = {
    dataSource: 'potential-listing-points',
    filterable: true,
    collapsible: true,
    isValid: true,
    isHidden: false,
    isCollapsed: false,
    autoCollapse: true,
    global: false,
    wrapperProps: {
        expanded: true,
    },
};

export const staticWidgets = ['3id32iodn4fingref'];

export const histogramIds = [
    'bookable_nights',
    'blocked_nights',
    'booked_nights',
    'los',
    'available_nights',
    'apr',
    'occupancy',
    'open_occupancy',
    'anr',
    'review_count',
    'cleaning_fee',
    'star_rating',
    'anr_0_90',
    'anr_0_365',
    'anr_90_0',
    'anr_365_0',
    'apr_0_90',
    'apr_0_365',
    'apr_90_0',
    'apr_365_0',
    'available_nights_0_90',
    'available_nights_0_365',
    'available_nights_90_0',
    'available_nights_365_0',
    'blocked_nights_0_90',
    'blocked_nights_0_365',
    'blocked_nights_90_0',
    'blocked_nights_365_0',
    'bookable_nights_0_90',
    'bookable_nights_0_365',
    'bookable_nights_90_0',
    'bookable_nights_365_0',
    'booked_nights_0_90',
    'booked_nights_0_365',
    'booked_nights_90_0',
    'booked_nights_365_0',
    'calendar_nights_0_90',
    'calendar_nights_0_365',
    'calendar_nights_90_0',
    'calendar_nights_365_0',
    'lead_time_0_90',
    'lead_time_0_365',
    'lead_time_90_0',
    'lead_time_365_0',
    'los_0_90',
    'los_0_365',
    'los_90_0',
    'los_365_0',
    'nrevenue_0_90',
    'nrevenue_0_365',
    'nrevenue_90_0',
    'nrevenue_365_0',
    'nrevpar_0_90',
    'nrevpar_0_365',
    'nrevpar_90_0',
    'nrevpar_365_0',
    'nrevpar_open_0_90',
    'nrevpar_open_0_365',
    'nrevpar_open_90_0',
    'nrevpar_open_365_0',
    'occupancy_0_90',
    'occupancy_0_365',
    'occupancy_90_0',
    'occupancy_365_0',
    'open_occupancy_0_90',
    'open_occupancy_0_365',
    'open_occupancy_90_0',
    'open_occupancy_365_0',
    'openness_0_90',
    'openness_0_365',
    'openness_90_0',
    'openness_365_0',
];

export const defaultWidgets = [
    {
        id: 'listing_id',
        title: 'Listing count',
        description: 'Count of potential competitor listings in the map view',
        note: '',
        type: 'listing',
        operation: 'count',
        animation: false,
        global: false,
        column: 'listing_id',
        dataSource: 'potential-listing-points',
        isValid: true,
    },
    {
        id: 'room_type',
        title: 'Room types',
        note: '',
        type: 'category',
        operation: 'count',
        column: 'room_type',
        searchable: true,
        ...defaultWidgetProperties,
    },
    {
        id: 'bedrooms',
        title: 'Bedrooms',
        note: '',
        type: 'category',
        operation: 'count',
        column: 'bedrooms',
        searchable: true,
        ...defaultWidgetProperties,
    },
    {
        id: 'bathrooms',
        title: 'Bathrooms',
        note: '',
        type: 'category',
        operation: 'count',
        column: 'bathrooms',
        searchable: true,
        ...defaultWidgetProperties,
    },
    {
        id: 'sleeps',
        title: 'Sleeps',
        note: '',
        type: 'category',
        operation: 'count',
        searchable: true,
        column: 'sleeps',
        formatter: (value) => `${value} listings`,
        ...defaultWidgetProperties,
    },
    {
        id: 'bookable_nights',
        title: 'Bookable nights',
        description: 'Number of nights that can be booked by a guest, i.e excluding blocked nights.',
        note: '',
        type: 'histogram',
        operation: 'count',
        bins: 30,
        column: 'bookable_nights',
        isTypeDependent: true,
        ...defaultWidgetProperties,
    },
    {
        id: 'blocked_nights',
        title: 'Blocked nights',
        description: 'Number of nights that are blocked off by the host.',
        note: '',
        type: 'histogram',
        operation: 'count',
        xAxisFormatter: (value) => value.toFixed(0),
        column: 'blocked_nights',
        bins: 30,
        isTypeDependent: true,
        ...defaultWidgetProperties,
    },
    {
        id: 'booked_nights',
        title: 'Booked nights',
        description: 'Number of nights that are booked by a guest.',
        note: '',
        type: 'histogram',
        operation: 'count',
        formatter: (value) => `${parseInt(value)}`,
        xAxisFormatter: (value) => value.toFixed(0),
        bins: 30,
        column: 'booked_nights',
        isTypeDependent: true,
        ...defaultWidgetProperties,
    },
    {
        id: 'los',
        title: 'Length of stay',
        note: '',
        type: 'histogram',
        bins: 30,
        operation: 'count',
        formatter: (value) => parseInt(value),
        xAxisFormatter: (value) => value.toFixed(0),
        column: 'los',
        isTypeDependent: true,
        ...defaultWidgetProperties,
    },
    {
        id: 'available_nights',
        title: 'Available nights',
        description: 'Number of nights still available to book by a guest.',
        note: '',
        type: 'histogram',
        operation: 'count',
        column: 'available_nights',
        bins: 30,
        xAxisFormatter: (value) => value.toFixed(0),
        isTypeDependent: true,
        ...defaultWidgetProperties,
    },
    {
        id: 'apr',
        title: 'Asking rate',
        description: 'Average nightly price of bookable nights, i.e. excluding blocked nights, in a time frame.',
        note: '',
        type: 'histogram',
        operation: 'count',
        xAxisFormatter: (value) => value.toFixed(0),
        bins: 50,
        column: 'apr',
        isTypeDependent: true,
        ...defaultWidgetProperties,
        isHidden: false,
    },
    {
        id: 'occupancy',
        title: 'Occupancy',
        isDecimal: true,
        description:
            'Percentage of nights booked out of total nights in a time-frame: booked nights / calendar nights. This means that “Occupancy” = “Adjusted Occupancy” * “Openness”.',
        note: '',
        type: 'histogram',
        ...defaultWidgetProperties,
        operation: 'count',
        tickFormatter: (value) => value,
        xAxisFormatter: (value) => ({
            value: (value * 100).toFixed(0),
            suffix: '%',
        }),
        bins: 10,
        column: 'occupancy',
        isTypeDependent: true,
        isHidden: false,
    },
    {
        id: 'open_occupancy',
        title: 'Adjusted occupancy',
        isDecimal: true,
        note: 'Percentage of nights booked out of bookable nights, i.e. excluding blocked nights, in a time-frame: booked nights / bookable nights.',
        type: 'histogram',
        ...defaultWidgetProperties,
        operation: 'count',
        tickFormatter: (value) => value,
        xAxisFormatter: (value) => ({
            value: (value * 100).toFixed(0),
            suffix: '%',
        }),
        column: 'open_occupancy',
        isTypeDependent: true,
        bins: 10,
        isHidden: false,
    },
    {
        id: 'anr',
        title: 'Avg. nightly revenue',
        description: 'Average of nightly prices of booked nights in a time-frame.',
        note: '',
        type: 'histogram',
        operation: 'count',
        bins: 50,
        column: 'anr',
        isTypeDependent: true,
        ...defaultWidgetProperties,
        xAxisFormatter: (value) => value.toFixed(0),
        isHidden: false,
    },
    {
        id: 'amenities',
        title: 'Amenities',
        description: 'Filter by amenities',
        note: '',
        type: 'amenities',
        animation: false,
        global: false,
        column: 'amenities',
        dataSource: 'potential-listing-points',
        isValid: true,
        isHidden: false,
    },
    {
        id: 'owner_name',
        title: 'Operators',
        type: 'category',
        operation: 'count',
        column: 'owner_name',
        searchable: true,
        labels: {
            null: 'Unknown',
        },
        ...defaultWidgetProperties,
        wrapperProps: {
            ...defaultWidgetProperties.wrapperProps,
        },
    },
    {
        id: 'star_rating',
        title: 'Avg. rating',
        note: '',
        type: 'histogram',
        operation: 'count',
        ticks: [
            0, 0.0625, 0.125, 0.1875, 0.25, 0.3125, 0.375, 0.4375, 0.5, 0.5625, 0.625, 0.6875, 0.75, 0.8125, 0.875, 0.9375, 1, 1.0625, 1.125, 1.1875, 1.25,
            1.3125, 1.375, 1.4375, 1.5, 1.5625, 1.625, 1.6875, 1.75, 1.8125, 1.875, 1.9375, 2, 2.0625, 2.125, 2.1875, 2.25, 2.3125, 2.375, 2.4375, 2.5, 2.5625,
            2.625, 2.6875, 2.75, 2.8125, 2.875, 2.9375, 3, 3.0625, 3.125, 3.1875, 3.25, 3.3125, 3.375, 3.4375, 3.5, 3.5625, 3.625, 3.6875, 3.75, 3.8125, 3.875,
            3.9375, 4, 4.0625, 4.125, 4.1875, 4.25, 4.3125, 4.375, 4.4375, 4.5, 4.5625, 4.625, 4.6875, 4.75, 4.8125, 4.875, 4.9375, 5,
        ],
        column: 'star_rating',
        isTypeDependent: false,
        ...defaultWidgetProperties,
        isHidden: false,
    },
    {
        id: 'review_count',
        title: 'Review count',
        note: '',
        type: 'histogram',
        operation: 'count',
        bins: 50,
        column: 'review_count',
        isTypeDependent: false,
        ...defaultWidgetProperties,
        isHidden: false,
        xAxisFormatter: (value) => value.toFixed(0),
    },
    {
        id: 'cleaning_fee',
        title: 'Cleaning fee',
        note: '',
        type: 'histogram',
        bins: 20,
        operation: 'count',
        column: 'cleaning_fee',
        isTypeDependent: false,
        xAxisFormatter: (value) => value.toFixed(0),
        ...defaultWidgetProperties,
        isHidden: false,
    },
];

export const sort_configuration = [
    { field: 'address', type: 'string' },
    { field: 'amenities', type: 'string' },
    { field: 'anr_0_90', type: 'number' },
    { field: 'anr_0_365', type: 'number' },
    { field: 'anr_90_0', type: 'number' },
    { field: 'anr_365_0', type: 'number' },
    { field: 'apr_0_90', type: 'number' },
    { field: 'apr_0_365', type: 'number' },
    { field: 'apr_90_0', type: 'number' },
    { field: 'apr_365_0', type: 'number' },
    { field: 'available_nights_0_90', type: 'number' },
    { field: 'available_nights_0_365', type: 'number' },
    { field: 'available_nights_90_0', type: 'number' },
    { field: 'available_nights_365_0', type: 'number' },
    { field: 'bathrooms', type: 'number' },
    { field: 'bedrooms', type: 'number' },
    { field: 'beds', type: 'number' },
    { field: 'blocked_nights_0_90', type: 'number' },
    { field: 'blocked_nights_0_365', type: 'number' },
    { field: 'blocked_nights_90_0', type: 'number' },
    { field: 'blocked_nights_365_0', type: 'number' },
    { field: 'bookable_nights_0_90', type: 'number' },
    { field: 'bookable_nights_0_365', type: 'number' },
    { field: 'bookable_nights_90_0', type: 'number' },
    { field: 'bookable_nights_365_0', type: 'number' },
    { field: 'booked_nights_0_90', type: 'number' },
    { field: 'booked_nights_0_365', type: 'number' },
    { field: 'booked_nights_90_0', type: 'number' },
    { field: 'booked_nights_365_0', type: 'number' },
    { field: 'calendar_nights_0_90', type: 'number' },
    { field: 'calendar_nights_0_365', type: 'number' },
    { field: 'calendar_nights_90_0', type: 'number' },
    { field: 'calendar_nights_365_0', type: 'number' },
    { field: 'cleaning_fee', type: 'number' },
    { field: 'currency', type: 'string' },
    { field: 'guests_included', type: 'number' },
    { field: 'lat', type: 'number' },
    { field: 'lead_time_0_90', type: 'number' },
    { field: 'lead_time_0_365', type: 'number' },
    { field: 'lead_time_90_0', type: 'number' },
    { field: 'lead_time_365_0', type: 'number' },
    { field: 'listing_created_at', type: 'string' },
    { field: 'listing_id', type: 'number' },
    { field: 'listing_last_updated', type: 'string' },
    { field: 'long', type: 'number' },
    { field: 'los_0_90', type: 'number' },
    { field: 'los_0_365', type: 'number' },
    { field: 'los_90_0', type: 'number' },
    { field: 'los_365_0', type: 'number' },
    { field: 'market_id', type: 'number' },
    { field: 'nrevenue_0_90', type: 'number' },
    { field: 'nrevenue_0_365', type: 'number' },
    { field: 'nrevenue_90_0', type: 'number' },
    { field: 'nrevenue_365_0', type: 'number' },
    { field: 'nrevpar_0_90', type: 'number' },
    { field: 'nrevpar_0_365', type: 'number' },
    { field: 'nrevpar_90_0', type: 'number' },
    { field: 'nrevpar_365_0', type: 'number' },
    { field: 'nrevpar_open_0_90', type: 'number' },
    { field: 'nrevpar_open_0_365', type: 'number' },
    { field: 'nrevpar_open_90_0', type: 'number' },
    { field: 'nrevpar_open_365_0', type: 'number' },
    { field: 'occupancy_0_90', type: 'number' },
    { field: 'occupancy_0_365', type: 'number' },
    { field: 'occupancy_90_0', type: 'number' },
    { field: 'occupancy_365_0', type: 'number' },
    { field: 'open_occupancy_0_90', type: 'number' },
    { field: 'open_occupancy_0_365', type: 'number' },
    { field: 'open_occupancy_90_0', type: 'number' },
    { field: 'open_occupancy_365_0', type: 'number' },
    { field: 'openness_0_90', type: 'number' },
    { field: 'openness_0_365', type: 'number' },
    { field: 'openness_90_0', type: 'number' },
    { field: 'openness_365_0', type: 'number' },
    { field: 'property_type', type: 'string' },
    { field: 'review_count', type: 'number' },
    { field: 'room_type', type: 'string' },
    { field: 'sleeps', type: 'number' },
    { field: 'star_rating', type: 'string' },
    { field: 'thumb_url', type: 'string' },
    { field: 'title', type: 'string' },
    { field: 'updated_at', type: 'string' },
    { field: 'status', type: 'string' },
];

export const metrics_label_dict = {
    address: 'Address',
    amenities: 'Amenities',
    anr_0_90: 'Avg. nightly rate',
    anr_0_365: 'Avg. nightly rate',
    anr_90_0: 'Avg. nightly rate',
    anr_365_0: 'Avg. nightly rate',
    apr_0_90: 'Asking rate',
    apr_0_365: 'Asking rate',
    apr_90_0: 'Asking rate',
    apr_365_0: 'Asking rate',
    available_nights_0_90: 'Available nights',
    available_nights_0_365: 'Available nights',
    available_nights_90_0: 'Available nights',
    available_nights_365_0: 'Available nights',
    bathrooms: 'Bathrooms',
    bedrooms: 'Bedrooms',
    beds: 'Beds',
    blocked_nights_0_90: 'Blocked nights',
    blocked_nights_0_365: 'Blocked nights',
    blocked_nights_90_0: 'Blocked nights',
    blocked_nights_365_0: 'Blocked nights',
    bookable_nights_0_90: 'Bookable nights',
    bookable_nights_0_365: 'Bookable nights',
    bookable_nights_90_0: 'Bookable nights',
    bookable_nights_365_0: 'Bookable nights',
    booked_nights_0_90: 'Booked nights',
    booked_nights_0_365: 'Booked nights',
    booked_nights_90_0: 'Booked nights',
    booked_nights_365_0: 'Booked nights',
    calendar_nights_0_90: 'Calendar nights',
    calendar_nights_0_365: 'Calendar nights',
    calendar_nights_90_0: 'Calendar nights',
    calendar_nights_365_0: 'Calendar nights',
    cleaning_fee: 'Cleaning fee',
    currency: 'Currency',
    guests_included: 'Guests incl.',
    lat: 'Latitude',
    lead_time_0_90: 'Lead time',
    lead_time_0_365: 'Lead time',
    lead_time_90_0: 'Lead time',
    lead_time_365_0: 'Lead time',
    listing_created_at: 'Created at',
    listing_id: 'id',
    listing_last_updated: 'Last updated',
    long: 'Longitude',
    los_0_90: 'Length of stay',
    los_0_365: 'Length of stay',
    los_90_0: 'Length of stay',
    los_365_0: 'Length of stay',
    market_id: 'Market',
    nrevenue_0_90: 'Nightly revenue',
    nrevenue_0_365: 'Nightly revenue',
    nrevenue_90_0: 'Nightly revenue',
    nrevenue_365_0: 'Nightly revenue',
    nrevpar_0_90: 'Nightly RevPar',
    nrevpar_0_365: 'Nightly RevPar',
    nrevpar_90_0: 'Nightly RevPar',
    nrevpar_365_0: 'Nightly RevPar',
    nrevpar_open_0_90: 'Adjusted Nightly RevPar',
    nrevpar_open_0_365: 'Adjusted Nightly RevPar',
    nrevpar_open_90_0: 'Adjusted Nightly RevPar',
    nrevpar_open_365_0: 'Adjusted Nightly RevPar',
    occupancy_0_90: 'Occupancy',
    occupancy_0_365: 'Occupancy',
    occupancy_90_0: 'Occupancy',
    occupancy_365_0: 'Occupancy',
    open_occupancy_0_90: 'Adjusted occupancy',
    open_occupancy_0_365: 'Adjusted occupancy',
    open_occupancy_90_0: 'Adjusted ooccupancy',
    open_occupancy_365_0: 'Adjusted occupancy',
    openness_0_90: 'Openness',
    openness_0_365: 'Openness',
    openness_90_0: 'Openness',
    openness_365_0: 'Openness',
    owner_name: 'Owner name',
    property_type: 'Property type',
    review_count: 'Review count',
    room_type: 'Room type',
    sleeps: 'Sleeps',
    star_rating: 'Star rating',
    thumb_url: 'Image',
    title: 'Title',
    updated_at: 'Updated at',
    status: 'Status',
};

export const code_layers = {
    wisconsin: {
        url: 'mapbox://philkeys.9x6bf2z9',
        source: 'USA_WI_ZIP_Code_Boundaries-bi722w',
    },
    california: {
        url: 'mapbox://philkeys.dhomdp3b',
        source: 'USA_CA_ZIP_Code_Boundaries-3g3e1p',
    },
    tennessee: {
        url: 'mapbox://philkeys.8fmirszq',
        source: 'USA_TN_ZIP_Code_Boundaries-d31opd',
    },
    louisiana: {
        url: 'mapbox://philkeys.dn95yvac',
        source: 'USA_LA_ZIP_Code_Boundaries-34ni09',
    },
    florida: {
        url: 'mapbox://philkeys.dpgtuxt2',
        source: 'USA_FL_GA_AL_MS_SC_NC_ZIP_Cod-2opxor',
    },
    'north carolina': {
        url: 'mapbox://philkeys.dpgtuxt2',
        source: 'USA_FL_GA_AL_MS_SC_NC_ZIP_Cod-2opxor',
    },
    'south carolina': {
        url: 'mapbox://philkeys.dpgtuxt2',
        source: 'USA_FL_GA_AL_MS_SC_NC_ZIP_Cod-2opxor',
    },
    mississippi: {
        url: 'mapbox://philkeys.dpgtuxt2',
        source: 'USA_FL_GA_AL_MS_SC_NC_ZIP_Cod-2opxor',
    },
    alabama: {
        url: 'mapbox://philkeys.dpgtuxt2',
        source: 'USA_FL_GA_AL_MS_SC_NC_ZIP_Cod-2opxor',
    },
    georgia: {
        url: 'mapbox://philkeys.dpgtuxt2',
        source: 'USA_FL_GA_AL_MS_SC_NC_ZIP_Cod-2opxor',
    },
    maine: {
        url: 'mapbox://philkeys.dpgtuxt2',
        source: 'USA_ME_MA_NH_VT_RI_CT_ZIP_Cod-d20h3y',
    },
    'new hampshire': {
        url: 'mapbox://philkeys.dpgtuxt2',
        source: 'USA_ME_MA_NH_VT_RI_CT_ZIP_Cod-d20h3y',
    },
    vermont: {
        url: 'mapbox://philkeys.dpgtuxt2',
        source: 'USA_ME_MA_NH_VT_RI_CT_ZIP_Cod-d20h3y',
    },
    massachusetts: {
        url: 'mapbox://philkeys.dpgtuxt2',
        source: 'USA_ME_MA_NH_VT_RI_CT_ZIP_Cod-d20h3y',
    },
    'rhode island': {
        url: 'mapbox://philkeys.dpgtuxt2',
        source: 'USA_ME_MA_NH_VT_RI_CT_ZIP_Cod-d20h3y',
    },
    connecticut: {
        url: 'mapbox://philkeys.dpgtuxt2',
        source: 'USA_ME_MA_NH_VT_RI_CT_ZIP_Cod-d20h3y',
    },
    'new york': {
        url: 'mapbox://philkeys.af07121p',
        source: 'USA_NY_NJ_VA_MD_DE_DC_ZIP_Cod-44nqlt',
    },
    'new jersey': {
        url: 'mapbox://philkeys.af07121p',
        source: 'USA_NY_NJ_VA_MD_DE_DC_ZIP_Cod-44nqlt',
    },
    maryland: {
        url: 'mapbox://philkeys.af07121p',
        source: 'USA_NY_NJ_VA_MD_DE_DC_ZIP_Cod-44nqlt',
    },
    delaware: {
        url: 'mapbox://philkeys.af07121p',
        source: 'USA_NY_NJ_VA_MD_DE_DC_ZIP_Cod-44nqlt',
    },
    virgina: {
        url: 'mapbox://philkeys.af07121p',
        source: 'USA_NY_NJ_VA_MD_DE_DC_ZIP_Cod-44nqlt',
    },
    'district of columbia': {
        url: 'mapbox://philkeys.af07121p',
        source: 'USA_NY_NJ_VA_MD_DE_DC_ZIP_Cod-44nqlt',
    },
    pennsylvania: {
        url: 'mapbox://philkeys.dga1hn6i',
        source: 'USA_PA__WV_KY_OH_MI_IL_IA_IN_-6ixn6x',
    },
    'west virginia': {
        url: 'mapbox://philkeys.dga1hn6i',
        source: 'USA_PA__WV_KY_OH_MI_IL_IA_IN_-6ixn6x',
    },
    kentucky: {
        url: 'mapbox://philkeys.dga1hn6i',
        source: 'USA_PA__WV_KY_OH_MI_IL_IA_IN_-6ixn6x',
    },
    ohio: {
        url: 'mapbox://philkeys.dga1hn6i',
        source: 'USA_PA__WV_KY_OH_MI_IL_IA_IN_-6ixn6x',
    },
    michigan: {
        url: 'mapbox://philkeys.dga1hn6i',
        source: 'USA_PA__WV_KY_OH_MI_IL_IA_IN_-6ixn6x',
    },
    illinois: {
        url: 'mapbox://philkeys.dga1hn6i',
        source: 'USA_PA__WV_KY_OH_MI_IL_IA_IN_-6ixn6x',
    },
    iowa: {
        url: 'mapbox://philkeys.dga1hn6i',
        source: 'USA_PA__WV_KY_OH_MI_IL_IA_IN_-6ixn6x',
    },
    minnesota: {
        url: 'mapbox://philkeys.dga1hn6i',
        source: 'USA_PA__WV_KY_OH_MI_IL_IA_IN_-6ixn6x',
    },
    indiana: {
        url: 'mapbox://philkeys.dga1hn6i',
        source: 'USA_PA__WV_KY_OH_MI_IL_IA_IN_-6ixn6x',
    },
    missouri: {
        url: 'mapbox://philkeys.68nhp4ev',
        source: 'USA_MO_AR_OK_KA_NE_ZIP_Code_B-2vr52e',
    },
    arkansas: {
        url: 'mapbox://philkeys.68nhp4ev',
        source: 'USA_MO_AR_OK_KA_NE_ZIP_Code_B-2vr52e',
    },
    oklahoma: {
        url: 'mapbox://philkeys.68nhp4ev',
        source: 'USA_MO_AR_OK_KA_NE_ZIP_Code_B-2vr52e',
    },
    kansas: {
        url: 'mapbox://philkeys.68nhp4ev',
        source: 'USA_MO_AR_OK_KA_NE_ZIP_Code_B-2vr52e',
    },
    nebraska: {
        url: 'mapbox://philkeys.68nhp4ev',
        source: 'USA_MO_AR_OK_KA_NE_ZIP_Code_B-2vr52e',
    },
    montana: {
        url: 'mapbox://philkeys.0v0revji',
        source: 'USA_MT_WY_ID_UT_CO_ZIP_Code_B-aow7i9',
    },
    wyoming: {
        url: 'mapbox://philkeys.0v0revji',
        source: 'USA_MT_WY_ID_UT_CO_ZIP_Code_B-aow7i9',
    },
    idaho: {
        url: 'mapbox://philkeys.0v0revji',
        source: 'USA_MT_WY_ID_UT_CO_ZIP_Code_B-aow7i9',
    },
    utah: {
        url: 'mapbox://philkeys.0v0revji',
        source: 'USA_MT_WY_ID_UT_CO_ZIP_Code_B-aow7i9',
    },
    colorado: {
        url: 'mapbox://philkeys.0v0revji',
        source: 'USA_MT_WY_ID_UT_CO_ZIP_Code_B-aow7i9',
    },
    'new mexico': {
        url: 'mapbox://philkeys.asfv9hyu',
        source: 'USA_AZ_NM_NV_ZIP_Code_Boundar-4xa6wa',
    },
    arizona: {
        url: 'mapbox://philkeys.asfv9hyu',
        source: 'USA_AZ_NM_NV_ZIP_Code_Boundar-4xa6wa',
    },
    nevada: {
        url: 'mapbox://philkeys.asfv9hyu',
        source: 'USA_AZ_NM_NV_ZIP_Code_Boundar-4xa6wa',
    },
    texas: {
        url: 'mapbox://philkeys.6lc0shk0',
        source: 'USA_TX_ZIP_Code_Boundaries-7f09bw',
    },
    oregon: {
        url: 'mapbox://philkeys.30tbzhad',
        source: 'USA_OR_WA_ZIP_Code_Boundaries-4i00ue',
    },
    washington: {
        url: 'mapbox://philkeys.30tbzhad',
        source: 'USA_OR_WA_ZIP_Code_Boundaries-4i00ue',
    },
    alaska: {
        url: 'mapbox://philkeys.c8fa2cid',
        source: 'USA_AK_ZIP_Code_Boundaries-4wof2x',
    },
    hawaii: {
        url: 'mapbox://philkeys.4cozv8y4',
        source: 'USA_HI_ZIP_Code_Boundaries-cnd0w3',
    },
};

export const initialSet = {
    id: null,
    name: 'Draft',
    created_at: new Date(),
    updated_at: new Date(),
    description: '',
    listings_with_metrics: {
        active: [],
        review: [],
        hidden: [],
    },
    notes: [],
    lastSpatialFilter: null,
};

export const localStorageTypes = {
    [SETS_DRAFT_KEY]: {
        key: [SETS_DRAFT_KEY],
        initial: initialSet,
    },
    'sets-ui': {
        key: 'sets-ui',
        initial: {
            showRightSidebar: true,
            showLeftSidebar: false,
            popupAppearance: 'default',
            popupTrigger: 'click',
            autoSearch: false,
            metricPeriod: '0_90',
            popupOffset: 0,
        },
    },
    [SETS_POPUP_KEY]: {
        key: SETS_POPUP_KEY,
        initial: {
            title: true,
            address: true,
            thumbnail: true,
            attributes: [
                { property: 'room_type', label: 'Type', type: 'string' },
                { property: 'bedrooms', label: 'Bedrooms', type: 'number' },
                { property: 'beds', label: 'Beds', type: 'number' },
                { property: 'bathrooms', label: 'Baths', type: 'number' },
            ],
            metrics: [
                { property: 'apr', label: 'Asking rate', type: 'number' },
                { property: 'anr', label: 'Avg. nightly rate', type: 'number' },
                { property: 'occupancy', label: 'Occupancy', type: 'number' },
                { property: 'openness', label: 'Openness', type: 'number' },
            ],
            calendar: true,
        },
    },
    [SETS_LISTING_SIDEBAR_KEY]: {
        key: SETS_LISTING_SIDEBAR_KEY,
        initial: {
            title: true,
            address: true,
            thumbnail: true,
            thumbnailSize: 'small',
            attributes: [
                { property: 'room_type', label: 'Type', type: 'string' },
                { property: 'bedrooms', label: 'Bedrooms', type: 'number' },
                { property: 'beds', label: 'Beds', type: 'number' },
                { property: 'bathrooms', label: 'Baths', type: 'number' },
            ],
            metrics: [
                { property: 'occupancy', label: 'Occupancy', type: 'number' },
                { property: 'nrevenue', label: 'Nightly revenue', type: 'number' },
                { property: 'nrevpar', label: 'Nightly RevPar', type: 'number' },
            ],
            calendar: true,
        },
    },
};

export const amenities = {
    internet: {
        id: 'internet',
        label: 'Wifi',
    },
    air_conditioning: {
        id: 'air_conditioning',
        label: 'Air conditioning',
    },

    dishwasher: {
        id: 'dishwasher',
        label: 'Dishwasher',
    },
    dryer: {
        id: 'dryer',
        label: 'Dryer',
    },
    hair_dryer: {
        id: 'hair_dryer',
        label: 'Hair dryer',
    },
    heating: {
        id: 'heating',

        label: 'Heating',
    },
    kitchen: {
        id: 'kitchen',
        label: 'Kitchen',
    },
    iron: {
        id: 'iron',
        label: 'Iron',
    },
    washing_machine: {
        id: 'washing_machine',
        label: 'Washing machine',
    },
    tv: {
        id: 'tv',
        label: 'TV',
    },
    '24_hour_check_in': {
        id: '24_hour_check_in',
        label: '24-hour check-in',
    },

    pets_allowed: {
        id: 'pets_allowed',
        label: 'Allows pets',
    },
    smoking_allowed: {
        id: 'smoking_allowed',
        label: 'Allows smoking',
    },
    baby_bath: {
        id: 'baby_bath',
        label: 'Baby bath',
    },
    baby_monitor: {
        id: 'baby_monitor',
        label: 'Baby monitor',
    },
    babysitter_recommendations: {
        id: 'babysitter_recommendations',
        label: 'Babysitter recommendations',
    },
    balcony: {
        id: 'balcony',
        label: 'Balcony',
    },
    bbq: {
        id: 'bbq',
        label: 'Barbeque',
    },
    bathroom_essentials: {
        id: 'bathroom_essentials',
        label: 'Bathroom essentials',
    },
    bathtub: {
        id: 'bathtub',
        label: 'Bathtub',
    },
    beach_essentials: {
        id: 'beach_essentials',
        label: 'Beach essentials',
    },
    beachfront: {
        id: 'beachfront',
        label: 'Beachfront',
    },
    bed_linens: {
        id: 'bed_linens',
        label: 'Bed linens',
    },
    bedroom_comforts: {
        id: 'bedroom_comforts',
        label: 'Bedroom comforts',
    },
    bicycles: {
        id: 'bicycles',
        label: 'Bicycles',
    },
    bocceballcourt: {
        id: 'bocceballcourt',
        label: 'Bocce ball court',
    },
    breakfast: {
        id: 'breakfast',
        label: 'Breakfast',
    },
    building_staff: {
        id: 'building_staff',
        label: 'Building staff',
    },
    cable_or_satellite_tv: {
        id: 'cable_or_satellite_tv',
        label: 'Cable / Satellite',
    },
    carbon_monoxide_detector: {
        id: 'carbon_monoxide_detector',
        label: 'Carbon monoxide detector',
    },
    ceiling_fans: {
        id: 'ceiling_fans',
        label: 'Ceiling fans',
    },
    changing_table: {
        id: 'changing_table',
        label: 'Changing table',
    },
    childrens_books_and_toys: {
        id: 'childrens_books_and_toys',
        label: 'Childrens books and toys',
    },
    childrens_dinnerware: {
        id: 'childrens_dinnerware',
        label: 'Childrens dinnerware',
    },
    cleaning_before_checkout: {
        id: 'cleaning_before_checkout',
        label: 'Cleaning before checkout',
    },
    coffee_maker: {
        id: 'coffee_maker',
        label: 'Coffee maker',
    },
    cooking_basics: {
        id: 'cooking_basics',
        label: 'Cooking basics',
    },
    deck: {
        id: 'deck',
        label: 'Deck',
    },
    dishes_and_silverware: {
        id: 'dishes_and_silverware',
        label: 'Dishes and silverware',
    },
    elderly_accessible: {
        id: 'elderly_accessible',
        label: 'Elderly accessible',
    },
    elevator: {
        id: 'elevator',
        label: 'Elevator',
    },
    essentials: {
        id: 'essentials',
        label: 'Essentials',
    },
    ev_charger: {
        id: 'ev_charger',
        label: 'EV charger',
    },
    extra_pillows_and_blankets: {
        id: 'extra_pillows_and_blankets',
        label: 'Extra pillows and blankets',
    },
    family_kid_friendly: {
        id: 'family_kid_friendly',
        label: 'Family/kid friendly',
    },
    fire_extinguisher: {
        id: 'fire_extinguisher',
        label: 'Fire extinguisher',
    },
    fireplace: {
        id: 'fireplace',
        label: 'Fireplace',
    },
    fireplace_guards: {
        id: 'fireplace_guards',
        label: 'Fireplace guards',
    },
    first_aid_kit: {
        id: 'first_aid_kit',
        label: 'First aid kit',
    },
    freezer: {
        id: 'freezer',
        label: 'Freezer',
    },
    fresh_linens: {
        id: 'fresh_linens',
        label: 'Fresh linens',
    },
    fridge: {
        id: 'fridge',
        label: 'Fridge',
    },
    game_console: {
        id: 'game_console',
        label: 'Game console',
    },
    garage: {
        id: 'garage',
        label: 'Garage',
    },
    gym: {
        id: 'gym',
        label: 'Gym',
    },
    hangers: {
        id: 'hangers',
        label: 'Hangers',
    },
    high_chair: {
        id: 'high_chair',
        label: 'High chair',
    },
    host_greets_you: {
        id: 'host_greets_you',
        label: 'Host greets you',
    },
    hot_tub: {
        id: 'hot_tub',
        label: 'Hot tub',
    },
    hot_water: {
        id: 'hot_water',
        label: 'Hot water',
    },
    jacuzzi: {
        id: 'jacuzzi',
        label: 'Jacuzzi',
    },
    kettle: {
        id: 'kettle',
        label: 'Kettle',
    },
    keypad: {
        id: 'keypad',
        label: 'Keypad',
    },
    lake_access: {
        id: 'lake_access',
        label: 'Lake access',
    },
    laptop_friendly_workspace: {
        id: 'laptop_friendly_workspace',
        label: 'Laptop friendly',
    },
    lock_on_bedroom_door: {
        id: 'lock_on_bedroom_door',
        label: 'Lock on bedroom door',
    },
    lockbox: {
        id: 'lockbox',
        label: 'Lockbox',
    },
    long_term_stays_allowed: {
        id: 'long_term_stays_allowed',
        label: 'Long term stays allowed',
    },
    luggage_dropoff: {
        id: 'luggage_dropoff',
        label: 'Luggage dropoff',
    },
    microwave: {
        id: 'microwave',
        label: 'Microwave',
    },
    outlet_covers: {
        id: 'outlet_covers',
        label: 'Outlet covers',
    },
    oven: {
        id: 'oven',
        label: 'Oven',
    },
    parking_paid: {
        id: 'paidparking',
        label: 'Paid parking',
    },
    parking_free_street: {
        id: 'parking_free_street',
        label: 'Free street parking',
    },
    parking: {
        id: 'parking',
        label: 'Parking',
    },
    patio: {
        id: 'patio',
        label: 'Patio',
    },
    shared_pool: {
        id: 'shared_pool',
        label: 'Shared pool',
    },
    pool: {
        id: 'pool',
        label: 'Pool',
    },
    pool_cover: {
        id: 'pool_cover',
        label: 'Pool cover',
    },

    pool_toys: {
        id: 'pool_toys',
        label: 'Pool toys',
    },
    private_entrance: {
        id: 'private_entrance',
        label: 'Private entrance',
    },
    private_living_room: {
        id: 'private_living_room',
        label: 'Private living room',
    },
    private_yard: {
        id: 'private_yard',
        label: 'Private yard',
    },
    refrigerator: {
        id: 'refrigerator',
        label: 'Refrigerator',
    },
    room_darkening_shades: {
        id: 'room_darkening_shades',
        label: 'Room darkening shades',
    },
    sauna: {
        id: 'sauna',
        label: 'Sauna',
    },
    self_check_in: {
        id: 'self_check_in',
        label: 'Self check-in',
    },
    shampoo: {
        id: 'shampoo',
        label: 'Shampoo',
    },
    single_level_home: {
        id: 'single_level_home',
        label: 'Single level home',
    },
    ski_in_out: {
        id: 'ski_in_out',
        label: 'Ski in/out',
    },
    smart_lock: {
        id: 'smart_lock',
        label: 'Smart lock',
    },
    smoke_detector: {
        id: 'smoke_detector',
        label: 'Smoke detector',
    },
    step_free_access: {
        id: 'step_free_access',
        label: 'Step free access',
    },
    stereo: {
        id: 'stereo',
        label: 'Stereo',
    },
    stove: {
        id: 'stove',
        label: 'Stove',
    },
    streaming: {
        id: 'streaming',
        label: 'Streaming',
    },
    suitable_for_events: {
        id: 'suitable_for_events',
        label: 'Suitable for events',
    },
    table_corner_guards: {
        id: 'table_corner_guards',
        label: 'Table corner guards',
    },
    towel: {
        id: 'towel',
        label: 'Towels',
    },
    travel_crib: {
        id: 'travel_crib',
        label: 'Travel crib',
    },
    water_views: {
        id: 'water_views',
        label: 'Water views',
    },
    waterfront: {
        id: 'waterfront',
        label: 'Waterfront',
    },
    window_guards: {
        id: 'window_guards',
        label: 'Window guards',
    },
};
