'use client';

import { useEffect, useState } from 'react';
import MobileDetect from 'mobile-detect';

export const useIsMobile = () => {
    const [isMobile, setMobile] = useState(false);
    useEffect(() => {
        const md = new MobileDetect(window.navigator.userAgent);
        setMobile(!!md.mobile());
    }, []);
    return isMobile;
};
