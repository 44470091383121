import LogRocket from 'logrocket';
import queryString from 'query-string';

let csrfToken = '';

export function setCSRFToken(token: string) {
    csrfToken = token;
}

export function getCSRFToken() {
    return csrfToken;
}

// Pulls CSRF out of response headers and saves it for future reference.
// We send new CSRF on every request to .
export function extractCSRFToken(res: Response) {
    const token = res?.headers?.get('X-Csrf-Token');
    if (token != null) {
        setCSRFToken(token);
    }
}

const getData = async (res: Response) => {
    // 204 No Content responses have no body
    // so json parse will throw and error.
    const text = await res.text();
    let data: any;
    if (text) {
        data = JSON.parse(text);
    }
    return data;
};

const handleResponse = async (res: Response) => {
    let data: any = undefined;
    try {
        data = await getData(res);
    } catch (e) {
        // Ignore
    }

    if (!res.ok) {
        throw {
            response: {
                status: res.status,
                data,
            },
        };
    } else {
        // Stay up-to-date with latest CSRF token
        extractCSRFToken(res);

        return {
            status: res.status,
            data,
        };
    }
};

export function defaultHeaders(): Record<string, string> {
    let headers: Record<string, string> = {
        // Rails needs these hints to return JSON
        accept: 'application/json, text/plain, */*',
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'application/json; charset=utf-8',
        'X-CSRF-Token': getCSRFToken(),
    };

    try {
        headers = {
            ...headers,
            'X-LogRocket-Recording-Url': LogRocket.sessionURL || '',
        };
    } catch (e) {
        // Logrocket isn't initialzied in our tests so catch and ignore the exception.
    }
    return headers;
}

export const post = async (path: string, body?: object) => {
    const res = await fetch(path, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: defaultHeaders(),
    });

    return handleResponse(res);
};

export const del = async (path: string, body?: object, params?: object) => {
    if (params) {
        path = path + '?' + queryString.stringify(params, { arrayFormat: 'bracket' });
    }

    const res = await fetch(path, {
        method: 'DELETE',
        body: JSON.stringify(body),
        headers: defaultHeaders(),
    });

    return handleResponse(res);
};

export const get = async (path: string) => {
    const res = await fetch(path, {
        headers: defaultHeaders(),
    });
    return handleResponse(res);
};

export const put = async (path: string, body?: object) => {
    const res = await fetch(path, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: defaultHeaders(),
    });
    return handleResponse(res);
};

export const request = async (url: string, method: string, body?: object, params?: object) => {
    if (params) {
        url = url + '?' + queryString.stringify(params, { arrayFormat: 'bracket' });
    }

    const res = await fetch(url, {
        method,
        body: JSON.stringify(body),
        headers: defaultHeaders(),
    });
    return handleResponse(res);
};
