'use client';

import React from 'react';
import { Badge, Tooltip } from '@chakra-ui/react';
import ChannelIcon from './styles/ChannelIcon';
import Intl from 'intl';
import moment from 'moment';
import { isArray, isNil } from 'lodash';
import { getChannelByShortId } from 'libs/global/hooks/useChannels';
import type { PERSONALIZATION_TYPE_LONGFORM, PERSONALIZATION_TYPES } from 'libs/constants';
import { BaseCalendarDay, isUnitType } from 'libs/types/calendarDay';

export function formatMoney(amount: string | number = '0', currency = 'USD') {
    if (amount == null || isNaN(parseInt(amount.toString()))) {
        return null;
    }

    const amountNumber = parseInt(amount.toString());
    const options = {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
    };
    // if it's a whole, dollar amount, leave off the .00
    if (amountNumber % 100 === 0) {
        options.minimumFractionDigits = 0;
    }
    const formatter = new Intl.NumberFormat('en-US', options);

    return formatter.format(amountNumber);
}

const badgeColor = {
    REC: {
        color: 'green.900',
        bg: 'green.200A',
    },
    CON: {
        color: 'yellow.800',
        bg: 'yellow.100',
    },
    AGG: {
        color: 'red.600',
        bg: 'red.100A',
    },
    CUS: {
        color: 'gray.800',
        bg: 'gray.100A',
    },
};

interface SettingsBadgeProps {
    shortcode: (typeof PERSONALIZATION_TYPES)[keyof typeof PERSONALIZATION_TYPES];
    longform: (typeof PERSONALIZATION_TYPE_LONGFORM)[keyof typeof PERSONALIZATION_TYPE_LONGFORM];
    isThreeLetter?: boolean;
    displayTooltip?: boolean;
}
export const SettingsBadge = ({ shortcode, longform, isThreeLetter = false, displayTooltip = true }: SettingsBadgeProps) => (
    <Tooltip label={displayTooltip ? longform : null} fontSize="xs">
        <Badge
            py={1}
            pl={1}
            pr={1}
            borderRadius={2}
            lineHeight={1}
            letterSpacing="wide"
            fontWeight={450}
            fontSize="11px"
            color={badgeColor[shortcode].color}
            bg={badgeColor[shortcode].bg}
            ml={2}>
            {isThreeLetter ? shortcode : shortcode.charAt(0)}
        </Badge>
    </Tooltip>
);

// TODO—confirm the channel values being sent down and that we have corresponding objects in getChannelByShortId
export function getSourceUI(row: any) {
    const channel = getChannelByShortId(row.value);
    if (!channel?.id_name) {
        return null;
    }
    return <ChannelIcon channelName={channel.id_name} />;
}

export const calendarDataIsMissing = (day: BaseCalendarDay | undefined) => {
    if (!day) {
        return true;
    }

    const valuesToCheck = [day.unit_number, day.available, day.booked, day.observed_price];
    return valuesToCheck.every((value) => isNil(value) || (isArray(value) && value.length === 0));
};

export const useUnitTypeDayInfo = (day: BaseCalendarDay | undefined) => {
    const details = React.useMemo(() => {
        if (day) {
            let type: 'unit' | 'listing' | undefined,
                totalUnits: number | undefined,
                availableUnits: number | undefined,
                bookedUnits: number | undefined,
                blockedUnits: number | undefined,
                isAvailable: boolean | undefined,
                isBlocked: boolean | undefined,
                isBooked: boolean | undefined,
                isAllAvailable: boolean | null = null,
                isAllBlocked: boolean | null = null,
                isAllBooked: boolean | null = null,
                bookedPrices: number[] = [],
                observed_price: number | undefined,
                bookedAverage: number | undefined;
            if (calendarDataIsMissing(day)) {
                // Calendar data is missing, do nothing
            } else if (isUnitType(day)) {
                type = 'unit';
                totalUnits = day.unit_number?.length;
                availableUnits = day.available.filter((x) => x).length;
                bookedUnits = day.booked.filter((x) => x).length;
                blockedUnits = day.available.filter((x, idx) => !x && !day.booked[idx]).length;

                const availableDay = day.available.findIndex((x) => x);
                observed_price = day.observed_price[availableDay];

                isAvailable = availableUnits > 0;
                isBlocked = blockedUnits > 0;
                isBooked = bookedUnits > 0;

                isAllAvailable = availableUnits === totalUnits;
                isAllBlocked = blockedUnits === totalUnits;
                isAllBooked = bookedUnits === totalUnits;
                bookedPrices = [];
                day.booked?.forEach((isBooked, idx) => {
                    if (isBooked) {
                        bookedPrices.push(day.observed_price?.[idx]);
                    }
                });

                bookedAverage = bookedPrices.length > 0 ? bookedPrices.reduce((a, b) => a + b, 0) / bookedPrices.length : undefined;
            } else {
                type = 'listing';
                totalUnits = 1;
                availableUnits = day.available ? 1 : 0;
                bookedUnits = day.booked ? 1 : 0;
                blockedUnits = !day.available && !day.booked ? 1 : 0;

                observed_price = day.observed_price;

                isAvailable = day.available;
                isBooked = day.booked && !day.available;
                isBlocked = !day.available && !day.booked;

                isAllAvailable = isAvailable;
                isAllBlocked = isBlocked;
                isAllBooked = isBooked;
            }
            const date = day.stay_date && moment(day.stay_date).format('MMM D');

            return {
                type,
                date,
                totalUnits,
                availableUnits,
                bookedUnits,
                blockedUnits,
                isAvailable,
                isBlocked,
                isBooked,
                isAllAvailable,
                isAllBlocked,
                isAllBooked,
                bookedAverage,
                isAutomated: day?.automated,
                observed_price,
            };
        }
    }, [day]);

    return details;
};
