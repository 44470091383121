export const ONBOARDING_BASE_PRICE = 'onboarding-base-price';
export const ONBOARDING_WHEELHOUSE_PRICING = 'onboarding-wheelhouse-pricing';
export const ONBOARDING_STRATEGY = 'onboarding-strategy';
export const ONBOARDING_FINAL = 'onboarding-final';
export const ONBOARDING_REVIEW = 'onboarding-review-prices';
export const ONBOARDING_APPROACH = 'onboarding-select-approach';
export const ONBOARDING_DATA_DRIVEN = 'onboarding-data-driven';
export const ONBOARDING_CALENDAR_CONFIGURATION_ONE = 'onboarding-calendar-configuration-one';
export const ONBOARDING_CALENDAR_CONFIGURATION_TWO = 'onboarding-calendar-configuration-two';
export const ONBOARDING_PREVIEW = 'onboarding-preview';
export const ONBOARDING_SYNC = 'onboarding-sync';
export const ONBOARDING_INTRO = 'onboarding-introduction';
export const ONBOARDING_INTER = 'onboarding-interstitial';
