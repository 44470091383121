import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Market } from 'libs/types/market';
import moment from 'moment';

export interface GlobalDataFilters {
    bedrooms: string;
    losCategory: string;
    marketTier: string;
    month: string;
    roomType: string;
}

export interface TimeSeriesDataFilters {
    comparisons: string[];
    endDateNum: number;
    granularity: string;
    maxDateNum: number;
    minDateNum: number;
    period: string;
    ranges: string[];
    startDateNum: number;
    vsPeriod: string;
}

export interface MarketsState {
    setId: string | number | undefined;
    globalDataFilters: GlobalDataFilters;
    market: Market;
    timeSeriesDataFilters: TimeSeriesDataFilters;
}

const initialState = {
    market: {},
    globalDataFilters: {
        bedrooms: 'all',
        month: moment().utc().startOf('month').format('YYYY-MM-DD'),
        roomType: 'all',
        marketTier: 'all', // 1,2,3
        losCategory: '',
    },
    timeSeriesDataFilters: {
        period: '1 year',
        vsPeriod: '',
        ranges: ['30 days', '60 days', '90 days', '1 year'],
        comparisons: ['YoY'],
        granularity: 'daily',
        //These will be set to days since epoch to match the market report data
        startDateNum: Math.floor(moment().utc().subtract(1, 'year').startOf('day').unix() / 60 / 60 / 24),
        endDateNum: Math.floor(moment().utc().add(1, 'year').startOf('day').unix() / 60 / 60 / 24),
        // TODO: figure out how to set these once we get the marketReport payload
        minDateNum: Math.floor(moment().utc().subtract(1, 'year').startOf('day').unix() / 60 / 60 / 24),
        maxDateNum: Math.floor(moment().utc().add(1, 'year').startOf('day').unix() / 60 / 60 / 24),
    },
} as MarketsState;

const marketsStateSlice = createSlice({
    name: 'marketsState',
    initialState,
    reducers: {
        setMarket: (state, action: PayloadAction<Market>) => {
            // Prevent market from being set to null or undefined.
            // Call sites expect market to be an object even if it's empty.
            state.market = action.payload || ({} as Market);
        },
        setSetId: (state, action: PayloadAction<string | number | undefined>) => {
            state.setId = action.payload;
        },
        setGlobalDataFilters: (state, action: PayloadAction<Partial<GlobalDataFilters>>) => {
            state.globalDataFilters = {
                ...state.globalDataFilters,
                ...action.payload,
            };
        },
        setTimeSeriesDataFilters: (state, action: PayloadAction<Partial<TimeSeriesDataFilters>>) => {
            state.timeSeriesDataFilters = {
                ...state.timeSeriesDataFilters,
                ...action.payload,
            };
            state.timeSeriesDataFilters.startDateNum = Math.max(state.timeSeriesDataFilters.startDateNum, state.timeSeriesDataFilters.minDateNum);
            state.timeSeriesDataFilters.endDateNum = Math.min(state.timeSeriesDataFilters.endDateNum, state.timeSeriesDataFilters.maxDateNum);
        },
    },
});

export default marketsStateSlice.reducer;
export const actions = marketsStateSlice.actions;
