'use client';

import React, { useContext, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { useAppSelector } from 'libs/global/hooks';
import { PortfolioListingRow, PortfolioTableContext } from 'libs/types/portfolioTable';
import { IHeaderParams } from 'ag-grid-community';
import { TableContext } from './TableContext';

const CheckBoxSelection = (props: IHeaderParams<PortfolioListingRow, PortfolioTableContext>) => {
    const selectedListings = useAppSelector(({ proState }) => proState.selectedListings);
    const { selectAll, deselectAll, getNumberOfSelectableNodes } = useContext(TableContext);
    const selectedCount = selectedListings.length;
    const numberOfSelectableNodes = getNumberOfSelectableNodes();

    const checked = useMemo(() => {
        if (!selectedCount || !numberOfSelectableNodes) {
            return '';
        } else if (selectedCount == numberOfSelectableNodes) {
            return 'checked';
        } else {
            return 'indeterminate';
        }
    }, [numberOfSelectableNodes, selectedCount]);

    const onChange = () => {
        if (checked == 'checked') {
            deselectAll();
        } else {
            selectAll();
        }
    };

    return (
        <Flex position="relative" className="select-all" height="100%" width="35px" align="center" p="2px">
            {selectedCount > 0 && (
                <Flex
                    className="left-arrow"
                    display="inline-block"
                    sx={{
                        '&::after': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            right: '100%',
                            top: '50%',
                            marginTop: '-8px',
                            width: 0,
                            height: 0,
                            borderTop: '8px solid transparent',
                            borderRight: '7px solid #F2B0EA',
                            borderBottom: '8px solid transparent',
                            borderLeft: '7px solid transparent',
                        },
                    }}
                    left="28px"
                    top="7px"
                    position="absolute"
                    bg="primary.50"
                    shadow="md"
                    border="1px"
                    borderColor="primary.200A"
                    color="primary.900"
                    px={2}
                    py={1}
                    fontSize="13px"
                    zIndex={5}
                    rounded="md">
                    {selectedCount} selected
                </Flex>
            )}

            <div role="presentation" className="ag-labeled ag-label-align-right ag-checkbox ag-input-field relative">
                <div className="ag-selection-checkbox" role="presentation">
                    <div className="ag-input-field-label ag-label ag-hidden ag-checkbox-label" role="presentation" />
                    <div className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper${checked == '' ? '' : ` ag-${checked}`}`} role="presentation">
                        <input
                            className="ag-input-field-input ag-checkbox-input"
                            type="checkbox"
                            checked={!!checked}
                            onChange={onChange}
                            data-cy={checked ? 'clear-selection' : 'select-all'}
                        />
                    </div>
                </div>
            </div>
        </Flex>
    );
};

export default CheckBoxSelection;
