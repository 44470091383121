import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import fetcher from 'libs/utils/fetcher';
import { toast } from 'sonner';

import { useAppSelector } from 'libs/global/hooks';
import { post } from 'libs/requests';
import { loadToastError } from 'libs/global/components/CompNext/Modals/utils';
import { compSetPath, setsPricingOffers, setsSubscriptionProposal, setsSubscriptionPurchase, setsSubscriptions } from 'libs/routes';
import { SetsPricingTier } from 'libs/types/pricingTier';
import { SetsPurchase } from 'libs/types/billing';
import { useSets } from './useSets';
import { ErrorResponse } from 'libs/types/errorResponse';

// Pull information from state regarding the sets being edited
export const useSetsToEdit = () => {
    const { set_ids, offer_id } = useAppSelector((state) => state.pricingTiers && state.pricingTiers.setsToEdit);
    const { data: filteredSets = [] } = useSets(set_ids);

    // determine if all filtered sets have the same pricing tier
    const pricingTier = filteredSets?.[0]?.subscription?.product_offer?.level;
    const samePricingTier = filteredSets?.every((set) => set?.subscription && set?.subscription?.product_offer?.level === pricingTier);

    return {
        sets: filteredSets,
        set_ids,
        offer_id,
        samePricingTier,
    };
};

// Fetch all sets pricing tier information
export const useSetsPricingOffers = () => {
    return useQuery({
        queryKey: [setsPricingOffers()],
        queryFn: async () => {
            const data = await fetcher(setsPricingOffers());
            return data.sort((a, b) => a.id - b.id);
        },
        staleTime: Infinity,
    });
};

// Pull specific information for a given tier
export const useSetsPricingTier = (level?: number) => {
    const { data: pricingTiers } = useSetsPricingOffers();
    if (level == undefined || !pricingTiers) {
        return null;
    }

    return pricingTiers.find((c: SetsPricingTier) => c.level === level);
};

// Fetch all sets subscriptions

export const useSetsSubscriptions = () => {
    return useQuery({
        queryKey: [setsSubscriptions()],
        queryFn: async () => {
            const data = await fetcher(setsSubscriptions());

            return data;
        },
        staleTime: Infinity,
    });
};

// Get billing information for the proposed sets for the proposed tier
export const useSetsSubscriptionPropose = (p?: { o_id?: number; s_ids?: number[] }) => {
    const { offer_id, set_ids } = useSetsToEdit();
    const offerId = p?.o_id || offer_id;
    const setIds = p?.s_ids || set_ids;

    const payload = setIds?.map((subscribable_id) => ({ subscribable_id, offer_id: offerId }));
    return useQuery<{
        purchases: SetsPurchase[];
        total_in_cents: number;
    }>({
        queryKey: [setsSubscriptionPurchase(), setIds, offerId, payload],
        queryFn: (): Promise<any> => post(setsSubscriptionProposal(), { purchases: payload }).then((result) => result.data),
        enabled: Boolean(setIds && offerId),
        staleTime: Infinity,
    });
};

const updateSetSubscription = async (payload: { offer_id: number; subscribable_id: number }[]) => {
    const url = setsSubscriptionPurchase();
    const data = await post(url, { purchases: payload });
    return data;
};

// Perform the necessary action (upgrade, downgrade)
export const useSetsSubscriptionFinalize = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateSetSubscription,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [compSetPath()],
            });
            toast.success('Set subscription updated');
        },
        onError: (error: ErrorResponse) => {
            loadToastError(error);
        },
    });
};
