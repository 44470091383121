import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultListingFilters, listingFilterReducers } from './shared/listingFilters';
import { defaultSegmentState, segmentReducers } from './shared/segments';
import type { ListingV1, ListingV1CalendarData } from 'libs/types/listingV1';
import type { CopiedSettings, CopiedSubRules, Settings } from 'libs/types/settings';
import { toast } from 'libs/utils/toast';
import { metricName } from 'libs/utils/metricDefinitions';
import { isEmpty, set, unionBy, uniqBy } from 'lodash';
import { Box, ToastProps } from '@chakra-ui/react';
import moment from 'moment';
import type { AppDispatch, RootState } from '../store';
import { request } from 'libs/requests';
import {
    deleteRatesAPI,
    editTag,
    listingBulkAdjustRatesPath,
    listingBulkAdjustRatesPathV2,
    listingBulkMngListingsPath,
    listingBulkPreferencesPath,
    listingBulkRatesPath,
    listingBulkTagsPath,
    listingTags,
    subusersPath,
} from 'libs/routes';
import { ERROR_CODES } from 'libs/constants';
import type { Tag } from 'libs/types/tag';
import { formatSettingsForApi } from 'libs/utils/apis';
import type { RatePeriod } from 'libs/types/ratePeriod';
import type { ActiveFilter } from 'libs/types/filters';
import { postWithBody } from 'libs/utils/fetcher';
import { GridApi } from 'ag-grid-community';
import { queryClient } from '../query';

const CALENDAR_UI_CONFIGURATION_KEY = 'calendarUiConfigurationV2';

interface BulkError {
    listing_id: number;
    display_name: string;
    message: string;
}

interface TagOptions {
    name?: string | null;
    ids?: number[] | null;
    refresh?: boolean;
}

export type SubRuleSettingsProps = {
    [key: string]: (keyof CopiedSubRules)[];
};

export type SettingsToUpdateList = {
    listing_id: number;
    listing_preference: Partial<Settings>;
}[];

export const granularityToUnitOfTime = (granularity: string) => {
    switch (granularity) {
        case 'daily':
            return 'days';
        case 'monthly':
            return 'months';
        case 'weekly':
            return 'weeks';
        default:
            return 'days';
    }
};

const saveChangesToast = () => {
    toast({
        title: 'Saving your changes now.',
        duration: null,
        id: 1,
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
    });
};

const defaultPerfColumns = {
    compTotal: { column: 'compTotal', hide: true },
    compDiff: { column: 'compDiff', hide: true },
};

export interface CalendarView {
    id: string;
    name: string;
    excludeFixedRates: boolean;
    excludeBookedPrice: boolean;
    priceVisibility: string[];
    backgroundVisibility: string[];
    granularity: string;
    isRolling: boolean;
    rollingDuration: string;
    customDuration: string;
    rollingCustom: boolean;
    loadingCompleted: boolean;
}

const defaultSubRuleCategories = {
    global: false,
    day_of_week: false,
    monthly: false,
    time_based: false,
    custom: false,
} as CopiedSubRules;

const defaultCopiedSettings = {
    automatic_rate_posting_enabled: false,
    base_price_adjustment: false,
    seasonality_adjustment: false,
    weekend_factor: false,
    gap_night: false,
    long_term_discounts: false,
    occupancy_pacing: false,
    revenue_pacing: false,
    minimum_price_rules_v3: defaultSubRuleCategories,
    maximum_price_rules_v3: defaultSubRuleCategories,
    minimum_stay_rules_v3: { ...defaultSubRuleCategories, gap: false },
    demand_sensitivity_rules: defaultSubRuleCategories,
    historical_anchoring_rules: defaultSubRuleCategories,
    far_future_premium: defaultSubRuleCategories,
    last_minute_discount: defaultSubRuleCategories,
    checkin_checkout: { ...defaultSubRuleCategories, active: false, auto_adjust: false },
} as CopiedSettings;

// The grid and column apis an non serializable so they should not be stored in the redux store.
// This will cause the browser to crash.
// TODO: Is there a better way to make these object avaliable to a wide range of components?
export let GlobalGridApi = undefined as GridApi | undefined;
export const PORTFOLIO_FETCH_ROWS_KEY = 'portfolioFetchRows';

const initialState = {
    filters: {
        ...defaultListingFilters,
        active: {
            is_active: {
                filterType: 'radio',
                type: 'set',
                values: ['active'],
            },
            'listing_views.in_market': {
                filterType: 'radio',
                type: 'set',
                values: ['active'],
            },
            removed_by_owner: {
                filterType: 'radio',
                type: 'set',
                values: ['not hidden'],
            },
        } as Record<string, ActiveFilter>,
    },
    ...defaultSegmentState,
    columns: ['occupany', 'revenue', 'adr', 'rev_par', 'booking_lead_time'],
    areColumnsCollapsed: false,
    allListingIds: [] as number[],
    selectedListings: [] as ListingV1[],
    isAllListingsSelected: false,
    settings: {} as Partial<Settings>,
    errors: [] as BulkError[],
    copiedListing: {
        id: null as number | null,
        settings: defaultCopiedSettings,
        rates: [] as string[],
    },
    purge: false,
    ui: {
        errorMsg: null as React.ReactNode | null,
        successMsg: null as React.ReactNode | null,
        loading: false,
        saving: false,
    },
    perfColumns: defaultPerfColumns,
    calendar: {
        start_date: undefined as string | undefined,
        end_date: undefined as string | undefined,
        // calendar.view is stored in local storage.
        // If you change a value here, you should consider changing
        // the local storage key to force the user to get the defaults.
        view: {
            id: 'rate_price_incl_booked_price',
            name: 'Wheelhouse',
            excludeFixedRates: false,
            excludeBookedPrice: false,
            priceVisibility: ['available'],
            backgroundVisibility: ['booked'],
            granularity: 'daily',
            isRolling: false,
            rollingDuration: '30',
            customDuration: '30',
            rollingCustom: false,
            loadingCompleted: false,
        } as CalendarView,
    },
    performance: {
        view: { id: 'overview', name: 'Overview' },
        views: [
            { id: 'overview', name: 'Overview' },
            {
                id: 'occupancy',
                name: metricName('adjusted_occupancy', true),
            },
            {
                id: 'revenue',
                name: metricName('nightly_revenue'),
            },
            {
                id: 'adr',
                name: metricName('average_nightly_rate', true),
            },
            {
                id: 'rev_par',
                name: metricName('adjusted_nightly_revpar', true),
            },
            {
                id: 'booking_lead_time',
                name: metricName('lead_time'),
            },
            {
                id: 'stay_length',
                name: metricName('length_of_stay'),
            },
        ],
    },
    loadingState: {
        isFetching: false,
        pages: 0,
        completedPages: 0,
    },
};

const proStateSlice = createSlice({
    name: 'proState',
    initialState,
    reducers: {
        ...listingFilterReducers,
        ...segmentReducers,
        saving: (state) => {
            state.ui.saving = true;
        },
        // Performance
        view: (state, action: PayloadAction<{ id: string; name: string }>) => {
            state.performance.view = action.payload;
        },
        updatePerfColumn: (state, action: PayloadAction<string>) => {
            const view = state.performance.view;
            if (view.id === 'overview') {
                state.perfColumns[action.payload].hide = !state.perfColumns[action.payload].hide;
            }
        },
        // Table
        setAllListingIds: (state, action: PayloadAction<number[]>) => {
            state.allListingIds = action.payload;
        },
        setIsAllListingsSelected: (state, action: PayloadAction<boolean>) => {
            state.isAllListingsSelected = action.payload;
        },
        setSelectedListings: (state, action: PayloadAction<ListingV1[]>) => {
            state.selectedListings = action.payload;
        },
        selectListing: (
            state,
            action: PayloadAction<{
                listing: ListingV1;
                isSelected: boolean;
            }>,
        ) => {
            const index = state.selectedListings.findIndex((l) => l.id === action.payload.listing.id);
            if (action.payload.isSelected && index === -1) {
                state.selectedListings.push(action.payload.listing);
            } else if (!action.payload.isSelected && index !== -1) {
                state.selectedListings.splice(index, 1);
            }
        },
        setAllSelectedListings: (state) => {
            const allListingIdeds = state.allListingIds.map((id) => ({ id }));
            state.selectedListings = unionBy(state.selectedListings, allListingIdeds, 'id') as ListingV1[];
            state.isAllListingsSelected = true;
        },
        setCopiedListingId: (state, action: PayloadAction<number>) => {
            state.copiedListing = {
                id: action.payload,
                settings: defaultCopiedSettings,
                rates: [],
            };
        },
        setCopiedSettings: (state, action: PayloadAction<CopiedSettings>) => {
            state.copiedListing.settings = action.payload;
        },
        setCopiedRates: (state, action: PayloadAction<string[]>) => {
            state.copiedListing.rates = action.payload;
        },
        resetCopiedState: (state) => {
            state.copiedListing = {
                id: null,
                settings: defaultCopiedSettings,
                rates: [],
            };
        },
        // Settings
        updateSetting: (state, action: PayloadAction<{ setting: string; value: any }>) => {
            state.settings[action.payload.setting] = action.payload.value;
        },
        updateSettings: (state, action: PayloadAction<Partial<Settings>>) => {
            state.settings = {
                ...state.settings,
                ...action.payload,
            };
        },
        updateErrors: (state, action: PayloadAction<BulkError[]>) => {
            state.errors = action.payload;
        },
        clearErrors: (state) => {
            state.errors = [];
        },
        clearStrategySetting: (state) => {
            state.settings = {};
        },
        refreshTable: () => {
            queryClient.invalidateQueries({
                queryKey: [PORTFOLIO_FETCH_ROWS_KEY],
            });
            GlobalGridApi?.refreshServerSide({
                purge: true,
            });
        },
        updateListingSettings: (state, action: PayloadAction<SettingsToUpdateList>) => {
            const updatedData = action.payload;
            const getUpdatedPreferences = (id: number) => {
                return updatedData.filter((d) => d).find(({ listing_id }) => listing_id == id);
            };

            // Update grid
            GlobalGridApi?.forEachNode((node) => {
                if (!node) {
                    return;
                }
                const updatedListingSettings = getUpdatedPreferences(node.data?.id);
                if (updatedListingSettings) {
                    node.setData({
                        ...node.data,
                        listing_preference: { ...node.data.listing_preference, ...updatedListingSettings.listing_preference },
                    });
                }
            });

            // Update state of current selected listings
            state.selectedListings = state.selectedListings.map((l) => {
                if (!l.listing_preference) {
                    return l;
                }
                const updatedListingSettings = getUpdatedPreferences(l.id);
                return {
                    ...l,
                    listing_preference: { ...l.listing_preference, ...updatedListingSettings?.listing_preference },
                };
            });
        },
        updateListingCalendars: (state, action: PayloadAction<ListingV1CalendarData[]>) => {
            const updatedData = action.payload;
            GlobalGridApi?.forEachNode((node) => {
                const updatedListingCalendar = updatedData.filter((d) => d).find(({ listing_id }) => listing_id == node.data?.id);
                if (updatedListingCalendar) {
                    const listingCalendar: ListingV1CalendarData = { ...node.data.calendar };
                    set(
                        listingCalendar,
                        'days',
                        updatedListingCalendar.days.reduce((map, day) => {
                            map[day.stay_date!] = day;
                            return map;
                        }, {}),
                    );
                    set(listingCalendar, 'rate_periods', updatedListingCalendar.rate_periods);

                    node.setData({
                        ...node.data,
                        calendar: listingCalendar,
                    });
                }
            });
        },
        startLoading: (state) => {
            state.ui.loading = true;
        },
        stopLoadingSuccess: (state, action: PayloadAction<{ successMsg: React.ReactNode; toastProps?: ToastProps }>) => {
            const { successMsg, toastProps } = action.payload;
            toast.update(1, {
                description: successMsg,
                title: 'Success',
                status: 'success',
                duration: 3000,
                ...toastProps,
            });
            queryClient.invalidateQueries({
                queryKey: [PORTFOLIO_FETCH_ROWS_KEY],
            });
            state.ui.loading = false;
            state.ui.successMsg = successMsg;
            state.ui.errorMsg = null;
        },
        stopLoadingError: (state, action: PayloadAction<{ errorMsg: React.ReactNode }>) => {
            const { errorMsg } = action.payload;
            setTimeout(() => {
                toast.update(1, {
                    description: errorMsg,
                    title: 'Error',
                    status: 'error',
                    duration: 3000,
                });
            }, 2000);
            state.ui.loading = false;
            state.ui.successMsg = null;
            state.ui.errorMsg = errorMsg;
        },
        clearUIMessages: (state) => {
            state.ui.successMsg = null;
            state.ui.errorMsg = null;
        },
        stopLoading: (state) => {
            state.ui.loading = false;
        },
        // Calendar
        calendarView: (state, action: PayloadAction<CalendarView>) => {
            state.calendar.view = action.payload;
        },
        setCalendarUiConfiguration: (state, action: PayloadAction<CalendarView>) => {
            state.calendar.view = {
                ...action.payload,
                loadingCompleted: true,
            };
        },
        loadCalendarUiConfiguration: (state) => {
            const local = localStorage.getItem(CALENDAR_UI_CONFIGURATION_KEY);
            if (local) {
                state.calendar.view = {
                    ...state.calendar.view,
                    ...JSON.parse(local),
                };
            }
            state.calendar.view.loadingCompleted = true;
        },
        updateCalendarUiConfiguration: (state, action: PayloadAction<Partial<CalendarView>>) => {
            state.calendar.view = {
                ...state.calendar.view,
                ...action.payload,
            };

            localStorage.setItem(CALENDAR_UI_CONFIGURATION_KEY, JSON.stringify(state.calendar.view));
        },
        updateCalendarDates: (state, action: PayloadAction<{ start_date?: string; end_date?: string; moveToDate?: boolean }>) => {
            const { start_date, end_date, moveToDate } = action.payload;
            if (!moment(start_date).isValid() || !moment(end_date).isValid()) {
                throw 'Invalid calendar dates: ' + start_date + ', ' + end_date;
            }

            const granularityUnit = granularityToUnitOfTime(state.calendar.view.granularity);
            const diff = Math.abs(moment(state.calendar.start_date).diff(moment(start_date), granularityUnit));
            state.calendar.start_date = start_date;
            state.calendar.end_date = end_date;

            if (moveToDate && diff >= 5 && start_date) {
                const columnName = `${state.calendar.view.id}-${start_date}`;
                window.setTimeout(() => {
                    // This timeout is to prevent the grid from rending and calling store.getState()
                    // or useAppSelector while the reducer is still executing.
                    GlobalGridApi?.ensureColumnVisible(columnName, diff < 5 ? undefined : 'start');
                }, 0);
            }
        },
        clearDetails: (state) => {
            state.calendar.start_date = moment().format('YYYY-MM-DD');
            state.calendar.end_date = moment().format('YYYY-MM-DD');
        },
        startDataFetch: (state, action: PayloadAction<{ pages: number }>) => {
            state.loadingState.isFetching = true;
            state.loadingState.pages = action.payload.pages;
            state.loadingState.completedPages = 0;
        },
        pageCompletedFetching: (state) => {
            state.loadingState.completedPages++;
        },
        finishDataFetching: (state) => {
            state.loadingState.isFetching = false;
        },
        setColumnsCollapsed: (state, action: PayloadAction<boolean>) => {
            state.areColumnsCollapsed = action.payload;
        },
    },
});

const handleErrorCodes = (dispatch: AppDispatch, error: any) => {
    switch (error?.response?.status) {
        case ERROR_CODES.FAILED_DEPENDENCY:
            let msg: string;
            const errors = error?.response?.data?.errors;
            if (errors?.length === 1) {
                msg = errors?.find((e) => e?.message)?.message;
            }
            msg ||= 'For some reason we were not able to update your listing(s). Could you please retry?';
            dispatch(
                proStateSlice.actions.stopLoadingError({
                    errorMsg: msg,
                }),
            );
            break;
        case ERROR_CODES.INVALID_OAUTH_TOKEN:
            dispatch(
                proStateSlice.actions.stopLoadingError({
                    errorMsg: 'Looks like we no longer have access to your account. Please relink it.',
                }),
            );
            break;
        case ERROR_CODES.INVALID_BILLING:
            dispatch(
                proStateSlice.actions.stopLoadingError({
                    errorMsg: 'We were unable to automate because you are out of the free trial period and have not yet updated your billing information.',
                }),
            );
            console.error('It looks like you are out of the free trial and your payment method id not processing.');
            dispatch(
                proStateSlice.actions.stopLoadingError({ errorMsg: 'It looks like you are out of the free trial and your payment method id not processing.' }),
            );
            break;
        default:
            dispatch(proStateSlice.actions.stopLoadingError({ errorMsg: 'For some reason we were not able to update your listing. Could you please retry?' }));
    }
    // There is no need to throw error because this is already handled via request
};

const handlePartialSuccess = (numberOfSelectedListings: number, numberOfUpdatedListings: number, dispatch: AppDispatch, errors: BulkError[]) => {
    dispatch(
        proStateSlice.actions.stopLoadingSuccess({
            successMsg: (
                <span>
                    Your changes have successfully been applied to{' '}
                    <b>
                        {numberOfUpdatedListings} out of {numberOfSelectedListings}
                    </b>{' '}
                    listings.{' '}
                    <Box
                        display="inline"
                        cursor="pointer"
                        fontSize="lg"
                        fontWeight="600"
                        onClick={() => {
                            toast.closeAll();
                            dispatch(proStateSlice.actions.updateErrors(errors));
                        }}>
                        Click to view errors.
                    </Box>
                </span>
            ),
            toastProps: { duration: null, isClosable: true, status: 'warning', title: 'Partial Success' },
        }),
    );
};

export const actions = {
    ...proStateSlice.actions,
    setApis: (payload: { gridApi: GridApi | undefined }) => {
        return async (dispatch: AppDispatch, getState: () => RootState) => {
            GlobalGridApi = payload.gridApi;
            dispatch({
                payload: undefined,
                type: 'proState/setApis',
            });
        };
    },
    resetTableView: () => {
        return async (dispatch: AppDispatch) => {
            dispatch(
                actions.updateCalendarDates({
                    start_date: undefined,
                    end_date: undefined,
                }),
            );
            GlobalGridApi?.clearRangeSelection();
            // Might be selected rows not on page. Deselect all.
            dispatch(actions.setIsAllListingsSelected(false));
            dispatch(actions.setSelectedListings([]));
            GlobalGridApi?.deselectAll();
        };
    },
    // Tags
    saveTags: (options: TagOptions = { name: null, ids: null, refresh: false }) => {
        return async (dispatch: AppDispatch, getState: () => RootState) => {
            const { selectedListings } = getState().proState;
            const [{ id }] = selectedListings;
            const payload = {
                ...options,
            };
            dispatch(proStateSlice.actions.saving());

            saveChangesToast();

            try {
                const result = await request(listingTags(id!), 'post', payload);
                dispatch(
                    proStateSlice.actions.stopLoadingSuccess({
                        successMsg: 'Your changes have been successfully updated.',
                    }),
                );
                return result.data;
            } catch (error) {
                handleErrorCodes(dispatch, error);
            }
        };
    },
    updateTags: ({ deletedTags, editedTags }: { deletedTags: Tag[]; editedTags: Tag[] }) => {
        return async (dispatch: AppDispatch, getState: () => RootState) => {
            const userId = getState().user.id;
            dispatch(proStateSlice.actions.saving());
            saveChangesToast();

            try {
                if (deletedTags?.length) {
                    await request(editTag(), 'delete', undefined, {
                        ids: deletedTags.map(({ id }) => id),
                        user_id: userId,
                    });
                }

                if (editedTags?.length) {
                    await request(editTag(), 'post', { tags: editedTags, user_id: userId });
                }

                dispatch(
                    proStateSlice.actions.stopLoadingSuccess({
                        successMsg: 'You have successfully updated your tags',
                    }),
                );
                dispatch(proStateSlice.actions.refreshTable());
            } catch (error) {
                handleErrorCodes(dispatch, error);
            }
        };
    },
    saveBulkTags: ({ nameOfNewTags, addTagIds, deleteTagIds }: { nameOfNewTags: string; addTagIds: number[]; deleteTagIds: number[] }) => {
        return async (dispatch: AppDispatch, getState: () => RootState) => {
            if (!addTagIds?.length && !deleteTagIds?.length && !nameOfNewTags?.length) {
                return;
            }
            const { selectedListings } = getState().proState;
            const userId = getState().user.id;
            const listingIds = selectedListings.map(({ id }) => id);
            const payload = {
                add_tag_ids: addTagIds,
                delete_tag_ids: deleteTagIds,
                listing_ids: listingIds,
                names: nameOfNewTags,
                user_id: userId,
            };

            dispatch(proStateSlice.actions.saving());

            saveChangesToast();

            try {
                await request(listingBulkTagsPath(), 'post', payload);
                dispatch(
                    proStateSlice.actions.stopLoadingSuccess({
                        successMsg: 'Your changes have been successfully updated.',
                    }),
                );
                dispatch(proStateSlice.actions.refreshTable());
            } catch (error) {
                handleErrorCodes(dispatch, error);
            }
        };
    },
    // Settings
    saveSettingsBulk:
        (
            options: { purgeOnSave?: boolean; merge?: boolean; returnCalendar?: boolean; removeSubRules?: SubRuleSettingsProps } = {
                purgeOnSave: false,
                merge: false,
                returnCalendar: false,
            },
        ) =>
        async (dispatch: AppDispatch, getState: () => RootState) => {
            const { settings, selectedListings } = getState().proState;
            const listingIds = selectedListings.map((l) => l.id);
            const apiSettings = formatSettingsForApi(settings);
            const payload = {
                preferences: {
                    ...apiSettings,
                    listing_ids: listingIds,
                },
                merge: options.merge,
                return_calendar: options.returnCalendar,
                remove_subrules: options.removeSubRules,
            };

            dispatch(proStateSlice.actions.saving());

            saveChangesToast();

            try {
                const result = await request(listingBulkPreferencesPath(), 'put', payload);
                if (result?.status === 207) {
                    const numberOfUpdatedListings = result?.data?.settings?.length;
                    const errors = result?.data?.errors || [];
                    handlePartialSuccess(selectedListings.length, numberOfUpdatedListings, dispatch, errors);
                } else {
                    dispatch(
                        proStateSlice.actions.stopLoadingSuccess({
                            successMsg: 'Your changes have been successfully updated.',
                        }),
                    );
                }
                dispatch(proStateSlice.actions.clearStrategySetting());
                dispatch(proStateSlice.actions.resetCopiedState());
                if (options.purgeOnSave) {
                    dispatch(proStateSlice.actions.refreshTable());
                } else if (options.returnCalendar) {
                    dispatch(proStateSlice.actions.updateListingCalendars(result?.data?.calendars));
                } else {
                    dispatch(proStateSlice.actions.updateListingSettings(result?.data?.settings));
                }
                return result.data;
            } catch (error) {
                handleErrorCodes(dispatch, error);
            }
        },
    saveRatesBulk: (rates: RatePeriod[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const { selectedListings } = getState().proState;
        const listingIds = selectedListings.map((l) => l.id);

        const payload = {
            rates,
            listing_ids: listingIds,
        };

        dispatch(proStateSlice.actions.saving());

        saveChangesToast();

        try {
            const result = await request(listingBulkRatesPath(), 'post', payload);
            if (result?.status === 207) {
                const numberOfUpdatedListings = result?.data?.listing_ids?.length;
                handlePartialSuccess(selectedListings.length, numberOfUpdatedListings, dispatch, result?.data?.errors);
            } else {
                dispatch(
                    proStateSlice.actions.stopLoadingSuccess({
                        successMsg: 'Your changes have been successfully updated.',
                    }),
                );
            }
            dispatch(proStateSlice.actions.clearStrategySetting());
            dispatch(proStateSlice.actions.resetCopiedState());
            dispatch(proStateSlice.actions.updateListingCalendars(result?.data?.calendars));
            return result.data;
        } catch (error) {
            handleErrorCodes(dispatch, error);
        }
    },
    // Calendar
    saveCalendar: (rate: RatePeriod) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const { selectedListings } = getState().proState;
        // Determines how we make the call (right now we loop over multiple listings)
        const listing_ids = selectedListings.map((l) => l.id);
        const data = {
            listing_ids,
            ...rate,
        };

        dispatch(proStateSlice.actions.saving());

        saveChangesToast();

        try {
            const result = await request(listingBulkRatesPath(), 'post', data);
            if (result?.status === 207) {
                const numberOfUpdatedListings = result?.data?.listing_ids?.length;
                handlePartialSuccess(selectedListings.length, numberOfUpdatedListings, dispatch, result?.data?.errors);
            } else {
                dispatch(
                    proStateSlice.actions.stopLoadingSuccess({
                        successMsg: 'Your changes have been successfully updated.',
                    }),
                );
            }

            dispatch(proStateSlice.actions.updateListingCalendars(result?.data?.calendars));
            return result.data;
        } catch (error) {
            handleErrorCodes(dispatch, error);
        }
    },
    /**
     * @deprecated Use saveBulkAdjustmentCalendarV2
     */
    saveBulkAdjustmentCalendar: (rate: RatePeriod) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const { selectedListings } = getState().proState;
        const listing_ids = selectedListings.map((l) => l.id);
        const data = {
            listing_ids,
            ...rate,
        };
        dispatch(proStateSlice.actions.saving());

        saveChangesToast();

        try {
            const result = await request(listingBulkAdjustRatesPath(), 'post', data);
            if (result?.status === 207) {
                const numberOfUpdatedListings = result?.data?.listing_ids?.length;
                handlePartialSuccess(selectedListings.length, numberOfUpdatedListings, dispatch, result?.data?.errors);
            } else {
                dispatch(
                    proStateSlice.actions.stopLoadingSuccess({
                        successMsg: 'Your changes have been successfully updated.',
                    }),
                );
            }
            dispatch(proStateSlice.actions.updateListingCalendars(result?.data?.calendars));
            return result.data;
        } catch (error) {
            handleErrorCodes(dispatch, error);
        }
    },
    saveBulkAdjustmentCalendarV2: (rate: RatePeriod) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const { selectedListings } = getState().proState;
        const listing_ids = selectedListings.map((l) => l.id);
        const data = {
            listing_ids,
            rate,
        };
        dispatch(proStateSlice.actions.saving());

        saveChangesToast();

        try {
            const result = await request(listingBulkAdjustRatesPathV2(), 'post', data);
            if (result?.status === 207) {
                const numberOfUpdatedListings = result?.data?.listing_ids?.length;
                handlePartialSuccess(selectedListings.length, numberOfUpdatedListings, dispatch, result?.data?.errors);
            } else {
                dispatch(
                    proStateSlice.actions.stopLoadingSuccess({
                        successMsg: 'Your changes have been successfully updated.',
                    }),
                );
            }
            dispatch(proStateSlice.actions.updateListingCalendars(result?.data?.calendars));
            return result.data;
        } catch (error) {
            handleErrorCodes(dispatch, error);
            return false;
        }
    },
    deleteRate: (rate: RatePeriod) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const { selectedListings } = getState().proState;
        // Determines how we make the call (right now we loop over multiple listings)
        const listingId = selectedListings[0].id;
        const data = {
            rate_ids: [rate.id],
        };
        dispatch(proStateSlice.actions.saving());

        saveChangesToast();

        try {
            const result = await request(deleteRatesAPI(listingId!), 'post', data);
            dispatch(
                proStateSlice.actions.stopLoadingSuccess({
                    successMsg: 'Your changes have been successfully updated.',
                }),
            );
            return result.data;
        } catch (error) {
            handleErrorCodes(dispatch, error);
        }
    },
    // SubUser
    saveSubUserAccess:
        (subUserAccess: Record<number, Record<number, string>>, purgeOnSave: boolean = false) =>
        async (dispatch: AppDispatch, getState: () => RootState) => {
            const { selectedListings } = getState().proState;
            const path = listingBulkMngListingsPath();
            const payload = {
                access: subUserAccess,
            };
            dispatch(proStateSlice.actions.saving());

            saveChangesToast();

            try {
                const result = await postWithBody([path, payload]);
                if (result.status === 207) {
                    const numberOfUpdatedListings = result.data?.listing_ids?.length;
                    const listingErrors = result.data?.errors || [];
                    const userErrors = uniqBy(result.data?.user_errors, 'user_id') || [];
                    const combinedErrors = [...listingErrors, ...userErrors];
                    handlePartialSuccess(selectedListings.length, numberOfUpdatedListings, dispatch, combinedErrors);
                } else {
                    dispatch(
                        proStateSlice.actions.stopLoadingSuccess({
                            successMsg: 'Your changes have been successfully updated.',
                        }),
                    );
                }
                if (purgeOnSave) {
                    dispatch(proStateSlice.actions.refreshTable());
                }
            } catch (error) {
                handleErrorCodes(dispatch, error);
            }
        },
    revokeAccess: (ids: number[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
        if (isEmpty(ids)) {
            return;
        }

        const userId = getState().user.id;

        dispatch(proStateSlice.actions.saving());

        saveChangesToast();

        try {
            const paths = ids.map((id) => subusersPath(userId) + '/' + id);
            await Promise.all(paths.map((path) => request(path, 'delete')));
            dispatch(
                proStateSlice.actions.stopLoadingSuccess({
                    successMsg: 'Your changes have been successfully updated.',
                }),
            );
            dispatch(proStateSlice.actions.refreshTable());
            return ids;
        } catch (error) {
            handleErrorCodes(dispatch, error);
        }
    },
};

export default proStateSlice.reducer;
