'use client';

import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { IHeaderParams } from 'ag-grid-community';
import { PortfolioListingRow, PortfolioTableContext } from 'libs/types/portfolioTable';
import { useAppDispatch, useAppSelector } from 'libs/global/hooks';
import { actions } from 'libs/global/slices/proState';

export const NameHeader = (params: IHeaderParams<PortfolioListingRow, PortfolioTableContext>) => {
    const dispatch = useAppDispatch();
    const isCollapsed = useAppSelector(({ proState }) => proState.areColumnsCollapsed);
    const { setSort } = params;
    const sortOrder = params.column.getSort();
    const updateCollapseColumns = () => {
        dispatch(actions.setColumnsCollapsed(!isCollapsed));
    };

    const onSortChange = () => {
        // Every third click is removes sorting.
        const newSort = sortOrder === 'asc' ? 'desc' : sortOrder === 'desc' ? null : 'asc';
        setSort(newSort);
    };

    return (
        <Flex align="center" justify="space-between" mr={1}>
            <Flex align="center">
                {sortOrder === 'asc' ? <i className="ag-icon ag-icon-asc" /> : sortOrder === 'desc' ? <i className="ag-icon ag-icon-desc" /> : null}
                <Box fontSize={13} fontWeight={400} onClick={onSortChange}>
                    Name
                </Box>
            </Flex>
            <Button height="26px" size="xs" variant="filter" onClick={updateCollapseColumns} data-cy="collapsible-columns">
                {isCollapsed ? <ArrowForwardIcon /> : <ArrowBackIcon />}
            </Button>
        </Flex>
    );
};

export default NameHeader;
