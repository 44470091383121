import { DayAttribution } from './dayAttribution';
import { Note } from './note';
import { RatePeriod } from './ratePeriod';

interface Base {
    attribution?: DayAttribution;
    automated: boolean;
    available: boolean | boolean[];
    booked: boolean | boolean[];
    booking?: {
        start_date: string;
        end_date: string;
        booked_at: string;
        currency: string;
        subtotal: number;
        total: number;
        nightly: number;
        guest_name: string;
    };
    events?: [
        {
            category: string;
            end: string;
            name: string;
            rank: number;
            start: string;
        },
    ];
    max_price?: number;
    min_price?: number;
    min_stay?: number;
    note?: Note;
    observed_price: number | number[];
    overbooked_count: number;
    price: number | number[] | null;
    rate?: RatePeriod;
    recommended_price: number;
    stay_date?: string;
    unit_number?: number[];
}

export interface CalendarDay extends Base {
    available: boolean;
    booked: boolean;
    observed_price: number;
    price: number;
    unit_number?: undefined;
}

export interface UnitCalendarDay extends Base {
    available: boolean[];
    booked: boolean[];
    observed_price: number[];
    price: number[] | null;
    unit_number: number[];
}

export type BaseCalendarDay = CalendarDay | UnitCalendarDay;

export interface MultiCalendarDay extends Omit<BaseCalendarDay, 'stay_date'> {
    available: boolean[];
    booked: boolean[];
    observed_price: number[];
    price: number[];
    stay_date: string[];
    unit_number: number[];
}

export const isUnitType = (day: CalendarDay | UnitCalendarDay): day is UnitCalendarDay => {
    return !!day.unit_number;
};

export const isMultiDay = (day: BaseCalendarDay | MultiCalendarDay): day is MultiCalendarDay => {
    return day.stay_date instanceof Array;
};
