import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Settings } from 'libs/types/settings';
import type { AppDispatch } from '../store';

export const initialState = {} as Settings;

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateSetting: (state, action: PayloadAction<{ setting: string; value: any }>) => {
            state[action.payload.setting] = action.payload.value;
        },
    },
});

export function updateSetting(setting: string, value: any) {
    return (d: AppDispatch) => {
        d(
            settingsSlice.actions.updateSetting({
                setting,
                value,
            }),
        );
    };
}

export default settingsSlice.reducer;
