'use client';

import React, { createContext, useCallback, useContext, useMemo } from 'react';
import {
    Box,
    Button,
    ButtonProps,
    ChakraProps,
    Checkbox,
    CheckboxGroup,
    Divider,
    Flex,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    NumberInput,
    NumberInputField,
    Popover,
    PopoverBody,
    PopoverBodyProps,
    PopoverContent,
    PopoverHeader,
    PopoverProps,
    PopoverTrigger,
    Radio,
    RadioGroup,
    Stack,
    Text,
    Tooltip,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { CaretDown, Circle, Plus, X } from 'phosphor-react';
import { compact, each, flatten, groupBy, isEmpty, isEqual, isNumber, lowerCase, map, replace, size, sortBy, without } from 'lodash';

import formatFilters, { FILTER_GTE, FILTER_IN_RANGE, FILTER_LTE } from './formatFilters';
import { useAppDispatch, useAppSelector } from 'libs/global/hooks';
import { actions } from 'libs/global/slices/proState';
import { ActiveFilterId, listingFilterReducers } from 'libs/global/slices/shared/listingFilters';
import {
    ActiveFilter as ActiveFilterType,
    CheckboxActiveFilter,
    CheckboxFilter,
    FilterSchema,
    FilterSchemaWithId,
    FilterSchemaWithIdByCategoryId,
    isCheckboxActiveFilter,
    isCheckboxFilter,
    isChildFilter,
    isNumberActiveFilter,
    isParentRadioFilter,
    isRadioActiveFilter,
    isTagsFilter,
    isTextActiveFilter,
    NumberFilter,
    ParentRadioFilter,
    RadioActiveFilter,
    RadioFilter,
    TagsFilter,
    TextFilter,
} from 'libs/types/filters';

import { useIsMobile } from 'libs/global/containers/IsMobile';
import { categoryTitleByCategoryId, schemaToArray } from './schema';
import { FilterCategoryTypes } from 'libs/types/meta';
import { useOnClickOutside } from 'usehooks-ts';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

interface ActionContextProps {
    addFilters: (payload: Parameters<typeof listingFilterReducers.addFilters>[1]['payload']) => void;
    removeFilter: (payload: Parameters<typeof listingFilterReducers.removeFilter>[1]['payload']) => void;
    updateFilter: (payload: Parameters<typeof listingFilterReducers.updateFilter>[1]['payload']) => void;
}
const ActionContext = createContext<ActionContextProps | null>(null);
const SchemaContext = createContext<Record<string, FilterSchema>>({});
const ActiveFiltersContext = createContext<Record<string, ActiveFilterType>>({});
const FilterIdContext = createContext<ActiveFilterId>('active');

const FilterPopoverBody = (props: PopoverBodyProps) => <PopoverBody className="scroll-update" px={0} pt={4} maxHeight="300px" overflowY="auto" {...props} />;

const valueGenerator = (property: string | undefined, option: { name: string; value: string | number }) => {
    if (property === 'market.name' || property === 'tags') {
        return `${option.name} (${option.value})`;
    } else if (property === 'source') {
        return replace(option.name.toLowerCase(), ' ', '_');
    } else if (isNumber(option.value)) {
        return JSON.stringify(option.value);
    } else {
        return option.value;
    }
};

const removeComparator = (value: string) => {
    const splitValues = value.split('_');
    if (splitValues.length === 1) {
        return value;
    }
    return splitValues.slice(0, -1).join('_');
};

interface CheckboxCompProps {
    category: CheckboxFilter;
    onClose: () => void;
    currentValues?: any[];
    showLabel?: boolean;
    tagComparatorValue?: string;
}
const CheckboxComp = ({ category, onClose, currentValues = [], showLabel = true, tagComparatorValue }: CheckboxCompProps) => {
    const myRef = React.useRef<HTMLElement>(null);
    const [values, setValues] = React.useState(currentValues);
    const [search, setSearch] = React.useState<string | null>(null);
    const { label, options, property } = category;
    const actions = useContext(ActionContext);
    const filterId = useContext(FilterIdContext);

    const handleChange = (values: any[]) => {
        if (values.length > 0 && !isEqual(currentValues, values)) {
            actions?.addFilters({
                filters: formatFilters({ ...category, selectedComparator: tagComparatorValue, values }),
                filterId,
            });
        } else if (values.length === 0 && !isEqual(currentValues, values)) {
            actions?.removeFilter({
                property,
                filterId,
            });
        }

        setValues([]);
        onClose();
        setSearch(null);
    };

    useOnClickOutside(myRef, () => {
        handleChange(values);
    });

    const filterBySearch = ({ name }: { name: string }) => {
        if (!search || search?.trim() == '') {
            return true;
        }

        return lowerCase(name).includes(lowerCase(search));
    };

    return (
        <PopoverContent
            width="240px"
            ref={myRef}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    handleChange(values);
                }
            }}>
            {showLabel ? (
                <PopoverHeader py={4} color="gray.700">
                    <Input
                        placeholder={label}
                        autoFocus={true}
                        variant="unstyled"
                        width="auto"
                        readOnly={!category?.options?.length}
                        value={search || ''}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </PopoverHeader>
            ) : null}
            <FilterPopoverBody pt={showLabel ? 0 : 2} pb={showLabel ? 0 : 2}>
                {!category?.options?.length && category.isEmptyMessage ? (
                    <Text px={4} py={6} color="gray.700" size="sm">
                        {category.isEmptyMessage}
                    </Text>
                ) : null}
                <CheckboxGroup defaultValue={currentValues} onChange={(e) => setValues(e)}>
                    <Box w="100%">
                        {options?.filter(filterBySearch)?.map((option) => (
                            <Box key={option.id}>
                                <Checkbox
                                    _hover={{
                                        bg: 'gray.50A',
                                    }}
                                    transition="background-colors"
                                    px={4}
                                    py={2}
                                    w="100%"
                                    variant="filter"
                                    value={valueGenerator(category.property, option)}>
                                    {option.name}
                                </Checkbox>
                            </Box>
                        ))}
                    </Box>
                </CheckboxGroup>
            </FilterPopoverBody>
        </PopoverContent>
    );
};

interface RadioCompComparatorsProps {
    category: TagsFilter;
    comparator: Record<string, string>;
    onClose: () => void;
    showLabel?: boolean;
    setTagComparatorValue: (value?: string) => void;
    tagComparatorValue: string;
    values: string[];
}
const RadioCompComparators = ({
    category,
    onClose,
    showLabel = true,
    setTagComparatorValue,
    tagComparatorValue,
    values,
    comparator,
}: RadioCompComparatorsProps) => {
    const { label } = category;
    const tagComparatorValues = Object.values(comparator);
    const actions = useContext(ActionContext);
    const filterId = useContext(FilterIdContext);

    const [currentValue, setCurrentValue] = React.useState(comparator[tagComparatorValue]);

    const myRef = React.useRef<HTMLElement>(null);

    const handleChange = () => {
        if (!tagComparatorValues.includes(currentValue)) {
            setCurrentValue(comparator[tagComparatorValue]);
        } else if (currentValue && !isEqual(comparator[tagComparatorValue], currentValue)) {
            const correspondingKey = Object.keys(comparator).find((key) => comparator[key] === currentValue);
            setTagComparatorValue(correspondingKey);
            actions?.addFilters({
                filters: formatFilters({ ...category, values, selectedComparator: correspondingKey, type: 'checkbox' }),
                filterId,
            });
        }

        setCurrentValue(currentValue);
        onClose();
    };

    useOnClickOutside(myRef, () => {
        handleChange();
    });

    return (
        <PopoverContent
            width="240px"
            ref={myRef}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    handleChange();
                }
            }}>
            {showLabel ? (
                <PopoverHeader py={4} color="gray.700">
                    {label}
                </PopoverHeader>
            ) : null}
            <FilterPopoverBody pt={showLabel ? 0 : 2} pb={showLabel ? 0 : 2}>
                <RadioGroup value={currentValue} onChange={setCurrentValue}>
                    <Box w="100%">
                        {tagComparatorValues?.map((value) => {
                            return (
                                <Flex
                                    alignItems="center"
                                    _hover={{
                                        bg: 'gray.50A',
                                    }}
                                    key={value}>
                                    <Radio
                                        name="tagComparator"
                                        w="100%"
                                        _hover={{
                                            bg: 'gray.50A',
                                        }}
                                        transition="background-colors"
                                        px={4}
                                        py={2}
                                        variant="filter"
                                        value={value}>
                                        {value}
                                    </Radio>
                                </Flex>
                            );
                        })}
                    </Box>
                </RadioGroup>
            </FilterPopoverBody>
        </PopoverContent>
    );
};

interface RadioCompProps {
    category: RadioFilter;
    onClose: () => void;
    showLabel?: boolean;
}
const RadioComp = ({ category, onClose, showLabel = true }: RadioCompProps) => {
    const { label, options, defaultValue } = category;
    // Pull out value so Chakra Radio correctly indicates selected value
    const [values, setValues] = React.useState(defaultValue?.[0]);
    const actions = useContext(ActionContext);
    const filterId = useContext(FilterIdContext);

    const myRef = React.useRef<HTMLElement>(null);

    const handleChange = () => {
        if (values && !isEqual(defaultValue, [values])) {
            // Put the value back into an array so it can be passed to formatFilters
            actions?.addFilters({
                filters: formatFilters({ ...category, values: [values] }),
                filterId,
            });
        }

        setValues([]);
        onClose();
    };

    useOnClickOutside(myRef, () => {
        handleChange();
    });

    return (
        <PopoverContent
            width="240px"
            ref={myRef}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    handleChange();
                }
            }}>
            {showLabel ? (
                <PopoverHeader py={4} color="gray.700">
                    {label}
                </PopoverHeader>
            ) : null}
            <FilterPopoverBody pt={showLabel ? 0 : 2} pb={showLabel ? 0 : 2}>
                <RadioGroup value={values} onChange={(e) => setValues(e)}>
                    <Box w="100%">
                        {options?.map((option) => {
                            return (
                                <Flex
                                    alignItems="center"
                                    _hover={{
                                        bg: 'gray.50A',
                                    }}
                                    key={option.name}>
                                    <Radio
                                        name={option.name}
                                        w="100%"
                                        _hover={{
                                            bg: 'gray.50A',
                                        }}
                                        transition="background-colors"
                                        px={4}
                                        py={2}
                                        variant="filter"
                                        value={isNumber(option.value) ? JSON.stringify(option.value) : (option.value as string)}>
                                        {option.name}
                                    </Radio>
                                </Flex>
                            );
                        })}
                    </Box>
                </RadioGroup>
            </FilterPopoverBody>
        </PopoverContent>
    );
};

interface RadioCompChildrenProps {
    category: ParentRadioFilter;
    setFilter: (filter: any) => void;
    showLabel?: boolean;
}
const RadioCompChildren = ({ category, setFilter, showLabel = true }: RadioCompChildrenProps) => {
    const { label, options } = category;

    const onChange = (e) => {
        const child = category.children!.find(({ paramKey }) => paramKey == category.paramKey(e));
        setFilter(child);
    };

    return (
        <PopoverContent width="240px">
            {showLabel ? (
                <PopoverHeader py={4} color="gray.700">
                    {label}
                </PopoverHeader>
            ) : null}
            <FilterPopoverBody pt={showLabel ? 0 : 2} pb={showLabel ? 0 : 2}>
                <RadioGroup onChange={onChange}>
                    <Box w="100%">
                        {options?.map((option) => (
                            <Flex
                                alignItems="center"
                                _hover={{
                                    bg: 'gray.50A',
                                }}
                                key={option.name}>
                                <Radio
                                    name={option.name}
                                    w="100%"
                                    _hover={{
                                        bg: 'gray.50A',
                                    }}
                                    transition="background-colors"
                                    px={4}
                                    py={2}
                                    variant="filter"
                                    value={option.value as string}>
                                    {option.name}
                                </Radio>
                            </Flex>
                        ))}
                    </Box>
                </RadioGroup>
            </FilterPopoverBody>
        </PopoverContent>
    );
};

interface ParentRadioCompProps {
    onClose: () => void;
    showLabel?: boolean;
    activeFilter: ActiveFilterType;
    filterSchema: NumberFilter;
}
const ParentRadioComp = ({ onClose, showLabel = true, activeFilter, filterSchema }: ParentRadioCompProps) => {
    const { label, property, parent, paramKey } = filterSchema;
    const { filter, filterTo } = activeFilter;
    const schema = useContext(SchemaContext);
    const parentData = schema[parent] as ParentRadioFilter;
    const myRef = React.useRef<HTMLElement>(null);
    const actions = useContext(ActionContext);
    const filterId = useContext(FilterIdContext);

    const initialOption = useMemo(() => {
        const option = parentData.options.find((option) => {
            return parentData.paramKey(option.value) === paramKey;
        });

        return option?.value.toString();
    }, [parentData, paramKey]);

    const [value, setValue] = React.useState<string | undefined>(initialOption);

    const handleChange = () => {
        if (value) {
            // Put the value back into an array so it can be passed to formatFilters
            // Need to get the new param key for the filter
            const child = parentData?.children.find(({ paramKey }) => paramKey == parentData?.paramKey(value));
            actions?.updateFilter({
                filters: formatFilters({ ...child, values: [filter, filterTo] }),
                replace: property,
                filterId,
            });
        }

        onClose();
    };

    useOnClickOutside(myRef, () => {
        handleChange();
    });

    return (
        <PopoverContent width="240px" ref={myRef}>
            {showLabel ? (
                <PopoverHeader py={4} color="gray.700">
                    {label}
                </PopoverHeader>
            ) : null}
            <FilterPopoverBody pt={showLabel ? 0 : 2} pb={showLabel ? 0 : 2}>
                <RadioGroup value={value} onChange={(e) => setValue(e)}>
                    <Box w="100%">
                        {parentData.options.map((option) => (
                            <Flex
                                alignItems="center"
                                _hover={{
                                    bg: 'gray.50A',
                                }}
                                key={option.name}>
                                <Radio
                                    name={option.name}
                                    w="100%"
                                    _hover={{
                                        bg: 'gray.50A',
                                    }}
                                    transition="background-colors"
                                    px={4}
                                    py={2}
                                    variant="filter"
                                    value={isNumber(option.value) ? option.value.toString() : option.value}>
                                    {option.name}
                                </Radio>
                            </Flex>
                        ))}
                    </Box>
                </RadioGroup>
            </FilterPopoverBody>
        </PopoverContent>
    );
};

interface TextCompProps {
    category: TextFilter;
    onClose: () => void;
    currentValue?: string;
    showLabel?: boolean;
    type?: string;
}
const TextComp = ({ category, type, onClose, showLabel = true, currentValue = '' }: TextCompProps) => {
    const { label, property } = category;

    const [values, setValues] = React.useState(currentValue);
    const [filterOption, setFilterOption] = React.useState(type || 'contains');
    const actions = useContext(ActionContext);
    const filterId = useContext(FilterIdContext);

    const myRef = React.useRef<HTMLElement>(null);
    const handleChange = () => {
        if (currentValue !== values && values !== '') {
            actions?.addFilters({
                filters: formatFilters({ ...category, values, filterOption }),
                filterId,
            });
        } else if (currentValue !== values && values === '') {
            actions?.removeFilter({
                property,
                filterId,
            });
        }

        setValues('');
        onClose();
    };

    useOnClickOutside(myRef, () => {
        handleChange();
    });

    return (
        <PopoverContent
            width={category?.filterOptions ? '330px' : '300px'}
            ref={myRef}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    handleChange();
                }
            }}>
            {showLabel ? (
                <PopoverHeader py={4} color="gray.700">
                    <Flex align="center" justify="space-between" width="100%">
                        <Box>{label}</Box>
                        {category?.filterOptions ? (
                            <RadioGroup onChange={setFilterOption} value={filterOption}>
                                <HStack spacing={1} align="flex-start">
                                    {category?.filterOptions.map(({ value, label }) => {
                                        return (
                                            <Radio key={value} value={value} size="sm" spacing="0.25rem">
                                                {label}
                                            </Radio>
                                        );
                                    })}
                                </HStack>
                            </RadioGroup>
                        ) : null}
                    </Flex>
                </PopoverHeader>
            ) : null}
            <FilterPopoverBody>
                <Box px={4}>
                    <Input border="1px solid" borderColor="gray.100" placeholder="" value={values} onChange={(event) => setValues(event.target.value)} />
                </Box>
            </FilterPopoverBody>
        </PopoverContent>
    );
};

const NumberInputAddon = ({ category, isRight }: { category: NumberFilter; isRight: boolean }) => {
    if (isRight) {
        return category.rightAddon ? (
            <InputRightAddon roundedLeft="none" roundedRight="md" color="gray.400" bg="white" borderColor="gray.100">
                {category.rightAddon}
            </InputRightAddon>
        ) : null;
    }
    return category.leftAddon ? (
        <InputLeftAddon roundedLeft="md" roundedRight="none" color="gray.300" bg="white" borderColor="gray.100">
            {category.leftAddon}
        </InputLeftAddon>
    ) : null;
};

const numberInputValue = (value: number | undefined) => (Number.isFinite(value) ? value : '');

interface RangeCompProps {
    category: NumberFilter;
    onClose: () => void;
    currentValues?: (number | undefined)[];
    showLabel?: boolean;
}
const RangeComp = ({ category, onClose, currentValues = [], showLabel = true }: RangeCompProps) => {
    const [values, setValues] = React.useState(currentValues);

    const myRef = React.useRef<HTMLElement>(null);
    const actions = useContext(ActionContext);
    const filterId = useContext(FilterIdContext);

    const handleChange = () => {
        const validInput = values.some((v) => Number.isFinite(v));
        if (validInput && !isEqual(currentValues, values)) {
            actions?.addFilters({
                filters: formatFilters({ ...category, values }),
                filterId,
            });
        }

        setValues([]);
        onClose();
    };

    useOnClickOutside(myRef, () => {
        handleChange();
    });

    const setLower = (_: string, value: number) => {
        const arr = [...values];
        arr[0] = Number.isFinite(value) ? value : undefined;
        setValues(arr);
    };

    const setUpper = (_: string, value: number) => {
        const arr = [...values];
        arr[1] = Number.isFinite(value) ? value : undefined;
        setValues(arr);
    };

    const roundedProps = {
        roundedLeft: 'md',
        roundedRight: 'md',
    };

    if (category.rightAddon) {
        roundedProps.roundedRight = 'none';
    }

    if (category.leftAddon) {
        roundedProps.roundedLeft = 'none';
    }

    return (
        <PopoverContent
            width="300px"
            ref={myRef}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    handleChange();
                }
            }}>
            {showLabel ? (
                <PopoverHeader py={4} color="gray.700">
                    {category.label}
                </PopoverHeader>
            ) : null}
            <FilterPopoverBody>
                <Box px={4}>
                    <HStack width="100%" align="center">
                        <InputGroup>
                            <NumberInputAddon category={category} isRight={false} />
                            <NumberInput value={numberInputValue(values[0])} borderColor="gray.100" min={0} onChange={setLower}>
                                <NumberInputField {...roundedProps} placeholder="From" />
                            </NumberInput>
                            <NumberInputAddon category={category} isRight={true} />
                        </InputGroup>
                        <Text>-</Text>
                        <InputGroup>
                            <NumberInputAddon category={category} isRight={false} />
                            <NumberInput value={numberInputValue(values[1])} borderColor="gray.100" min={0} onChange={setUpper}>
                                <NumberInputField {...roundedProps} placeholder="To" />
                            </NumberInput>
                            <NumberInputAddon category={category} isRight={true} />
                        </InputGroup>
                    </HStack>
                </Box>
            </FilterPopoverBody>
        </PopoverContent>
    );
};

const RangeComparator = ({ type }: { type: string }) => {
    let comparator: string | null;
    switch (type) {
        case FILTER_IN_RANGE:
            comparator = 'is between';
            break;
        case FILTER_LTE:
            comparator = 'is lt.';
            break;
        case FILTER_GTE:
            comparator = 'is gt.';
            break;
        default:
            return null;
    }
    return (
        <Box color="gray.400" py={0} px={1} cursor="default" fontSize="sm">
            {comparator}
        </Box>
    );
};

const NumericRangeComparator = ({ type }: { type: string }) => {
    return <RangeComparator type={type} />;
};

const TextComparator = ({ comparator, type }: { comparator: any; type: string }) => {
    return (
        <Box color="gray.400" py={0} px={1} cursor="default" fontSize="sm">
            {comparator[type]}
        </Box>
    );
};

interface ComparatorProps {
    setTagComparatorValue: (value: string) => void;
    tagComparatorValue: string;
    filterSchema: FilterSchema;
    filter: ActiveFilterType;
}
const Comparator = ({ setTagComparatorValue, tagComparatorValue, filter, filterSchema }: ComparatorProps) => {
    const { values } = filter;
    const isArray = Array.isArray(values) && values.length > 1;
    const { isOpen, onToggle, onClose } = useDisclosure();
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    if (isNumberActiveFilter(filter, filterSchema)) {
        return <NumericRangeComparator type={filter.type} />;
    }

    if (isTextActiveFilter(filter, filterSchema)) {
        return <TextComparator comparator={(filterSchema as TextFilter).comparator} type={filter.type} />;
    }

    if (isTagsFilter(filterSchema) && isCheckboxActiveFilter(filter, filterSchema)) {
        const values = filter.values.map((value) => {
            return removeComparator(value);
        });
        const comp = isArray ? filterSchema.comparator.multiple : filterSchema.comparator.single;
        return (
            <Popover isOpen={isOpen} onClose={onClose}>
                <Tooltip>
                    <Box mr={1}>
                        <PopoverTrigger>
                            <Button
                                ref={buttonRef}
                                onClick={onToggle}
                                rounded="none"
                                size="sm"
                                h="26px"
                                letterSpacing="tight"
                                variant="filter"
                                color="gray.700"
                                borderColor="transparent"
                                lineHeight="inherit"
                                _hover={{ bg: 'gray.100A' }}
                                py={0}
                                px={0.5}>
                                {comp[tagComparatorValue]}
                            </Button>
                        </PopoverTrigger>
                    </Box>
                </Tooltip>
                <RadioCompComparators
                    showLabel={false}
                    onClose={onClose}
                    category={filterSchema}
                    comparator={comp}
                    setTagComparatorValue={setTagComparatorValue}
                    tagComparatorValue={tagComparatorValue}
                    values={values}
                />
            </Popover>
        );
    }

    const comp = isArray ? (filterSchema as RadioFilter).comparator.multiple : (filterSchema as RadioFilter).comparator.single;
    return (
        <Box color="gray.400" py={0} px={1.5} cursor="default" fontSize="sm">
            {comp}
        </Box>
    );
};

function marketRenderer(value: string) {
    // Don't show the market id in brackets. It's just added to the value to extract for filtering.
    // Example "San Francisco (1)" => "San Francisco"
    return value.replace(/ \([^)]*\)$/g, '');
}

function removeFirstWord(str: string) {
    const indexOfSpace = str.indexOf(' ');

    if (indexOfSpace === -1) {
        return '';
    }

    return str.substring(indexOfSpace + 1);
}

interface ActiveFilterValueCategoryProps {
    filterSchema: NumberFilter;
    activeFilter: ActiveFilterType;
}
const ActiveFilterValueCategory = ({ activeFilter, filterSchema }: ActiveFilterValueCategoryProps) => {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    const { parent, label, shortLabel } = filterSchema;
    const schema = useContext(SchemaContext);
    const parentData = schema[parent];
    return (
        <Flex alignItems="center">
            <Text px={0.5} fontSize="sm" color="gray.400" lineHeight={0}>
                {parentData.label}
            </Text>
            <Popover isOpen={isOpen}>
                <>
                    <PopoverTrigger>
                        <Button
                            ref={buttonRef}
                            onClick={onToggle}
                            rounded="none"
                            h="26px"
                            color="gray.900"
                            letterSpacing="tight"
                            size="sm"
                            variant="filter"
                            borderColor="transparent"
                            _hover={{ bg: 'gray.100A' }}
                            py={0}
                            px={1}>
                            {shortLabel || removeFirstWord(label)}
                        </Button>
                    </PopoverTrigger>
                    {parentData?.type == 'radio' && isOpen && (
                        <ParentRadioComp showLabel={false} onClose={onClose} activeFilter={activeFilter} filterSchema={filterSchema} />
                    )}
                </>
            </Popover>
        </Flex>
    );
};

const getValueRenderedStrings = (filter: CheckboxActiveFilter | RadioActiveFilter, filterSchema: FilterSchema) => {
    if (isTagsFilter(filterSchema)) {
        return filter.values.map((v) => marketRenderer(removeComparator(v)));
    } else if (filterSchema.property === 'market.name') {
        return filter.values.map((v) => marketRenderer(v));
    } else if (isCheckboxFilter(filterSchema) && filterSchema.isAcronym) {
        return filter.values.map((v, i) => {
            return filterSchema.options?.find((o) => o.value == filter.values[i])?.name;
        });
    } else {
        return filter.values;
    }
};

interface ActiveFilterValueProps {
    filterSchema: FilterSchema;
    activeFilter: ActiveFilterType;
    tagComparatorValue: string;
}
const ActiveFilterValue = ({ activeFilter, filterSchema, tagComparatorValue }: ActiveFilterValueProps) => {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    const buttonLabel = React.useMemo(() => {
        if (isNumberActiveFilter(activeFilter, filterSchema)) {
            return without([activeFilter.filter, activeFilter.filterTo], undefined, null).join(' - ');
        } else if (isTextActiveFilter(activeFilter, filterSchema)) {
            return activeFilter.filter;
        } else if (isCheckboxActiveFilter(activeFilter, filterSchema) || isRadioActiveFilter(activeFilter, filterSchema)) {
            const values = activeFilter.values;
            if (values.length > 1) {
                return `${values.length} types`;
            }

            const valueStrings = getValueRenderedStrings(activeFilter, filterSchema);
            return valueStrings[0];
        } else {
            return '';
        }
    }, [activeFilter, filterSchema]);

    const tooltipLabel = React.useMemo(() => {
        if (!isCheckboxActiveFilter(activeFilter, filterSchema) && !isRadioActiveFilter(activeFilter, filterSchema)) {
            return null;
        }

        const valueStrings = getValueRenderedStrings(activeFilter, filterSchema);
        // valueStrings may be undefined if the filter was incorrectly parsed from the URL.
        return valueStrings?.join(', ');
    }, [activeFilter, filterSchema]);

    const Trigger = (
        <Button
            ref={buttonRef}
            onClick={onToggle}
            rounded="none"
            size="sm"
            h="26px"
            letterSpacing="tight"
            variant="filter"
            color="gray.700"
            borderColor="transparent"
            lineHeight="inherit"
            _hover={{ bg: 'gray.100A' }}
            py={0}
            px={0.5}>
            {buttonLabel}
        </Button>
    );

    return (
        <Popover isOpen={isOpen} onClose={onClose}>
            <>
                <Tooltip label={tooltipLabel}>
                    <Box>
                        <PopoverTrigger>{Trigger}</PopoverTrigger>
                    </Box>
                </Tooltip>
                {isCheckboxActiveFilter(activeFilter, filterSchema) && isOpen && (
                    <CheckboxComp
                        showLabel={false}
                        onClose={onClose}
                        currentValues={isTagsFilter(filterSchema) ? activeFilter.values.map((v) => removeComparator(v)) : activeFilter.values}
                        category={filterSchema as CheckboxFilter}
                        tagComparatorValue={tagComparatorValue}
                    />
                )}
                {isRadioActiveFilter(activeFilter, filterSchema) && isOpen && (
                    <RadioComp showLabel={false} onClose={onClose} category={{ ...filterSchema, defaultValue: activeFilter.values } as RadioFilter} />
                )}
                {isTextActiveFilter(activeFilter, filterSchema) && isOpen && (
                    <TextComp
                        showLabel={false}
                        onClose={onClose}
                        currentValue={activeFilter.filter}
                        category={filterSchema as TextFilter}
                        type={activeFilter.type}
                    />
                )}
                {isNumberActiveFilter(activeFilter, filterSchema) && isOpen && (
                    <RangeComp
                        showLabel={false}
                        onClose={onClose}
                        currentValues={activeFilter.type == FILTER_LTE ? [undefined, activeFilter.filter] : [activeFilter.filter, activeFilter.filterTo]}
                        category={filterSchema as NumberFilter}
                    />
                )}
            </>
        </Popover>
    );
};

const FilterIcon = ({ filter }: { filter: FilterSchema }) => {
    return <Icon as={filter.icon || Circle} />;
};

const getFilterInfo = (schema: Record<string, FilterSchema>, property: string) => {
    if (schema[property]) {
        return schema[property];
    }
    // Search trough filters with children
    const allChildren: FilterSchema[] = compact(
        flatten(
            Object.keys(schema).map((filterKey) => {
                const filter = schema[filterKey];
                if (isParentRadioFilter(filter)) {
                    return filter.children?.map((child) => ({
                        ...child,
                        // Use this to grab the category dropdown on multi-filters
                        parent: filterKey,
                    }));
                } else {
                    return undefined;
                }
            }),
        ),
    );

    return allChildren.find((c) => property == c.property)!;
};

interface ActiveFilterProps {
    filter: ActiveFilterType;
    property: string;
}
const ActiveFilter = ({ filter, property }: ActiveFilterProps) => {
    const [tagComparatorValue, setTagComparatorValue] = React.useState('or');
    const actions = useContext(ActionContext);
    const schema = useContext(SchemaContext);
    const filterId = useContext(FilterIdContext);
    const filterSchema = useMemo(() => getFilterInfo(schema, property), [schema, property]);

    if (!filter || !filterSchema) {
        return null;
    }
    return (
        <Flex height="26px" alignItems="center" flexShrink={0} fontSize="md" borderRadius="4px" borderColor="gray.100" borderWidth="1px">
            <Flex pl={2} py={0} pr={0} gap={1} align="center" color="gray.400" mr={2}>
                <FilterIcon filter={filterSchema} />
                {isChildFilter(filterSchema) ? (
                    <ActiveFilterValueCategory activeFilter={filter} filterSchema={filterSchema} />
                ) : (
                    <Text fontSize="sm" color="gray.400">
                        {filterSchema.label}
                    </Text>
                )}
            </Flex>
            <Comparator setTagComparatorValue={setTagComparatorValue} tagComparatorValue={tagComparatorValue} filterSchema={filterSchema} filter={filter} />
            <ActiveFilterValue tagComparatorValue={tagComparatorValue} filterSchema={filterSchema} activeFilter={filter} />

            <Tooltip label="Remove filter">
                <Flex
                    as="button"
                    letterSpacing="tight"
                    px={1.5}
                    alignItems="center"
                    height="100%"
                    onClick={() => {
                        actions?.removeFilter({
                            property,
                            filterId,
                        });
                    }}
                    _focus={{
                        outlineColor: 'purple.100',
                    }}
                    _active={{
                        outlineColor: 'purple.100',
                        outlineWidth: 1,
                    }}
                    _hover={{
                        bg: 'gray.50A',
                    }}>
                    <Icon as={X} color="gray.300" />
                </Flex>
            </Tooltip>
        </Flex>
    );
};

interface HiddenFilterProps {
    filter: ActiveFilterType;
    property: string;
}

const HiddenFilter = ({ filter, property }: HiddenFilterProps) => {
    const [tagComparatorValue, setTagComparatorValue] = React.useState('or');
    const actions = useContext(ActionContext);
    const schema = useContext(SchemaContext);
    const filterId = useContext(FilterIdContext);
    const filterSchema = useMemo(() => getFilterInfo(schema, property), [schema, property]);

    if (!filter || !filterSchema) {
        return null;
    }

    return (
        <Box>
            <Flex alignItems="center" justifyContent="space-between">
                <Flex pl={2} py={0} pr={0} gap={1} align="center" color="gray.400">
                    <FilterIcon filter={filterSchema} />
                    {isChildFilter(filterSchema) ? (
                        <ActiveFilterValueCategory activeFilter={filter} filterSchema={filterSchema} />
                    ) : (
                        <Text fontSize="sm" color="gray.400">
                            {filterSchema.label}
                        </Text>
                    )}
                </Flex>
                <Comparator tagComparatorValue={tagComparatorValue} setTagComparatorValue={setTagComparatorValue} filterSchema={filterSchema} filter={filter} />
                <Box px={4}>
                    <Box position="static">
                        <ActiveFilterValue tagComparatorValue={tagComparatorValue} filterSchema={filterSchema} activeFilter={filter} />
                    </Box>
                </Box>

                <Tooltip label="Remove filter">
                    <Box
                        as="button"
                        letterSpacing="tight"
                        px={2}
                        py={1}
                        onClick={() => {
                            actions?.removeFilter({
                                property,
                                filterId,
                            });
                        }}
                        _focus={{
                            outlineColor: 'purple.100',
                        }}
                        _active={{
                            outlineColor: 'purple.100',
                            outlineWidth: 1,
                        }}
                        _hover={{
                            bg: 'gray.50A',
                        }}>
                        <Icon as={X} color="gray.400" />
                    </Box>
                </Tooltip>
            </Flex>
        </Box>
    );
};

const initialHiddenValueSet = ['removed_by_owner', 'listing_views.in_market', 'is_active', 'searchable'];

interface ActiveFiltersProps {
    maxDisplayedFilters?: number;
}
export const ActiveFilters = ({ maxDisplayedFilters = 4 }: ActiveFiltersProps) => {
    const isMobile = useIsMobile();
    const filters = useContext(ActiveFiltersContext);
    const [displayFilters, hiddenFilters] = useMemo(() => {
        const display = {};
        const hidden = {};

        Object.keys(filters)
            // Reverse keys so that the filter that was added last is placed in first position etc..
            .reverse()
            .forEach((filter) => {
                const count = Object.keys(display).length;
                if (initialHiddenValueSet.includes(filter) || count >= maxDisplayedFilters) {
                    hidden[filter] = filters[filter];
                } else {
                    display[filter] = filters[filter];
                }
            });

        return [display, hidden];
    }, [filters, maxDisplayedFilters]);

    return (
        <Flex flexGrow={1} gap={2} h="full" alignItems="center" overflow="hidden">
            {!isEmpty(displayFilters) && !isMobile ? (
                <HStack spacing={1} overflowX="auto" className="horizontal-scrollbar overflow-shadow" h="full">
                    {Object.keys(displayFilters).map((filter, idx) => {
                        return <ActiveFilter key={filter} property={filter} filter={filters[filter]} />;
                    })}
                </HStack>
            ) : null}
            {(!isEmpty(hiddenFilters) || maxDisplayedFilters == 0) && !isMobile ? (
                <>
                    <AdditionalFilters isDisplaying={!isEmpty(displayFilters)} hiddenFilters={hiddenFilters} />
                </>
            ) : null}

            {!isEmpty(hiddenFilters) && isMobile ? (
                <>
                    <Box w={2} />
                    <FiltersMobile />
                </>
            ) : null}
        </Flex>
    );
};

interface AdditionalFiltersProps {
    hiddenFilters: Record<string, ActiveFilterType>;
    isDisplaying: boolean;
}
const AdditionalFilters = ({ hiddenFilters, isDisplaying }: AdditionalFiltersProps) => {
    const numberOfHiddenFilters = size(hiddenFilters);
    const filters = useContext(ActiveFiltersContext);

    if (numberOfHiddenFilters == 0) {
        return (
            <Flex align="center" height="26px" border="1px" rounded="md" bgColor="gray.50A" borderColor="gray.50A" px={2}>
                <Text fontSize="sm" color="gray.300">
                    0 active filters
                </Text>
            </Flex>
        );
    }

    return (
        <Popover>
            <PopoverTrigger>
                <Button size="sm" height="26px" borderColor="gray.100" flexShrink={0} rightIcon={<Icon as={CaretDown} color="gray.400" />} variant="filter">
                    {isDisplaying ? `+ ${numberOfHiddenFilters} more` : `${numberOfHiddenFilters} active filter${numberOfHiddenFilters == 1 ? '' : 's'}`}
                </Button>
            </PopoverTrigger>
            <PopoverContent width="300px" id="first">
                <PopoverHeader color="gray.400">Filter{numberOfHiddenFilters > 1 ? 's' : ''}</PopoverHeader>
                <PopoverBody maxHeight="350px" px={4} pt={2} className="scroll-update" position="relative">
                    <VStack spacing={1} w="100%" alignItems="stretch">
                        {Object.keys(hiddenFilters).map((filter) => {
                            return <HiddenFilter key={filter} property={filter} filter={filters[filter]} />;
                        })}
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

const FiltersMobile = () => {
    const filters = useContext(ActiveFiltersContext);
    return (
        <Popover>
            <PopoverTrigger>
                <Button
                    letterSpacing="tight"
                    size="sm"
                    lineHeight={1}
                    height="26px"
                    flexShrink={0}
                    rightIcon={<Icon as={CaretDown} color="gray.400" />}
                    variant="filter">
                    {`${size(filters)} active filter${size(filters) > 1 ? 's' : ''}`}
                </Button>
            </PopoverTrigger>
            <PopoverContent width="300px" id="first">
                <PopoverHeader color="gray.400">Filter{size(filters) > 1 ? 's' : ''}</PopoverHeader>
                <PopoverBody maxHeight="350px" px={4} pt={0} overflowY="auto" className="scroll-update" position="relative">
                    <VStack spacing={2} w="100%" alignItems="stretch">
                        {Object.keys(filters).map((filter, idx) => {
                            return <HiddenFilter key={`${filter}-${idx}`} property={filter} filter={filters[filter]} />;
                        })}
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

interface CategoryCompProps {
    setFilter: (filter: FilterSchema) => void;
    initialRef: React.MutableRefObject<any>;
    categoryStyleProps?: any;
}
const CategoryComp = ({ setFilter, initialRef, categoryStyleProps = {} }: CategoryCompProps) => {
    const [search, setSearch] = React.useState('');
    const schema = useContext(SchemaContext);

    const filteredSchemaByGroup = useMemo(() => {
        const schemaArr = schemaToArray(schema);
        const filtersByGroup = groupBy(schemaArr, (e) => e.categoryId) as FilterSchemaWithIdByCategoryId;
        const sortByLabel = (filter: FilterSchemaWithId) => filter.label;
        const sortedFiltersByGroup = {} as FilterSchemaWithIdByCategoryId;
        const searchIsEmpty = !search || search == '';

        each(filtersByGroup, (filters: FilterSchemaWithId[], categoryId: FilterCategoryTypes) => {
            sortedFiltersByGroup[categoryId] = sortBy(
                filters.filter((f) => {
                    return searchIsEmpty || f.label?.toLowerCase()?.includes(search.toLowerCase());
                }),
                sortByLabel,
            );
        });
        return sortedFiltersByGroup;
    }, [search, schema]);

    return (
        <PopoverContent width="240px" id="first" {...categoryStyleProps.popoverContent}>
            <PopoverHeader color="gray.500" {...categoryStyleProps.popoverHeader}>
                <Input ref={initialRef} value={search || ''} variant="unstyled" placeholder="Filter by..." onChange={(e) => setSearch(e.target.value)} />
            </PopoverHeader>
            <PopoverBody
                maxHeight="calc(100vh - 200px)"
                pt={0}
                overflowY="auto"
                className="scroll-update"
                position="relative"
                {...categoryStyleProps.popoverBody}>
                <Stack divider={<Divider borderColor="gray.100A" />} w="full">
                    {map(filteredSchemaByGroup, (filters, categoryId: FilterCategoryTypes) => {
                        if (isEmpty(filters)) {
                            return null;
                        }
                        return (
                            <Stack key={categoryId}>
                                <Flex color="gray.500" pt={2} px={2}>
                                    <Text variant="label">{categoryTitleByCategoryId[categoryId]}</Text>
                                </Flex>
                                <Stack spacing={1}>
                                    {filters.map((filter) => {
                                        return (
                                            <Flex
                                                key={filter.id}
                                                align="center"
                                                gap={2}
                                                py={1.5}
                                                px={4}
                                                onClick={() => {
                                                    setFilter(filter);
                                                    setSearch('');
                                                }}
                                                as="button"
                                                _focus={{
                                                    borderColor: 'purple.200',
                                                    outlineColor: 'purple.100',
                                                    outlineWidth: 0,
                                                    outlineOffset: 0,
                                                    backgroundColor: 'rgba(210, 4, 125, 0.025)',
                                                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                                                }}
                                                _active={{
                                                    boxShadow: '0 0 0 3px rgba(210, 4, 125, 0.1)',
                                                    color: 'purple.300',
                                                }}
                                                width="100%"
                                                textAlign="left"
                                                color="gray.500"
                                                _hover={{
                                                    bg: 'gray.50A',
                                                }}>
                                                <Icon as={filter?.icon} color="gray.400" />
                                                <Tooltip placement="top" label={(filter as ParentRadioFilter)?.tooltipLabel || ''}>
                                                    <Text>{filter?.label}</Text>
                                                </Tooltip>
                                            </Flex>
                                        );
                                    })}
                                </Stack>
                            </Stack>
                        );
                    })}
                </Stack>
            </PopoverBody>
        </PopoverContent>
    );
};

interface FilterOptionsProps {
    filter: FilterSchema;
    setFilter: (filter: FilterSchema) => void;
    onClose: () => void;
}

const FilterOptions = ({ filter, setFilter, onClose }: FilterOptionsProps) => {
    switch (filter.type) {
        case 'checkbox':
            return <CheckboxComp onClose={onClose} category={filter} />;
        case 'text':
            return <TextComp onClose={onClose} category={filter} />;
        case 'number':
            return <RangeComp onClose={onClose} category={filter} />;
        case 'radio':
            return isParentRadioFilter(filter) ? (
                <RadioCompChildren category={filter} setFilter={setFilter} />
            ) : (
                <RadioComp onClose={onClose} category={filter} />
            );
        default:
            return null;
    }
};

interface FilterButtonProps {
    popoverProps?: PopoverProps;
    title?: string;
    buttonProps?: ButtonProps;
    categoryStyleProps?: {
        popoverBody?: ChakraProps;
        popoverContent?: ChakraProps;
        popoverHeader?: ChakraProps;
    };
}
export const FilterButton = ({ popoverProps, title = 'Filter', buttonProps, categoryStyleProps }: FilterButtonProps) => {
    const initialRef = React.useRef(null);
    const [filter, setFilter] = React.useState<FilterSchema | null>(null);
    const schema = useContext(SchemaContext);
    if (isEmpty(schema)) {
        return null;
    }

    return (
        <Popover
            onClose={() => {
                setTimeout(() => {
                    setFilter(null);
                }, 200);
            }}
            initialFocusRef={initialRef}
            {...popoverProps}>
            {({ onClose }) => (
                <>
                    <PopoverTrigger>
                        <Button
                            flexShrink={0}
                            height="26px"
                            px={2}
                            size="sm"
                            sx={{
                                span: {
                                    mr: title ? 1 : 0,
                                },
                            }}
                            leftIcon={<Icon as={Plus} color="purple.300" />}
                            variant="filter"
                            _hover={{
                                bg: 'purple.50A',
                            }}
                            borderColor="purple.100"
                            color="purple.400"
                            {...buttonProps}>
                            {title}
                        </Button>
                    </PopoverTrigger>
                    {!filter ? (
                        <CategoryComp setFilter={setFilter} initialRef={initialRef} categoryStyleProps={categoryStyleProps} />
                    ) : (
                        <FilterOptions filter={filter} setFilter={setFilter} onClose={onClose} />
                    )}
                </>
            )}
        </Popover>
    );
};

interface FiltersProps {
    addFilters: ActionCreatorWithPayload<Parameters<typeof listingFilterReducers.addFilters>[1]['payload'], string>;
    removeFilter: ActionCreatorWithPayload<Parameters<typeof listingFilterReducers.removeFilter>[1]['payload'], string>;
    updateFilter: ActionCreatorWithPayload<Parameters<typeof listingFilterReducers.updateFilter>[1]['payload'], string>;
    staticSchema: Record<string, FilterSchema>;
    activeFilters: Record<string, ActiveFilterType>;
    filterId?: ActiveFilterId;
}

interface FiltersPropsExtended extends FiltersProps {
    categoryStyleProps?: {
        popoverBody?: ChakraProps;
        popoverContent?: ChakraProps;
        popoverHeader?: ChakraProps;
    };
}

export const Filters = ({ categoryStyleProps, ...rest }: FiltersPropsExtended) => {
    return (
        <FiltersProvider {...rest}>
            <FilterButton popoverProps={{ placement: 'bottom-end' }} categoryStyleProps={categoryStyleProps} />
            <ActiveFilters />
        </FiltersProvider>
    );
};

export const FiltersProvider = ({
    addFilters,
    removeFilter,
    updateFilter,
    staticSchema,
    activeFilters,
    filterId,
    children,
}: FiltersProps & { children: React.ReactNode }) => {
    const dispatch = useAppDispatch();
    const addFiltersCallback = useCallback(
        (...args: Parameters<typeof addFilters>) => {
            dispatch(addFilters(...args));
        },
        [dispatch, addFilters],
    );

    const removeFilterCallback = useCallback(
        (...args: Parameters<typeof removeFilter>) => {
            dispatch(removeFilter(...args));
        },
        [dispatch, removeFilter],
    );

    const updateFilterCallback = useCallback(
        (...args: Parameters<typeof updateFilter>) => {
            dispatch(updateFilter(...args));
        },
        [dispatch, updateFilter],
    );
    return (
        <ActionContext.Provider
            value={{
                addFilters: addFiltersCallback,
                removeFilter: removeFilterCallback,
                updateFilter: updateFilterCallback,
            }}>
            <SchemaContext.Provider value={staticSchema}>
                <FilterIdContext.Provider value={filterId || 'active'}>
                    <ActiveFiltersContext.Provider value={activeFilters}>{children}</ActiveFiltersContext.Provider>
                </FilterIdContext.Provider>
            </SchemaContext.Provider>
        </ActionContext.Provider>
    );
};

export const FilterSet = () => {
    const staticSchema = useAppSelector((state) => state.proState.filters.staticSchema);
    const active = useAppSelector((state) => state.proState.filters.active);

    return (
        <Filters
            addFilters={actions.addFilters}
            removeFilter={actions.removeFilter}
            updateFilter={actions.updateFilter}
            staticSchema={staticSchema}
            activeFilters={active}
        />
    );
};
