import React from 'react';

import CompareModal, { CompareModalId } from './CompareModal';
import DetailsModal, { DetailsModalId } from './DetailsModal';
import DeleteModal, { DeleteModalId } from './DeleteModal';
import DuplicateModal, { DuplicateModalId } from './DuplicateModal';
import LimitedDataModal, { LimitedDataModalId } from './LimitedDataModal';
import SaveFilterSetModal, { FilterSetModalId } from './FilterSetModal';
import BoundaryModal, { boundaryId } from './BoundaryModal';

import ManageAssignedModal, { ManageAssignedModalId } from './ManageModal';

import { selectSet } from 'libs/global/selectors/sets';
import { useAppSelector } from 'libs/global/hooks';
import { useSetId } from 'libs/global/hooks/useSets';
import { LoadingModal } from './SubscriptionModal';

export const modalIds = {
    DETAILS: DetailsModalId,
    DELETE: DeleteModalId,
    EDIT: 'edit',
    DUPLICATE: DuplicateModalId,
    COMPARE: CompareModalId,
    BOUNDARY: boundaryId,
    MANAGE: ManageAssignedModalId,
    LIMITED: LimitedDataModalId,
    FILTER_SET: FilterSetModalId,
};

const Modals = () => {
    const modalId = useAppSelector((state) => state.sets.modalId);
    const defaultIndex = useAppSelector((state) => state.sets.modalProps?.defaultIndex);
    // Determine if it's from overview or from set
    const id = useAppSelector(selectSet);
    const { data: set, isLoading } = useSetId(id);
    const boundaryId = useAppSelector((state) => state.sets.boundaryId);

    if (!set && modalId && isLoading) {
        return <LoadingModal isOpen={true} />;
    }

    return (
        <>
            {modalId === modalIds.COMPARE && <CompareModal isOpen={true} />}
            {modalId === modalIds.DETAILS && <DetailsModal set={set} isOpen={true} />}
            {modalId === modalIds.DELETE && <DeleteModal set={set} isOpen={true} />}
            {modalId === modalIds.DUPLICATE && <DuplicateModal set={set} isOpen={true} />}
            {modalId === modalIds.BOUNDARY && <BoundaryModal boundaryId={boundaryId} isOpen={true} />}
            {modalId === modalIds.MANAGE && <ManageAssignedModal set={set} isOpen={true} defaultIndex={defaultIndex} />}
            {modalId === modalIds.LIMITED && <LimitedDataModal set={set} isOpen={true} />}
            {modalId === modalIds.FILTER_SET && <SaveFilterSetModal isOpen={true} />}
        </>
    );
};

export default Modals;
