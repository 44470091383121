import type { SerializedError } from '@reduxjs/toolkit';
import { ErrorResponse } from 'libs/types/errorResponse';
import { toast } from 'sonner';

export const loadToastError = (error?: SerializedError | ErrorResponse | string) => {
    let errorMessage = 'An error occurred. Please try again.';

    if (typeof error === 'string') {
        errorMessage = error;
    } else if (error && typeof error === 'object') {
        if ('name' in error && error.name) {
            errorMessage = error.name;
        } else if ('response' in error && error.response && error.response.data) {
            errorMessage = error.response.data.error;
        } else {
            errorMessage = JSON.stringify(error);
        }
    }

    toast.error(`${errorMessage}`, {
        id: 'error-saving',
        style: {
            background: 'hsl(359, 100%, 97%)',
            borderColor: 'hsl(359, 100%, 94%)',
            color: 'hsl(359, 100%, 45%)',
        },
    });
};
