import { useQuery } from '@tanstack/react-query';
import fetcher from 'libs/utils/fetcher';
import { channelRedirectUrl, channelsApiPath, linkChannelPath } from 'libs/routes';
import { channelMixins, ssapiLogo } from 'libs/constants';
import { Channel } from 'libs/types/channel';

// Store Channels globally for components that can't be converted to hooks.
let CHANNELS: Channel[] = [];

export const useChannels = (): Channel[] => {
    const { data } = useQuery({
        queryKey: [channelsApiPath()],
        queryFn: async () => {
            let data: Channel[] = await fetcher(channelsApiPath());
            data = data.map((c: Channel) => (c.integration_type === 'ssapi' ? { ...c, icon: ssapiLogo } : c));
            data = data.map((c: Channel) => {
                const mixin = channelMixins[String(c.id_name)];
                if (mixin) {
                    return { ...c, ...mixin };
                } else {
                    return c;
                }
            });
            CHANNELS = data;
            return data;
        },
        staleTime: Infinity,
    });

    if (!data) {
        return [];
    }

    return data || [];
};

export const useChannelByShortId = (shortId: string | undefined): Channel | undefined => {
    const channels = useChannels();
    return channels.find((c: Channel) => c.short_id === shortId);
};

export const useChannelByIdName = (idName?: string): Channel | undefined => {
    const channels = useChannels();
    if (!idName) {
        return undefined;
    }
    return channels.find((c: Channel) => c.id_name === idName.toLowerCase());
};

export const useCheckinCheckoutIsEnabledByShortId = (shortId: string | undefined) => {
    const channel = useChannelByShortId(shortId);
    return channel?.enable_checkin_checkout;
};

export const useLongTermDiscountsIsEnabledByShortId = (shortId: string | undefined) => {
    const channel = useChannelByShortId(shortId);
    return !channel?.disable_long_term_discounts;
};

export const useMinimumStaysIsEnabledByShortId = (shortId: string | undefined) => {
    const channel = useChannelByShortId(shortId);
    return channel?.enable_min_stays;
};

export const useChannelRedirectUrl = (channelId?: string) => {
    const channel = useChannelByIdName(channelId);
    return channelRedirectUrl(channel);
};

export const useLinkChannelAccountsPath = (source: string | undefined) => {
    const channel = useChannelByShortId(source);
    return linkChannelPath(channel?.id_name);
};

export const useAccountTypesLongForm = (): Record<string, string> => {
    const channels = useChannels();
    return Object.fromEntries(new Map(channels.map((c: Channel) => [c.short_id, c.id_name])));
};

// Global functions for when hooks are not available.
export const getAccountTypesLongForm = (): Record<string, string> => {
    return Object.fromEntries(new Map(CHANNELS.map((c: Channel) => [c.short_id, c.id_name])));
};

export const getAccountTypesShortForm = (): Record<string, string> => {
    return Object.fromEntries(new Map(CHANNELS.map((c: Channel) => [c.id_name, c.short_id])));
};

export const getChannelByShortId = (shortId: string) => {
    return CHANNELS.find((c: Channel) => c.short_id === shortId);
};
