import { extendTheme, withDefaultColorScheme, withDefaultSize, withDefaultVariant } from '@chakra-ui/react';
import {
    borders,
    breakpoints,
    colors,
    components,
    fontSizes,
    fontWeights,
    letterSpacings,
    lineHeights,
    opacity,
    radii,
    shadows,
    sizes,
    space,
    zIndices,
} from './theme';

export const theme = extendTheme(
    {
        breakpoints,
        space,
        sizes,
        fontSizes,
        fontWeights,
        lineHeights,
        letterSpacings,
        colors,
        radii,
        borders,
        shadows,
        zIndices,
        opacity,
        components,
        layerStyles: {
            container: {
                border: '1px solid',
                borderColor: 'gray.100',
                rounded: 'md',
            },
        },
        textStyles: {
            sidebarSectionTitle: {
                fontSize: 'md',
                color: 'gray.800',
                fontWeight: 600,
            },
            sidebarSectionLabel: {
                fontSize: 'md',
                color: 'gray.500',
            },
            sidebarSectionValue: {
                fontSize: 'md',
                fontWeight: 500,
                color: 'gray.800',
            },
        },
        semanticTokens: {
            colors: {
                text: {
                    default: 'gray.800',
                    secondary: 'gray.400',
                    _disabled: 'gray.300',
                    hint: 'gray.300',
                    link: 'brand.500',
                    'link-hover': 'brand.700',
                    'link-active': 'brand.800',
                    brand: 'brand.500',
                    'brand-hover': 'brand.700',
                    'brand-on-bg-fill': 'white',
                    'brand-on-bg-fill-hover': 'whiteAlpha.800',
                    'brand-on-bg-fill-active': 'whiteAlpha.900',
                    'brand-on-bg-fill-disabled': 'whiteAlpha.500',
                },
                bg: {
                    default: 'gray.10',
                    inverse: 'gray.800',
                    surface: 'white',
                    'surface-hover': 'gray.25',
                    'surface-active': 'gray.25',
                    'surface-selected': 'gray.50',
                    'surface-disabled': 'gray.50A',
                    'surface-secondary': 'gray.25',
                    'surface-secondary-hover': 'gray.50',
                    'surface-secondary-active': 'gray.50',
                    'surface-secondary-selected': 'gray.100',
                    'surface-inverse': 'gray.800',
                    'surface-transparent': 'transparent',
                    secondary: 'gray.50A',
                    _disabled: 'gray.50',
                    hint: 'gray.50',
                },
                border: {
                    default: 'gray.100',
                    hover: 'gray.150',
                    disabled: 'gray.100A',
                    secondary: 'gray.100A',
                    tertiary: 'gray.200',
                    focus: 'brand.500',
                    error: 'red.500',
                    brand: 'brand.500',

                    inverse: 'gray.800',
                    'inverse-hover': 'gray.700',
                    'inverse-disabled': 'gray.700',
                    'inverse-active': 'gray.700',
                },
            },
        },
        styles: {
            global: {
                body: {
                    letterSpacing: '-0.008em',
                    color: '#1B191B',
                },
                ':not(.chakra-dont-set-collapse) > .chakra-collapse': {
                    overflow: 'initial !important',
                },
            },
        },
    },
    withDefaultColorScheme({
        colorScheme: 'purple',
        components: ['Checkbox', 'Select'],
    }),
    withDefaultVariant({
        variant: 'outline',
        components: ['Input', 'Select'],
    }),
    withDefaultSize({
        size: 'md',
        components: ['Button', 'Badge'],
    }),
);
