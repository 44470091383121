import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppDispatch } from '../store';
import { Settings } from 'libs/types/settings';
import { clearUiMessages as serverUiClearUiMessages } from './serverUi';
import React from 'react';

const defaultLoadingText = 'Loading';
let timeout;

interface UiState {
    viewDate: Date;
    isLoading: boolean;
    loadingTitle?: string;
    successMsg?: string | null;
    errorMsg?: string | React.ReactNode | null;
    showUpgradeModal: boolean;
    showDowngradeModal: boolean;
    upgradeMessage?: string;
    upgradeRedirect: boolean;
    showAllTiers: boolean;
    dirty?: Partial<Settings>;
}

export const initialState = {
    viewDate: new Date(),
    isLoading: false,
    loadingTitle: defaultLoadingText,
    showUpgradeModal: false,
    upgradeRedirect: false,
} as UiState;

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        markDirty: (state, action: PayloadAction<{ setting: string; value: any }>) => {
            state.dirty = state.dirty || {};
            state.dirty[action.payload.setting] = action.payload.value;
        },
        markClean: (state, action: PayloadAction<{ setting: string }>) => {
            state.dirty = state.dirty || {};
            delete state.dirty[action.payload.setting];
        },
        markAllClean: (state) => {
            delete state.dirty;
        },
        startLoading: (state, action: PayloadAction<{ title: string }>) => {
            state.isLoading = true;
            state.loadingTitle = action.payload.title;
        },
        stopLoading: (state) => {
            state.isLoading = false;
            state.loadingTitle = defaultLoadingText;
        },
        stopLoadingSuccess: (state, action: PayloadAction<{ successMsg: string }>) => {
            state.isLoading = false;
            state.successMsg = action.payload.successMsg;
            state.errorMsg = null;
            state.loadingTitle = defaultLoadingText;
        },
        stopLoadingError: (state, action: PayloadAction<{ errorMsg: string | React.ReactNode }>) => {
            state.isLoading = false;
            state.successMsg = null;
            state.errorMsg = action.payload.errorMsg;
            state.loadingTitle = defaultLoadingText;
        },
        showSuccess: (state, action: PayloadAction<{ successMsg: string }>) => {
            state.successMsg = action.payload.successMsg;
        },
        clearUiMessages: (state) => {
            state.successMsg = null;
            state.errorMsg = null;
        },
        showUpgradeModal: (state, action: PayloadAction<{ upgradeMessage?: string; upgradeRedirect: boolean; showAllTiers: boolean }>) => {
            state.showUpgradeModal = true;
            state.upgradeMessage = action.payload.upgradeMessage;
            state.upgradeRedirect = action.payload.upgradeRedirect;
            state.showAllTiers = action.payload.showAllTiers;
        },
        hideUpgradeModal: (state) => {
            state.showUpgradeModal = false;
        },
        showDowngradeModal: (state) => {
            state.showDowngradeModal = true;
        },
        hideDowngradeModal: (state) => {
            state.showDowngradeModal = false;
        },
    },
});

export const { markDirty, markClean, markAllClean, hideUpgradeModal, showDowngradeModal, hideDowngradeModal, stopLoading } = uiSlice.actions;

export const pageChanged = () => {
    return (d: AppDispatch) => {
        d(uiSlice.actions.clearUiMessages());
    };
};

export const startLoading = (title: string) => {
    return (d: AppDispatch) => {
        d(uiSlice.actions.clearUiMessages());
        clearTimeout(timeout); // clear timeout, so new loading can refresh the duration
        d(uiSlice.actions.startLoading({ title }));
    };
};

export function stopLoadingSuccess(successMsg: string) {
    return (d: AppDispatch) => {
        timeout = setTimeout(() => d(uiSlice.actions.clearUiMessages()), 4000); // clear notification status
        d(uiSlice.actions.stopLoadingSuccess({ successMsg }));
    };
}

export function stopLoadingError(errorMsg: string | React.ReactNode) {
    return (d: AppDispatch) => {
        timeout = setTimeout(() => d(uiSlice.actions.clearUiMessages()), 4000); // clear notification status
        d(uiSlice.actions.stopLoadingError({ errorMsg }));
    };
}

export function showSuccess(successMsg: string) {
    return (d: AppDispatch) => {
        timeout = setTimeout(() => d(uiSlice.actions.clearUiMessages()), 4000); // clear notification status
        d(uiSlice.actions.showSuccess({ successMsg }));
    };
}

export function showUpgradeModal(message?: string, redirect?: boolean, allTiers: boolean = false) {
    return (d: AppDispatch) => {
        d(
            uiSlice.actions.showUpgradeModal({
                upgradeMessage: message,
                upgradeRedirect: redirect || false,
                showAllTiers: allTiers,
            }),
        );
    };
}

export function clearUiMessages() {
    return (d: AppDispatch) => {
        d(uiSlice.actions.clearUiMessages());
        d(serverUiClearUiMessages());
    };
}

export default uiSlice.reducer;
