import type { Moment } from 'moment';

export enum RATE_TYPE {
    AUTOMATED = 'adjustment', // eslint-disable-line no-unused-vars
    FIXED = 'fixed', // eslint-disable-line no-unused-vars
    BLOCKED = 'blocked', // eslint-disable-line no-unused-vars
    ADJUSTMENT = 'bulk-adjustment', // eslint-disable-line no-unused-vars
}

export interface RatePeriod {
    created_at?: string | null;
    created_user?: string;
    created_user_id?: number | null;
    currency?: string | null;
    defaultPrice?: number | string | null;
    end_date?: string;
    expires_at?: string | Moment | null;
    fixed?: boolean | null;
    friday?: number | null;
    id?: number | null;
    is_blocked?: boolean | null;
    listing_id?: number | null;
    monday?: number | null;
    price_adjustment?: number | null;
    rate_type?: RATE_TYPE | null;
    saturday?: number | null;
    start_date?: string;
    sunday?: number | null;
    thursday?: number | null;
    tuesday?: number | null;
    updated_at?: string | null;
    valid_until?: string | null;
    wednesday?: number | null;
}

export interface EditingRatePeriod extends Omit<RatePeriod, 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'> {
    monday?: string | number | null;
    tuesday?: string | number | null;
    wednesday?: string | number | null;
    thursday?: string | number | null;
    friday?: string | number | null;
    saturday?: string | number | null;
    sunday?: string | number | null;
}

export interface RatePeriodState {
    current: RatePeriod[];
    dirty: RatePeriod[];
}
