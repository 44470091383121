import { hexToRgba } from 'libs/wheelhouse_design/utils/color-conversion';

const purple = {
    '0A': hexToRgba('#D22CC3', 1),
    0: '#FFFFFF',
    '25A': hexToRgba('#D22CC3', 0.02),
    '50A': hexToRgba('#D22CC3', 0.05),
    50: '#FFF3FE',
    '100A': hexToRgba('#D22CC3', 0.1),
    100: '#FCD4F8',
    '200A': hexToRgba('#D22CC3', 0.35),
    200: '#F9B7F3',
    '300A': hexToRgba('#D22CC3', 0.6),
    300: '#F27FE8',
    '400A': hexToRgba('#D22CC3', 0.8),
    400: '#f722db',
    500: '#DB10C9',
    600: '#BB25AC',
    700: '#9B1D90',
    800: '#75136C',
    900: '#480842',
    1000: '#190116',
};

const blue = {
    '0A': hexToRgba('#404CDB', 1),
    0: '#FFFFFF',
    '25A': hexToRgba('#404CDB', 0.02),
    '50A': hexToRgba('#404CDB', 0.05),
    50: '#E3E4FF',
    '100A': hexToRgba('#404CDB', 0.1),
    100: '#CDCDFA',
    '200A': hexToRgba('#404CDB', 0.35),
    200: '#B3B3F5',
    '300A': hexToRgba('#404CDB', 0.6),
    300: '#9395ED',
    '400A': hexToRgba('#404CDB', 0.8),
    400: '#6D71E8',
    500: '#404CDB',
    600: '#2D3CA8',
    700: '#1A2A87',
    800: '#05125E',
    900: '#000B45',
    1000: '#000417',
};

const green = {
    '0A': hexToRgba('#36E89E', 1),
    0: '#FFFFFF',
    '25A': hexToRgba('#36E89E', 0.02),
    '50A': hexToRgba('#36E89E', 0.05),
    50: '#F5FFFB',
    '100A': hexToRgba('#36E89E', 0.1),
    100: '#D3FEEC',
    '200A': hexToRgba('#36E89E', 0.35),
    200: '#B1FDDD',
    '300A': hexToRgba('#36E89E', 0.6),
    300: '#6EF6BD',
    '400A': hexToRgba('#36E89E', 0.8),
    400: '#36E89E',
    500: '#0CD381',
    600: '#00B86B',
    700: '#009658',
    800: '#007242',
    900: '#004D2D',
    1000: '#002918',
};

const yellow = {
    '0A': hexToRgba('#F4D019', 1),
    0: '#FFFFFF',
    '25A': hexToRgba('#F4D019', 0.02),
    '50A': hexToRgba('#F4D019', 0.05),
    50: '#FFF5D9',
    '100A': hexToRgba('#F4D019', 0.1),
    100: '#FFF2CC',
    '200A': hexToRgba('#F4D019', 0.35),
    200: '#FCEBB1',
    '300A': hexToRgba('#F4D019', 0.6),
    300: '#FAE191',
    '400A': hexToRgba('#F4D019', 0.8),
    400: '#FAD96B',
    500: '#F4D019',
    600: '#D4AB0B',
    700: '#A68500',
    800: '#6E5B00',
    900: '#4D4200',
    1000: '#151200',
};

const red = {
    '0A': hexToRgba('#ED4B3C', 1),
    0: '#FFFFFF',
    '25A': hexToRgba('#ED4B3C', 0.02),
    '50A': hexToRgba('#ED4B3C', 0.05),
    50: '#FFE2DE',
    '100A': hexToRgba('#ED4B3C', 0.1),
    100: '#FCBFB6',
    '200A': hexToRgba('#ED4B3C', 0.35),
    200: '#FAA89B',
    '300A': hexToRgba('#ED4B3C', 0.6),
    300: '#FA9082',
    '400A': hexToRgba('#ED4B3C', 0.8),
    400: '#F77968',
    500: '#ED4B3C',
    600: '#CC2D25',
    700: '#AB1E1A',
    800: '#6E0909',
    900: '#4F0000',
    1000: '#1C0000',
};

const gray = {
    '0A': hexToRgba('#8f8d8e', 1),
    0: '#FFFFFF',
    10: '#FAFAFB',
    25: '#F7F7F7',
    '25A': hexToRgba('#8f8d8e', 0.02),
    '50A': hexToRgba('#8f8d8e', 0.05),
    50: '#efeeee',
    '100A': hexToRgba('#8f8d8e', 0.1),
    100: '#e5e4e4',
    '200A': hexToRgba('#8f8d8e', 0.35),
    150: '#d9d8d8',
    '150A': hexToRgba('#8f8d8e', 0.2),
    200: '#cbcaca',
    '300A': hexToRgba('#8f8d8e', 0.6),
    250: '#bdbcbd',
    300: '#b1afb0',
    350: '#8f8d8e',
    '400A': hexToRgba('#8f8d8e', 0.8),
    400: '#727072',
    450: '#6b6a6b',
    500: '#646264',
    600: '#595759',
    700: '#4e4c4e',
    800: '#343234',
    900: '#282528',
    1000: '#1b191b',
};

const blacks = {
    '0A': hexToRgba('#444', 1),
    0: '#FFFFFF',
    '25A': hexToRgba('#444', 0.02),
    '50A': hexToRgba('#444', 0.05),
    50: '#F3F3F3',

    '100A': hexToRgba('#444', 0.15),
    100: '#D9D9D9',
    '200A': hexToRgba('#444', 0.35),
    200: '#C3C3C3',
    '300A': hexToRgba('#444', 0.6),
    300: '#A0A0A0',
    '400A': hexToRgba('#444', 0.8),
    400: '#666',
    500: '#444',
    600: '#444',
    700: '#333',
    800: '#222',
    900: '#111',
    1000: '#000',
};

const whites = {
    '0A': hexToRgba('#FFFFFF', 1),
    0: '#FFFFFF',
    '25A': hexToRgba('#FFFFFF', 0.02),
    '50A': hexToRgba('#FFFFFF', 0.05),
    50: '#FFFFFF',
    '100A': hexToRgba('#FFFFFF', 0.1),
    100: '#FFFFFF',
    '200A': hexToRgba('#FFFFFF', 0.35),
    200: '#FFFFFF',
    '300A': hexToRgba('#FFFFFF', 0.6),
    300: '#FFFFFF',
    '400A': hexToRgba('#FFFFFF', 0.8),
    400: '#FFFFFF',
    500: '#FFFFFF',
    600: '#F8F8F8',
    700: '#EEEEEE',
    800: '#E8E8E8',
    900: '#222',
    1000: '#111',
};

const whiteAlpha = {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
};

const blackAlpha = {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
};

// *************************
// *************************
// ******** TIER 2 *********
// *************************
// *************************
const primary = purple;
const secondary = gray;
const tertiary = green;
const destructive = red;
const warning = yellow;
const success = green;

export const colorStyles = {
    blue: {
        backgroundColor: blue[500],
        color: whites[500],
    },
    purple: {
        backgroundColor: purple[500],
        color: whites[500],
    },
    red: {
        backgroundColor: red[500],
        color: whites[500],
    },
    green: {
        backgroundColor: green[500],
        color: whites[500],
    },
    gray: {
        backgroundColor: gray[500],
        color: whites[500],
    },
    yellow: {
        backgroundColor: yellow[500],
        color: yellow[1000],
    },
    white: {
        backgroundColor: whites[500],
        color: blacks[500],
    },
    lightBlue: {
        backgroundColor: blue[50],
        color: blue[800],
    },
    lightPurple: {
        backgroundColor: purple['100A'],
        color: purple[900],
    },
    lightRed: {
        backgroundColor: red[50],
        color: red[800],
    },
    lightGreen: {
        backgroundColor: green[50],
        color: green[800],
    },
    lightNeutral: {
        backgroundColor: gray[50],
        color: gray[900],
    },
    lightYellow: {
        backgroundColor: yellow[50],
        color: yellow[900],
    },
    black: {
        backgroundColor: blacks[500],
        color: whites[500],
    },
};

const colors = {
    current: 'currentColor',
    transparent: 'transparent',
    white: '#fff',
    black: '#111',
    yellow,
    green,
    blue,
    red,
    gray,
    purple,
    blacks,
    whites,
    primary,
    secondary,
    tertiary,
    destructive,
    success,
    warning,

    whiteAlpha,
    blackAlpha,
};

export default colors;
