'use client';

import React, { useMemo } from 'react';
import { isEqual, sortBy } from 'lodash';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Flex,
    HStack,
    Input,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    Tooltip,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { LEVEL_FULL, LEVEL_NOT, LEVEL_READ } from './accessColumnDefs';
import { useSubUsers } from 'libs/global/hooks/useTeams';
import { useUserFullName } from 'libs/global/hooks/user';
import { SubUser } from 'libs/types/subUser';
import { useAppDispatch } from 'libs/global/hooks';
import { actions } from 'libs/global/slices/proState';

const formatSubUsersForEndpoint = (currentSubUsers: number[], subUsers: number[], listingId: number, readOnly: boolean) => {
    // Access have been granted
    const added = subUsers.filter((id) => !currentSubUsers.includes(id));
    // Access have been revoked
    const removed = currentSubUsers.filter((id) => !subUsers.includes(id));

    const obj = {};
    added.forEach((id) => {
        obj[id] = readOnly ? LEVEL_READ : LEVEL_FULL;
    });
    removed.forEach((id) => {
        obj[id] = LEVEL_NOT;
    });
    return { [listingId]: obj };
};

export const getNameOfSubUser = (user: SubUser) => {
    const { subuser_first_name, subuser_last_name } = user;
    return [subuser_first_name, subuser_last_name].join(' ');
};

interface SubUserListCheckboxProps {
    subUsers: number[];
    allSubUsers: SubUser[];
    updateSubUsers: (e: number[]) => void;
}
const SubUserListCheckbox = ({ subUsers, allSubUsers, updateSubUsers }: SubUserListCheckboxProps) => {
    const sortedSubUsersByCheckedAndName = sortBy(allSubUsers, (user) => {
        const checked = subUsers.find((id) => id == user.sub_user_id);
        return [!checked, getNameOfSubUser(user)];
    });

    return (
        <CheckboxGroup
            value={subUsers}
            onChange={(e: (string | number)[]) => {
                // CheckboxGroup may convert values to strings,
                // so map all the values to numbers before updating.
                updateSubUsers(e.map((id) => parseInt(id as string)));
            }}>
            <VStack align="flex-start" py={allSubUsers.length ? 4 : 0} px={4} spacing={2}>
                {sortedSubUsersByCheckedAndName.map((subuser) => {
                    return (
                        <HStack spacing={2} key={subuser.sub_user_id}>
                            <Checkbox value={subuser.sub_user_id}>{getNameOfSubUser(subuser)}</Checkbox>
                        </HStack>
                    );
                })}
            </VStack>
        </CheckboxGroup>
    );
};

interface ListingAccessProps {
    userId: number;
    currentSubUsers: number[];
    saveEdit: (e: any) => void;
    listingId: number;
    isOwnedListing: boolean;
    readOnly: boolean;
}
export const ListingAccess = ({ userId, currentSubUsers, saveEdit, listingId, isOwnedListing, readOnly }: ListingAccessProps) => {
    const dispatch = useAppDispatch();
    const userFullName = useUserFullName();
    const { data: allSubUsers } = useSubUsers();
    const [subUsers, setSubUsers] = React.useState(currentSubUsers);
    const [search, setSearch] = React.useState<string | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);

    const isActive = (subUser: SubUser) => {
        return subUser.status == 'A';
    };

    const activeSubUsers = useMemo(() => {
        if (!allSubUsers) {
            return [];
        } else {
            return (isOwnedListing ? allSubUsers.filter((subUser) => subUser.sub_user_id != userId) : allSubUsers).filter(isActive);
        }
    }, [allSubUsers, userId, isOwnedListing]);

    const getNameOfSubUserId = (id: number) => {
        const subuser = activeSubUsers.find((user) => user.sub_user_id == id);
        if (!subuser) {
            return id == userId ? userFullName : null;
        }

        return getNameOfSubUser(subuser);
    };

    const resetState = () => {
        setSearch(null);
        setSubUsers(currentSubUsers);
    };

    const onSave = () => {
        onClose();
        if (outOfSync) {
            const formattedPayload = formatSubUsersForEndpoint(currentSubUsers, subUsers, listingId, readOnly);
            dispatch(actions.saveSubUserAccess(formattedPayload));
            saveEdit(formattedPayload);
        }
    };

    const onCancel = () => {
        onClose();
        resetState();
    };

    const isEditable = activeSubUsers?.length > 0;

    const outOfSync = React.useMemo(() => {
        return !isEqual(subUsers.sort(), [...currentSubUsers].sort());
    }, [subUsers, currentSubUsers]);

    const filteredSubUsers = React.useMemo(() => {
        if (!search || search?.length <= 1) {
            return activeSubUsers;
        } else {
            return activeSubUsers.filter((subuser) => getNameOfSubUser(subuser).toLowerCase().includes(search.toLowerCase()));
        }
    }, [search, activeSubUsers]);

    return (
        <Box
            as={Button}
            isDisabled={!isOwnedListing}
            variant="filter"
            borderColor="transparent"
            overflow="auto hidden"
            position="relative"
            w="full"
            px={0}
            py={1}
            display="block"
            height="100%"
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            onClick={!isOpen && isEditable ? onOpen : undefined}>
            <Popover isOpen={isOpen} onClose={onSave} initialFocusRef={initialRef} isLazy={true} placement="right">
                <PopoverTrigger>
                    <Flex alignSelf="center" gap={1}>
                        {subUsers?.length ? (
                            subUsers.map((id) => {
                                const name = getNameOfSubUserId(id);
                                return name ? (
                                    <Box key={id}>
                                        <Tooltip label={name}>
                                            <Box>
                                                <Avatar size="xs" name={name} color="purple.700" bg="purple.100A" />
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                ) : null;
                            })
                        ) : (
                            // We're setting opacity equals 0 so that the avatar is present, but not visible
                            <Avatar size="xs" name="Empty" color="purple.700" bg="purple.100A" opacity={0} />
                        )}
                    </Flex>
                </PopoverTrigger>
                <Portal>
                    <PopoverContent>
                        <PopoverHeader pt={3}>
                            <Input
                                ref={initialRef}
                                placeholder="Adjust access..."
                                variant="unstyled"
                                width="auto"
                                value={search || ''}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </PopoverHeader>
                        <PopoverCloseButton onClick={onCancel} />
                        {filteredSubUsers ? (
                            <PopoverBody p={0}>
                                <VStack align="flex-start" spacing={0}>
                                    <Flex maxHeight="210px" overflow="auto" width="100%">
                                        <SubUserListCheckbox subUsers={subUsers} allSubUsers={filteredSubUsers} updateSubUsers={setSubUsers} />
                                    </Flex>
                                </VStack>
                            </PopoverBody>
                        ) : null}
                    </PopoverContent>
                </Portal>
            </Popover>
        </Box>
    );
};

export default ListingAccess;
