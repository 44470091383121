import { ActiveFilter, FilterSchema, isCheckboxFilter, isParentRadioFilter, isRadioFilter } from 'libs/types/filters';
import { isArray, isEmpty, reduce } from 'lodash';
import moment from 'moment';
import { filterSchema } from 'libs/global/components/Filters/schema';
import { marketStringToMarketId, tagsFilter } from 'libs/global/components/Pro/settings-next/getListingColumnDefs';
import { getAccountTypesShortForm } from 'libs/global/hooks/useChannels';
import { LEVEL_FULL, LEVEL_READ } from 'libs/global/components/Pro/settings-next/accessColumnDefs';
import queryString from 'query-string';
/*
AG Grid supports 4 different type of filters
    1. Text
    2. Number
    3. Date
    4. Set filter

For more information see https://www.ag-grid.com/javascript-data-grid/filter-provided/

In our current setup we have the following types in Filters/schema.js, i.e. defined by the parameter 'type'
    1. text         (ag grid: Text)
    2. checkbox     (ag grid: Set)
    3. radio        (ag grid: Set) (we restrict the user to select a single value)

    Under development
    4. number       (ag grid: Number)

Note that we're using a simplified version of the filter API, i.e. we don't support all versions of the different filters.
I.e. if you're using AG Grid built-in functionality for a set filter, you could do: 'Show all rows where columnX is NOT IN [...]' etc.

When updating the filter model (via this.api.setFilterModel()) in AG Grid each item in the updated filter model object should look like
this (dependent on which type of filter it is):

    1. Text
        {
            [colId || field ] : {
                filterType: 'text',
                filter: <value-your-searching-for>,
                type: 'contains',
            }
        }
    2. Number
        {
            [colId || field ] : {
                    filter: <lower-bound>,
                    filterTo: <upper-bound>,
                    filterType: 'number',
                    type: 'inRange',
            }
        }
    3. Date (ignoring this since we're not using it)
    4. Set filter
        {
            [colId || field ] : {
                    type: 'set',
                    values: [...],
            }
        }
*/

export const FILTER_IN_RANGE = 'inRange';
export const FILTER_GTE = 'greaterThanOrEqual';
export const FILTER_LTE = 'lessThanOrEqual';
export const FILTER_NOT = '_not';
export const FILTER_OPTIONS = [FILTER_IN_RANGE, FILTER_GTE, FILTER_LTE, FILTER_NOT];

const columnId = ({ property, paramKey }: { property?: string; paramKey?: any }) => {
    return property || paramKey;
};

const numericTypeAndValues = ([filter, filterTo]: [number | undefined, number | undefined]) => {
    const lth = Number.isFinite(filter);
    const uth = Number.isFinite(filterTo);
    if (lth && uth) {
        return { type: FILTER_IN_RANGE, filter, filterTo };
    } else if (lth) {
        return { type: FILTER_GTE, filter };
    } else {
        return { type: FILTER_LTE, filter: filterTo };
    }
};

const dateTypeAndValues = ([filter, filterTo]: [string, string]) => {
    if (filter && filterTo) {
        return { type: FILTER_IN_RANGE, dateFrom: filter, dateTo: filterTo };
    } else if (filter) {
        return { type: FILTER_GTE, dateFrom: filter, dateTo: null };
    } else {
        return { type: FILTER_LTE, dateFrom: filterTo, dateTo: null };
    }
};

const appendComparator = (comparator: string = 'or', value: string) => {
    return `${value}_${comparator}`;
};

interface FormatFilterProps {
    type?: string;
    property?: string;
    values: any;
    filterOption?: string;
    selectedComparator?: string;
}
const formatFilter = ({ type, property, values, filterOption, selectedComparator }: FormatFilterProps): ActiveFilter => {
    switch (type) {
        case 'text':
            return {
                filterType: 'text',
                type: filterOption || 'contains',
                filter: values,
            };
        case 'number':
            return {
                filterType: 'number',
                ...numericTypeAndValues(values),
            };
        case 'date':
            return {
                filterType: 'date',
                ...dateTypeAndValues(values),
            };
        case 'checkbox':
            if (property === 'tags') {
                const valuesWithComparator = values.map((value) => appendComparator(selectedComparator, value));
                return {
                    filterType: 'checkbox',
                    type: 'set',
                    values: valuesWithComparator,
                };
            } else {
                return {
                    filterType: 'checkbox',
                    type: 'set',
                    values: values,
                };
            }

        case 'radio':
            return {
                filterType: 'radio',
                type: 'set',
                values: values,
            };
        default:
            throw new Error('Invalid filter type');
    }
};

interface FormatFiltersProps {
    type?: string;
    property?: string;
    paramKey?: any;
    values: any;
    filterOption?: string;
    selectedComparator?: string;
}
const formatFilters = ({ type, property, paramKey, values, filterOption, selectedComparator }: FormatFiltersProps) => {
    const filterKey = columnId({ property, paramKey })!;

    return {
        [filterKey]: formatFilter({ type, property, values, filterOption, selectedComparator }),
    };
};

const textFilterType = (paramName: string, filter: ActiveFilter) => {
    switch (filter.type) {
        case 'contains':
            return { [paramName]: filter.filter };
        case 'notContains':
            return { [`${paramName}${FILTER_NOT}`]: filter.filter };
        default:
            return {};
    }
};

const numberFilterType = (paramName: string, filter: ActiveFilter, isPercent?: boolean) => {
    const adj = isPercent ? 1 / 100 : 1;
    switch (filter.type) {
        case 'inRange':
            return {
                [`${paramName}_gte`]: Number(filter.filter) * adj,
                [`${paramName}_lte`]: Number(filter.filterTo) * adj,
            };
        case 'lessThanOrEqual':
            return { [`${paramName}_lte`]: Number(filter.filter) * adj };
        case 'greaterThanOrEqual':
            return { [`${paramName}_gte`]: Number(filter.filter) * adj };
    }
};

const radioFilterType = (paramName: string, paramValue: string, filter: ActiveFilter) => {
    const [value] = filter?.values || [];
    return { [paramName]: value == paramValue };
};

const checkboxFilterType = (paramName: string, filter: ActiveFilter) => {
    if ((filter?.values?.length as number) > 0) {
        return { [paramName]: filter.values };
    } else {
        return {};
    }
};

const accessLevelFilter = (paramName: string, filter: ActiveFilter, userId: number) => {
    if (isEmpty(filter?.values)) {
        return;
    }
    const [value] = filter.values || [];
    switch (value) {
        case 'admin':
            return {
                [paramName]: [userId],
            };
        case 'editor':
            return {
                [`${paramName}_ne`]: [userId],
                'users.id': [userId],
                'managed_listings.level': LEVEL_FULL,
            };
        case 'viewer':
            return {
                [`${paramName}_ne`]: [userId],
                'users.id': [userId],
                'managed_listings.level': LEVEL_READ,
            };
        default:
            return;
    }
};

const getDateDaysAgo = (numberOfDays: moment.DurationInputArg1): string => {
    return moment().subtract(numberOfDays, 'days').format('YYYY-MM-DD');
};

const lastBookedAtFilter = (paramName: string, filter: ActiveFilter) => {
    switch (filter.type) {
        case 'inRange':
            return {
                [`${paramName}_gte`]: getDateDaysAgo(filter.filterTo),
                [`${paramName}_lte`]: getDateDaysAgo(filter.filter),
            };
        case 'lessThanOrEqual':
            return {
                [`${paramName}_gte`]: getDateDaysAgo(filter.filter),
            };
        case 'greaterThanOrEqual':
            return {
                [`${paramName}_lte`]: getDateDaysAgo(filter.filter),
            };
    }
};

const getSchema = (schemaKey: string): FilterSchema | undefined => {
    if (filterSchema[schemaKey]) {
        return filterSchema[schemaKey];
    }

    // Loop through schema items with children
    for (const key in filterSchema) {
        const schema = filterSchema[key];

        if (isParentRadioFilter(schema)) {
            const childSchema = schema.children.find((child) => child.paramName === schemaKey || child.property === schemaKey);
            if (childSchema) {
                return childSchema;
            }
        } else if (schema.paramKey === schemaKey || schema.paramName === schemaKey) {
            return schema;
        }
    }
};

export const formatFiltersForApi = (
    filters: Record<string, ActiveFilter> | undefined,
    userId: number,
    options: {
        includeTagNames?: boolean;
    } = {},
): {
    [x: string]: any;
} => {
    if (isEmpty(filters)) {
        return {};
    }

    return reduce(
        filters,
        (params, filter, schemaKey) => {
            const schema = getSchema(schemaKey);
            if (!schema) {
                return params;
            }
            switch (schema.type) {
                case 'text':
                    return { ...params, ...textFilterType(schema.paramName, filter) };
                case 'number':
                    switch (schemaKey) {
                        case 'stat.last_booked_at':
                            return { ...params, ...lastBookedAtFilter(schema.paramName, filter) };
                        default:
                            return { ...params, ...numberFilterType(schema.paramName, filter, schema.isPercent) };
                    }
                case 'checkbox':
                    switch (schemaKey) {
                        case 'access_level':
                            return { ...params, ...accessLevelFilter(schema.paramName, filter, userId) };
                        case 'market.name':
                            return { ...params, [schema.paramName]: filter?.values?.map(marketStringToMarketId) };
                        case 'source':
                            return { ...params, [schema.paramName]: filter?.values?.map((s) => getAccountTypesShortForm()[s]) };
                        case 'tags':
                            return { ...params, ...tagsFilter(filter, options.includeTagNames) };
                        default:
                            return { ...params, ...checkboxFilterType(schema.paramName, filter) };
                    }
                case 'radio':
                    return { ...params, ...(isRadioFilter(schema) ? radioFilterType(schema.paramName, schema.paramValue, filter) : {}) };
                default:
                    return params;
            }
        },
        {},
    );
};

const getValidSchemaProperties = (schema: Record<string, FilterSchema>) => {
    return Object.values(schema)
        .map((item) => {
            if (isParentRadioFilter(item)) {
                return item.children.map((child) => child.paramName);
            } else if (item.paramName) {
                return item.paramName;
            }
            return [];
        })
        .flat()
        .map((key) => [key, `${key}_gte`, `${key}_lte`])
        .flat(2);
};

export const convertSearchParamsToFilterSet = (search: string, schema: Record<string, FilterSchema>) => {
    const parsedParams = queryString.parse(search, { arrayFormat: 'bracket' });
    const parsedKeys = Object.keys(parsedParams);
    const validKeys = getValidSchemaProperties(schema);
    const filterSet: Record<string, ActiveFilter> = {};

    parsedKeys
        .filter((key) => validKeys.includes(key))
        .forEach((key) => {
            const isNumericRange = key.endsWith('_gte') || key.endsWith('_lte');
            const schema = isNumericRange ? getSchema(key.replace(/_gte|_lte/g, ''))! : getSchema(key)!;
            const schemaKey = columnId({ property: schema.property, paramKey: schema.paramKey });

            if (isNumericRange) {
                const filter = filterSet[schemaKey] || {};
                const toFrom: [number | undefined, number | undefined] = filterSet[schemaKey]
                    ? [filter.filter as number, filter.filterTo as number]
                    : [undefined, undefined];

                if (key.endsWith('_gte')) {
                    toFrom[0] = parseFloat(parsedParams[key] as string);
                } else {
                    toFrom[1] = parseFloat(parsedParams[key] as string);
                }

                filterSet[schemaKey] = {
                    filterType: schema.type,
                    ...numericTypeAndValues(toFrom),
                };
            } else if (isCheckboxFilter(schema)) {
                filterSet[schemaKey] = {
                    filterType: schema.type,
                    type: 'set',
                    values: isArray(parsedParams[key]) ? (parsedParams[key] as string[]) : [parsedParams[key] as string],
                };
            } else if (isRadioFilter(schema)) {
                filterSet[schemaKey] = {
                    filterType: schema.type,
                    type: 'set',
                    // This is fragile.
                    // TODO: Figure out if there's a better way to convert the string back to the option values.
                    values: [parsedParams[key] == 'true' ? schema.options[0].value.toString() : schema.options[1].value.toString()],
                };
            } else if (!isArray(parsedParams[key])) {
                let type = 'contains';

                if (key.endsWith(FILTER_NOT)) {
                    type = 'notContains';
                }

                filterSet[schemaKey] = {
                    filterType: schema.type,
                    type,
                    filter: parsedParams[key] as string | number,
                };
            } else {
                const type = FILTER_OPTIONS.find((option) => key.endsWith(option));
                if (type) {
                    if (parsedParams[key]!.length > 1) {
                        filterSet[schemaKey] = {
                            filterType: schema.type,
                            type: type,
                            filter: parseInt(parsedParams[key]![0]!),
                            filterTo: parseInt(parsedParams[key]![1]!),
                        };
                    } else {
                        filterSet[schemaKey] = {
                            filterType: schema.type,
                            type: type,
                            filter: parseInt(parsedParams[key]![0]!),
                        };
                    }
                } else {
                    filterSet[schemaKey] = {
                        filterType: schema.type,
                        type: 'set',
                        values: parsedParams[key] as string[],
                    };
                }
            }
        });

    return filterSet;
};

export default formatFilters;
